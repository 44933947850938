/**
 * @file CreateGalleryImage.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
} from "@chakra-ui/react";

import useCreateGalleryImage from "hooks/GalleryImage/mutations/useCreateGalleryImage";
import useUploadImage from "hooks/useUploadImage";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import ErrorMsg from "Blueprints/ErrorMsg";

/**
 * @component CreateGalleryImage
 */
const CreateGalleryImage = ({ isOpen, onClose, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState([]);

	const handleSetImage = (imageFiles) => {
		setImage(imageFiles);
	};

	const  uploadImage = useUploadImage();
	const { createGalleryImage } = useCreateGalleryImage();

	const handleCreate = async (e) => {
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_url;
		try{
			image_url = await uploadImage(image[0]);
		} 
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading image. Please try again.");
			return; // Exit the function
		}
	
		const { data, error: createError } = await createGalleryImage({
			title,
			description,
			image: image_url
		});
	
		if (createError) {
			setLoading(false);
			setError(createError.message);
			return; // Exit the function
		} else if (data) {
			setLoading(false);
			onClose();
			refetch();
			return; // Exit the function
		} 
	
		setError("Unexpected error while creating gallery image. Please try again.");
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!title) {
			errors.title = "Title is required.";
		}
		if (!image || image.length === 0) {
			errors.image = "Image is required.";
		}
		setFormErrors(errors);
		//Return true if errors has no keys
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Gallery Image</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl id="title" isRequired
							isInvalid={formErrors.title}
						>
							<FormLabel>Title</FormLabel>
							<Input
								type="text"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.title}</FormErrorMessage>
						</FormControl>
						<FormControl id="description"
							isInvalid={formErrors.description}
						>
							<FormLabel>Description</FormLabel>
							<Input
								type="text"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.description}</FormErrorMessage>
						</FormControl>
						<FormControl id="image" isRequired
							isInvalid={formErrors.image}
						>
							<FormLabel>Image</FormLabel>
							<ImagesDropzone
								imageFiles={image}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						{
							error &&
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							onClick={handleCreate}
							variant="primary"
							isLoading={loading}
							loadingText="Creating..."
						>
							Create
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default CreateGalleryImage;