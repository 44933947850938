/** 
 * @file mutations.js is a file for all Contact mutations 
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} SEND_CONTACT_FORM_EMAIL - GraphQL mutation to send a contact form email.
 * 
 * @summary
 * - This mutation will send a contact form email and return true if successful.
 *
 * @param {Object} input - ContactFormInput! The input object for sending a contact form email.
 * @property {String} input.subject - The subject line of the email.
 * @property {Object} input.formData - The data from the contact form. This object can have the following properties:
 *  - {String} name (or firstName and lastName)
 * - {String} email
 * - {String} phone
 * - {String} message
 * 
 * @returns {Boolean}
 */
export const SEND_CONTACT_FORM_EMAIL = gql`
	mutation sendContactFormEmail($input: ContactFormInput!) {
		sendContactFormEmail(input: $input)
	}
`;
