/**
 * @file mutations.js is a file for all Admin mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_ADMIN - GraphQL mutation to create an admin.
 * 
 * @summary
 * - This mutation will create a new Admin and return the admin object if successful.
 * 
 * @param {Object} createAdminInput - The input object for creating an admin.
 * @property {String} createAdminInput.username - The username of the admin.
 * @property {String} createAdminInput.email - The email of the admin.
 * @property {String} createAdminInput.password - The hashed password of the admin.
 * @property {String} createAdminInput.firstName - The first name of the admin.
 * @property {String} createAdminInput.lastName - The last name of the admin.
 * @property {Object} createAdminInput.permissions - The permissions of the admin.
 */
export const CREATE_ADMIN = gql`
	mutation createAdmin($input: CreateAdminInput!) {
		createAdmin(input: $input) {
			_id
			username
			email
			firstName
			lastName
			accessLevel
		}
	}
`;

/**
 * @const {Object} LOGIN - GraphQL mutation to login an admin.
 * 
 * @summary
 * - This mutation will login an Admin and return the admin object if successful.
 * 
 * @param {Object} LoginInput - The input object for logging in an admin.
 * @property {String} LoginInput.email - The email of the admin.
 * @property {String} LoginInput.username - The username of the admin.
 * @property {String} LoginInput.password - The hashed password of the admin.
 * 
 * @returns {Object} - The admin object.
 */
export const LOGIN = gql`
	mutation login($input: LoginInput!) {
		login(input: $input) {
			_id
			username
			email
			firstName
			lastName
			accessLevel
			createdAt
			updatedAt
		}
	}
`;

/**
 * @const {Object} LOGOUT - GraphQL mutation to logout an admin.
 * 
 * @summary
 * - This mutation will logout an Admin and return the boolean true if successful.
*/
export const LOGOUT = gql`
	mutation logout {
		logout
	}
`;

/**
 * @const {Object} UPDATE_ADMIN_INFO - GraphQL mutation to update an admins info
 * 
 * @summary
 * - This mutation will update an Admins info and return the admin object if successful.
 * 
 * @param {String} adminId - The id of the admin.
 * @param {Object} updateAdminInfoInput - The input object for updating an admin.
 * @property {String} updateAdminInfoInput.email - The email of the admin.
 * @property {String} updateAdminInfoInput.firstName - The first name of the admin.
 * @property {String} updateAdminInfoInput.lastName - The last name of the admin.
 * @property {String} updateAdminInfoInput.password - The password of the admin.
 * 
 * @returns {Boolean} - True if successful. False if not.
 * 
 */
export const UPDATE_ADMIN_INFO = gql`
	mutation updateAdminInfo($adminId: String!, $input: UpdateAdminInfoInput!) {
		updateAdminInfo(adminId: $adminId, input: $input)
	}
`;

/**
 * @const {Object} UPDATE_ADMIN_PERMISSIONS - GraphQL mutation to update an admins permissions
 * 
 * @summary
 * - This mutation will update an Admins permissions and return the admin object if successful.
 * 
 * @param {String} adminId - The id of the admin.
 * @param {Object} updateAdminPermissionsInput - The input object for updating an admin.
 */
export const UPDATE_ADMIN_PERMISSIONS = gql`
	mutation updateAdminPermissions(
		$adminId: String!
		$accessLevel: [String!]!
	) {
		updateAdminPermissions(adminId: $adminId, accessLevel: $accessLevel)
	}
`;

/**
 * @const {Object} REQUEST_ADMIN_PASSWORD_RESET_EMAIL - GraphQL mutation to request a password reset email
 * 
 * @summary
 * - This mutation will send a password reset email to the admin and return the boolean true if successful.
 * 
 * @param {String} email - The email of the admin.
 * 
 * @returns {Boolean} - True if successful. False if not.
 */
export const REQUEST_ADMIN_PASSWORD_RESET_EMAIL = gql`
	mutation requestAdminPasswordResetEmail($email: String!) {
		requestAdminPasswordResetEmail(email: $email)
	}
`;

/**
 * @const {Object} RESET_ADMIN_PASSWORD - GraphQL mutation to reset an admins password
 * 
 * @summary
 * - This mutation will reset an Admins password and return the boolean true if successful.
 * 
 * @param {String} adminId - The id of the admin.
 * @param {String} token - The token of the admin.
 * @param {String} password - The password of the admin.
 * 
 * @returns {Boolean} - True if successful. False if not.
 */
export const RESET_ADMIN_PASSWORD = gql`
	mutation resetAdminPassword(
		$adminId: String!
		$resetToken: String!
		$password: String!
	) {
		resetAdminPassword(
			adminId: $adminId
			resetToken: $resetToken
			password: $password
		)
	}
`;


/**
 * @const {Object} DELETE_ADMIN - GraphQL mutation to delete an admin
 * 
 * @summary
 * - This mutation will delete an Admin and return the boolean true if successful.
 * 
 * @description
 * - Admin cannot be deleted if they are the only admin.
 * 
 * @param {String} adminId - The id of the admin.
 * 
 * @returns {Boolean} - True if successful. False if not.
 */
export const DELETE_ADMIN = gql`
	mutation deleteAdmin($adminId: String!) {
		deleteAdmin(adminId: $adminId)
	}
`;