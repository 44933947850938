import { Box, Image, Text, VStack } from "@chakra-ui/react";

/**
 * FeaturedDealCard component to render individual featured deals.
 *
 * @param {Object} deal - The featured deal object.
 * @returns {HTMLElement} - A Chakra UI Box component styled to display the deal.
 */
const FeaturedDealCard = ({ deal }) => {
	if (!deal) return null; // If no deal, render nothing
	return (
		<Box
			className="card_class"
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			p={4}
			boxShadow="md"
			w="sm"
		>
			<Image src={deal.images[0]} alt={deal.name} w="100%" h="200px" objectFit="cover" />
			<VStack spacing={2} align="start" mt={4}>
				<Text 
					fontWeight={{
						base: "semibold",
						md: "bold",
					}}
					fontSize={["sm", "md", "lg", "xl"]}
				>
					{deal.name}
				</Text>
				<Text
					fontSize={["xs", "sm", "md", "lg"]}
					noOfLines={3}
				>
					{deal.description}
				</Text>
			</VStack>
		</Box>
	);
};

export default FeaturedDealCard;
