import React from "react";
import { Box, Flex, Heading, Image, Button } from "@chakra-ui/react";

const Hero = (props) => {
	const {
		objectFit = "cover",
		objectPosition = "center",
		button = null,
		boxHeight = { base: "340px", md: "500px", lg: "600px" },
		boxPaddingY = { base: "28", md: "40", lg: "52" },
		subHeadingSize = { base: "lg", md: "xl", lg: "2xl" },
		searchBar: SearchBar,
		image_url,
		title,
		subtitle
	} = props;

	return (
		<Box
			bg="gray.500"
			as="section"
			position="relative"
			zIndex={0}
			w="100%"
			minH={boxHeight}
			overflow="hidden"
			alignItems="center"
			justifyContent="center"
			display="flex"
		>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="100%"
				overflow="hidden"
				align="center"
				zIndex={-1}
			>
				<Box position="relative" w="full" h="100%">
					<Image
						src={image_url}
						alt="Main Image"
						w="100%"
						h="100%"
						objectFit={objectFit}
						objectPosition={objectPosition}
						position="absolute"
						loading="eager"
					/>
					<Box position="absolute" w="full" h="100%" bg="#8B8589" opacity={0.28} />
				</Box>
			</Flex>
			<Box py={boxPaddingY} position="relative" zIndex={0}>
				<Box maxW="100%" mx="auto" px={{ base: "6", md: "8" }} color="white">
					<Box maxW="2xl" zIndex={10}>
						<Heading
							as="h1"
							size={{ base: "2xl", md: "3xl", lg: "4xl" }}
							fontWeight="extrabold"
							textShadow="3px 3px 3px rgba(0, 0, 0, 0.8)"
							color="white"
							bg="rgba(0, 0, 0, 0.0)"
							borderRadius="6px"
							pb={6} pt={4} px={4}
						>
							{title}
						</Heading>
						{subtitle && (
							<Heading
								as="h2"
								size={subHeadingSize}
								fontWeight="medium"
								textShadow="3px 3px 3px rgba(0, 0, 0, 0.8)"
								color="white"
								bg="rgba(0, 0, 0, 0.0)"
								borderRadius="6px"
								px={4}
								pb={6}
							>
								{subtitle}
							</Heading>
						)}
						{SearchBar && <SearchBar />}
						{button && (
							<Button {...button.props}>
								{button.text}
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Hero;
