/**
 * @file useGetStripeInvoiceLink.js
 */

import { useQuery } from "@apollo/client";
import { GET_STRIPE_INVOICE_LINK } from "cache/Invoice/queries"; // Update the path as per your project structure

/**
 * @hook useGetStripeInvoiceLink
 *
 * @description
 * Custom hook that handles getting a Stripe Checkout Session URL functionality.
 * Used to retrieve a Stripe Checkout Session URL for a specified invoice ID.
 *
 * @param {String} id - The ID of the invoice to get a Stripe Checkout Session URL for.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query, containing the Stripe Checkout Session URL
 * - refetch: Method to refetch the data
 * - networkStatus: Network status information
 */
const useGetStripeInvoiceLink = (id) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_STRIPE_INVOICE_LINK, {
		variables: {
			id
		}
	});
    
	let snaggedData = data?.getStripeInvoiceLink;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetStripeInvoiceLink;
