/**
 * @file UpdateReview.js
 */
import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	FormControl,
	Input,
	FormLabel,
	Button,
	Textarea,
	Stack
} from "@chakra-ui/react";

import useUpdateReview from "hooks/Review/mutations/useUpdateReview";

import ErrorMsg from "Blueprints/ErrorMsg";

import { convertDateToInputFormat } from "utils/utils";

const UpdateReview = ({ isOpen, onClose, review, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [input, setInput] = useState({
		author: review.author,
		reviewDate: convertDateToInputFormat(review.reviewDate),
		title: review.title,
		comment: review.comment,
		rating: review.rating,
	});

	useEffect(() => {
		setInput({
			author: review.author,
			reviewDate: convertDateToInputFormat(review.reviewDate),
			title: review.title,
			comment: review.comment,
			rating: review.rating,
		});
	}, [review]);


	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
		validateInput();
	};

	const { updateReview } = useUpdateReview();

	const validateInput = () => {
		if (input.author === "" && input.reviewDate === "" && input.title === "" && input.comment === "" && input.rating === "") {
			setErrorMsg("At least one field must be filled out.");
			return false;
		}
		try{
			parseFloat(input.rating);
		}
		catch(err) {
			setErrorMsg("Rating must be a number.");
			return false;
		}
		setErrorMsg(null);
		return true;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!validateInput()) return;
		setLoading(true);
		let updateReviewInput = {};
		if (input.author !== "") updateReviewInput.author = input.author;
		if (input.reviewDate !== "") updateReviewInput.reviewDate = input.reviewDate;
		if (input.title !== "") updateReviewInput.title = input.title;
		if (input.comment !== "") updateReviewInput.comment = input.comment;
		if (input.rating !== "") updateReviewInput.rating = parseFloat(input.rating);

		const { error } = await updateReview(review._id, updateReviewInput);
		if (error) {
			setErrorMsg(error.message);
		} else {
			await refetch();
			onClose();
		}
		setLoading(false);
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose}
			size="3xl"
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Review</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{errorMsg && <ErrorMsg error={errorMsg} />}
					<FormControl isRequired>
						<FormLabel>Author</FormLabel>
						<Stack spacing={4}>
							{errorMsg && <ErrorMsg error={errorMsg} />}
							<FormControl isRequired>
								<FormLabel>Author</FormLabel>
								<Input
									name="author"
									placeholder="Author"
									value={input.author}
									onChange={handleUpdateInput}
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Review Date</FormLabel>
								<Input
									name="reviewDate"
									placeholder="Review Date"
									value={input.reviewDate}
									onChange={handleUpdateInput}
									type="date"
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Title</FormLabel>
								<Input
									name="title"
									placeholder="Title"
									value={input.title}
									onChange={handleUpdateInput}
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Comment</FormLabel>
								<Textarea
									name="comment"
									placeholder="Comment"
									value={input.comment}
									onChange={handleUpdateInput}
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Rating</FormLabel>
								<Input
									name="rating"
									placeholder="Rating"
									value={input.rating}
									onChange={handleUpdateInput}
									type="number"
									min={0}
									max={5}
									step={0.1}		
								/>
							</FormControl>
							<Button
								variant="primary"
								type="submit"
								isLoading={loading}
								loadingText="Updating Review"
								onClick={handleSubmit}
							>
								Update Review
							</Button>
						</Stack>
					</FormControl>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default UpdateReview;
