import { Box, Button, Container, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";

export const HelpCenterFeature = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleContactUsClick = () => {
		onOpen();
	};

	return (
		<Box 
			as="section" 
			bg="bg-surface"
		>
			<Container 
				maxW="container.lg"
				py={2} 
				w="100%"
			>
				<Button
					onClick={handleContactUsClick}
					bg="#EF3737"
					color="white"
					borderRadius="md"
					borderWidth="1px"
					borderColor="gray.200"
					boxShadow="lg"
					_hover={{
						bg: "bg-primary-hover",
					}}
					_active={{
						bg: "bg-primary-active",
					}}
					_focus={{
						bg: "bg-primary-focus",
					}}
					transition="background-color 0.2s ease-in-out"
					fontSize={{
						base: "lg",
						md: "xl",
						lg: "2xl",
					}}
					fontWeight={{
						base: "semibold",
						lg: "bold",
					}}
					lineHeight="shorter"
					px={{
						base: "4",
						md: "6",
					}}
					py={{
						base: "4",
						md: "6",
					}}
					rounded="lg"
				>
					Contact Us
				</Button>
			</Container>
			<Modal 
				isOpen={isOpen} 
				onClose={onClose} 
				isCentered
				size={{
					base: "xl",
					md: "2xl",
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						p={4}
						textAlign="center"
						fontSize={{
							base: "lg",
							md: "xl",
							lg: "2xl",
						}}
						fontWeight={{
							base: "semibold",
							lg: "bold",
						}}
						lineHeight="shorter"
						color="#EF3737"
					>
						Contact Us
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						p={4}
						mt={{
							base: "4",
							md: "6",
						}}
						mb={{
							base: "4",
							md: "6",
						}}
						textAlign="center"
					>
						<Text
							fontSize={{
								base: "lg",
								md: "xl",
								lg: "2xl",
							}}
							fontWeight="semibold"
							lineHeight="shorter"
							color="gray.700"
						>
						Info@TaylorFenceNJ.com
						</Text>
						<br/>
						<Text
							fontSize={{
								base: "lg",
								md: "xl",
								lg: "2xl",
							}}
							fontWeight="semibold"
							lineHeight="shorter"
							color="gray.700"
						>
						(732) 747-5498
						</Text>
						<br/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
};

