/**
 * @file useGetAllBrochures.js
 */

import { useQuery } from "@apollo/client";
import { GET_ALL_BROCHURES } from "cache/Brochure/queries"; // Update the path as per your project structure

/**
 * @hook useGetAllBrochures
 *
 * @description
 * Custom hook that handles the functionality to retrieve all brochures.
 * Use this hook wherever the list of all brochures is required.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query, specifically the list of all brochures
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetAllBrochures = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_BROCHURES);
    
	let brochures = data?.getAllBrochures;

	return { loading, error, data: brochures, refetch, networkStatus };
};

export default useGetAllBrochures;
