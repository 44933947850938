/**
 * @file - Table.js
 */
import React, { useEffect, useState } from "react";

import {
	Button,
} from "@chakra-ui/react";

import UpdateReview from "./modals/UpdateReview";
import DeleteReview from "./modals/DeleteReview";

import { convertDateToReadableFormat } from "utils/utils";

import { TextCell, TableCell } from "../../components/TableCell";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";

/**
 * @component ReviewRow
 * 
 * @param {} props
 * @returns
 */
const ReviewRow = (props) => {

	const review = props.review;

	const [showUpdateReview, setShowUpdateReview] = useState(false);
	const openUpdateReviewModal = () => setShowUpdateReview(true);
	const closeUpdateReviewModal = () => setShowUpdateReview(false);

	const [showDeleteReview, setShowDeleteReview] = useState(false);
	const openDeleteReviewModal = () => setShowDeleteReview(true);
	const closeDeleteReviewModal = () => setShowDeleteReview(false);

	return (
		<RowContainer>
			<TextCell>{review.author}</TextCell>
			<TextCell>{convertDateToReadableFormat(review.reviewDate)}</TextCell>
			<TextCell>{review.title}</TextCell>
			<TextCell>{review.comment}</TextCell>
			<TextCell>{review.rating}</TextCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openUpdateReviewModal}
				>
					Update
				</Button>
				<Button
					variant="danger"
					onClick={openDeleteReviewModal}
				>
					Delete
				</Button>
			</TableCell>
			<UpdateReview
				isOpen={showUpdateReview}
				onClose={closeUpdateReviewModal}
				review={review}
				refetch={props.reviewsRefetch}
			/>
			<DeleteReview
				isOpen={showDeleteReview}
				onClose={closeDeleteReviewModal}
				review={review}
				refetch={props.reviewsRefetch}
			/>
		</RowContainer>
	);
};


/**
 * @component ReviewsTable
 */
const ReviewsTable = (props) => {

	const [reviews, setReviews] = useState(props.reviews);

	useEffect(() => {
		if(props.reviews) {
			setReviews(props.reviews);
		}
	}, [props.reviews]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("author", order)}
					search={(value) => props.search("author", value)}
					clearToggle={props.clearToggle}
				>
					Author
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("reviewDate", order)}
					search={(value) => props.search("reviewDate", value)}
					clearToggle={props.clearToggle}
				>
					Date
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("title", order)}
					search={(value) => props.search("title", value)}
					clearToggle={props.clearToggle}
				>
					Title
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("comment", order)}
					search={(value) => props.search("comment", value)}
					clearToggle={props.clearToggle}
				>
					Comment
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("rating", order)}
					search={(value) => props.search("rating", value)}
					clearToggle={props.clearToggle}
				>
					Rating
				</TextCell>
				<TextCell>
					Actions
				</TextCell>
			</HeaderContainer>
			{
				reviews?.map((review, index) => {
					return (
						<ReviewRow
							key={index}
							review={review}
							reviewsRefetch={props.reviewsRefetch}
						/>
					);
				})
			}
		</TableContainer>
	);

};

export default ReviewsTable;