/**
 * @file useReorderFeaturedDeals.js
 */

import { useMutation } from "@apollo/client";
import { REORDER_FEATURED_DEALS } from "cache/FeaturedDeal/mutations"; // Update the path as per your project structure

/**
 * @hook useReorderFeaturedDeals
 * 
 * @description
 * Custom hook that handles the functionality of reordering featured deals.
 * This hook can be utilized whenever there's a need to reorder the deals 
 * in the application, ensuring that the latest ordering is reflected in the 
 * backend.
 *
 * @returns
 * - reorderFeaturedDeals: Function to execute the reorder featured deals mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderFeaturedDeals = () => {

	const [reorderMutation, { loading, error }] = useMutation(REORDER_FEATURED_DEALS);

	/**
	 * Executes the reorder mutation.
	 *
	 * @param {Array} input - An array of objects describing how to reorder the deals.
	 * Each object should have the required fields needed for the ReorderInput type.
	 *
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderFeaturedDeals = async (input) => {
		try {
			const { data } = await reorderMutation({
				variables: {
					input
				}
			});
			
			if (error) {
				throw new Error(error);
			}

			return { data: data.reorderFeaturedDeals };
		} catch (error) {
			return { error };
		}
	};

	return { reorderFeaturedDeals, loading, error };
};

export default useReorderFeaturedDeals;
