/**
 * @file useGetAllReviews.js
 */

import { useQuery } from "@apollo/client";
import { GET_ALL_REVIEWS } from "cache/Review/queries"; // Update the path as per your project structure

/**
 * @hook useGetAllReviews
 *
 * @description
 * Custom hook that handles getting all reviews from the Review collection.
 * This hook is typically used in components that display a list of reviews.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data containing an array of review objects
 * - refetch: Method to refetch the data
 * - networkStatus: Network status information
 */
const useGetAllReviews = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_REVIEWS);
	
	// Extract the data specific to the getAllReviews query
	let reviewsData = data?.getAllReviews;

	return { loading, error, data: reviewsData, refetch, networkStatus };
};

export default useGetAllReviews;
