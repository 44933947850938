import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";
import { AdminContext } from "contexts/AdminContext";
import { Flex, Stack, Heading, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FiUser, FiUsers, FiList, FiLogOut, FiTag, FiAward, FiInfo, FiFileText, FiImage, FiThumbsUp, FiBookOpen, FiUserPlus } from "react-icons/fi";
import { NavButton } from "./NavButton";
import useAdminLogout from "hooks/Admin/mutations/useAdminLogout";
import { useApolloClient } from "@apollo/client";

import { PERMISSION_TO_ACCESS_LEVEL } from "../Pages/Admins/accessLevels";

const Sidebar = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { selectedTab, handleTabChange } = props;
	const client = useApolloClient();
	const { handlePageChange } = useContext(PageChangeContext);
	const { admin, adminRefetch } = useContext(AdminContext);

	const handleTabClick = (tab) => {
		handleTabChange(tab);
	};

	const showButton = (permission) => {
		if (admin) {
			if (admin.accessLevel.includes("Admin")) {
				return true;
			} else {
				if (admin.accessLevel.includes(PERMISSION_TO_ACCESS_LEVEL[permission])) {
					return true;
				}
			}
		}
		return false;
	};

	const allButtonData = [
		{ value: "categories", label: "Categories", icon: FiList, permission: "Category" },
		{ value: "products", label: "Products", icon: FiTag, permission: "Product" },
		{ value: "deals", label: "Featured Deals", icon: FiAward, permission: "Deal" },
		{ value: "customers", label: "Customers", icon: FiUsers, permission: "Customer" },
		{ value: "invoices", label: "Invoices", icon: FiFileText, permission: "Invoice" },
		{ value: "gallery", label: "Gallery", icon: FiImage, permission: "Gallery" },
		{ value: "reviews", label: "Reviews", icon: FiThumbsUp, permission: "Review" },
		{ value: "brochures", label: "Brochures", icon: FiBookOpen, permission: "Brochure" },
		{ value: "partners", label: "Partners", icon: FiUserPlus, permission: "Partner" },
	];

	const buttonData = allButtonData.filter((button) => showButton(button.permission));



	const bottomButtonData = [
		{ value: "documentation", label: "Documentation", icon: FiInfo},
		{ value: "logout", label: "Logout", icon: FiLogOut },
	];

	if (showButton("Admin")) {
		bottomButtonData.unshift({ value: "admins", label: "Admins", icon: FiUser });
	}

	const { logout } = useAdminLogout();

	const handleBottomClick = async (value) => {
		if (value === "logout") {
			let { error } = await logout();
			if (error) alert(error.message);
			handlePageChange("admin/dashboard");
			const { data } = await adminRefetch();
			if (data) {
				await client.resetStore();
			}
		} else if (value === "admins") {
			handleTabClick("admins");
		} else if (value === "documentation") {
			handleTabClick("documentation");
		}
	};

	return (
		<>
			<IconButton 
				aria-label='Open Menu' 
				size='lg' 
				icon={<HamburgerIcon />} 
				onClick={onOpen} 
				display={{ md: "none" }} 
				position='fixed'
				top='0.20rem'
				left='0.20rem'
				bottom='0.20rem'
				zIndex='999'
				boxShadow='lg'
				borderRadius='md'
				borderColor='gray.400'
				borderWidth='1px'
			/>
			<Drawer isOpen={isOpen} placement='left' onClose={onClose} motionPreset="slideInLeft" transition={{duration: 1.8, ease: [0.68, -0.55, 0.27, 1.55], }}>
				<DrawerOverlay>
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader 
							borderBottomWidth='1px'
							borderBottomColor='gray.200'
							py={4}
							alignSelf='center'
						>
							Admin Panel
						</DrawerHeader>
						<DrawerBody>
							<Stack spacing='4' mt="auto" mb="auto">
								{buttonData.map((button, idx) => (
									<NavButton key={idx} {...button} isActive={selectedTab === button.value} handleClick={() => { handleTabChange(button.value); onClose(); }} />
								))}
							</Stack>

							<Flex justifyContent="flex-end" flexDir="column" minH="auto" h="auto" mt="auto" mb="auto">
								<Stack spacing='5' mt={4} borderTopWidth='1px' borderTopColor='gray.200' pt={4}>
									{bottomButtonData.map((button, idx) => (
										<NavButton key={idx} {...button} isActive={selectedTab === button.value} handleClick={() => { handleBottomClick(button.value); onClose(); }} />
									))}
								</Stack>
							</Flex>
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
			<Flex
				h="100vh"
				flexDir="column"
				maxW="100%"
				bg="bg.canvas"
				borderRightColor="gray.200"
				borderRightWidth="1px"
				overflowY="auto"
				transition=".3s ease"
				display={{ base: "none", md: "flex" }}
		
			>
				<Flex 
					flexDir="column" 
					flex="1" 
					bg="bg.surface" 
					boxShadow="sm" 
					w="100%"
					justifyContent="space-between"
					py={{
						base: 0,
						sm: 0,
						md: 8,
						lg: 4,
						xl: 4,
					}}
					px={{
						base: 0,
						sm: 6,
						md: 4,
						lg: 4,
						xl: 4,
					}}
				>
					<Stack 
						spacing="4"
					>
						<Heading 
							as="h1" 
							size="md" 
							fontWeight="bold" 
							color="fg.default" 
							alignSelf="center" 
							borderBottomColor="gray.200" 
							borderBottomWidth="1px" 
							pb="4"
						>
						Admin Panel
						</Heading>
						{buttonData.map((button, idx) => (
							<NavButton 
								key={idx} 
								{...button} 
								isActive={selectedTab === button.value} 
								handleClick={handleTabChange} 
							/>
						))}
					</Stack>
					<Flex 
						flexDir="column"
						w="100%" 
						h="100%"
						mt="auto"
						mb="auto"
						justifyContent="flex-end"
					>
						<Stack 
							spacing="4"
							borderTopColor="gray.200"
							borderTopWidth="1px"
							pt="4"
						>
							{bottomButtonData.map((button, idx) => (
								<NavButton 
									key={idx} 
									{...button} 
									isActive={selectedTab === button.value} 
									handleClick={handleBottomClick} 
								/>
							))}
						</Stack>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default Sidebar;
