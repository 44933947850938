/**
 * @file useGetAllCustomers.js
 */

import { useQuery } from "@apollo/client";
import { GET_ALL_CUSTOMERS } from "cache/Customer/queries"; // Update the path as per your project structure

/**
 * @hook useGetAllCustomers
 *
 * @description
 * Custom hook that handles the functionality to retrieve all customers.
 * This hook can be used wherever the application needs to fetch the list of all customers.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query containing an array of customer objects
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetAllCustomers = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_CUSTOMERS);
    
	let allCustomers = data?.getAllCustomers;

	return { loading, error, data: allCustomers, refetch, networkStatus };
};

export default useGetAllCustomers;
