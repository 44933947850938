import React from "react";

import {
	Box,
	Image,
	Text,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

const MotionBox = motion(Box);

const BrochureDisplay = ({ brochure }) => {

	return (
		<MotionBox
			p={4}
			mb={6}
			boxShadow="lg"
			borderRadius="md"
			borderColor="gray.400"
			borderWidth="1px"
			_hover={{
				textDecoration: "none",
				transition: "all 0.2s ease-in-out",
				transform: "scale(1.025) translateY(-2px)",
				cursor: "pointer",
			}}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
			as="a" // Make it a link
			href={brochure.url} // Set the brochure URL
			target="_blank" // Open in a new tab
			rel="noopener noreferrer" // Security measure
		>
			<Image src={brochure.image} alt={brochure.name} mb={4} borderRadius="md" w="100%" h="100%" />
			<Text>{brochure.name}</Text>
		</MotionBox>
	);

};

export default BrochureDisplay;