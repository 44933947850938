import React from "react";
import CategoryRow from "./CategoryRow";

import { TableContainer, HeaderContainer } from "../../components/TableContainer";
import { TextCell } from "../../components/TableCell";
const CategoryTable = ({ categories, refetch }) => {
	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell>Image</TextCell>
				<TextCell>Descriptor</TextCell>
				<TextCell>Name</TextCell>
				<TextCell>Actions</TextCell>
			</HeaderContainer>
			{categories.map((category) => (
				<CategoryRow key={category._id} category={category} depth={0} descriptor={"Material"} refetch={refetch} />
			))}
		</TableContainer>
	  );
};
	
export default CategoryTable;
