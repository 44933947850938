/**
 * @file queries.js is a file for all Featured Deal queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_FEATURED_DEALS - GraphQL query to get all featured deals.
 *
 * @summary
 * - This query will return an array of featured deals.
 * 
 * @returns
 * - _id: ID
 * - name: String
 * - description: String
 * - images: [String]
 * - expires: String
 * - createdAt: String
 * - updatedAt: String
 */
export const GET_FEATURED_DEALS = gql`
	query getAllFeaturedDeals {
		getAllFeaturedDeals {
			_id
			name
			description
			images
			expires
			createdAt
			updatedAt
			order
		}
	}
`;
