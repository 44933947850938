import React, { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import {
	Center,
	Image,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

const MotionImage = motion(Image);

const item = {
	hidden: { y: 20, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
	},
};


const PartnerDisplay = ({ partner }) => {

	const { handlePageChange } = useContext(PageChangeContext);

	const handlePartnerClick = (partner) => {
		handlePageChange(`showroom/partner/${partner._id}`);
	};

	return (
		<Center
			onClick={() => handlePartnerClick(partner)} // open the modal when the logo is clicked
			bgImage={`url(${partner?.images[0]})`}
			bgSize="100% 100%"
			bgPosition="center 150%"
			p={4}
			borderRadius="lg"
			boxShadow={{
				base: "lg",
				md: "lg",
			}}
			borderWidth={{
				base: "1px",
				md: "1px",
			}}
			borderColor="gray.400"
			transition="all 0.4s cubic-bezier(.08,.52,.52,1)"
			_hover={{ 
				transform: "scale(1.05)",
				cursor: "pointer", 
			}}
			//bg="transparent"
		>
			<MotionImage
				src={partner.image}
				alt="logo"
				variants={item}
				initial="hidden"
				animate="visible"
			/>
		</Center>

	);
};

export default PartnerDisplay;
