/**
 * @file
 * This is the ContactPage component.
 * It is used to render the Contact Page of the website.
 *
 * @summary Render function for the Contact page.
 */

import React from "react";
import Hero from "Blueprints/Hero";
import MotionPage from "Blueprints/MotionPage";
import ContactForm from "./components/contactForm";
import Contact from "assets/TaylorFenceImg/PVC_SpacedPicket1.png";
import "./Contact.scss";



/**
 * ContactPage component.
 * This component returns a JSX element that renders the Contact page.
 * It consists of a Hero component with the page title and image,
 * and a ContactForm component inside a wrapper for user to reach out.
 *
 * @returns {HTMLElement} A MotionPage component with Hero and ContactForm components.
 */

const ContactPage = () => {
	return (
		<MotionPage>
			<div
				className="contact"
			>
				<Hero
					title="Contact Us"
					image_url={Contact}
					objectPosition="center 30%"
					imageHeight={{
						base: "300px",
						md: "400px",
					}}
					imageWidth="100%"
					boxHeight="300px"
					boxPaddingY="10"
				/>
				<div
					className="contact-form-wrapper"
				>
					<ContactForm />
				</div>
			</div>
		</MotionPage>
	);
};

export default ContactPage;
