/**
 * @file RefundInvoice.js is responsible for rendering the refund invoice modal
 */
import React, { useState } from "react";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useRefundInvoice from "hooks/Invoice/mutations/useRefundInvoice";

/**
 * @function RefundInvoice
 * 
 * @summary - Modal to refund an invoice
 */
const RefundInvoice = ({ isOpen, onClose, invoice, refetch }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { refundInvoice } = useRefundInvoice();

	/**
	 * @function handleRefundInvoice
	 * 
	 * @description - This function is used to refund an invoice from the database.
	 */
	const handleRefundInvoice = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await refundInvoice(invoice._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		refetch();
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Refund Invoice</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text mb={3}>
						Are you sure you want to refund this invoice?
					</Text>
					<Text mb={3}>
						Please type <strong>REFUND</strong> to confirm.
					</Text>
					<Input
						placeholder="Type REFUND to confirm"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
					{errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

				</ModalBody>

				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						isLoading={loading}
						onClick={handleRefundInvoice}
						isDisabled={confirmText !== "REFUND"}
					>
						Refund Invoice
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default RefundInvoice;
