/**
 * @file useUpdatePartner.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_PARTNER } from "cache/Partner/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdatePartner
 *
 * @description
 * Custom hook that handles the functionality to update an existing partner.
 * Use this hook wherever updating a partner's details is required.
 *
 * @returns
 * - updatePartner: Handler to execute the updatePartner mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdatePartner = () => {

	const [updatePartnerMutation, { loading, error }] = useMutation(UPDATE_PARTNER);

	/**
	 * Custom mutation handler for updating a partner.
	 *
	 * @param {String} _id - The ID of the partner to be updated.
	 * @param {Object} input - Input object containing the new details of the partner.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updatePartner = async (_id, input) => {
		try {
			const { data } = await updatePartnerMutation({
				variables: {
					_id,
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.updatePartner };
		} catch (error) {
			return { error };
		}
	};

	return { updatePartner, loading, error };

};

export default useUpdatePartner;
