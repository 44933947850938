/**
 * @file useUploadPDF.js
 */
import useGetS3Url from "./s3/mutations/useGetS3Url";

/**
 * @hook useUploadPDF
 *
 * @description
 * Custom hook that handles PDF upload functionality.
 *
 * @returns
 * - uploadPDF function
 */
const useUploadPDF = () => {

	const { getS3Url } = useGetS3Url();

	const uploadPDF = async (pdfFile) => {
		let pdf_url = "";

		let response = await getS3Url(true);
		if(response.error){
			throw new Error(response.error);
		}
		if (response.url) {
			let url = response.url;
			const result = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/pdf",
				},
				body: pdfFile,
			});
			if(!result.ok){
				throw new Error("PDF upload failed");
			}
			pdf_url = url.split("?")[0];
		}

		return pdf_url;
	};

	return uploadPDF;
};

export default useUploadPDF;
