/**
 * @file useReorderPartners.js
 */

import { useMutation } from "@apollo/client";
import { REORDER_PARTNERS } from "cache/Partner/mutations"; // Update the path as per your project structure

/**
 * @hook useReorderPartners
 * 
 * @description
 * Custom hook that handles the functionality of reordering partners.
 * It can be utilized whenever there's a need to reorder the partners
 * in a specific order, ensuring that the latest ordering is reflected in the 
 * backend.
 *
 * @returns
 * - reorderPartners: Function to execute the reorder partners mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderPartners = () => {

	const [reorderMutation, { loading, error }] = useMutation(REORDER_PARTNERS);

	/**
	 * Executes the reorder mutation.
	 *
	 * @param {Array} input - An array of objects describing how to reorder the partners.
	 * Each object should have the required fields needed for the ReorderInput type.
	 *
	 * @returns {Object} - An object containing the mutation result (true/false) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderPartners = async (input) => {
		try {
			const { data } = await reorderMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// As the mutation returns a Boolean, it's processed accordingly.
			return { data: data.reorderPartners };
		} catch (error) {
			return { error };
		}
	};

	return { reorderPartners, loading, error };
};

export default useReorderPartners;
