/**
 * @file Table.js is a file for the Gallery Table component.
 */
import React, { useState, useEffect } from "react";

import {
	Button,
	Image
} from "@chakra-ui/react";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

import UpdateGalleryImage from "./modals/UpdateGalleryImage";
import DeleteGalleryImage from "./modals/DeleteGalleryImage";

/**
 * @component GalleryRow
 */
const GalleryRow = (props) => {

	const galleryImage = props.galleryImage;

	const [showUpdateGalleryImage, setShowUpdateGalleryImage] = useState(false);
	const openUpdateGalleryImageModal = (e) => {
		e.stopPropagation();
		setShowUpdateGalleryImage(true);
	};
	const closeUpdateGalleryImageModal = () => setShowUpdateGalleryImage(false);

	const [showDeleteGalleryImage, setShowDeleteGalleryImage] = useState(false);
	const openDeleteGalleryImageModal = (e) => {
		e.stopPropagation();
		setShowDeleteGalleryImage(true);
	};
	const closeDeleteGalleryImageModal = () => setShowDeleteGalleryImage(false);

	return (
		<RowContainer>
			<TextCell>{galleryImage.title}</TextCell>
			<TextCell>{galleryImage.description}</TextCell>
			<TableCell>
				<Image
					src={galleryImage.image}
					alt={galleryImage.title}
					maxH={{
						base: "auto",
						md: "100px",
					}}
					maxW={{
						base: "auto",
						md: "100px",
					}}
					objectFit="cover"
					borderRadius="lg"
					borderWidth="1px"
					borderColor="#CBD5E0"
				/>
			</TableCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openUpdateGalleryImageModal}
					mr={2}
				>
					Update
				</Button>
				<Button variant="danger" onClick={openDeleteGalleryImageModal}>
					Delete
				</Button>
			</TableCell>
			<UpdateGalleryImage
				isOpen={showUpdateGalleryImage}
				onClose={closeUpdateGalleryImageModal}
				galleryImage={galleryImage}
				refetch={props.refetch}
			/>
			<DeleteGalleryImage
				isOpen={showDeleteGalleryImage}
				onClose={closeDeleteGalleryImageModal}
				galleryImage={galleryImage}
				refetch={props.refetch}
			/>
		</RowContainer>
	);
};

/**
 * @component GalleryTable
 * 
 * @returns GalleryTable component.
 */
const GalleryTable = (props) => {
	const [galleryImages, setGalleryImages] = useState(props.galleryImages);

	useEffect(() => {
		if (props.galleryImages) setGalleryImages(props.galleryImages);
	}, [props.galleryImages]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("title", order)}
					search={(value) => props.search("title", value)}
					clearToggle={props.clearToggle}
				>
					Title
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("description", order)}
					search={(value) => props.search("description", value)}
				>
					Description
				</TextCell>
				<TextCell>Image</TextCell>
				<TextCell>Actions</TextCell>
			</HeaderContainer>
			{galleryImages.map((galleryImage) => (
				<GalleryRow
					key={galleryImage._id}
					galleryImage={galleryImage}
					refetch={props.galleryImagesRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default GalleryTable;
