/**
 * @file CreateBrochure.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useToast
} from "@chakra-ui/react";

import useCreateBrochure from "hooks/Brochure/mutations/useCreateBrochure";
import useUploadImage from "hooks/useUploadImage";
import useUploadPDF from "hooks/useUploadPDF";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";
import { PDFDropzone } from "Blueprints/Dropzones/PDFDropzone";

import ErrorMsg from "Blueprints/ErrorMsg";

/**
 * @component CreateBrochure
 */
const CreateBrochure = ({ isOpen, onClose, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [name, setName] = useState("");
	const [image, setImage] = useState([]);
	const [pdf, setPDF] = useState(null);

	const handleSetImage = (imageFiles) => {
		setImage(imageFiles);
	};

	const handleSetPDF = (pdfFile) => {
		setPDF(pdfFile);
	};

	const handleClose = () => {
		setName("");
		setImage([]);
		setPDF(null);
		setError(null);
		setLoading(false);
		onClose();
	};

	const uploadImage = useUploadImage();
	const uploadPDF = useUploadPDF();
	const { createBrochure } = useCreateBrochure();

	const toast = useToast();

	const handleCreate = async (e) => {
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_url;
		try{
			image_url = await uploadImage(image[0]);
		} 
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading image. Please try again.");
			return; // Exit the function
		}

		let pdf_url;
		try{
			pdf_url = await uploadPDF(pdf);
		}
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading PDF. Please try again.");
			return; // Exit the function
		}

		const { data, error } = await createBrochure({
			name,
			image: image_url,
			url: pdf_url
		});
		if(error){
			setLoading(false);
			setError(error.message);
			return; // Exit the function
		}
		if(data){
			setLoading(false);
			toast({
				title: "Brochure created.",
				description: "Brochure successfully created.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			handleClose();
			refetch();
			return; // Exit the function
		}
		setError("Unexpected error while creating gallery image. Please try again.");
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!name) {
			errors.name = "Name";
		}
		if (!image || image.length === 0) {
			errors.image = "Image is required.";
		}
		if (!pdf) {
			errors.pdf = "PDF is required.";
		}
		setFormErrors(errors);
		//Return true if errors has no keys
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Brochure</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="image" 
							isRequired
							isInvalid={formErrors.image}
						>
							<FormLabel>Image</FormLabel>
							<ImagesDropzone
								imageFiles={image}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="pdf" 
							isRequired
							isInvalid={formErrors.pdf}
						>
							<FormLabel>PDF</FormLabel>
							<PDFDropzone
								pdfFile={pdf}
								setPDFFile={handleSetPDF}
							/>
							<FormErrorMessage>{formErrors.pdf}</FormErrorMessage>
						</FormControl>
						{
							error &&
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							onClick={handleCreate}
							variant="primary"
							isLoading={loading}
							loadingText="Creating..."
						>
							Create
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default CreateBrochure;