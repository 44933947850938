/**
 * @file DashboardController.js
 */
import React, { useState, useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import {
	Flex,
} from "@chakra-ui/react";


import Sidebar from "./Sidebar/Sidebar";

import Welcome from "./Pages/Welcome";
import Admins from "./Pages/Admins/Admins";
import Documentation from "./Pages/Guide/Documentation";
import Categories from "./Pages/Categories/Categories";
import Products from "./Pages/Products/Products";
import FeaturedDeals from "./Pages/FeaturedDeals/FeaturedDeals";
import Customers from "./Pages/Customers/Customers";
import Invoices from "./Pages/Invoices/Invoices";
import Gallery from "./Pages/Gallery/Gallery";
import Reviews from "./Pages/Reviews/Reviews";
import Brochures from "./Pages/Brochures/Brochures";
import Partners from "./Pages/Partners/Partners";

import { Routes, Route } from "react-router-dom";

/**
 * @component DashboardController
 * 
 * @param {Object} props
 */
const DashboardController = () => {

	const { page, handlePageChange } = useContext(PageChangeContext);

	let currentPage = page.split("/").pop();

	const [adminTab, setAdminTab] = useState(currentPage);

	/**
	 * Handle the tab change.
	 * Set the adminTab state and call handlePageChange with the appropriate page.
	 * 
	 * @param {string} tab - The tab that was clicked.
	 */
	const handleTabChange = (tab) => {
		setAdminTab(tab);
		handlePageChange("admin/dashboard/" + tab);
	};

	return (
		<Flex
			flexDir={{
				base: "column",
				sm: "row"
			}}
			justify="flex-start"
			align="flex-start"
			w="100%"
			h="100%"
	  >
			<Sidebar
				selectedTab={adminTab}
				handleTabChange={handleTabChange}
				h="100%"
			/>
			<Flex
				w={{ base: "100%", md: "80%" }}
				h="100%"
				mx="auto"
			>
				<Routes>
					<Route path="/*" element={<Welcome />} />
					<Route path="/admins/*" element={<Admins />} />
					<Route path="/documentation/*" element={<Documentation />} />
					<Route path="/categories/*" element={<Categories />} />
					<Route path="/products/*" element={<Products />} />
					<Route path="/deals/*" element={<FeaturedDeals />} />
					<Route path="/customers/*" element={<Customers />} />
					<Route path="/invoices/*" element={<Invoices />} />
					<Route path="/gallery/*" element={<Gallery />} />
					<Route path="/reviews/*" element={<Reviews />} />
					<Route path="/brochures/*" element={<Brochures />} />
					<Route path="/partners/*" element={<Partners />} />
				</Routes>
		  </Flex>
		</Flex>
	  );
};
	
export default DashboardController;