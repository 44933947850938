import React from "react";
import MotionPage from "Blueprints/MotionPage";
import { motion } from "framer-motion";
import BrochureSelect from "../modals/Brochures/BrochuresModal";


const Brochures = () => {
	// Add the motion values, animate, exit and variants props as per your design.
	const motionProps = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<MotionPage>
			<div
				className="resources"
			>
				<motion.div
					{...motionProps}
				>
					<BrochureSelect />
				</motion.div>
			</div>
		</MotionPage>
	);
};

export default Brochures;