/**
 * @file ImagesDropzone.js is a file that contains the dropzone component.
 */
import React, { useState, useRef } from "react";

import {
	Button,
	Center,
	HStack,
	Icon,
	Square,
	Text,
	useColorModeValue,
	VStack,
	Input,
	Box,
	Collapse,
	Flex,
	Grid,
	Image,
} from "@chakra-ui/react";

import { FiUploadCloud } from "react-icons/fi";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
  
const MAX_IMAGE_SIZE = 2 * 1024 * 1024; //2MB
/**
 * @component ImagesDropzone
 * 
 * @description - A dropzone component that allows the user to upload images
 * 
 * @param {*} props
 * @param {Array} props.imageFiles - An array of image files
 * @param {Function} props.setImageFiles - A function to set the image files
 * @param {Boolean} props.allowMultiple - A boolean to allow multiple files to be uploaded
 * @returns 
 */
export const ImagesDropzone = (props) => {

	const [showImages, setShowImages] = useState(false); //Boolean to show the images
    
	const files = props.imageFiles; //Files is an array of image files

	const fileInputRef = useRef(); //Reference to the file input
	const handleFileInputClick = () => fileInputRef.current.click(); //Function to handle the file input click

	/**
   * @function onFilesAdded
   * 
   * @description - A function to handle the files added
   * 
   * @param {*} evt 
   */
	const onFilesAdded = (evt) => {
		let filesAdded = Array.from(evt.target.files);
		for(let i = 0; i < filesAdded.length; i++){
			//Check that file size is less than max file size
			if(filesAdded[i].size > MAX_IMAGE_SIZE){
				alert(`File ${filesAdded[i].name} is too large. Please upload a file less than 2MB.`);
				filesAdded.splice(i, 1);
				i--;
			}
		}
		if(props.allowMultiple && files){
			filesAdded = files.concat(filesAdded);
		}
		props.setImageFiles(filesAdded);
	};

	/**
   * @function handleDragOver
   * 
   * @description - A function to handle the drag over
   * 
   * @param {*} evt
   */
	const handleDragOver = (evt) => {
		evt.preventDefault();
	};

	/**
   * @function handleDrop
   * 
   * @description 
   * - A function to handle the drop of the files
   * 
   * @param {*} evt 
   */
	const handleDrop = (evt) => {
		evt.preventDefault();
		let filesAdded = [];

		if (evt.dataTransfer.items) {
			for (let i = 0; i < evt.dataTransfer.items.length; i++) {
				if (evt.dataTransfer.items[i].kind === "file") {
					const file = evt.dataTransfer.items[i].getAsFile();
					//Make sure the file is an image
					if(file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/webp"){
						//Check that file size is less than max file size
						if(file.size > MAX_IMAGE_SIZE){
							alert(`File ${file.name} is too large. Please upload a file less than 2MB.`);
							continue;
						}
						filesAdded.push(file);
					}
					else{
						alert(`File ${file.name} is not a valid image in the required format (.png, .jpg, .jpeg).`);
					}
				}
			}
		} else {
			filesAdded = Array.from(evt.dataTransfer.files);
		}
		if(props.allowMultiple && files){
			filesAdded = files.concat(filesAdded);
		}
		props.setImageFiles(filesAdded);
	};


	/**
   * @function handleDeleteImage
   * 
   * @description
   * - A function to handle the delete of an image that was uploaded and is currently in the dropzone
   * 
   * @param {*} file - The file to delete
   */
	const handleDeleteImage = (file) => {
		let newFiles = [...files];
		let index = newFiles.indexOf(file);
		if(index !== -1){
			newFiles.splice(index, 1);
			props.setImageFiles(newFiles);
		}
	};

	return(
		<Center
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			borderWidth="1px"
			borderRadius="lg"
			borderColor="gray.300"
			boxShadow="md"
			px="6"
			py="6"
			bg={useColorModeValue("white", "gray.800")}
		>
			<VStack spacing="3">
				<Square size="10" bg="bg-subtle" borderRadius="lg">
					<Icon as={FiUploadCloud} boxSize="5" color="muted" />
				</Square>
				<VStack spacing="1">
					<HStack spacing="1" whiteSpace="nowrap">
						<Button 
							variant="link"
							size="sm"
							onClick={handleFileInputClick}
						>
              				Click to upload
						</Button>
						<Text fontSize="sm" color="muted">
             				or drag and drop
						</Text>
						<Input
							type="file"
							ref={fileInputRef}
							onChange={onFilesAdded}
							accept=".png, .jpg, .jpeg, .webp"
							multiple={props.allowMultiple}
							style={{ display: "none" }}
						/>
					</HStack>
					<Text fontSize="xs" color="muted">
            			PNG, JPG, or WEBP up to 2MB
					</Text>
				</VStack>
				{files.length > 0 && (
					<Flex 
						direction="column"
						marginTop="4"
						px="4"
						w="100%"
						justifyContent="center"
						alignItems="center"
						rowGap="1rem"
					>
						<Button
							variant="primary"
							size="sm"
							onClick={() => setShowImages(!showImages)}
							rightIcon={showImages ? <ChevronUpIcon /> : <ChevronDownIcon />}
						>
							{showImages ? "Hide" : "Show"} Uploaded Image{(props.allowMultiple && files.length > 1) ? "s" : ""}
						</Button>
						<Collapse 
							in={showImages} 
							animateOpacity
							style={{width: "100%"}}  
						>
							<Flex
								direction="column"
								w="100%"
								h="100%"
								justifyContent="center"
								alignItems="center"
							>
								<Grid
									templateColumns={{base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} 
									gap={6}
								>
									{files.map((file, index) => (
										<Box key={index} position="relative">
											<Image
												src={URL.createObjectURL(file)}
												alt={file.name}
												objectFit="cover"
												w="100%"
												h="100%"
												borderRadius="lg"
											/>
											<Button
												position="absolute"
												right="5px"
												top="5px"
												size="sm"
												variant="delete"
												onClick={() => handleDeleteImage(file)}
											>
                        						Delete
											</Button>
										</Box>
									))}
								</Grid>
							</Flex>
						</Collapse>
					</Flex>
				)}
			</VStack>
		</Center>
	);
};