/**
 * @file useDeleteGalleryImage.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_GALLERY_IMAGE } from "cache/GalleryImage/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteGalleryImage
 *
 * @description
 * Custom hook that manages the functionality of deleting a gallery image.
 * This hook is used in components where a gallery image needs to be deleted.
 *
 * @returns
 * - deleteGalleryImageExecute: Method to initiate the delete gallery image mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object, if there is an error
 */
const useDeleteGalleryImage = () => {

	// Initialize the Apollo useMutation hook
	const [deleteGalleryImageMutation, { loading, error }] = useMutation(DELETE_GALLERY_IMAGE);

	/**
	 * Method to execute the delete gallery image mutation.
	 *
	 * @param {String} id - The ID of the gallery image to be deleted.
	 * @returns {Object} - An object containing a Boolean indicating the mutation result or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteGalleryImage = async (id) => {
		try {
			const { data } = await deleteGalleryImageMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			return { data: data.deleteGalleryImage };
		} catch (error) {
			return { error };
		}
	};

	return { deleteGalleryImage, loading, error };

};

export default useDeleteGalleryImage;
