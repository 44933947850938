/**
 * @file useDeleteAdmin.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_ADMIN } from "cache/Admin/mutations"; // Make sure to update the path to the correct location of DELETE_ADMIN mutation

/**
 * @hook useDeleteAdmin
 *
 * @description
 * Custom hook that handles deleting an admin.
 *
 * @returns
 * - delete function
 */
const useDeleteAdmin = () => {

	const [deleteAdminMutation, { loading, error }] = useMutation(DELETE_ADMIN);

	/**
	 * @function deleteAdmin
	 *
	 * @description
	 * Function to delete an admin by their ID.
	 *
	 * @param {String} adminId - The ID of the admin to be deleted.
	 *
	 * @throws
	 * Will throw an error if the deletion is not successful or if there are any GraphQL errors.
	 *
	 * @returns {Object} - An object containing:
	 * - success: Boolean indicating whether the deletion was successful.
	 * - error: Error object if there's an error.
	 */
	const deleteAdmin = async (adminId) => {

		try {
			const { data } = await deleteAdminMutation({ 
				variables: { adminId: adminId }
			});
			if(error){
				throw new Error(error);
			}
			let deleteAdminData = data.deleteAdmin;
			if(deleteAdminData !== true){
				throw new Error("Deletion failed");
			}
			else{
				return { success: true };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { deleteAdmin, loading, error };

};

export default useDeleteAdmin;
