/**
 * @file useUpdateAdminPermissions.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_ADMIN_PERMISSIONS } from "cache/Admin/mutations"; // Make sure to update the path to the correct location of UPDATE_ADMIN_PERMISSIONS mutation

/**
 * @hook useUpdateAdminPermissions
 *
 * @description
 * Custom hook that handles updating an admin's permissions.
 *
 * @returns
 * - update function
 */
const useUpdateAdminPermissions = () => {

	const [updateAdminPermissionsMutation, { loading, error }] = useMutation(UPDATE_ADMIN_PERMISSIONS);

	const update = async (adminId, accessLevel) => {

		try {
			const { data } = await updateAdminPermissionsMutation({ 
				variables: { adminId, accessLevel }
			});
			if(error){
				throw new Error(error);
			}
			let updateAdminData = data.updateAdminPermissions;
			if(updateAdminData !== true){
				throw new Error("Update failed");
			}
			else{
				return { success: true };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { update, loading, error };

};

export default useUpdateAdminPermissions;
