import React from "react";
import {
	Container,
	Divider,
	Stack,
	VStack,
	Text,
	Flex,
	Box,
	useMediaQuery,
} from "@chakra-ui/react";
import { FcLike } from "react-icons/fc";
import LogoAndSocialIcons from "./components/LogoAndSocial";
import FooterLinks from "./components/FooterLinks";
import ContactForm from "./components/contactForm";

const Footer = () => {

	const [isSmallerScreen] = useMediaQuery("(max-width: 900px)");

	return (
		<Flex>
			<Box as="footer" role="contentinfo" borderTop="1px solid" w="100%" color="muted" bg="smoke">
				<Container w="100%" maxW="100%" py={4} px={isSmallerScreen ? 2 : 4}>
					<Flex
						direction={isSmallerScreen ? "column" : "row"}
						justify="center"
						alignItems="center"
						mb={isSmallerScreen ? 4 : 10}
						spacing={4}
					>
						<LogoAndSocialIcons />
						<FooterLinks />
						<ContactForm />
					</Flex>

					<Divider />
					<Stack justify="center" direction={isSmallerScreen ? "column-reverse" : "row"} align="center" mt={10} textAlign="center">
						<VStack>
							<Text 
								spacing="4" 
								fontSize="sm" 
								color="subtle"
							>
								{"\u00A9"} {new Date().getFullYear()} TaylorFenceNJ.com, All Rights Reserved.
								{/* <br />
							Made with <Box as={FcLike} display="inline" /> by Webify Services. */}
							</Text>
							<br />
							<Text
								spacing="4" 
								fontSize="sm" 
								color="subtle"
							>
							Made with <Box as={FcLike} display="inline" /> by Webify Services.
							</Text>
						</VStack>
					</Stack>
				</Container>
			</Box>
		</Flex>
	);
};

export default Footer;
