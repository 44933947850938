/**
 * @file CreateFeaturedDeal.js
 */
import React, { useState } from "react";

import {
	Alert,
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Textarea,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import useCreateFeaturedDeal from "hooks/FeaturedDeal/mutations/useCreateFeaturedDeal";
import useUploadImage from "hooks/useUploadImage";


/**
 * @component CreateFeaturedDeal
 * 
 * @description Component that displays the modal for creating a new featured deal.
 * 
 * @returns
 */
const CreateFeaturedDeal = ({ isOpen, onClose, dealsRefetch }) => {

	const [input, setInput] = useState({
		name: "",
		description: "",
		expires: "",
	});

	const [images, setImages] = useState([]);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const handleUpdateImages = (imageFiles) => {
		setImages(imageFiles);
	};


	const uploadImage = useUploadImage();

	const { createFeaturedDeal } = useCreateFeaturedDeal();

	const handleCreateFeaturedDeal = async (e) => {
		e.preventDefault();

		e.stopPropagation();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_urls = [];
		try{
			for(let i = 0; i < images.length; i++){
				const imageUrl = await uploadImage(images[i]);
				image_urls.push(imageUrl);
			}
		}
		catch(error){
			setError("There was an error uploading the images for this deal, please try again later.");
			setLoading(false);
			return;
		}

		const { data, error } = await createFeaturedDeal({
			...input,
			images: image_urls,
		});
		if (error) {
			setError(error.message);
		}
		if (data) {
			await dealsRefetch();
			onClose();
		}
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if(!input.name){
			errors.name = "Name is required";
		}
		if(!input.description){
			errors.description = "Description is required";
		}
		if(!input.expires){
			errors.expires = "Expiration date is required";
		}
		if(!images || images.length === 0){
			errors.images = "At least one image is required";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};


	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size="3xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader
					fontSize="2xl"
					fontWeight="bold"
					px={6}
					align="center"
				>
					Create Featured Deal
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody
					h="100%"
					overflowY="auto"
					px={14}
					mx={4}
				>
					<Stack
						as="form"
						spacing={6}
						mb={4}
						onSubmit={handleCreateFeaturedDeal}
					>
						{error && (
							<Alert status="error">
								<AlertIcon />
								<AlertDescription>{error}</AlertDescription>
							</Alert>
						)}
						<FormControl 
							id="name"
							isRequired	
							isInvalid={formErrors.name}
						>
							<FormLabel
								fontSize="lg"
								fontWeight="bold"
							>
								Name
							</FormLabel>
							<Input
								name="name"
								type="text"
								value={input.name}
								onChange={handleUpdateInput}
								placeholder="Name"
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							id="description"
							isRequired
							isInvalid={formErrors.description}
						>
							<FormLabel
								fontSize="lg"
								fontWeight="bold"
							>
								Description
							</FormLabel>
							<Textarea
								name="description"
								type="text"
								value={input.description}
								onChange={handleUpdateInput}
								placeholder="Description"
							/>
							<FormErrorMessage>{formErrors.description}</FormErrorMessage>
						</FormControl>
						<FormControl
							id="expires"
							isRequired
							isInvalid={formErrors.expires}
						>
							<FormLabel
								fontSize="lg"
								fontWeight="bold"
							>
								Expires
							</FormLabel>
							<Input
								name="expires"
								type="date"
								value={input.expires}
								onChange={handleUpdateInput}
								placeholder="Expires"
							/>
							<FormErrorMessage>{formErrors.expires}</FormErrorMessage>
						</FormControl>
						<FormControl
							id="images"
							name="images"
						>
							<FormLabel
								fontSize="lg"
								fontWeight="bold"
							>
								Images
							</FormLabel>
							<ImagesDropzone
								imageFiles={images}
								setImageFiles={handleUpdateImages}
								allowMultiple={true}
							/>
							<FormErrorMessage>{formErrors.images}</FormErrorMessage>
						</FormControl>
						<Button
							type="submit"
							variant="primary"
							isLoading={loading}
							loadingText="Creating Featured Deal"
							fontSize="lg"
							my={6}
						>
							Create Featured Deal
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CreateFeaturedDeal;