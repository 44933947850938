import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";

import { SimpleGrid, Box, Image, Text, Center } from "@chakra-ui/react";
import { motion } from "framer-motion";

import GalvanizedImage from "assets/TaylorFenceImg/Resources/Galv.png";
import VinylImage from "assets/TaylorFenceImg/Resources/Vinyl.png";

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionText = motion(Text);

const Categories = () => {

	const { handlePageChange } = useContext(PageChangeContext);

	const categories = [
		{
			_id: "1",
			name: "Galvanized",
			images: [
				GalvanizedImage
			],
		},
		{
			_id: "2",
			name: "Vinyl",
			images: [
				VinylImage
			],
		}
	];

	const handleCategoryClicked = (category) => {
		const lowercaseName = category.name.toLowerCase();
		handlePageChange(`/showroom/chainlink/${lowercaseName}`);
	};

	return (
		<SimpleGrid
			// templateColumns={{
			// 	base: "repeat(1, 1fr)",
			// 	sm: "repeat(2, 1fr)",
			// 	md: "repeat(2, 1fr)",
			// 	lg: "repeat(3, 1fr)",
			// 	llg: "repeat(4, 1fr)",
			// }}
			minChildWidth="300px"
			gap={8}
			p={12}
			w="80%"
			h="80%"
			placeItems="center"
			alignItems={"center"}
			justifyContent={"center"}
		>
			{categories?.map(category => (
				<MotionBox
					key={category._id}
					h={{
						base: "100%",
						md: "100%",
					}}
					minH={{
						base: "140px",
						md: "300px",
					}}
					// maxW={{
					// 	base: "100%",
					// 	md: "300px",
					// }}
					rounded="md"
					boxShadow="2xl"
					overflow="hidden"
					borderWidth="1px"
					borderColor="gray.500"
					position="relative"
					onClick={() => handleCategoryClicked(category)}
					whileHover={{ 
						scale: 1.05,
						transition: { duration: 0.2 },
						cursor: "pointer",
					}}
					layout
				>
					{category.images && category.images.length > 0 && (
						<MotionImage
							src={category.images[0]}
							alt={category.name}
							fit="cover"
							w="100%"
							h="100%"
							layout
						/>
					)}
					<Center 
						position="absolute"
						top="0"
						right="0"
						bottom="0"
						left="0"
						backgroundColor="rgba(0, 0, 0, 0.28)"
						py={4}
					>
						<MotionText 
							fontSize="xl"
							fontWeight="bold"
							fontFamily="heading"
							textShadow="2px 2px 2px rgba(0, 0, 0, 0.30)"
							color="white" 
							initial={{ opacity: 0 }} 
							animate={{ opacity: 1 }}
						>
							{category.name}
						</MotionText>
					</Center>
				</MotionBox>
			))}
		</SimpleGrid>
	);

};

export default Categories;
