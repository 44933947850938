/**
 * @file useSendInvoice.js
 * Custom hook to handle the SEND_INVOICE mutation functionality.
 */

import { useMutation } from "@apollo/client";
import { SEND_INVOICE } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useSendInvoice
 *
 * @description
 * Custom hook that handles the SEND_INVOICE mutation functionality.
 * This hook provides an interface to send an existing invoice.
 *
 * @returns
 * - sendInvoice: Function to execute the SEND_INVOICE mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useSendInvoice = () => {

	const [invokeSendInvoice, { loading, error }] = useMutation(SEND_INVOICE);

	/**
	 * Sends an existing invoice.
	 *
	 * @param {String} id - The ID of the invoice to send.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const sendInvoice = async (id) => {
		try {
			const { data } = await invokeSendInvoice({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.sendInvoice };
		} catch (error) {
			return { error };
		}
	};

	return { sendInvoice, loading, error };

};

export default useSendInvoice;
