/**
 * @file useUpdateCustomer.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER } from "cache/Customer/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateCustomer
 *
 * @description
 * Custom hook that handles the functionality to update an existing customer.
 * This hook can be used wherever the application needs to update a customer's details.
 *
 * @returns
 * - updateCustomer: Function to execute the update customer mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateCustomer = () => {

	const [updateCustomerMutation, { loading, error }] = useMutation(UPDATE_CUSTOMER);

	/**
	 * Custom mutation to update an existing customer.
	 *
	 * @param {Object} updateCustomerInput - The input object for updating a customer.
	 * @property {String} updateCustomerInput._id - The id of the customer.
	 * @property {String} updateCustomerInput.name - The name / company name of the customer.
	 * @property {String} updateCustomerInput.email - The email of the customer.
	 * @property {String} updateCustomerInput.phone - The phone number of the customer.
	 * @property {Object} updateCustomerInput.address - The address of the customer.
	 * @property {String} updateCustomerInput.address.line1 - The first line of the address.
	 * @property {String} updateCustomerInput.address.line2 - The second line of the address.
	 * @property {String} updateCustomerInput.address.city - The city of the address.
	 * @property {String} updateCustomerInput.address.state - The state of the address.
	 * @property {String} updateCustomerInput.address.zip - The zip code of the address.
	 * @property {String} updateCustomerInput.address.country - The country of the address.
	 * @returns {Object} - An object containing the updated customer object or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateCustomer = async (updateCustomerInput) => {
		try {
			const { data } = await updateCustomerMutation({
				variables: {
					input: updateCustomerInput
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return the updated customer object
			return { data: data.updateCustomer };
		} catch (error) {
			return { error };
		}
	};

	return { updateCustomer, loading, error };
};

export default useUpdateCustomer;
