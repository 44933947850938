import React from "react";

import ProductDisplay from "./ProductDisplay";

import {
	Box,
	Stack,
	Divider,
} from "@chakra-ui/react";


const ChildProductsStack = ({ products }) => {
	return (
		<Box
			w="100%"
			bg="gray.50"
			borderRadius="md"
			borderWidth="1px"
			borderColor="gray.200"
			boxShadow="sm"
		>
			<Stack
				direction="column"
				gap={4}
				alignItems="center"
				justifyContent="space-between"
				w="100%"
			>
				{products.map((product, index) => (
					<React.Fragment key={product._id}>
						<ProductDisplay product={product} />
						{index !== products.length - 1 && <Divider />}
					</React.Fragment>
				))}
			</Stack>
		</Box>
	);
};

export default ChildProductsStack;
