import { useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightAddon,
	Stack,
	Text,
	SimpleGrid,
	Divider
} from "@chakra-ui/react";

const VariantInput = ({ variants, setVariants }) => {

	if(!variants) return null;

	const [variantKey, setVariantKey] = useState("");

	const handleAddVariant = () => {
		let newVariants = { ...variants };
		newVariants[variantKey] = [];
		setVariants(newVariants);
		setVariantKey("");
	};

	const handleDeleteVariant = (key) => {
		let newVariants = { ...variants };
		delete newVariants[key];
		setVariants(newVariants);
	};

	const handleAddValue = (key, value) => {
		let newVariants = { ...variants };
		newVariants[key].push(value);
		setVariants(newVariants);
	};

	const handleDeleteValue = (key, value) => {
		let newVariants = { ...variants };
		newVariants[key] = newVariants[key].filter((v) => v !== value);
		setVariants(newVariants);
	};

	return (
		<Box>
			<Stack spacing={4} mt={4}>
				{Object.entries(variants)?.map(([key, values]) => (
					<Box 
						key={key}
						p={4}
						borderWidth="1px"
						borderColor="gray.300"
						boxShadow="md"
						rounded="md"
					>
						<Stack 
							direction={{
								base: "column",
								md: "row",
							}}
							alignItems="center"
							justifyContent="space-between" 
							spacing={4}
							mb={2}
						>
							<Stack
								direction="row"
								spacing={4}
							>
								<Text
									fontSize="2xl"
									fontWeight="bold"
								>
								Option Name:
								</Text>
								<Text
									fontSize="2xl"
									fontWeight="normal"
								>
									{key}
								</Text>
							</Stack>
							<Button 
								onClick={() => handleDeleteVariant(key)}
								variant="primary"
							>
								Delete {key}?
							</Button>
						</Stack>
						<Divider />
						<Stack 
							spacing={2} 
							mt={2}
						>
							<SimpleGrid
								minChildWidth={["10%", "25%"]}
								spacing={4}
							>
								{values?.map((value) => (
									<Stack 
										key={value} 
										direction={{
											base: "column",
											md: "row",
										}} 
										spacing={4}
									>
										<Text
											fontSize="2xl"
											fontWeight="normal"
										>
											{value}
										</Text>
										<Button 
											variant="primary"
											onClick={() => handleDeleteValue(key, value)}
										>
											Delete
										</Button>
									</Stack>
								))}
							</SimpleGrid>
							<Stack direction="row" spacing={4}>
								<FormControl>
									<FormLabel>Add Value for {key}</FormLabel>
									<InputGroup>
										<Input 
											id={`variant-value-${key}`}
											placeholder="Enter a value here, ex: 4'"
										/>
										<InputRightAddon>
											<Button
												onClick={() => {
													const value = document.getElementById(
														`variant-value-${key}`
													).value;
													handleAddValue(key, value);
													document.getElementById(`variant-value-${key}`).value = "";
												}}
											>
												Add
											</Button>
										</InputRightAddon>
									</InputGroup>
								</FormControl>
							</Stack>
						</Stack>
					</Box>
				))}
			</Stack>
			{Object.keys(variants).length === 0 && (
				<Text my={4}>No options added yet.</Text>
			)}
			<Stack 
				mt={4}
				direction={{
					base: "column",
					md: "row",
				}}
			>
				<FormControl>
					<FormLabel>
						Add a New Option
					</FormLabel>
					<InputGroup>
						<Input
							value={variantKey}
							onChange={(e) => setVariantKey(e.target.value)}
							placeholder={"Input an Option Name (Ex: Height)"}
						/>
						<InputRightAddon>
							<Button onClick={handleAddVariant}>
								Add
							</Button>
						</InputRightAddon>
					</InputGroup>
				</FormControl>
			</Stack>
		</Box>
	);
};

export default VariantInput;
