/**
 * @file useCreateCategory.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_CATEGORY } from "cache/Category/mutations"; // Update the path as per your project structure

/**
 * @hook useCreateCategory
 *
 * @description
 * Custom hook that handles category creation functionality.
 *
 * @returns
 * - create function
 */
const useCreateCategory = () => {

	const [createCategoryMutation, { loading, error }] = useMutation(CREATE_CATEGORY);

	const create = async (input) => {

		try {
			const { data } = await createCategoryMutation({ 
				variables: { input: input }
			});
			if (error) {
				throw new Error(error);
			}
			let createCategoryData = data.createCategory;
			if (createCategoryData?.error) {
				throw new Error(createCategoryData.error);
			} else {
				return { data: createCategoryData };
			}
		} catch (error) {
			return { error };
		}

	};

	return { create, loading, error };

};

export default useCreateCategory;
