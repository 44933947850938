/**
 * @file useGetAllAdmins.js
 */
import { useQuery } from "@apollo/client";
import { GET_ALL_ADMINS } from "cache/Admin/queries"; // Make sure to update the path to the correct location of GET_ALL_ADMINS query

/**
 * @hook useGetAllAdmins
 *
 * @description
 * Custom hook that handles getting all admin information.
 * Used in the Admin component or wherever the list of admins is needed.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Array containing all admin information
 * - refetch: Function to refetch the all admin information
 * - networkStatus: Network status information
 */
const useGetAllAdmins = () => {

	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_ADMINS, {
		notifyOnNetworkStatusChange: true
	});

	let snaggedData = data?.getAllAdmins;

	let errorMsg = error?.message;

	return { loading, error: errorMsg, data: snaggedData, refetch, networkStatus };
};

export default useGetAllAdmins;
