/** 
 * @file queries.js is a file for all Category queries 
 * 
 * @author Steven Secreti
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_CATEGORY_TREE - GraphQL query to get the category tree.
 * 
 * @summary
 * - This query will return the top-level category object with all child categories populated.
 */
export const GET_CATEGORY_TREE = gql`
    query getCategoryTree {
        getCategoryTree
    }
`;

/**
 * @const {Object} GET_TOP_LEVEL_CATEGORIES - GraphQL query to get the top-level categories.
 * 
 * @summary
 * - This query will return an array of top-level category objects.
 */
export const GET_TOP_LEVEL_CATEGORIES = gql`
    query getTopLevelCategories {
        getTopLevelCategories {
            _id
            name
            child_descriptor
			child_categories
			child_partners
            products {
                _id
            }
			images
			description
            createdAt
            updatedAt
        }
    }
`;

/**
 * @const {Object} GET_CHILD_CATEGORIES - GraphQL query to get a category by its ID.
 * 
 * @summary
 * - This query will return the category object for the given ID, with its child_categories populated.
 */
export const GET_CHILD_CATEGORIES = gql`
    query getChildCategories($_id: String!) {
        getChildCategories(_id: $_id) {
			_id
            name
            child_descriptor
			child_categories
			child_partners
            products {
                _id
            }
			images
			description
            createdAt
            updatedAt
        }
    }
`;

/**
 * @const {Object} GET_CATEGORY - GraphQL query to get a category by its ID.
 * 
 * @summary
 * - This query will return the category object for the given ID.
 */
export const GET_CATEGORY = gql`
	query getCategory($_id: String!) {
		getCategory(_id: $_id) {
			_id
            name
            child_descriptor
			child_categories
			child_partners
            products {
                _id
            }
			images
			description
            createdAt
            updatedAt
		}
	}
`;

/**
 * @const {Object} GET_SHOWROOM_CATEGORY - GraphQL query to get a category by its ID.
 * 
 * @summary
 * - This query will return the category object for the given ID, with its child_categories and child_partners populated.
 */
export const GET_SHOWROOM_CATEGORY = gql`
	query getShowroomCategory($_id: String!) {
		getShowroomCategory(_id: $_id) {
			_id
			name
			parent
			child_descriptor
			child_categories {
				_id
				name
				parent
				child_descriptor
				child_categories
				child_partners
				products {
					_id
				}
				images
				description
				createdAt
				updatedAt
			}
			child_partners {
				_id
				name
				image
				images
				website
				brochures {
					_id
					name
					image
					url
					order
				}
				order
			}
			products {
				_id
				name
				category {
					_id
					name
					breadcrumbs {
						_id
						name
					}
				}
				images
				description
				variants
				variations {
					_id
					sku
					quantity
					price
					isDefaultVariation
				}
				partner
				createdAt
				updatedAt
			}
			images
			description
			createdAt
			updatedAt
		}
	}
`;