import React, { useState } from "react";
import {
	Box,
	Image,
	Heading,
	Text,
	Flex,
	SimpleGrid,
	VStack,
} from "@chakra-ui/react";

const ProductDisplay = ({ product }) => {
	const { images, name, description, variants } = product;
	const [selectedImage, setSelectedImage] = useState(images[0]);
	const variantSections = Object.entries(variants);

	const renderVariantSection = (variantName, variantValues) => (
		<Box
			key={variantName}
			p={3}
			borderRadius="md"
			borderWidth="1px"
			borderColor="gray.200"
			boxShadow="sm"
			bg="white"
		>
			<Heading as="h3" size="sm" mb={1}>
				{variantName}:
			</Heading>
			<Text color="gray.600">{variantValues.join(", ")}</Text>
		</Box>
	);

	return (
		<Flex
			align="center"
			justify="center"
			wrap="wrap"
			p={4}
			bg="gray.50"
			w="100%"
		>
			<SimpleGrid
				columns={{ base: 1, md: 2 }}
				spacing={8}
				alignItems="center"
				justifyContent="space-evenly"
				p={4}
				w="100%"
			>
				<VStack
					display="flex"
					flexShrink={0}
					width={{ base: "100%", md: "50%" }}
					height="auto"
					spacing={4}
					alignItems="end"
				>
					<Image
						src={selectedImage}
						alt={`Image of ${name}`}
						width="100%"
						height="100%"
						objectFit="contain"
					/>
					<SimpleGrid 
						columns={{ base: 3, md: 5 }} 
						spacing={2}
						mx="0"
						px={0}
						w="100%"
					>
						{images.map((image, index) => (
							<Image
								key={index}
								src={image}
								alt={`Thumbnail of ${name}`}
								width="100%"
								height="auto"
								maxHeight="100px"
								objectFit="cover"
								cursor="pointer"
								border={image === selectedImage ? "2px solid" : "none"}
								borderColor="blue.500"
								onClick={() => setSelectedImage(image)}
							/>
						))}
					</SimpleGrid>
				</VStack>
				<VStack
					width={{ base: "100%", md: "50%" }}
					align="start"
					spacing={4}
				>
					<Heading as="h2" size="lg">{name}</Heading>
					<Text
						fontSize="lg"
						color="gray.600"
						textAlign={{ base: "center", md: "left" }}
					>
						{description}
					</Text>
					<SimpleGrid columns={2} spacing={3} w="100%">
						{variantSections.map(([variantName, variantValues]) =>
							renderVariantSection(variantName, variantValues)
						)}
					</SimpleGrid>
				</VStack>
			</SimpleGrid>
		</Flex>
	);
};

export default ProductDisplay;
