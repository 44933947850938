import { 
	Box, 
	SimpleGrid, 
	Image,  
	Link
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import AFA from "./AFA.png";
import GSFA from "./GSFA.png";

const MotionBox = motion(Box);

const stats = [
	{
		label: "AFA",
		url: "https://www.americanfenceassociation.com/",
		img: AFA,
	},
	{
		label: "GSFA",
		url: "https://gsafa.org/",
		img: GSFA,
	},
];

const Badges = () => (
	<SimpleGrid 
		columns={{ base: 1, md: 2 }} 
		gap={{ base: "2", md: "100" }}
	>
		{stats.map(({ label, url, img }) => (
			<MotionBox
				key={label}
				textAlign="center"
				whileHover={{
					scale: 1.05,
					cursor: "pointer",
				}}
				transition={{ duration: 0.3 }}
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -20 }}
			>
				<Link href={url} isExternal>
					<Image 
						src={img} 
						alt={label} 
						mb="3" 
						maxWidth="150px"
						mx="auto" 
					/>
				</Link>
			</MotionBox>
		))}
	</SimpleGrid>
);

export default Badges;
