
/**
 * @file EditVariant.js
 * 
 * @author Steven Secreti
 */

import React, { useState, useEffect } from "react";

// Chakra UI imports 
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Button,
	Checkbox,
	Flex
} from "@chakra-ui/react";

const EditVariant = ({ isOpen, onClose, onSave, variation, index }) => {

	if(!variation) return null;

	const [newPriceKey, setNewPriceKey] = useState("");
	const [newPriceValue, setNewPriceValue] = useState("");

	const [input, setInput] = useState({
		sku: variation.sku,
		price: variation.price || {},
		isDefaultVariation: variation.isDefaultVariation,
	});

	useEffect(() => {
		setInput({
			sku: variation.sku,
			price: variation.price || {},
			isDefaultVariation: variation.isDefaultVariation,
		});
	}, [variation]);

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
		validateInput();
	};

	const handlePriceChange = (key, value) => {
		setInput({
		  ...input,
		  price: {
				...input.price,
				[key]: parseFloat(value) || 0,
		  },
		});
		validateInput();
	};

	const handlePriceDelete = (key) => {
		const newPrice = { ...input.price };
		delete newPrice[key];
		setInput({
		  ...input,
		  price: newPrice,
		});
		validateInput();
	};


	const handleIsDefaultVariationChange = (e) => {
		setInput({
			...input,
			isDefaultVariation: e.target.checked,
		});
		validateInput();
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if (input.sku === "") {
			errors.sku = "SKU is required.";
		}
		if (Object.keys(input.price).length === 0) {
			errors.price = "At least one price is required.";
		}
		if (input.isDefaultVariation === "") {
			errors.isDefaultVariation = "Default Variation is required.";
		}
		setFormErrors(errors);
	};


	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit Variant</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl
						isInvalid={formErrors.sku}
					>
						<FormLabel>SKU</FormLabel>
						<Input
							name="sku"
							value={input.sku}
							onChange={handleInputChange}
						/>
						<FormErrorMessage>{formErrors.sku}</FormErrorMessage>
					</FormControl>
					{/* <FormControl
						isInvalid={formErrors.price}
					>
						<FormLabel>Price</FormLabel>
						<Input
							name="price"
							value={input.price}
							onChange={handlePriceChange}
							type="number"
						/>
						<FormErrorMessage>{formErrors.price}</FormErrorMessage>
					</FormControl> */}
					<FormControl
						isInvalid={formErrors.price}
					>
						<FormLabel>Price</FormLabel>
						{Object.keys(input.price).map((key) => (
							<Flex 
								key={key} 
								mb={2}
								gap={2}	
							>
								<Input
									flex={1}
									placeholder="Key"
									value={key}
									readOnly
								/>
								<Input
									flex={1}
									placeholder="Value"
									type="number"
									value={input.price[key]}
									onChange={(e) => handlePriceChange(key, e.target.value)}
								/>
								<Button onClick={() => handlePriceDelete(key)}>
        							Delete
								</Button>
							</Flex>
						))}
						<Flex mb={2}>
							<Input
								flex={1}
								placeholder="New Key"
								value={newPriceKey}
								onChange={(e) => setNewPriceKey(e.target.value)}
							/>
							<Input
								flex={1}
								placeholder="New Value"
								type="number"
								value={newPriceValue}
								onChange={(e) => setNewPriceValue(e.target.value)}
							/>
						</Flex>
						<Button
							variant="primary"
							onClick={() => {
								handlePriceChange(newPriceKey, newPriceValue);
								setNewPriceKey("");
								setNewPriceValue("");
							}}
						>
    						Add Price
						</Button>
						<FormErrorMessage>{formErrors.price}</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={formErrors.isDefaultVariation}
					>
						<FormLabel>Default Variant</FormLabel>
						<Checkbox
							name="isDefaultVariation"
							value={input.isDefaultVariation}
							onChange={handleIsDefaultVariationChange}
						/>
						<FormErrorMessage>{formErrors.isDefaultVariation}</FormErrorMessage>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary2"
						onClick={async () => {
							await onSave(input, index);
							onClose();
						}}
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

};

export default EditVariant;
