/**
 * @file useGetTopLevelCategories.js
 */
import { useQuery } from "@apollo/client";
import { GET_TOP_LEVEL_CATEGORIES } from "cache/Category/queries";

/**
 * @hook useGetTopLevelCategories
 *
 * @description
 * Custom hook that handles getting the top-level categories.
 * Used in the Category component.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Top-level categories data
 * - refetch: Function to refetch the top-level categories data
 * - networkStatus: Network status information
 */
const useGetTopLevelCategories = () => {

	const { loading, error, data, refetch, networkStatus } = useQuery(GET_TOP_LEVEL_CATEGORIES, {
		notifyOnNetworkStatusChange: true
	});

	let snaggedData = data?.getTopLevelCategories;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetTopLevelCategories;
