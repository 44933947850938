import { motion } from "framer-motion";

const pageVariants = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	},
};

const pageTransition = {
	type: "tween",
	ease: "easeInOut",
	duration: 0.65
};

const MotionPage = ({ children }) => (
	<motion.div
		initial="initial"
		animate="in"
		exit="out"
		variants={pageVariants}
		transition={pageTransition}
		style={{ width: "100%" }}
	>
		{children}
	</motion.div>
);

export default MotionPage;
