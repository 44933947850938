/**
 * @file Login.js
 */
import React from "react";

import LoginForm from "./components/LoginForm";

/**
 * @component Login 
 * 
 * @description
 * - This component is the login screen for the admin dashboard
 * 
 * @param {*} props 
 * @returns 
 */
const Login = () => {

	return (
		<div
			className="container-fluid"
			style={{
				height: "100vh",
				backgroundColor: "#f5f5f5",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<LoginForm />
		</div>
	);

};

export default Login;