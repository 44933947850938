import React from "react";
import { Box, Stack, Text, SimpleGrid, Divider } from "@chakra-ui/react";

const VariantDisplay = ({ variants }) => {
	if (!variants) return <Text my={4}>No options available.</Text>;

	return (
		<Box
			w="100%"
			h="100%"
			px={4}
		>
			<Stack spacing={4} mt={4}>
				{Object.entries(variants).map(([key, values]) => (
					<Box
						key={key}
						p={4}
						borderWidth="1px"
						borderColor="gray.300"
						boxShadow="md"
						rounded="md"
					>
						<Stack
							direction={{ base: "column", md: "row" }}
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
							mb={2}
						>
							<Stack direction="row" spacing={4}>
								<Text fontSize="2xl" fontWeight="bold">Option Name:</Text>
								<Text fontSize="2xl" fontWeight="normal">{key}</Text>
							</Stack>
						</Stack>
						<Divider />
						<SimpleGrid minChildWidth={["10%", "25%"]} spacing={4} mt={2}>
							{values.map((value) => (
								<Text
									key={value}
									fontSize="xl"
									fontWeight="normal"
								>
									{value}
								</Text>
							))}
						</SimpleGrid>
					</Box>
				))}
			</Stack>
		</Box>
	);
};

export default VariantDisplay;
