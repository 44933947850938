/**
 * @file useUpdateGalleryImage.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_GALLERY_IMAGE } from "cache/GalleryImage/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateGalleryImage
 *
 * @description
 * Custom hook that handles the functionality of updating a gallery image.
 * This hook is used in components where an existing gallery image needs to be updated.
 *
 * @returns
 * - updateGalleryImageExecute: Method to execute the update gallery image mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateGalleryImage = () => {

	// Initialize the Apollo useMutation hook
	const [updateGalleryImageMutation, { loading, error }] = useMutation(UPDATE_GALLERY_IMAGE);

	/**
	 * Method to execute the update gallery image mutation.
	 *
	 * @param {String} id - The ID of the gallery image to be updated.
	 * @param {Object} input - The input object for updating the gallery image.
	 * @property {String} input.title - The title of the gallery image.
	 * @property {String} input.description - The description of the gallery image.
	 * @property {String} input.image - The image URL of the gallery image.
	 * @returns {Object} - An object containing a Boolean indicating the mutation result or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateGalleryImage = async (id, input) => {
		try {
			const { data } = await updateGalleryImageMutation({
				variables: {
					id,
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			return { data: data.updateGalleryImage };
		} catch (error) {
			return { error };
		}
	};

	return { updateGalleryImage, loading, error };

};

export default useUpdateGalleryImage;
