import React from "react";

import { 
	Stack, 
	Text, 
	Flex 
} from "@chakra-ui/react";

import { NavigateButton } from "components/common";

import { links } from "../footerLinks";

const FooterLinks = () => {
	return (
		<Flex
			direction={{
				base: "column",
				sm: "column",
				md: "row",
				lg: "row"
			}}
			// columns={{ base: 1, sm: 3, md: 3, lg: 3 }}
			spacing={{
				base: "1.7rem",
				sm: "2rem",
				md: "2.6rem",
				lg: "3rem"

			}}
			w={{ 
				base: "full",
				sm: "full",
				md: "full",
				lg: "30%"

			}}
			alignItems="center"
			justifyContent="space-evenly"
		>
			{links.map((group, idx) => (
				<Stack 
					key={idx} 
					spacing="4"
				>
					<Text fontSize="sm" fontWeight="semibold" color="subtle">
						{group.title}
					</Text>
					<Stack spacing="2">
						{group.links.map((link, idx) => (
							<NavigateButton 
								key={idx} 
								to={link.href}
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
								variant="link"
								color="dark_gray"
							>
								{link.label}
							</NavigateButton>
						))}
					</Stack>
				</Stack>
			))}
		</Flex>
	);
};

export default FooterLinks;

