/**
 * @file useCreateBrochure.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_BROCHURE } from "cache/Brochure/mutations"; // Update the path as per your project structure

/**
 * @hook useCreateBrochure
 *
 * @description
 * Custom hook that handles the functionality to create a new brochure.
 * Use this hook wherever the creation of a new brochure is required.
 *
 * @returns
 * - createBrochure: Function to execute the createBrochure mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCreateBrochure = () => {

	const [createBrochureMutation, { loading, error }] = useMutation(CREATE_BROCHURE);

	/**
	 * Custom mutation handler for creating a brochure.
	 *
	 * @param {Object} input - Input object containing brochure details for creation.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const createBrochure = async (input) => {
		try {
			const { data } = await createBrochureMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.createBrochure };
		} catch (error) {
			return { error };
		}
	};

	return { createBrochure, loading, error };

};

export default useCreateBrochure;
