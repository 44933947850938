/**
 * @file UpdateFeaturedDealImages.js
 */
import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	FormLabel,
	FormErrorMessage,
	Stack,
	Box,
	Image,
	Grid,
	Alert,
	AlertIcon,
} from "@chakra-ui/react";

import useUpdateFeaturedDealImages from "hooks/FeaturedDeal/mutations/useUpdateFeaturedDealImages";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import useUploadImage from "hooks/useUploadImage";

/**
 * @component UpdateFeaturedDealImages
 * 
 * @description
 * - Modal to update the images of a car in the garage
 * - Modal contains a mechanism to update the car images 
 */
const UpdateFeaturedDealImages = ({ isOpen, onClose, deal, refetch }) => {

	const uploadImage = useUploadImage();

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const [imageURLs, setImageURLs] = useState(deal.images);
	const [imageFiles, setImageFiles] = useState([]);

	/**
	 * @function useEffect
	 * 
	 * @description
	 * - Updates the image URLs when the product changes
	 */
	useEffect(() => {
		setImageURLs(deal.images);
	}, [deal]);

	/**
	 * @function handleDeleteImage
	 * 
	 * @param {*} imageURL 
	 */
	const handleDeleteImage = (imageURL) => {
		setImageURLs(imageURLs.filter(url => url !== imageURL));
	};
	
	/**
	 * @function handleSetImageFiles
	 * 
	 * @description
	 * - Sets the image files to be uploaded
	 * 
	 * @param {*} files 
	 */
	const handleSetImageFiles = (files) => {
		setImageFiles(files);
	};


	const { updateFeaturedDealImages } = useUpdateFeaturedDealImages();

	/**
	 * @function handleUpdateImages
	 * 
	 * @description
	 * - Handles the update images process
	 * - Utilizes the updateImages mutatin
	 */
	const handleUpdateImages = async () => {
		setLoading(true);

		if(!validateInput()) return;
		let image_urls = new Array();
		for(let i = 0; i < imageFiles.length; i++) {
			try{
				const currentImage = imageFiles[i];
				const imageUrl = await uploadImage(currentImage);
				image_urls.push(imageUrl);
			}
			catch(err){
				setLoading(false);
				setErrorMsg("Error uploading images. Please try again.");
				return;
			}
		}
		const { success, error } = await updateFeaturedDealImages(deal._id, [...image_urls, ...imageURLs]);
		if(error){
			setLoading(false);
			setErrorMsg(error.message);
		}
		if(success){
			await refetch();
			setImageFiles([]);
			setLoading(false);
			onClose();
			return; 
		}
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if(!imageFiles.length){
			errors.images = "Please upload at least one image.";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			size="2xl"
			scrollBehavior="inside"
			isCentered
			motionPreset="slideInBottom"
			closeOnOverlayClick={true}
			closeOnEsc={true}
			closeOnBodyClick={false}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Images</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							{errorMsg}
						</Alert>
                	)}
					<Stack 
						spacing={6}
					>
						<Box>
							<FormLabel
								isInvalid={formErrors.images ? false : true}
							>
								Upload new images
							</FormLabel>
							<ImagesDropzone 
								imageFiles={imageFiles}
								setImageFiles={handleSetImageFiles}
								allowMultiple={true}
							/>
							<FormErrorMessage>{formErrors.images}</FormErrorMessage>
						</Box>
						{/* Display current images */}
						<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
							{imageURLs.map((imageURL, i) => (
								<Box key={i} position="relative">
									<Image src={imageURL} alt={`Product image ${i}`} objectFit="cover" borderRadius="md"/>
									<Button
										position="absolute"
										right="5px"
										top="5px"
										size="sm"
										variant="delete"
										onClick={() => handleDeleteImage(imageURL)}
									>
										Delete
									</Button>
								</Box>
							))}
						</Grid>
						<Button
							isLoading={loading}
							loadingText="Updating..."
							variant="primary"
							onClick={handleUpdateImages}
						>
							Update Images
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
	
};

export default UpdateFeaturedDealImages;