import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";
import { Grid, Text, Box, Center, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionImage = motion(Image);

const ChildCategoriesGrid = ({ categories }) => {
	const { handlePageChange } = useContext(PageChangeContext);

	const handleCategoryClicked = (category) => {
		handlePageChange(`showroom/${category._id}`);
	};

	return (
		<Grid
			templateColumns={{
				base: "repeat(1, 1fr)",
				sm: "repeat(2, 1fr)",
				md: "repeat(2, 1fr)",
				lg: "repeat(3, 1fr)",
				llg: "repeat(4, 1fr)",
			}}
			gap={8}
			p={12}
			w="100%"
		>
			{categories?.map(category => (
				<MotionBox
					key={category._id}
					h={{
						base: "100%",
						md: "100%",
					}}
					minH={{
						base: "140px",
						md: "300px",
					}}
					rounded="md"
					boxShadow="2xl"
					overflow="hidden"
					borderWidth="1px"
					borderColor="gray.500"
					position="relative"
					onClick={() => handleCategoryClicked(category)}
					whileHover={{ 
						scale: 1.05,
						transition: { duration: 0.2 },
						cursor: "pointer",
					}}
					layout
				>
					{category.images && category.images.length > 0 && (
						<MotionImage
							src={category.images[0]}
							alt={category.name}
							fit="cover"
							w="100%"
							h="100%"
							layout
						/>
					)}
					<Center 
						position="absolute"
						top="0"
						right="0"
						bottom="0"
						left="0"
						backgroundColor="rgba(0, 0, 0, 0.28)"
						py={4}
					>
						<MotionText 
							fontSize="xl"
							fontWeight="bold"
							fontFamily="heading"
							textShadow="2px 2px 2px rgba(0, 0, 0, 0.30)"
							color="white" 
							initial={{ opacity: 0 }} 
							animate={{ opacity: 1 }}
						>
							{category.name}
						</MotionText>
					</Center>
				</MotionBox>
			))}
		</Grid>
	);
};

export default ChildCategoriesGrid;
