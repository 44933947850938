/**
 * @file
 * This is the ResourcesPage component.
 * It is used to render the Resources Page of the website.
 *
 * @summary Render function for the Resources page.
 */

// Importing React and Chakra UI components
import React from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import MotionPage from "Blueprints/MotionPage";
import Partners from "./Partners/Partners";

// MotionBox component based on the Box component from Chakra UI and motion from framer-motion
const MotionBox = motion(Box);

/**
 * ResourcesPage component.
 * This component returns a JSX element that renders the Resources page.
 * It consists of a Hero component with the page title and image,
 * and a Partners component inside a MotionBox component.
 *
 * @returns {HTMLElement} A MotionPage component with Hero and Partners components.
 */

const ResourcesPage = () => {

	return (
		<MotionPage>
			<div
				className="resources"
			>
				<MotionBox 
				>
					<Partners />
				</MotionBox>
			</div>
		</MotionPage>
	);
};

export default ResourcesPage;
