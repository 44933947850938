/**
 * @file UpdateProduct.js
 */

import React, { useState } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Button,
	Stack,
	Select,
	Textarea
} from "@chakra-ui/react";

import useUpdateProduct from "hooks/Product/mutations/useUpdateProduct";

import CategoryDropdown from "../components/CategoryDropdown";

import useGetAllPartners from "hooks/Partner/queries/useGetAllPartners";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import ErrorMsg from "Blueprints/ErrorMsg";

import VariantInput from "../components/VariantInput";

const UpdateProduct = ({ isOpen, onClose, product, refetch }) => {

	const { loading: partnersLoading, error: partnersError, data: partners } = useGetAllPartners();

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [name, setName] = useState(product?.name);
	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const [category, setCategory] = useState(product?.category?._id);
	const handleCategoryChange = (value) => {
		setCategory(value);
	};

	const [description, setDescription] = useState(product?.description);
	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const [partner, setPartner] = useState(product?.partner);
	const handlePartnerChange = (e) => {
		setPartner(e.target.value);
	};

	const [variants, setVariants] = useState(product?.variants || {});

	const variantsHasChanged = () => {
		if(!variants) return false;
		if(!product?.variants) return true;
		if(Object.keys(variants).length !== Object.keys(product?.variants).length) return true;
		for(let key in variants) {
			if(!product?.variants[key]) return true;
			if(variants[key].length !== product?.variants[key].length) return true;
			for(let i = 0; i < variants[key].length; i++) {
				if(variants[key][i] !== product?.variants[key][i]) return true;
			}
		}
		return false;
	};

	const { update } = useUpdateProduct();

	const handleUpdateProduct = async (e) => {
		e.preventDefault();
		setLoading(true);
		setErrorMsg(null);

		let updateCategoryInput = {};
		if(category !== product.category._id) {
			updateCategoryInput = { category };
		}
		if(name !== product.name) {
			updateCategoryInput = { ...updateCategoryInput, name };
		}
		if(description !== product.description) {
			if(description === "") {
				updateCategoryInput = { ...updateCategoryInput, description: "REMOVE" };
			}
			else updateCategoryInput = { ...updateCategoryInput, description };
		}
		if(partner !== product.partner) {
			updateCategoryInput = { ...updateCategoryInput, partner };
		}

		if(variantsHasChanged()) {
			updateCategoryInput = { ...updateCategoryInput, variants };
		}

		if(Object.keys(updateCategoryInput).length === 0) {
			setLoading(false);
			onClose();
			return;
		}

		const { error } = await update(product._id, updateCategoryInput);
		if (error) {
			setErrorMsg(error.message);
			setLoading(false);
			return;
		}
		setLoading(false);
		refetch();
		onClose();
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			isCentered
			size="full"
		>
			<ModalOverlay />
			<ModalContent
				height="100%"
				maxHeight="100vh"
				overflowY="auto"
			>
				<ModalHeader
					fontSize="2xl"
					fontWeight="bold"
					px={6}
					align="center"
				>
					Update Product
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing={4}>
						<FormControl id="name">
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								value={name}
								onChange={handleNameChange}
							/>
						</FormControl>
						<FormControl id="category">
							<FormLabel>Category</FormLabel>
							<CategoryDropdown
								value={category}
								onChange={handleCategoryChange}
							/>
						</FormControl>
						<FormControl id="description">
							<FormLabel>Description</FormLabel>
							<Textarea
								value={description}
								onChange={handleDescriptionChange}
							/>
						</FormControl>
						<FormControl id="partner">
   						 <FormLabel>Partner</FormLabel>
							<LoadingErrorDataWrapper
								loading={partnersLoading}
								error={partnersError?.message}
								data={partners}
							>
								<Select
									placeholder={"Select a Partner..."}
									value={partner}
									onChange={handlePartnerChange}
								>
									<option value={"REMOVE"}>None</option>
									{partners?.map((partner) => (
										<option key={`${product._id}PartnerOption${partner.name}`} value={partner.name}>
											{partner.name}
										</option>
									))}
								</Select>
							</LoadingErrorDataWrapper>
						</FormControl>
						<FormControl id="variants">
							<FormLabel>Variants</FormLabel>
							<VariantInput
								variants={variants}
								setVariants={setVariants}
							/>
						</FormControl>
					</Stack>
					{errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						isLoading={loading}
						onClick={handleUpdateProduct}
					>
						Update
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

};

export default UpdateProduct;
