/**
 * @file
 * This is the PaymentsController component of the application.
 * It represents the controller for the Payments page routes.
 * 
 * @summary Payments page routes controller.
 */

import React from "react";
import { Route } from "react-router-dom";
import RoutesWrapper from "wrappers/RoutesWrapper";
import "./Payments.scss";
import Payments from "./Payments";
import Invoices from "./Invoices/Invoices";

/**
 * The PaymentsController component manages the routes for the Payments page of the website.
 *
 * @returns {HTMLElement} - A div component that includes the Routes child component from react-router-dom. 
 * This child component manages the "/" and "/checkout" routes which respectively map to the Payments and CheckoutWrapper components.
 */
const PaymentsController = () => {
	return(
		<div className="showroom-wrapper">
			<RoutesWrapper>
				<Route path="/" element={<Payments/>} />
				<Route path="/invoices/*" element={<Invoices />} />
			</RoutesWrapper>
		</div>
	);
};

export default PaymentsController;
