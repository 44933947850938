/**
 * @file mutations.js is a file for all GalleryImage mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_GALLERY_IMAGE - GraphQL mutation to create a gallery image.
 * 
 * @param {Object} createGalleryImageInput - The input object for creating a gallery image.
 * @property {String} createGalleryImageInput.title - The title of the gallery image.
 * @property {String} createGalleryImageInput.description - The description of the gallery image.
 * @property {String} createGalleryImageInput.image - The image of the gallery image.
 * 
 * @returns
 * - Boolean
 */
export const CREATE_GALLERY_IMAGE = gql`
	mutation createGalleryImage($input: CreateGalleryImageInput!) {
		createGalleryImage(input: $input)
	}
`;

/**
 * @const {Object} UPDATE_GALLERY_IMAGE - GraphQL mutation to update a gallery image.
 * 
 * @param {Object} updateGalleryImageInput - The input object for updating a gallery image.
 * @property {String} updateGalleryImageInput.title - The title of the gallery image.
 * @property {String} updateGalleryImageInput.description - The description of the gallery image.
 * @property {String} updateGalleryImageInput.image - The image of the gallery image.
 * 
 * @returns
 * - Boolean
 */
export const UPDATE_GALLERY_IMAGE = gql`
	mutation updateGalleryImage($id: ID!, $input: UpdateGalleryImageInput!) {
		updateGalleryImage(id: $id, input: $input)
	}
`;

/**
 * @const {Object} REORDER_GALLERY_IMAGES - GraphQL mutation to reorder gallery images.
 * 
 * @param {Object} input - The input object for reordering gallery images.
 * 
 * @returns
 * - Boolean
 */
export const REORDER_GALLERY_IMAGES = gql`
	mutation reorderGalleryImages($input: [ReorderInput!]!) {
		reorderGalleryImages(input: $input)
	}
`;


/**
 * @const {Object} DELETE_GALLERY_IMAGE - GraphQL mutation to delete a gallery image.
 * 	
 * @param {String} id - The id of the gallery image.
 * 
 * @returns
 * - Boolean
 */
export const DELETE_GALLERY_IMAGE = gql`
	mutation deleteGalleryImage($id: ID!) {
		deleteGalleryImage(id: $id)
	}
`;
