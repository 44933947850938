/**
 * @file useSendContactFormEmail.js
 */
import { useMutation } from "@apollo/client";
import { SEND_CONTACT_FORM_EMAIL } from "cache/Contact/mutations"; // Update the path as per your project structure

/**
 * @hook useSendContactFormEmail
 *
 * @description
 * Custom hook that handles the functionality for sending a contact form email.
 * Typically used in Contact Form components.
 *
 * @returns
 * - sendContactEmail: A callable that sends the contact form email
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useSendContactFormEmail = () => {

	// Mutation call to SEND_CONTACT_FORM_EMAIL
	const [sendContactFormEmailMutation, { loading, error }] = useMutation(SEND_CONTACT_FORM_EMAIL);

	/**
	 * @function sendContactEmail
	 * 
	 * Callable for sending a contact form email.
	 *
	 * @param {Object} input - ContactFormInput object containing subject, and formData (name, email, phone, and message)
	 * @returns {Object} - An object containing the result data (true or false) or an error object
	 * @throws {Error} - Throws an error if the mutation execution fails
	 */
	const sendContactEmail = async (input) => {
		try {
			const { data } = await sendContactFormEmailMutation({
				variables: {
					input // Mapping directly to the input parameter of the mutation
				}
			});
			if(error){
				throw new Error(error);
			}
			// If the mutation itself throws an error, it will be caught in the catch block
			return { success: data.sendContactFormEmail };
		} catch (error) {
			// If an error occurs, wrap it into an object and return
			return { error: error };
		}
	};

	return { sendContactEmail, loading, error };

};

export default useSendContactFormEmail;
