import React from "react";

import "../App.scss";

const Loader = () => {

	return (
		<div className="loading-spinner">
			<span className="loader"></span>
		</div>
	);

};

export default Loader;