/**
 * @file useUpdateCategory.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_CATEGORY } from "cache/Category/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateCategory
 *
 * @description
 * Custom hook that handles updating a category's information.
 *
 * @returns
 * - update function
 */
const useUpdateCategory = () => {

	const [updateCategoryMutation, { loading, error }] = useMutation(UPDATE_CATEGORY);

	const update = async (input) => {

		try {
			const { data } = await updateCategoryMutation({ 
				variables: { input: input }
			});
			if (error) {
				throw new Error(error);
			}
			let updateCategoryData = data.updateCategory;
			if (updateCategoryData?.error) {
				throw new Error(updateCategoryData.error);
			} else {
				return { data: updateCategoryData };
			}
		} catch (error) {
			return { error };
		}

	};

	return { update, loading, error };

};

export default useUpdateCategory;
