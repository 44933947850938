/**
 * @file useAdminSearchProducts.js
 */

import { useQuery } from "@apollo/client";
import { ADMIN_SEARCH_PRODUCTS } from "cache/Product/queries";

/**
 * @hook useAdminSearchProducts
 *
 * @description
 * Custom hook that handles the ADMIN_SEARCH_PRODUCTS query functionality.
 * This hook can be used wherever you need to search for products based on the input criteria provided.
 *
 * @param {Object} input - The input for the adminSearchProducts query. This input should match the AdminSearchProductsInput type.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useAdminSearchProducts = (input) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(ADMIN_SEARCH_PRODUCTS, {
		variables: {
			input
		},
		fetchPolicy: "network-only"
	});

	let snaggedData = data?.adminSearchProducts;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useAdminSearchProducts;
