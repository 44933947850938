import React from "react";
import { Box, Flex, Text, Image, VStack } from "@chakra-ui/react";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
import useGetGalleryImages from "hooks/GalleryImage/queries/useGetGalleryImages";

import Carousel from "Blueprints/Carousel/Carousel";
import InfoMsg from "Blueprints/InfoMsg";

const ImgGallery = () => {

	const { loading, error, data: galleryImages } = useGetGalleryImages();

	// const [isModalOpen, setIsModalOpen] = useState(false);
	// const [selectedImage, setSelectedImage] = useState(null);

	return (
		<Flex 
			w="100%" 
			minH="100%"
			h="100%"
			bg="#edf3f8" 
			alignItems="center" 
			justifyContent="center"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={galleryImages}
			>
				<Flex 
					w={{
						base: "90%",
						md: "100%",
					}} 
					minH="100%"
					h="100%"
					overflow="hidden"
				>
					{
						galleryImages?.length > 0 ?
							<Carousel
								height="800px"
								showArrowsOnMobile={true}
							>
								{galleryImages?.map((galleryImage, index) => (
									<Box 
										key={`slide-${index}`} 
										className="card_class"
										shadow="md" 
										_hover={{ "> img": { filter: "brightness(0.8)" } }}
										borderWidth="1px"
										borderRadius="lg"
										p={4}
										boxShadow="md"
										w={{
											base: "70vw",
											md: "3xl",
										}}
										h="100%"
										position="relative"
										display="flex"
										flexDirection="column"
									>
										<Image 
											src={galleryImage.image} 
											alt="carousel image" 
											h="100%"
											w="100%"
											maxH="500px"
											backgroundSize="cover" 
											transition="filter 2s ease" 
											cursor="pointer" 
											borderWidth="1px"
											rounded="lg"
											borderColor="gray.300"
										/>
										<VStack spacing={2} align="center" mt={4}>
											<Text fontWeight="bold" fontSize="lg">{galleryImage.title}</Text>
											<Text>{galleryImage.description}</Text>
										</VStack>
									</Box>
								))}
							</Carousel>
							:
							<Flex
								minH="40vh"
								w="100%"
								justifyContent="center"
								alignItems="center"
							>
								<InfoMsg>
									There are currently no Gallery Images. Please check back later.
								</InfoMsg>
							</Flex>
					}
				</Flex>
			</LoadingErrorDataWrapper>
		</Flex>
	);
};

export default ImgGallery;
