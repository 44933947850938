/**
 * @file mutations.js is a file for all Product mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_PRODUCT - GraphQL mutation to create a product.
 *
 * @summary
 * - This mutation will create a new Product and return the product object if successful.
 *
 * @param {Object} createProductInput - The input object for creating a product.
 */
export const CREATE_PRODUCT = gql`
	mutation createProduct($input: CreateProductInput!) {
		createProduct(input: $input) {
			_id
			name
			category {
				_id
				name
				breadcrumbs {
					_id
					name
				}
			}
			images
			description
			variants
			variations {
				_id
				sku
				quantity
				price
				isDefaultVariation
			}
			similar_products {
				_id
				name
			}
			createdAt
			updatedAt
			partner
		}
	}
`;

/**
 * @const {Object} UPDATE_PRODUCT - GraphQL mutation to update a product.
 *
 * @summary
 * - This mutation will update a Product and return the boolean true if successful.
 *
 * @param {String} productId - The id of the product.
 * @param {Object} updateProductInput - The input object for updating a product.
 */
export const UPDATE_PRODUCT = gql`
	mutation updateProduct($productId: String!, $input: UpdateProductInput!) {
		updateProduct(productId: $productId, input: $input)
	}
`;

/**
 * @const {Object} UPDATE_PRODUCT_IMAGES
 * 
 * @summary
 * - This mutation will update a Product's images and return the boolean true if successful.
 * 
 * @param {String} productId - The id of the product.
 * @param {Array} images - The array of images to update the product with.
 */
export const UPDATE_PRODUCT_IMAGES = gql`
	mutation updateProductImages($productId: String!, $images: [String]!) {
		updateProductImages(productId: $productId, images: $images)
	}
`;

/**
 * @const {Object} REORDER_PRODUCT_IMAGES
 * 
 * @summary
 * - This mutation will reorder a Product's images and return the boolean true if successful.
 * 
 * @param {String} productId - The id of the product.
 * @param {Array} images - The array of images to update the product with.
 */
export const REORDER_PRODUCT_IMAGES = gql`
	mutation reorderProductImages($productId: String!, $images: [String]!) {
		reorderProductImages(productId: $productId, images: $images)
	}
`;

/**
 * @const {Object} DELETE_PRODUCT - GraphQL mutation to delete a product.
 *
 * @summary
 * - This mutation will delete a Product and return the boolean true if successful.
 *
 * @param {String} productId - The id of the product.
 */
export const DELETE_PRODUCT = gql`
	mutation deleteProduct($productId: String!) {
		deleteProduct(productId: $productId)
	}
`;
