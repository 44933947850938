import React from "react";

import {
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

/**
 * @component ErrorMsg
 * 
 * @description
 * - This component is used to display an error message
 */
const InfoMsg = ({ info, children}) => {
	return (
		<Alert 
			status="info" 
			variant="subtle" 
			minHeight="200px" 
			w="100%" 
			flexDirection="column" 
			justifyContent={"space-evenly"}
			size="xl"
		>
			<AlertIcon boxSize="40px"/>
			<AlertDescription fontSize="md">{children} {info}</AlertDescription>
		</Alert>
	);
};

export default InfoMsg;