/**
 * @file - Table.js
 */
import React, { useEffect, useState } from "react";

import {
	Button,
} from "@chakra-ui/react";

import UpdateCustomer from "./modals/UpdateCustomer";

import DeleteCustomer from "./modals/DeleteCustomer";

import { TextCell, TableCell } from "../../components/TableCell";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";

import "./Table.scss";

/**
 * @component CustomerRow
 * 
 * @param {*} props 
 * @returns 
 */
const CustomerRow = (props) => {
	const customer = props.customer;

	const [showUpdateCustomer, setShowUpdateCustomer] = useState(false);
	const openUpdateCustomerModal = (e) => {
		e.stopPropagation();
		setShowUpdateCustomer(true);
	};
	const closeUpdateCustomerModal = () => setShowUpdateCustomer(false);

	const [showDeleteCustomer, setShowDeleteCustomer] = useState(false);
	const openDeleteCustomerModal = (e) => {
		e.stopPropagation();
		setShowDeleteCustomer(true);
	};
	const closeDeleteCustomerModal = () => setShowDeleteCustomer(false);


	return (
		<RowContainer
			onClick={openUpdateCustomerModal}
		>
			<TextCell>
				{customer.name}
			</TextCell>

			<TextCell>
				{customer.email}
			</TextCell>

			<TextCell>
				{customer.phone ? customer.phone : ""}
			</TextCell>

			<TableCell>
				<Button
					mr={2}
					variant="primary"
					onClick={openUpdateCustomerModal}
				>
						Update
				</Button>
				<Button
					variant="danger"
					onClick={openDeleteCustomerModal}
				>
						Delete
				</Button>
			</TableCell>
			<UpdateCustomer
				isOpen={showUpdateCustomer}
				onClose={closeUpdateCustomerModal}
				selectedCustomer={customer}
				customersRefetch={props.customersRefetch}
			/>
			<DeleteCustomer
				isOpen={showDeleteCustomer}
				onClose={closeDeleteCustomerModal}
				customer={customer}
				refetch={props.customersRefetch}
			/>
		</RowContainer>
	);
};

const CustomersTable = (props) => {

	const [customers, setCustomers] = useState(props.customers);

	useEffect(() => {
		if (props.customers) setCustomers(props.customers);
	}, [props.customers]);


	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("name", order)}
					search={(value) => props.search("name", value)}
					clearToggle={props.clearToggle}
				>
					Name/Company
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("email", order)}
					search={(value) => props.search("email", value)}
					clearToggle={props.clearToggle}
				>
					Email
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("phone", order)}
					search={(value) => props.search("phone", value)}
					clearToggle={props.clearToggle}
				>	
					Phone
				</TextCell>
				<TextCell>
					Actions
				</TextCell>
			</HeaderContainer>
			{customers.map((customer, index) => (
				<CustomerRow
					key={index}
					customer={customer}
					customersRefetch={props.customersRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default CustomersTable;