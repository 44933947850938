/* eslint-disable no-unused-vars */
/**
 * @file
 * This file defines the custom theme for the Chakra UI library.
 * 
 * @summary Custom Chakra UI theme definition.
 */

import { defineStyle, extendTheme, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";


/**
 * Custom color definitions for the theme.
 */
export const TF_RED = "#EB1313";
const TF_DELGATO = "#E41219";
export const TF_AMARA = "#E5E4E2";
const TF_WHITE = "#ffffff";
const TF_SHADOW_WHITE = "#F2F3F5";
const TF_DARK_GRAY = "#292929";
const TF_LIGHT_GRAY = "#7B809A";
export const TF_BLACK = "#1F1F1F";
const TF_TIMBERGRAY = "#DBD7D2";
const TF_ADMIN_RED = "#DC343B";
const TF_ADMIN_OFFWHITE = "#FFFFFF";
export const TF_ADMIN_SOFTRED = "#AB4E52";
const TF_ADMIN_WHITE = "#FFFFFF";
const TF_ADMIN_GRAY = "#E5E4E2";
const TF_ADMIN_DARKSKY = "#DBD7D2";

/**
 * Breakpoints for responsive design.
 */
export const breakpoints = {
	base: "0em", // 0px Scale 0
	xs: "15em", // 240px Scale 0.5
	sm: "30em", // 480px Scale 1
	md: "45em", // 720px Scale 1.5
	lg: "60em", // 960px Scale 2
	llg: "75em", // 1200px Scale 2.5
	xl: "90em", // 1440px Scale 3
	"2xl": "105em", // 1680px Scale 3.5
};

/**
 * Button style definition.
 */
const TF_BUTTON = defineStyle({
	baseStyle: {
		transition: "all 0.2s ease-in-out",
		_hover: {
			transform: "scale(1.05)",
		}
	},
	variants: {
		primary: {
			bg: TF_RED,
			color: TF_WHITE,
			borderRadius: "1rem",
			border: "1px solid " + TF_WHITE,
			_hover: {
				bg: TF_WHITE,
				color: TF_RED,
				border: "1px solid " + TF_RED,
			}
		},
		primary2: {
			bg: TF_RED,
			color: TF_WHITE,
			borderRadius: "1rem",
			border: "1px solid " + TF_TIMBERGRAY,
			_hover: {
				bg: TF_WHITE,
				color: TF_RED,
				border: "1px solid " + TF_TIMBERGRAY,
			}
		},
		admin_primary: {
			bg: TF_ADMIN_OFFWHITE,
			color: TF_ADMIN_SOFTRED,
			borderRadius: "1rem",
			border: "1px solid " + TF_ADMIN_GRAY,
			_hover: {
				bg: TF_ADMIN_RED,
				color: TF_ADMIN_OFFWHITE,
				border: "1px solid " + TF_ADMIN_DARKSKY,
			}
		},
		admin_inverse: {
			bg: TF_ADMIN_OFFWHITE,
			color: TF_ADMIN_SOFTRED,
			borderRadius: "1rem",
			border: "1px solid " + TF_ADMIN_GRAY,
			_hover: {
				bg: TF_ADMIN_RED,
				color: TF_ADMIN_OFFWHITE,
				border: "1px solid " + TF_ADMIN_DARKSKY,
			}
		},
		search_btn: {
			bg: TF_ADMIN_RED,
			color: TF_ADMIN_OFFWHITE,
			borderRadius: "1rem",
			border: "1px solid " + TF_ADMIN_DARKSKY,
			_hover: {
				bg: TF_ADMIN_RED,
				color: TF_ADMIN_OFFWHITE,
				border: "1px solid " + TF_ADMIN_DARKSKY,
			}
		},
		inverse: {
			bg: TF_WHITE,
			color: TF_RED,
			borderRadius: "1rem",
			border: "1px solid " + TF_RED,
			_hover: {
				bg: TF_RED,
				color: TF_WHITE,
				border: "1px solid " + TF_WHITE,
			}
		},
		gelato: {
			bg: TF_WHITE,
			color: TF_DELGATO,
			borderRadius: "lg",
			border: "1px solid " + TF_AMARA,
			boxShadow: "lg",
			_hover: {
				bg: TF_WHITE,
				color: TF_DELGATO,
				border: "1px solid " + TF_AMARA,
				transfowm: "scale(1.05)",
				grow: 1.5,
				boxShadow: "lg",
			}
		},
		delgato: {
			bg: TF_DELGATO,
			color: TF_WHITE,
			borderRadius: "lg",
			border: "1px solid " + TF_WHITE,
			_hover: {
				bg: TF_DELGATO,
				color: TF_WHITE,
				border: "1px solid " + TF_WHITE,
				transfowm: "scale(1.05)",
				boxShadow: "lg",
			}
		},
		amara: {
			bg: TF_AMARA,
			color: TF_DELGATO,
			borderRadius: "1rem",
			border: "1px solid " + TF_AMARA,
			_hover: {
				bg: TF_AMARA,
				color: TF_DELGATO,
				border: "1px solid " + TF_AMARA,
				transfowm: "scale(1.05)",
				boxShadow: "lg",
			}
		},
		text_admin: {
			_hover: {
				color: TF_ADMIN_RED,
				textDecoration: "bold",
				transform: "scale(1.05)",
				transition: "all 0.2s ease-in-out",
			}
		},
	}
});



/**
 * Heading style definition.
 */
const TF_HEADING = defineStyle({
	variants: {
		white_hero: {
			color: TF_WHITE,
			fontWeight: "extrabold",
			fontSize: "5xl",
			textShadow: "2px 2px " + TF_BLACK,
		},
		gray_heading: {
			color: TF_DARK_GRAY,
			fontWeight: "bold",
			fontSize: "5xl",
			textShadow: "1px 1px #292929",
		},
		gray_subheading: {
			color: TF_DARK_GRAY,
			fontWeight: "normal",
			fontSize: "3xl",
			textShadow: "1px 1px #000000",
		},
		main_subheading: {
			color: TF_DARK_GRAY,
			fontWeight: "normal",
			fontSize: "3xl",
           	textShadow: "0px 0px 1px #000000",
		},
		main_heading: {
			color: TF_DARK_GRAY,
			fontWeight: "normal",
			fontSize: "4xl",
           	textShadow: "0px 0px 1px #000000",
		},
	}
});

/**
 * Text style definition.
 */
const TF_TEXT = defineStyle({
	variants: {
		light_gray_text: {
			color: TF_LIGHT_GRAY,
			fontSize: "lg",
		},
		dark_gray_text: {
			color: TF_DARK_GRAY,
			fontSize: "lg",
		},
		white_text: {
			color: TF_WHITE,
			fontSize: "lg",
		},
		black_text: {
			color: TF_BLACK,
			fontSize: "lg",
		},
		cancelled_text: {
			color: TF_RED,
			fontWeight: "bold",
			fontSize: "lg",
		},
	}
});

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const CHECKBOX_PRIMARY = definePartsStyle({
	control: defineStyle({
		_checked: {
			borderColor: TF_RED,
			backgroundColor: TF_RED,
			color: TF_SHADOW_WHITE,  // You can choose a different color for the icon if you like
			//Set hover to be background black, check is white
			transition: "all 0.2s cubic-bezier(.08,.52,.52,1)",
			_hover: {
				bg: TF_ADMIN_SOFTRED,
				color: TF_SHADOW_WHITE,
				borderColor: TF_ADMIN_SOFTRED,
				transform: "scale(1.2)",
			},
		},
	}),
});

const TF_CHECKBOX = defineMultiStyleConfig({
	variants: { primary: CHECKBOX_PRIMARY },
});

/**
 * Extension of the default Chakra UI theme with custom styles, colors, and breakpoints.
 */
export const TF_THEME = extendTheme({
	components: {
		Heading: {
			variants: {...TF_HEADING.variants},
		},
		Text: {
			variants: {...TF_TEXT.variants},
		},
		Button: {
			baseStyle: {...TF_BUTTON.baseStyle},
			variants: {...TF_BUTTON.variants},
		},
		Checkbox: {
			variants: { ...TF_CHECKBOX.variants },
		},
	},
	fonts: {},
	colors: {
		white: TF_WHITE,
		smoke: TF_SHADOW_WHITE,
		dark_gray: TF_DARK_GRAY,
		light_gray: TF_LIGHT_GRAY,
		black: TF_BLACK,
		tf_red: TF_RED,
	},
	breakpoints,
	config: {
		initialColorMode: "light",
		useSystemColorMode: false,
	},
});
