/**
 * @file useReorderReviews.js
 */

import { useMutation } from "@apollo/client";
import { REORDER_REVIEWS } from "cache/Review/mutations"; // Update the path as per your project structure

/**
 * @hook useReorderReviews
 * 
 * @description
 * Custom hook that manages the functionality of reordering reviews.
 * This can be utilized in scenarios where there's a requirement to rearrange 
 * the reviews in a specific sequence, ensuring the backend reflects the updated order.
 *
 * @returns
 * - reorderReviews: Function to execute the reorder reviews mutation
 * - loading: Boolean indicating the mutation's loading state
 * - error: Error object if there's an error during mutation execution
 */
const useReorderReviews = () => {

	const [reorderMutation, { loading, error }] = useMutation(REORDER_REVIEWS);

	/**
	 * Executes the reorder mutation.
	 *
	 * @param {Array} input - An array of objects detailing how to reorder the reviews.
	 * Each object must conform to the requirements of the ReorderInput type.
	 *
	 * @returns {Object} - An object containing the mutation result (true/false) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution encounters an issue.
	 */
	const reorderReviews = async (input) => {
		try {
			const { data } = await reorderMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// As the mutation yields a Boolean, it's processed accordingly.
			return { data: data.reorderReviews };
		} catch (error) {
			return { error };
		}
	};

	return { reorderReviews, loading, error };
};

export default useReorderReviews;
