/**
 * @file CreateCustomer.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

import {
	Alert,
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useCreateCustomer from "hooks/Customer/mutations/useCreateCustomer";

/**
 * @component CreateCustomer
 * 
 * @returns 
 */
const CreateCustomer = ({ isOpen, onClose, customersRefetch }) => {

	const [input, setInput] = useState({
		name: "",
		email: "",
		phone: "",
		address: {
			line1: "",
			line2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
		},
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const handleUpdateAddress = (e) => {
		setInput({
			...input,
			address: {
				...input.address,
				[e.target.name]: e.target.value,
			},
		});
	};

	const { createCustomer } = useCreateCustomer();

	const handleCreateCustomer = async (e) => {
		e.preventDefault();

		if(!validateInput()) return;

		setLoading(true);

		const { data, error } = await createCustomer(input);
		if (error) {
			setError(error.message);
			setLoading(false);
			return;
		}
		if (data) {
			await customersRefetch();
			setLoading(false);
			onClose();
			return;
		}
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if(!input.name){
			errors.name = "Name/Company is required";
		}
		if(!input.email){
			errors.email = "Email is required";
		}
		if(input.email && !(input.email)){
			errors.email = "Invalid email";
		}
		if (input.address.line1 && !input.address.city) {
			errors.city = "City is required";
		}
		if (input.address.line1 && !input.address.state) {
			errors.state = "State is required";
		}
		if (input.address.line1 && !input.address.zip) {
			errors.zip = "Zip is required";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose}
			isCentered
			size="2xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Customer</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{error && (
						<Alert status="error" mb={4}>
							<AlertIcon />
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}
					<Stack 
						spacing={4}
						as="form"	
					>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name/Company</FormLabel>
							<Input
								name="name"
								type="text"
								value={input.name}
								onChange={handleUpdateInput}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="email" 
							isRequired
							isInvalid={formErrors.email}
						>
							<FormLabel>Email</FormLabel>
							<Input
								name="email"
								type="email"
								value={input.email}
								onChange={handleUpdateInput}
							/>
							<FormErrorMessage>{formErrors.email}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="phone"
						>
							<FormLabel>Phone</FormLabel>
							<Input
								name="phone"
								type="text"
								value={input.phone}
								onChange={handleUpdateInput}
							/>
						</FormControl>
						<FormControl 
							id="address"
						>
							<FormLabel>Address</FormLabel>
							<Input
								name="line1"
								type="text"
								placeholder="Line 1"
								value={input.address.line1}
								onChange={handleUpdateAddress}
							/>
							<Input
								name="line2"
								type="text"
								placeholder="Line 2"
								value={input.address.line2}
								onChange={handleUpdateAddress}
							/>
							<Input
								name="city"
								type="text"
								placeholder="City"
								value={input.address.city}
								onChange={handleUpdateAddress}
							/>
							<Input
								name="state"
								type="text"
								placeholder="State"
								value={input.address.state}
								onChange={handleUpdateAddress}
							/>
							<Input
								name="zip"
								type="text"
								placeholder="Zip"
								value={input.address.zip}
								onChange={handleUpdateAddress}
							/>
							<Input
								name="country"
								type="text"
								placeholder="Country"
								value={input.address.country}
								onChange={handleUpdateAddress}
							/>
						</FormControl>
						<Button
							type="submit"
							variant="primary"
							onClick={handleCreateCustomer}
							isLoading={loading}
							isDisabled={Object.keys(formErrors).length !== 0}
						>
							Create Customer
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default CreateCustomer;