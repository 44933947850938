/**
 * @file - deleteAdminModal.js
 * 
 * @version 1.0.0
 * 
 * @description 
 * - This file contains the functions that are used to delete an admin from the database.
 * - ChakraUI is used to create the modal.
 * - The functions are called from the deleteAdminModal.js file. 
 * - The modal is used to confirm the deletion of an admin.
 * - The modal is used to display the error message if the admin is not deleted.
 * - The modal is used to display the success message if the admin is deleted.
 * - The modal is used to display the loading spinner while the admin is being deleted.
 */

// ----- Imports ----- //

import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useDeleteAdmin from "hooks/Admin/mutations/useDeleteAdmin";

/**
 * @function DeleteAdminModal
 * @description - This function is used to create the modal that is used to delete an admin.
 * @returns - This function returns the JSX used to create the modal.
 */
const DeleteAdminModal = ({ isOpen, onClose, admin, adminsRefetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteAdmin } = useDeleteAdmin();

	/**
	 * @function handleDeleteAdmin
	 * @description - This function is used to delete an admin from the database.
	 * 
	 * @param {*} e - The event object
	 */
	const handleDeleteAdmin = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await deleteAdmin(admin._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success) {
			setLoading(false);
			await adminsRefetch();
			onClose();
		}
	};

	return(
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Admin</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						{`Are you sure you want to delete the admin: ${admin.username}?`}
					</Text>
					<Text>
						This action cannot be undone.
					</Text>
					<Text>
						Type the admin's username to confirm.
					</Text>
					<Input
						placeholder="Admin Username"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
					{errorMsg && (
						<Alert status="error" mt={4}>
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						onClick={handleDeleteAdmin}
						isLoading={loading}
						isDisabled={confirmText !== admin.username}
					>
						Delete Admin
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>		
	);
};

export default DeleteAdminModal;
