import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Image, Button, Text, useColorModeValue } from "@chakra-ui/react";

const GalleryHero = (props) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	// Destructure props for clarity
	const {
		images = [], // Expecting an array of image URLs
		description,
		objectFit = "cover",
		objectPosition = "top bottom",
		button = null,
		boxHeight = { base: "340px", md: "md" },
		boxPaddingY = "28",
		subHeadingSize = "lg",
		searchBar,
		title,
		subtitle,
		shouldRepeatImage = false,
		shouldUseOverlay = true,
	} = props;

	// Change image every 5 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 3000);

		return () => clearInterval(interval);
	}, [images.length]);

	const textBgColor = useColorModeValue("rgba(0, 0, 0, 0.5)", "rgba(255, 255, 255, 0.5)"); // This will adapt to light/dark mode if enabled


	const bgValue = shouldUseOverlay ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.0)";

	return (
		<Box
			bg="gray.500"
			as="section"
			position="relative"
			zIndex={0}
			w="100%"
			minH={boxHeight}
			overflow="hidden"
			alignItems="center"
			justifyContent="center"
			display="flex"
		>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="100%"
				overflow="hidden"
				align="center"
				zIndex={-1}
				//Add slight opaque overlay to images
				_after={{
					content: "\"\"",
					position: "absolute",
					inset: "0",
					bg: bgValue,//"blackAlpha.600",
					zIndex: 0,
				}}
			>
				{images.map((img, index) => (
					<Box
						key={img}
						position="absolute"
						w="full"
						h="full"
						display="block"
						opacity={index === currentImageIndex ? 1 : 0}
						transition={"all 0.75s cubic-bezier(.17,.67,.83,.67)"}
					>
						{shouldRepeatImage ? (
							<Box
								bgImage={`url(${img})`}
								bgRepeat="repeat"
								bgSize="300px 300px"
								bgPosition="center 150%"
								position="absolute"
								w="full"
								h="full"
								opacity={index === currentImageIndex ? 1 : 0}
								transition="all 0.75s cubic-bezier(.17,.67,.83,.67)"
							/>
						) : (
							<Image
								src={img}
								alt={`Slide ${index}`}
								w="100%"
								h="100%"
								objectFit={objectFit}
								objectPosition={objectPosition}
								position="absolute"
								loading="eager"
							/>
						)}
						<Box
							position="absolute"
							w="100%"
							h="100%"
							bg={shouldUseOverlay ? "#8B8589" : "rgba(0, 0, 0, 0.3)"}
							opacity={0.28}
						/>
					</Box>
				))}
			</Flex>
			<Box py={boxPaddingY} position="relative" zIndex={0}>
				<Box maxW="100%" mx="auto" px={{ base: "6", md: "8" }} color="white">
					<Box maxW="7xl" zIndex={10}>
						<Heading as="h1" size="3xl" fontWeight="extrabold" textShadow="3px 3px 3px rgba(0, 0, 0, 0.8)" color="white" bg="rgba(0, 0, 0, 0.0)" borderRadius="6px" pb={6} pt={4} px={4}>
							{title}
						</Heading>
						{subtitle && (
							<Heading as="h2" size={subHeadingSize} fontWeight="medium" textShadow="3px 3px 3px rgba(0, 0, 0, 0.8)" color="white" bg="rgba(0, 0, 0, 0.0)" borderRadius="6px" px={4} pb={6}>
								{subtitle}
							</Heading>
						)}
						{description && (
							<Box color="gray.200" mt={4}>
								<Text
									bg={textBgColor}
									bgClip="text"
									color="white"
									fontWeight="bold"
									fontSize="xl"
									textAlign="center"
									p={4}
									rounded="md"
									textShadow="2px 2px 2px rgba(0, 0, 0, 0.8)"
								>
									{description}
								</Text>
							</Box>
						)}
						{searchBar && <searchBar />}
						{button && (
							<Button {...button.props}>
								{button.text}
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default GalleryHero;
