/**
 * @file queries.js is a file for all Product queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_PRODUCT - GraphQL query to get a product by ID.
 *
 * @summary
 * - This query will return the product object.
 * - This query will return null if the product is not found.
 */
export const GET_PRODUCT = gql`
	query getProduct($name: String!) {
		getProduct(name: $name) {
			_id
			name
			category {
				_id
				name
				breadcrumbs {
					_id
					name
				}
			}
			images
			description
			variants
			variations {
				_id
				sku
				quantity
				price
				isDefaultVariation
			}
			similar_products {
				_id
				name
				images
				description
				partner
			}
			createdAt
			updatedAt
			partner
		}
	}
`;

/**
 * @const {Object} SEARCH_PRODUCTS
 * 
 * @summary
 * - This query will return an array of product objects.
 * 
 * @param {String} term - The search term.
 * 
 * 
 */
export const SEARCH_PRODUCTS = gql`
	query searchProducts($input: SearchProductsInput!) {
		searchProducts(input: $input) {
			items {
				_id
				name
				category {
					_id
					name
					breadcrumbs {
						_id
						name
					}
				}
				images
				description
				variants
				variations {
					_id
					sku
					quantity
					price
					isDefaultVariation
				}
				similar_products {
					_id
					name
					images
					description
					partner
				}
				createdAt
				updatedAt
				partner
			}
			totalItems
			hasNextPage
			hasPreviousPage
		}
	}
`;

/**
 * @const {Object} ADMIN_GET_ALL_PRODUCTS
 * 
 * @summary
 * - This query will return an array of product objects
 * 
 */
export const ADMIN_SEARCH_PRODUCTS = gql`
	query adminSearchProducts($input: AdminSearchProductsInput!) {
		adminSearchProducts(input: $input) {
			items {
				_id
				name
				category {
					_id
					name
					breadcrumbs {
						_id
						name
					}
				}
				images
				description
				variants
				variations {
					_id
					sku
					quantity
					price
					isDefaultVariation
				}
				similar_products {
					_id
					name
					images
					description
					partner
				}
				createdAt
				updatedAt
				partner
			}
			totalItems
			hasNextPage
			hasPreviousPage
		}
	}
`;

/**
 * @const {Object} GET_PRODUCTS_BY_CATEGORY
 * 
 * @summary
 * - This query will return an array of product objects.
 */
export const GET_PRODUCTS_BY_CATEGORY = gql`
	query getProductsByCategory($category: String!) {
		getProductsByCategory(category: $category) {
			_id
			name
			category {
				_id
				name
				breadcrumbs {
					_id
					name
				}
			}
			images
			description
			variants
			variations {
				_id
				sku
				quantity
				price
				isDefaultVariation
			}
			similar_products {
				_id
				name
				images
				description
				partner
			}
			createdAt
			updatedAt
			partner
		}
	}
`;