/**
 * @file
 * This is the PaymentsPage component of the application.
 * It represents the payments page of the website and includes the Invoice child component.
 * It uses framer-motion for page transitions.
 * 
 * @summary Payments page component.
 */

import React from "react";
// import Hero from "Blueprints/Hero";
import { motion } from "framer-motion";
import Authentication from "./components/Authentication";
import "./Payments.scss";
// import Payments from "../../../assets/TaylorFenceImg/PVC_SpindleTop.png";

/**
 * The PaymentsPage component displays the payments page of the website.
 *
 * @returns {HTMLElement} - A motion.div component from framer-motion that includes the Hero and Invoice child components for the payments page.
 */
const PaymentsPage = () => {
	const pageVariants = {
		initial: {
			opacity: 0,
		},
		in: {
			opacity: 1,
		},
		out: {
			opacity: 0,
		}
	};
    
	const pageTransition = {
		type: "tween",
		ease: "easeInOut",
		duration: 0.65
	};
	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
			className="payments"
		>
			<Authentication />
		</motion.div>
	);
};

export default PaymentsPage;

