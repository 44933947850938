import {
	Button,
	Center,
	VStack,
	Icon,
	useColorModeValue,
	Text,
	Input,
	Flex
} from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";
import React, { useRef } from "react";

const MAX_PDF_SIZE = 10 * 1024 * 1024; //5MB

export const PDFDropzone = (props) => {

	const fileInputRef = useRef();
	const handleFileInputClick = () => fileInputRef.current.click();

	const onFilesAdded = (evt) => {
		const file = evt.target.files[0];
		if (file && file.size > MAX_PDF_SIZE) {
			alert("File is too large. Please upload a PDF less than 10MB.");
			return;
		}
		if (file && file.type !== "application/pdf") {
			alert("Invalid file type. Please upload a PDF.");
			return;
		}
		props.setPDFFile(file); // Assumes a prop function setPDFFile is provided
	};

	const handleDeletePDF = () => {
		props.setPDFFile(null);
	};

	return (
		<Center
			onDrop={onFilesAdded} // This can be refined to support drag-drop if needed
			borderWidth="1px"
			borderRadius="lg"
			px="6"
			py="4"
			bg={useColorModeValue("white", "gray.800")}
		>
			<VStack spacing="3">
				<Icon as={FiUploadCloud} boxSize="5" color="muted" />
				{!props.pdfFile ? (
					<VStack spacing="1">
						<Button 
							variant="link" 
							colorScheme="blue" 
							size="sm"
							onClick={handleFileInputClick}
						>
							Click to upload
						</Button>
						<Text fontSize="xs" color="muted">
							PDF up to 10MB
						</Text>
						<Input
							type="file"
							ref={fileInputRef}
							onChange={onFilesAdded}
							accept=".pdf"
							style={{ display: "none" }}
						/>
					</VStack>
				) : (
					<Flex direction="column" align="center">
						<Text>{props?.pdfFile?.name}</Text>
						<Button
							size="sm"
							colorScheme="red"
							onClick={handleDeletePDF}
						>
							Delete
						</Button>
					</Flex>
				)}
			</VStack>
		</Center>
	);
};
