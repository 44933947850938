/**
 * @file VariationDisplay.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Stack,
	Text,
	FormControl,
	Checkbox,
	Button,
	Wrap,
	WrapItem,
	useBreakpointValue,
} from "@chakra-ui/react";

import { FiEdit, FiTrash2 } from "react-icons/fi";

import EditVariant from "../Modals/EditVariant";

/**
 * @component VariationDisplay
 * @param {} props 
 */
const VariationDisplay = (props) => {

	const [variation, setVariation] = useState(props.variation);
	const index = props.index;

	useEffect(() => {
		if(props.variation){
			setVariation(props.variation);
		}
	}, [props.variation]);

	const [showEditVariant, setShowEditVariant] = useState(false);
	const openEditVariant = () => {setShowEditVariant(true);};
	const closeEditVariant = () => {setShowEditVariant(false);};

	const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

	return (
		<Flex 
			alignItems="center" 
			justifyContent="space-between" 
			direction={isMobile ? "column" : "row"}
			gap={"1rem"}
			borderWidth="1px"
			borderColor="gray.300"
			boxShadow="md"
			rounded="md"
			py={8}
			px={5}
			mb={4}
		>
			<Stack
				direction={isMobile ? "column" : "row"}
				width="100%"
			>
				<Text
					fontSize={{
						sm: "md",
						md: "lg",
					}}
				>
					Variation {index+1}
				</Text>
			</Stack>
			<Stack
				direction={isMobile ? "column" : "row"}
				width="100%"
			>
				<Text>SKU: {variation.sku}</Text>	
			</Stack>
			<FormControl>
				<Checkbox
					isChecked={variation.isDefaultVariation}
					contentEditable={false}
				>
                    Default Variation?
				</Checkbox>
			</FormControl>
			{/* <FormControl>
				<FormLabel>
                    Options
				</FormLabel>
				{
					props.variationOptions.map((variation_option, options_index) => {
						return (
							<FormControl key={options_index}>
								<Stack
									direction={["column", "row"]}
									width="100%"
								>
									<Text>{variation_option}: {variation["options"][variation_option]}</Text>	
								</Stack>
							</FormControl>
						);
					})
				}
			</FormControl> */}
			<Wrap 
				spacing={4}
				align="center"
				width="100%"
				px={2}
				py={2}
				direction={isMobile ? "column" : "row"}
			>
				<WrapItem>
					<Button
						variant="admin_inverse"
						size={isMobile ? "md" : "sm"}
						onClick={openEditVariant}
						leftIcon={<FiEdit/>}
					>
                        Edit Variant {index+1}
					</Button>
				</WrapItem>
				<WrapItem>
					<Button
						variant="admin_inverse"
						size={isMobile ? "md" : "sm"}
						onClick={() => props.onDelete(index)}
						leftIcon={<FiTrash2/>}
					>
                        Delete Variant {index+1}
					</Button>
				</WrapItem>
			</Wrap>
			{
				variation &&
				<EditVariant
					isOpen={showEditVariant}
					onClose={closeEditVariant}
					onSave={props.onSave}
					variation={variation}
					index={index}
				/>
			}
		</Flex>
	);
};

export default VariationDisplay;
