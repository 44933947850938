/**
 * @file CategoryRow.js is a file for the CategoryRow component.
 * 
 * @NoteByBryan 
 * - Would we able to somehow be able to render a 'view product' button for when the user gets all the way down the tree?
 * 
 */
import React, { useState, useEffect } from "react";

import {
	Collapse,
	Box,
	Text,
	Image,
	IconButton,
	Divider,
	AbsoluteCenter,
	Tooltip,
} from "@chakra-ui/react";

import { FiPlusCircle, FiEdit, FiTrash2, FiUserPlus, FiList } from "react-icons/fi";

import useGetChildCategories from "hooks/Category/queries/useGetChildCategories";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import CreateCategoryModal from "./Modals/CreateCategoryModal";

import UpdateCategory from "./Modals/UpdateCategory";

import DeleteCategory from "./Modals/DeleteCategory";

import UpdatePartnersModal from "./Modals/UpdatePartnersModal";

import ReorderCategoryProductsModal from "./Modals/ReorderCategoryProductsModal";

import PlaceholderImage from "assets/placeholder_image.svg";

import { ComplexRowContainer, ComplexRowContentContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

/**
 * @component CategoryRow
 * 
 * @description
 * - Recursive component that renders the category provided as a row, along with a collapse
 *  component that renders the children of the category when the category is clicked
 * - The children of the category are rendered as CategoryRow components
 * - The category is provided as a prop
 * - The category is queried for its children
 * 
 * 
 *  
 * @param {*} props
 */
const CategoryRow = (props) => {

	const [category, setCategory] = useState(props.category);

	useEffect(() => {
		setCategory(props.category);
	}, [props.category]);

	const { data: children, loading, error: errorMsg, refetch: childrenRefetch } = useGetChildCategories(category._id);

	const [showChildren, setShowChildren] = useState(false);

	const toggleShowChildren = () => {
		setShowChildren(!showChildren);
	};

	useEffect(() => {
		if(showChildren){
			childrenRefetch();
		}
	}, [showChildren]);

	const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);

	const openCreateCategoryModal = () => {
		setShowCreateCategoryModal(true);
	};

	const closeCreateCategoryModal = () => {
		setShowCreateCategoryModal(false);
	};

	const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);

	const openUpdateCategoryModal = () => {
		setShowUpdateCategoryModal(true);
	};

	const closeUpdateCategoryModal = () => {
		setShowUpdateCategoryModal(false);
	};

	const [showUpdatePartnersModal, setShowUpdatePartnersModal] = useState(false);
	const openUpdatePartnersModal = () => setShowUpdatePartnersModal(true);
	const closeUpdatePartnersModal = () => setShowUpdatePartnersModal(false);

	const [showReorderProductsModal, setShowReorderProductsModal] = useState(false);
	const openReorderProductsModal = () => setShowReorderProductsModal(true);
	const closeReorderProductsModal = () => setShowReorderProductsModal(false);

	const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

	const openDeleteCategoryModal = () => {
		setShowDeleteCategoryModal(true);
	};

	const closeDeleteCategoryModal = () => {
		setShowDeleteCategoryModal(false);
	};


	useEffect(() => {
		setCategory(props.category);
	}, [props.category]);

	return (
		<ComplexRowContainer
			px={props.depth * 2}
		>
			<ComplexRowContentContainer
				onClick={toggleShowChildren}
				showChildren={showChildren}
			>
				<TableCell>
					<Image 
						src={(category.images && category.images.length > 0) ? category.images[0] : null}
						fallbackSrc={PlaceholderImage}
						alt={category.name} 
						maxH={{
							base: "auto",
							md: "60px",
						}}
						maxW={{
							base: "auto",
							md: "60px",
						}}
						objectFit="cover"
						borderRadius="lg"
						borderWidth="1px"
						borderColor="#CBD5E0"
					/>
				</TableCell>
				<TextCell>
					{props.descriptor}
				</TextCell>
				<TextCell>
					{category.name}
				</TextCell>
				<TableCell>
					<Tooltip
						label="Edit"
						aria-label="Edit"
						openDelay={500}
						bg="gray.500"
						borderRadius="lg"
						placement='bottom-start'
					>
						<IconButton
							variant="text_admin"
							onClick={openUpdateCategoryModal}
							leftIcon={<FiEdit />}
							size="lg"
						></IconButton>
					</Tooltip>
					
					<Tooltip
						label="Delete"
						aria-label="Delete"
						openDelay={500}
						bg="gray.500"
						borderRadius="lg"
						placement='bottom'
					>
						<IconButton
							variant="text_admin"
							size="lg"
							isDisabled={children && children.length > 0 ? true : false}
							onClick={openDeleteCategoryModal}
							leftIcon={<FiTrash2 />}
						></IconButton>
					</Tooltip>
					
					<Tooltip
						label="Add Category"
						aria-label="Add Category"
						openDelay={500}
						bg="gray.500"
						borderRadius="lg"
						placement='bottom'
					>
						<IconButton
							variant="text_admin"
							onClick={openCreateCategoryModal}
							leftIcon={<FiPlusCircle />}
							size="lg"
						></IconButton>
					</Tooltip>

					<Tooltip
						label="Add Partners"
						aria-label="Add Partners"
						openDelay={500}
						bg="gray.500"
						borderRadius="lg"
						placement='bottom'
					>
						<IconButton
							variant="text_admin"
							onClick={openUpdatePartnersModal}
							leftIcon={<FiUserPlus />}
							size="lg"
						></IconButton>
					</Tooltip>

					{category.products && category.products.length > 0 && 
					<Tooltip
						label="Reorder Products"
						aria-label="Reorder Products"
						openDelay={500}
						bg="gray.500"
						borderRadius="lg"
						placement='bottom'
					>
						<IconButton
							variant="text_admin"
							onClick={openReorderProductsModal}
							leftIcon={<FiList />}
							size="lg"
						></IconButton>
					</Tooltip>
					}
				
				</TableCell>
			</ComplexRowContentContainer>
			<Collapse
				in={showChildren}
				animateOpacity
			>
				<LoadingErrorDataWrapper
					loading={loading}
					error={errorMsg?.message}
					data={children}
				>
					{
						children?.length === 0 ? (
							<Text textStyle="lg" fontWeight="medium">
									No subcategories.
							</Text>
						)
							:
							<>
								<Box position='relative' padding='10'>
									<Divider />
									<AbsoluteCenter bg='white' px='4'>
    									{category.child_descriptor}
									</AbsoluteCenter>
								</Box>
								{children?.map((child) => (
									<CategoryRow
										key={child._id}
										category={child}
										depth={props.depth + 1}
										descriptor={category.child_descriptor}
										refetch={childrenRefetch}
									/>
								))}
								<Box position='relative' padding='10'>
									<Divider />
									<AbsoluteCenter bg='white' px='4'>
    									End {category.child_descriptor}
									</AbsoluteCenter>
								</Box>
							</>
					}		
				</LoadingErrorDataWrapper>
			</Collapse>
			<CreateCategoryModal
				isOpen={showCreateCategoryModal}
				onClose={closeCreateCategoryModal}
				parent={category._id}
				refetch={childrenRefetch}
			/>
			<UpdateCategory
				isOpen={showUpdateCategoryModal}
				onClose={closeUpdateCategoryModal}
				category={category}
				refetch={props.refetch}
			/>
			<DeleteCategory
				isOpen={showDeleteCategoryModal}
				onClose={closeDeleteCategoryModal}
				category={category}
				refetch={props.refetch}
			/>
			<UpdatePartnersModal
				isOpen={showUpdatePartnersModal}
				onClose={closeUpdatePartnersModal}
				category={category}
				refetch={props.refetch}
			/>
			{
				category.products && category.products.length > 0 && showReorderProductsModal &&
				<ReorderCategoryProductsModal
					isOpen={showReorderProductsModal}
					onClose={closeReorderProductsModal}
					category={category}
					refetch={props.refetch}
				/>
			}
		</ComplexRowContainer>
	);
};

export default CategoryRow;