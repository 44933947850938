/************************************************************************************************
 * @file CustomFenceCTA.js
 * 
 * @todo Add a form submission handler
 * 
 * @todo Add a form submission success message
 * 
 ***********************************************************************************************/

/**
 * @file CustomFenceCT
 * 
 * @useRef - { CustomFenceCT } from CustomFenceCTA.js to create a new CustomFenceCT object
 * 
 * @useDisclosure - Disclosure function for custom display functions Chakra UI
 * 
 * @useContext - Called when the display function is called from the constructor 
 * 
 */

import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Button, Container, Heading, Image, Stack, Text } from "@chakra-ui/react";

import ContactFormModal from "Blueprints/Modals/contactModal.js";

import HomeCustom from "../../../../assets/Home/CustomWoodCTAImage.jpg";

/**
 * @function CustomFenceCTA
 * @param {*} props 
 * @returns 
 * 
 */

const CustomFenceCTA = () => {

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleContactUs = () => {
		onOpen();
	};
  
	return(
		<Box 
			as="section"
			zIndex={1}
			w="100%"
			h="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Container
				py={{
					base: "16",
					md: "32",
				}}
				maxW="containerxl"
			>
				<Stack
					direction={{
						base: "column",
						md: "row",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						borderRadius="lg"
						borderWidth="1px"
						borderColor="gray.200"
						w={{
							base: "100%",
							md: "50%",
						}}
						bg="rgba(255, 255, 255, 0.7)"
						boxShadow="lg"
						p={8}
						rounded="lg"
					>
						<Box
							textAlign="left"
							py={4}
							px={4}
						> 
							<Heading
								variant="gray_heading"
								align="center"
							>
                            Custom Wood Fencing.
							</Heading>
						</Box>
						<Box
							textAlign="left"
							py={4}
							px={4}
						> 
							<Text
								variant="dark_gray_text"
							>
                              Give yourself the opportunity to truly personalize and manifest your vision. Whether its a unique design or intricate detailing, we have the ability and know-how to get it done.
							</Text>
						</Box>
						<Box
							w="100%"
							alignItems="center"
							py={10}
							px={4}
						>
							<Button
								variant="primary"
								onClick={handleContactUs}
								w="50%"
							>
                              Get Started
							</Button>
						</Box>
					</Box>
					<Box
						w={{
							base: "100%",
							md: "50%",
						}}
						mx={12}
					>
						<Image
							width="full"
							height={{
								base: "auto",
								md: "lg",
							}}
							objectFit="cover"
							src={HomeCustom}
							boxShadow="2xl"
							borderRadius="md"
							alt="Custom Wood Fencing"
						/>
					</Box>
				</Stack>
				<ContactFormModal isOpen={isOpen} onClose={onClose} /> 
			</Container>
		</Box>
	);
};

export default CustomFenceCTA;
