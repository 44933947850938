/**
 * @file useGetShowroomCategory.js
 */

import { useQuery } from "@apollo/client";
import { GET_SHOWROOM_CATEGORY } from "cache/Category/queries"; // Update the path as per your project structure

/**
 * @hook useGetShowroomCategory
 *
 * @description
 * Hook to fetch showroom category data by ID. It retrieves detailed information
 * about the category, including child categories, child partners, and products.
 *
 * @param {String} categoryId - The ID of the category to be fetched.
 *
 * @returns
 * - loading: Boolean indicating the loading state.
 * - error: Error object if there's an error.
 * - data: Result data from the query, specifically the showroom category details.
 * - refetch: Function to refetch the data.
 * - networkStatus: Network status information.
 */
const useGetShowroomCategory = (categoryId) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_SHOWROOM_CATEGORY, {
		variables: { _id: categoryId }
	});

	let showroomCategoryData = data?.getShowroomCategory;

	return { loading, error, data: showroomCategoryData, refetch, networkStatus };
};

export default useGetShowroomCategory;
