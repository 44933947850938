import { 
	Flex, 
	Box, 
	SimpleGrid,
	Text, 
	Center, 
	Heading, 
	useBreakpointValue 
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import FeatureDeals from "./FeatureDeals";

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);

const container = {
	hidden: { opacity: 1, scale: 0 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.8,
			staggerChildren: 0.4
		}
	}
};

import useGetTopLevelCategories from "hooks/Category/queries/useGetTopLevelCategories";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

const CategorySelection = () => {

	const { loading, error, data: categories } = useGetTopLevelCategories();

	const { handlePageChange } = useContext(PageChangeContext);

	const numColumns = useBreakpointValue({ base: 1, md: 2, lg: 4 });

	const handleCategoryClicked = (category) => {
		//If category.name is "Chainlink", redirect to "showroom/chainlink"
		if(category.name.toLowerCase() === "chainlink"){
			handlePageChange("showroom/chainlink");
			return;
		}
		if(category.name.toLowerCase() === "wood"){
			handlePageChange("showroom/6539a77531eefc17f8a3d3ca"); //Styles Category ID
			return;
		}
		handlePageChange(`showroom/${category._id}`);
	};

	return (
		<Flex
			w="full"
			h="full"
			flexDirection="column"
			justifyContent="center"
			pt={{
				base: "0.3rem",
				md: "1rem",
			}}
		>
			<MotionHeading
				fontSize={{
					base: "2xl",
					md: "4xl",
				}}
				fontWeight="bold"
				color="#1B1B1B"
				mt="1rem"
				variants={container}
				initial="hidden"
				animate="visible"
			>
          		Fence Solutions for Every Need
			</MotionHeading>
			<SimpleGrid 
				w="full" 
				h="100%" 
				columns={numColumns}
				spacing={10}
				as="section"
				minH={{
					base: "100px",
					md: "140px",
				}}
				position="relative"
				zIndex={0}
				mt={2}
				mb={{
					base: "4",
					md: "14",
				}}
			>
				<LoadingErrorDataWrapper
					loading={loading}
					error={error?.message}
					data={categories}
				>
					{categories?.map((category, index) => (
						<MotionBox
							key={index}
							py={6}
							px={5}
							mt={{
								base: "0.5rem",
								md: "14",
							}}
							ml={10}
							mr={10}
							h={{
								base: "140px",
								md: "300px",
							}}
							rounded="md"
							boxShadow="2xl"
							bg="blue.500"
							color="white"
							whileHover={{ 
								scale: 1.1,
								transition: { duration: 0.2 },
								cursor: "pointer",
							}}
							transition={{ 
								duration: 0.2,
								scale: 1.1,
								transition: { duration: 0.2 },
							}}
							bgImage={(category.images && category.images.length) > 0 ? `url(${category.images[0]})` : ""}
							bgPosition="center"
							bgRepeat="no-repeat"
							bgSize="cover"
							borderWidth="1px"
							borderColor="gray.500"
							position="relative"
							onClick={() => handleCategoryClicked(category)}
						>
							<Center 
								position="absolute"
								top="0"
								right="0"
								bottom="0"
								left="0"
								backgroundColor="rgba(0, 0, 0, 0.28)"
								rounded="md"
								py={4}
							>
								<MotionText 
									fontSize="xl"
									fontWeight="bold"
									fontFamily="heading"
									// textTransform="uppercase"
									textShadow="2px 2px 2px rgba(0, 0, 0, 0.30)"
									color="white" 
									initial={{ opacity: 0 }} 
									animate={{ opacity: 1 }}
								>
									{category.name}
								</MotionText>
							</Center>
						</MotionBox>
					))}
				</LoadingErrorDataWrapper>
			</SimpleGrid>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<FeatureDeals />
			</Box>
		</Flex>
	);
};

export default CategorySelection;
