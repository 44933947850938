import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Button,
	Box,
	Flex,
	ModalFooter,
	Image
} from "@chakra-ui/react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
	TF_ADMIN_SOFTRED,
	TF_AMARA,
} from "chakraStyles";

const grid = 1;
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	padding: "1rem",
	margin: `0 0 ${grid}px 0`,
	background: isDragging ? TF_AMARA : "transparent",
	borderRadius: "1rem",
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? TF_ADMIN_SOFTRED : TF_AMARA,
	padding: grid,
	width: "100%",
	borderRadius: "8px",
	borderColor: "gray.200",
	boxShadow: "lg",
});

const ReorderImagesModal = ({ isOpen, onClose, initialImages, onSubmitReorder }) => {
	const [reorderedImages, setReorderedImages] = useState({});

	useEffect(() => {
		const initialState = {
			"column": initialImages
		};
		setReorderedImages(initialState);
	}, [initialImages]);

	const onDragEnd = (result) => {
		if (!result.destination) return;
    
		const itemsCopy = Array.from(reorderedImages["column"]);
		const [movedItem] = itemsCopy.splice(result.source.index, 1);
		itemsCopy.splice(result.destination.index, 0, movedItem);
    
		setReorderedImages({ "column": itemsCopy });
	};

	const handleSave = async () => {
		const orderedImages = reorderedImages["column"];
		await onSubmitReorder(orderedImages);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Reorder Images</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Column
						initialState={reorderedImages}
						onDragEnd={onDragEnd}
					/>
				</ModalBody>
				<ModalFooter>
					<Button mt={4} onClick={handleSave} variant="primary">
            			Save Order
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

const Column = ({ initialState, onDragEnd }) => {
	const [state, setState] = useState(initialState);

	useEffect(() => {
		setState(initialState);
	}, [initialState]);

	return (
		<DragDropContext 
			onDragEnd={onDragEnd}
			// set the class for styling
			className="kanban-board"
		>
			{
				//Map Initial Tasks Key-Value Pairs to Kanban Columns
				Object.keys(state).map((column, ind) => {
					return (
						<Flex
							key={ind}
							bg="white"
						>
							<Droppable 
								droppableId={column} 
								key={ind}
								className="kanban-column"
							>
								{(provided, snapshot) => (
									<Flex
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
										{...provided.droppableProps}
										direction="column"
									>
										{state[column].map((item, index) => (
											<Draggable 
												key={item} 
												draggableId={item} 
												index={index}
												color="white"
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
														className="kanban-item-wrapper"
													>
														<Box
															p={4}
															mb={2}
															boxShadow="sm"
															borderRadius="md"
															bg="white"
														>
															<Image src={item} alt={`Image ${index + 1}`} />
														</Box>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</Flex>
								)}
							</Droppable>
						</Flex>
					);
				})
			}
		</DragDropContext>
	);
};

export default ReorderImagesModal;
