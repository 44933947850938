/**
 * @File - Products.js
 * 
 * @Description 
 * - This file will contain all of the products for Taylor Fence's Showroom page.
 * - We will be using Chakra UI for the components and styling.
 * - We will be using React Icons for the icons.
 * - We will be using React Router for the routing.
 * - Along with the list of products, we will also be including the following functions/methods:
 *      - Create new product (Chakra UI Modal)
 *      - Edit existing product (Chakra UI Modal)
 *      - Delete existing product (Chakra UI Modal)
 */

// Imports
import React, { useState } from "react";

// Chakra UI imports
import { 
	Flex, 
	Button,
	Input,
} from "@chakra-ui/react";

//Product Table Import 
import ProductTable from "./ProductTable";

import CreateProduct from "./Modals/CreateProduct";

// React Icons imports
import { MdAddCircleOutline } from "react-icons/md";

import useAdminSearchProducts from "hooks/Product/queries/useAdminSearchProducts";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import BackendPagination from "../../components/BackendPagination";

import { FooterContainer } from "../../components/TableContainer";

const Products = () => {
	
	const [searchInput, setSearchInput] = useState({
		limit: 5,
		offset: 0,
		term: "",
	});

	const { loading, error: errorMsg, data, refetch: allProductsRefetch } = useAdminSearchProducts({...searchInput});

	const [showCreateProductModal, setShowCreateProductModal] = useState(false);

	const handleCreateProductModalClose = () => setShowCreateProductModal(false);
	const handleCreateProductModalOpen = () => setShowCreateProductModal(true);

	const setLimit = (limit) => {
		setSearchInput({
			...searchInput,
			limit,
		});
	};

	const setOffset = (offset) => {
		setSearchInput({
			...searchInput,
			offset,
		});
	};

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper 
				loading={loading} 
				error={errorMsg?.message} 
				data={data}
			>
				
				<ProductTable 
					products={data?.items} 
					refetch={allProductsRefetch} 
				/>
				<FooterContainer>
					<Input
						placeholder="Search Products"
						defaultValue={searchInput.term}
						w="70%"
						mr="5%"
						id="searchTerm"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								let searchTerm = document.getElementById("searchTerm").value;
								setSearchInput({
									...searchInput,
									term: searchTerm,
									offset: 0,
								});
							}
						}}
					/>
					<Button
						variant="primary"
						onClick={() => {
							let searchTerm = document.getElementById("searchTerm").value;
							setSearchInput({
								...searchInput,
								term: searchTerm,
								offset: 0,
							});
						}}
						w="25%"
					>
						Search
					</Button>
				</FooterContainer>
				<FooterContainer>
					<BackendPagination
						limit={searchInput.limit}
						setLimit={setLimit}
						offset={searchInput.offset}
						setOffset={setOffset}
						totalItems={data?.totalItems}
						hasNextPage={data?.hasNextPage}
						hasPreviousPage={data?.hasPreviousPage}
					/>
					<Button
						leftIcon={<MdAddCircleOutline />}
						variant="primary"
						onClick={handleCreateProductModalOpen}
						w={{
							base: "100%",
							md: "25%",
						}}
						fontSize={{
							base: "sm",
							lg: "md",
						}}
						textAlign="center"
					>
							Add Product
					</Button>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreateProduct 
				isOpen={showCreateProductModal} 
				onClose={handleCreateProductModalClose} 
				refetch={allProductsRefetch} 
			/>
		</Flex>
	);
};

export default Products;