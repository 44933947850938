/**
 * @file useUpdateProductImages.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT_IMAGES } from "cache/Product/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateProductImages
 *
 * @description
 * Custom hook that handles updating a product's images.
 *
 * @returns
 * - updateImages function
 */
const useUpdateProductImages = () => {

	const [updateProductImagesMutation, { loading, error }] = useMutation(UPDATE_PRODUCT_IMAGES);

	const updateImages = async (productId, images) => {

		try {
			const { data } = await updateProductImagesMutation({ 
				variables: { productId: productId, images: images }
			});
			if (error) {
				throw new Error(error);
			}
			let updateProductImagesSuccess = data.updateProductImages;
			if (!updateProductImagesSuccess) {
				throw new Error("Update failed");
			} else {
				return { success: true };
			}
		} catch (error) {
			return { error };
		}

	};

	return { updateImages, loading, error };

};

export default useUpdateProductImages;
