import { IoMdHammer } from "react-icons/io";
import { AiOutlineBulb, AiOutlineTool } from "react-icons/ai";

export const faqs = [
	{
		category: "General",
		faqs: [
			{
				question: "What are your business hours?",
				answer: "Our business hours are 8:00 AM to 5:00 PM from Monday to Friday and 8:00 AM to 4:00 PM on Saturday (Seasonal), 8:00 AM to 1:00 PM (Winter).",
			},
			{
				question: "How can I contact your customer support?",
				answer: "You can contact our sales person for all inquiries.",
			},
			{
				question: "Where is your company located?",
				answer: "Our company is located at 1246 Route 33 Highway 33, Farmingdale, NJ 07727.",
			},
			{
				question: "Are you a licensed and insured company?",
				answer: "Yes, we are a fully licensed and insured company. We ensure all our work complies with local regulations and codes.",
			},
		],
	},
	{
		category: "Products",
		faqs: [
			{
				question: "What materials are your fences made of?",
				answer: "Our fences are made of high-quality wood, vinyl, and aluminum materials.",
			},
			{
				question: "Do you offer custom fence designs?",
				answer: "Yes, we offer custom fence designs to meet your specific needs and preferences.",
			},
			{
				question: "Can I buy your fencing materials separately?",
				answer: "Yes, we sell fencing materials separately. Please contact our sales team for pricing information.",
			},
			{
				question: "What kind of warranty do you offer on your products?",
				answer: "Warranty is different for each product. Restocking fee may apply.",
			},
			// Add more FAQs related to Products
		],
	},
	{
		category: "Pickup & Delivery",
		faqs: [
			{
				question: "Can I pick up my order from your location?",
				answer: "Yes, you can arrange to pick up your order from our designated pickup location during our business hours.",
			},
			{
				question: "Do you offer delivery services?",
				answer: "We offer local delivery only. Delivery timeframes are dependent on material supply. Please call for delivery timeframes.",
			},
			{
				question: "How long will it take to deliver my order?",
				answer: "Delivery times depend on material supply. Please call us for more information.",
			},
			// Removed question about changing delivery/shipping address
			// Add more FAQs related to Pickup & Delivery
		],
	},
	{
		category: "Custom Fencing",
		faqs: [
			{
				question: "Can I request a custom fence design?",
				answer: "Yes, we specialize in creating custom fence designs tailored to your specific requirements. Our team will work with you to bring your vision to life.",
			},
			{
				question: "How long does it take to complete a custom fence project?",
				answer: "The duration of a custom fence project depends on various factors such as the complexity of the design and the availability of materials. Our team will provide you with an estimated timeline during the consultation process.",
			},
			{
				question: "How long does it take to receive a quote?",
				answer: "Typically, you will receive a quote within 2-3 business days after we have all the necessary details about your project.",
			},
			{
				question: "What if I need to modify my project after receiving a quote?",
				answer: "If you need to modify your project after receiving a quote, please contact our sales team. We will review the changes and provide a new quote.",
			},
			// Add more FAQs related to Custom Fencing
		],
	},
	{
		category: "Quotes",
		faqs: [
			{
				question: "How can I request a quote for a fence installation?",
				answer: "You can request a quote by filling out the form on our website or contacting our sales team directly. Provide us with details about your project, and we will get back to you with a customized quote.",
			},
			{
				question: "Are the quotes provided free of charge?",
				answer: "Yes, we provide free quotes for our services. Our team will assess your requirements and provide you with a detailed quote without any obligation.",
			},
			// Removed question about discounts or promotions
			{
				question: "Are there any hidden fees I should be aware of?",
				answer: "We strive for transparency in our pricing. All potential costs will be clearly outlined in your quote. However, unforeseen circumstances such as changes in materials or additional labor may alter the final cost.",
			},
			// Add more FAQs related to Quotes
		],
	},
	{
		category: "Pricing",
		faqs: [
			{
				question: "What factors affect the pricing of a fence installation?",
				answer: "The pricing of a fence installation can be influenced by factors such as the type of material, fence height, project size, additional features, and site conditions. Our team will provide you with a transparent breakdown of the pricing during the quote process.",
			},
			// Removed question about financing
			// Add more FAQs related to Pricing
		],
	},
	{
		category: "Misc.",
		faqs: [
			{
				question: "What is your cancellation policy?",
				answer: "Our cancellation policy may vary depending on the specific circumstances and the stage of the project. Please refer to our terms and conditions or contact our customer support team for more information.",
			},
			{
				question: "Do you offer maintenance services for fences?",
				answer: "Yes, we offer maintenance services to help keep your fence in excellent condition. Our team can provide guidance on maintenance practices and schedule periodic inspections and repairs as needed.",
			},
			// Add more FAQs related to Misc.
		],
	},
];

  


export const features = [
	{
		name: "Call",
		description: "(732) 938-4355",
		icon: AiOutlineBulb,
	},
	{
		name: "Email",
		description: "Info@taylorfencenj.com",
		icon: IoMdHammer,
	},
	{
		name: "Message",
		description: "Contact Form",
		icon: AiOutlineTool,
		onClick: () => {
			// Handle the modal open functionality here
			// You can set a state variable or use a modal library to open the modal
			
		},
	},
];
  