/**
 * @file - Table.js
 */
import React, { useEffect, useState } from "react";

import {
	Button,
} from "@chakra-ui/react";


import UpdateFeaturedDeal from "./modals/UpdateFeaturedDeal";
import UpdateFeaturedDealImages from "./modals/UpdateFeaturedDealImages";
import DeleteFeaturedDeal from "./modals/DeleteFeaturedDeal";
import { convertDateToReadableFormat } from "utils/utils";

import { TextCell, TableCell } from "../../components/TableCell";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";

/**
 * @component DealRow
 * 
 * @param {} props 
 * @returns 
 */
const DealRow = (props) => {

	const deal = props.deal;

	const [showUpdateDeal, setShowUpdateDeal] = useState(false);

	const openUpdateDealModal = () => {
		setShowUpdateDeal(true);
	};

	const closeUpdateDealModal = () => {
		setShowUpdateDeal(false);
	};

	const [showUpdateImages, setShowUpdateImages] = useState(false);

	const openUpdateImagesModal = (e) => {
		e.stopPropagation();
		setShowUpdateImages(true);
	};

	const closeUpdateImagesModal = () => setShowUpdateImages(false);

	const [showDeleteDeal, setShowDeleteDeal] = useState(false);

	const openDeleteDealModal = (e) => {
		e.stopPropagation();
		setShowDeleteDeal(true);
	};

	const closeDeleteDealModal = () => setShowDeleteDeal(false);

	return (
		<RowContainer
			onClick={openUpdateDealModal}
		>
			<TextCell>
				{deal.name}
			</TextCell>
			<TextCell>
				{deal.description}
			</TextCell>
			<TextCell>
				{convertDateToReadableFormat(deal.expires)}
			</TextCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={(e) => { e.stopPropagation(); openUpdateImagesModal(e); }}
					fontSize={{
						base: "xs",
						sm: "xs",
						md: "xs",
						lg: "sm",
						xl: "sm",
					}}
				>
					Edit Images
				</Button>
			</TableCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={(e) => { e.stopPropagation(); openDeleteDealModal(e); }}
					fontSize={{
						base: "xs",
						sm: "xs",
						md: "xs",
						lg: "sm",
						xl: "sm",
					}}
				>
					Delete
				</Button>	
			</TableCell>			
			<UpdateFeaturedDealImages
				isOpen={showUpdateImages}
				onClose={closeUpdateImagesModal}
				deal={deal}
				refetch={props.dealsRefetch}
			/>
			<UpdateFeaturedDeal
				isOpen={showUpdateDeal}
				onClose={closeUpdateDealModal}
				selectedDeal={deal}
				dealsRefetch={props.dealsRefetch}
			/>
			<DeleteFeaturedDeal
				isOpen={showDeleteDeal}
				onClose={closeDeleteDealModal}
				deal={deal}
				refetch={props.dealsRefetch}
			/>
		</RowContainer>
	);
};

const DealTable = (props) => {

	const [deals, setDeals] = useState(props.deals);

	useEffect(() => {
		setDeals(props.deals);
	}, [props.deals]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("name", order)}
					search={(value) => props.search("name", value)}
					clearToggle={props.clearToggle}
				>
					Name
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("description", order)}
					search={(value) => props.search("description", value)}
					clearToggle={props.clearToggle}
				>
					Description
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("expires", order)}
					search={(value) => props.search("expires", value)}
					clearToggle={props.clearToggle}
				>
					Expires
				</TextCell>
				<TextCell>
					Images
				</TextCell>
				<TextCell>
					Actions
				</TextCell>
			</HeaderContainer>
			{deals?.map((deal, idx) => (
				<DealRow
					key={idx}
					deal={deal}
					dealsRefetch={props.dealsRefetch}
				/>
			))}
		</TableContainer>
	);

};

export default DealTable;
