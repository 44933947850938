/**
 * @file useGetAllPartners.js
 */
import { useQuery } from "@apollo/client";
import { GET_ALL_PARTNERS } from "cache/Partner/queries"; // Update the path as per your project structure

/**
 * @hook useGetAllPartners
 *
 * @description
 * Custom hook that handles the functionality to retrieve all partners.
 * Use this hook wherever the list of all partners is required.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query, specifically the list of all partners
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetAllPartners = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_PARTNERS);
    
	let partners = data?.getAllPartners;

	return { loading, error, data: partners, refetch, networkStatus };
};

export default useGetAllPartners;
