/**
 * @file UpdateImages.js
 */
import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	FormLabel,
	Stack,
	Box,
	Image,
	Grid,
	Alert,
	AlertIcon,
} from "@chakra-ui/react";

import useUpdateProductImages from "hooks/Product/mutations/useUpdateProductImages";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import useUploadImage from "hooks/useUploadImage";

/**
 * @component UpdateImages
 * 
 * @description
 * - Modal to update the images of a car in the garage
 * - Modal contains a mechanism to update the car images
 * - The modal will showcase all of the current images of the car and allow the user to delete them
 * - The modal will allow the user to upload new images for the car through a dropzone
 */
const UpdateImages = ({ isOpen, onClose, product, refetch }) => {

	const uploadImage = useUploadImage();

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const [imageURLs, setImageURLs] = useState(product.images);
	const [imageFiles, setImageFiles] = useState([]);

	const { updateImages } = useUpdateProductImages();

	/**
	 * @function useEffect
	 * 
	 * @description
	 * - Updates the image URLs when the product changes
	 */
	useEffect(() => {
		setImageURLs(product.images);
	}, [product]);


	const handleDeleteImage = (imageURL) => {
		setImageURLs(imageURLs.filter(url => url !== imageURL));
		validateInput();
	};

	/**
	 * @function handleSetImageFiles
	 * 
	 * @description
	 * - Sets the image files to be uploaded
	 * 
	 * @param {*} files 
	 */
	const handleSetImageFiles = (files) => {
		setImageFiles(files);
		validateInput();
	};

	/**
	 * @function handleUpdateImages
	 * 
	 * @description
	 * - Updates the images of the car in the garage
	 * - Utilizes the UPDATE_IMAGES mutation
	 * - Passes the remaining images that havent been deleted and the new images that have been uploaded
	 * - Closes the modal if successful
	 */
	const handleUpdateImages = async () => {
		setLoading(true);
		let image_urls = new Array();
		for (let i = 0; i < imageFiles.length; i++) {
			try{
				const currentImage = imageFiles[i];
				let url = await uploadImage(currentImage);
				image_urls.push(url);
			}
			catch(err){
				setLoading(false);
				setErrorMsg("Error uploading images, please try again later.");
				return;
			}
		}

		let response = await updateImages(product._id, [...imageURLs, ...image_urls]);
		if(response.error){
			setLoading(false);
			setErrorMsg(response.error.message);
			return;
		}
		if(response.success){
			await refetch();
			setImageFiles([]);
			setLoading(false);
			onClose();
			return;
		}
		validateInput();
	};

	const [, setFormError] = useState({});

	const validateInput = () => {
		let errors = {};
		if (imageFiles.length === 0) {
			errors.imageFiles = "Please upload at least one image.";
		}
		setFormError(errors);
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			size="2xl"
			scrollBehavior="inside"
			isCentered
			motionPreset="slideInBottom"
			closeOnOverlayClick={true}
			closeOnEsc={true}
			closeOnBodyClick={false}
		>
			<ModalOverlay />
			<ModalContent
				overflowY="auto"
			>
				<ModalHeader>Update Images</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{/* Display error message */}
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							{errorMsg}
						</Alert>
                	)}
					<Stack 
						spacing={6}
					>

						{/* Dropzone for new images */}
						<Box>
							<FormLabel>Upload new images</FormLabel>
							<ImagesDropzone 
								imageFiles={imageFiles}
								setImageFiles={handleSetImageFiles}
								allowMultiple={true}
							/>
						</Box>

						{/* Display current images */}
						<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
							{imageURLs.map((imageURL, i) => (
								<Box key={i} position="relative">
									<Image src={imageURL} alt={`Product image ${i}`} objectFit="cover" borderRadius="md"/>
									<Button
										position="absolute"
										right="5px"
										top="5px"
										size="sm"
										variant="delete"
										onClick={() => handleDeleteImage(imageURL)}
									>
										Delete
									</Button>
								</Box>
							))}
						</Grid>
						<Button
							isLoading={loading}
							loadingText="Updating..."
							variant="primary"
							onClick={handleUpdateImages}
						>
							Update Images
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UpdateImages;