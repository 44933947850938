export const links = [
	{
		title: "Company",
		links: [
			{
				label: "Location & Hours",
				href: "/about/location",
			},
			{
				label: "Showroom",
				href: "/showroom",
			},
			{
				label: "Brochures",
				href: "/resources/brochures",
			},
			{
				label: "Gallery",
				href: "/gallery",
			},
		],
	},

	{
		title: "Resources",
		links: [
			{
				label: "Online Payments",
				href: "/payments",
			},
			{
				label: "Help Center",
				href: "/about/help",
			},
			{
				label: "Contact Us",
				href: "/contact",
			},
			{
				label: "Testimonials",
				href: "/about/testimonials",
			},
		],
	},
	// {
	// 	title: "Website Info",
	// 	links: [
	// 		{
	// 			label: "Terms of Service",
	// 			href: "#",
	// 		},
	// 		{
	// 			label: "Privacy Policy",
	// 			href: "#",
	// 		},
	// 	],
	// },
];