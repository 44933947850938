/**
 * @file
 * This is the Showroom component of the application.
 * It represents the Showroom page, where the product categories and trending products are displayed.
 * 
 * @summary Showroom page component.
 */

import React from "react";

import { motion } from "framer-motion";

import Hero from "Blueprints/Hero";

import CategorySelection from "./components/CategorySelect";

// import CategorySelection from "./components/CategorySelect";

// import TrendingProducts from "./components/TrendingProducts";

// import ShowroomCTA from "./components/CTA";

import ShowroomHero from "../../../assets/TaylorFenceImg/Showroom/Wood_HorizontalSpacedCedar.png";

import {
	AutoComplete,
	AutoCompleteInput,
	AutoCompleteItem,
	AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

// eslint-disable-next-line no-unused-vars
const SearchBar = ({ options }) => (
	<AutoComplete>
	  <AutoCompleteInput 
			variant="filled" 
			placeholder="Search..."
			_hover={{
				bg: "gray.200",
			}}
			_focus={{
				bg: "gray.200",
			}}
			px={4}
			boxShadow={{
				base: "none",
				md: "lg",
			}}
			color="gray.500"
	  />
	  <AutoCompleteList style={{ position: "absolute", bottom: "-50px", width: "100%", zIndex: 1000 }}>
			{options.map((option, index) => (
		  <AutoCompleteItem key={`option-${index}`} value={option}>
					{option}
		  </AutoCompleteItem>
			))}
	  </AutoCompleteList>
	</AutoComplete>
);

/**
 * The Showroom component manages the rendering of the Showroom page of the website.
 * This page includes a hero banner, product category selection, a CTA, and a section for trending products.
 *
 * @param {object} props - The properties passed down from the parent component (not used in this component, but retained for possible future use)
 * 
 * @returns {HTMLElement} - A motion.div component from Framer Motion library for page transition effects. This component includes the Hero, CategorySelection, ShowroomCTA and TrendingProducts components.
 */
const Showroom = () => {
	
	const pageVariants = {
		initial: {
			opacity: 0,
		},
		in: {
			opacity: 1,
		},
		out: {
			opacity: 0,
		}
	};

	const pageTransition = {
		type: "tween",
		ease: "easeInOut",
		duration: 0.7
	};

	return (
		<motion.div
			className="showroom"
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<Hero
				title="Product Showroom"
				image_url={ShowroomHero}
				objectPosition="center 60%"
				boxHeight={{
					base: "340px",
					md: "md",
				}}
				boxPaddingY="10"
				subHeadingSize="md"
				subtitle="Fence products that are the perfect fit for your home."
				//button={heroButton}
			/>
			<CategorySelection />	
		</motion.div>
	);
};

export default Showroom;
