/**
 * @file - Galvanized.js
 * @description - Chainlink/Galvanized page for the Showroom section of the website.
 * @author Webify Services, LTD.
 */
import GalleryHero from "Blueprints/GalleryHero";
import CTAStacked from "../components/CTAStacked";
import List from "../components/List";

import { Flex, Divider, Box, Heading } from "@chakra-ui/react";

import Galv1 from "assets/TaylorFenceImg/Resources/TF_GalvChainLink.png";
import Galv2 from "assets/TaylorFenceImg/Resources/Galv2.jpg";
import Galv4 from "assets/TaylorFenceImg/Resources/Galv4.webp";
import Galv6 from "assets/TaylorFenceImg/Resources/Galv6.jpg";

import SlatColors from "assets/TaylorFenceImg/Resources/Slats2.JPG";
import SlatsColor1 from "assets/TaylorFenceImg/Resources/WingedSlats.JPG";
import HedgeSlat from "assets/TaylorFenceImg/Resources/HedgeSlats.JPG";
// import GalvSlats from "assets/TaylorFenceImg/Resources/TF_GalvChainLink.png";



/**
 * @function Galvanized
 * 
 * @returns {React.ReactElement}
 * 
 * @utilizes
 * - GalleryHero (HOC/Blueprint)
 * - List (component)
 * - CTA (component)
 */
const Galvanized = () => {

	const chainlink_images = [
		Galv2,
		Galv1,
		Galv4,
		Galv6,
	];

	const listMesh = [
		{ title: "1-1/4″ Mesh", description: "Most commonly used for poolcode requirements." },
		{ title: "1-3/4″ Mesh", description: "Most commonly used for tennis court requirements." },
		{ title: "2″ Mesh", description: "Used for a wide array of applications." },
	];

	const wireGaugeData = [
		{ title: "11G (.120)", description: "Most economical." },
		{ title: "9G (.148)", description: "Standard for industrial and commercial use." },
	];

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
			h="100%"
			gap={4}
		>
			<GalleryHero
				images={chainlink_images}
				title={"Galvanized Chainlink Fencing"}
				description={"Galvanized chain-link fencing offers versatility, easy upkeep, and robust security, making it an excellent choice for both residential and commercial properties."}
				imageHeight={{
					sm: "180px",
					md: "100%",
				}}
				imageWidth="100%"
				boxHeight={{
					sm: "140px",
					md: "500px",
				}}
				boxPaddingY="10"
				subHeadingSize="lg"
			/>
			<Flex
				flex="1"
				minW="500px"
				w="100%"
				px={{
					base: "4",
					md: "12",
				}}
				flexDirection={{ base: "column", md: "row" }}
				alignItems="center"
				justifyContent="space-between"
				gap="4"
			>
				<Box 
					flex="1" 
					px={{
						base: "6",
						md: "4",
					}}

				>
					<List header="Mesh Sizes" items={listMesh} />
				</Box>
				<Box 
					flex="1" 
					px="4"
					mr={{
						base: "10",
					}}
				>
					<List header="Wire Gauges" items={wireGaugeData} />
				</Box>
			</Flex>
			<Flex
				flex="1"
				w="100%"
				px={{
					base: "4",
					md: "6",
				}}
				flexDirection={{ base: "column", md: "row" }} 
				alignItems="center"
				justifyContent="space-between"
				gap="4"
			>
				<Heading
					fontSize={{ base: "md", md: "xl" }}
					mb={{
						base: "2",
						md: "4",
					}}
					mt={{ base: "2", md: "4" }}
					alignSelf="center"
					px={{
						base: "4",
						md: "24",
					}}
					
				>
					There are arrays of different sizes available upon request. Please inquire as needed.
				</Heading>
			</Flex>
			<Divider w="calc(100% - 8rem)" my="4"/>
			<Heading
				fontSize={{ base: "md", md: "4xl" }}
				mb={{
					base: "2",
					md: "0",
				}}
				mt={{ base: "2", md: "0" }}
				alignSelf="start"
				px={{
					base: "4",
					md: "24",
				}}
			>
                Add privacy and style to your chainlink fence.
			</Heading>
			<CTAStacked 
				title="Top Lock Slats"
				imageUrls={[SlatColors]}
				listData={{
					header: "",
					description: "",
					items: [
						// ... your items ...
					]
				}}
			/>
			<Divider w="calc(100% - 8rem)" my="4"/>

			<CTAStacked 
				title="Winged Slats"
				imageUrls={[SlatsColor1]}
				listData={{
					header: "",
					description: "",
					items: [
						// ... your items ...
					]
				}}
			/>
			<Divider w="calc(100% - 8rem)" my="4"/>
			<CTAStacked 
				title="Hedge/Evergreen Slats"
				imageUrls={[HedgeSlat]}
				listData={{
					header: "",
					items: [] // Provide an empty array if no items are available
				}}
			/>
		</Flex>
	);
}; 

export default Galvanized;

// FaCircle