/**
 * @file
 * This is the AboutUsPage component.
 * It is displayed when the user navigates to the "About Us" section of the website.
 *
 * @summary "About Us" page component.
 */

import React from "react";
import OurStory from "./components/OurStory";
// import Hero from "Blueprints/Hero";
import MotionPage from "Blueprints/MotionPage";
// import AboutUs from "../../../../assets/TaylorFenceImg/PVC_Cross_Lattice.png";

/**
 * AboutUsPage component is shown when a user navigates to the "About Us" page.
 * It includes a Hero component with an image and title, an OurStory section, and a Badges section.
 *
 * @returns {HTMLElement} - A MotionPage component that includes the Hero, OurStory, and Badges components.
 */

const AboutUsPage = () => {

	return (
		<MotionPage>
			<div
				className="about-us"
			>
				{/* <Hero
					title="About Us"
					image_url={AboutUs}
					objectPosition="center 30%"
				/> */}
				<OurStory />
			</div>
		</MotionPage>
	);
};

export default AboutUsPage;

