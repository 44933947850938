/**
 * @file ResetPassword.js is the main page for resetting an admin's password.
 */
import React, { useState, useEffect, useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import {
	Flex,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	Input,
	FormControl,
	FormLabel,
} from "@chakra-ui/react";

import useResetAdminPassword from "hooks/Admin/mutations/useResetAdminPassword";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import { useSearchParams } from "react-router-dom";
import { isValidPassword } from "utils/utils";

import PasswordPopover from "Blueprints/Popovers/PasswordPopover";

/**
 * @component ResetPassword
 * 
 * @description
 * - This page is used to reset an admin's password.
 */
const ResetPassword = () => {

	const { handlePageChange } = useContext(PageChangeContext);

	const [input, setInput] = useState({
		password: "",
		confirmPassword: "",
	});

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const { resetPassword } = useResetAdminPassword();
	
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if(searchParams.has("id") && searchParams.has("token")) {
			setLoading(false);
		}
		else{
			setError("Invalid Password Reset Link. Please request a new password reset email from the login page.");
			setLoading(false);
		}
	}, []);

	const handleResetPassword = async () => {
		setError(null);
		setLoading(true);

		const { error, data } = await resetPassword(
			searchParams.get("id"),
			searchParams.get("token"),
			input.password
		);
		
		if(error) {
			setError(error.message);
			setLoading(false);
			return;
		}
		if(data){
			setSuccess(true);
			setLoading(false);
			return;
		}
		setError("Something went wrong. Please try again later.");
	};

	return (
		<Flex
			direction="column"
			align="center"
			justify="center"
			w="100%"
			h="100%"
			p="10%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error}
				data={true}
			>
				{success ? (
					<Alert status="success">
						<AlertIcon />
						<AlertDescription>
							Password successfully reset. Please login with your new password.
						</AlertDescription>
						<Button
							variant="primary"
							onClick={() => handlePageChange("/admin/login")}
						>
							Login
						</Button>
					</Alert>
				) : (
					<>
						<FormControl 
							id="password" 
							isRequired
						>
							<FormLabel>
								New Password
								<PasswordPopover password={input.password} />
							</FormLabel>
							<Input
								type="password"
								placeholder="New Password"
								value={input.password}
								onChange={(e) => setInput({ ...input, password: e.target.value })}
							/>
						</FormControl>
						<FormControl 
							id="confirmPassword" 
							isRequired
							mt={4}
						>
							<FormLabel>Confirm New Password</FormLabel>
							<Input
								type="password"
								placeholder="Confirm New Password"
								value={input.confirmPassword}
								onChange={(e) => setInput({ ...input, confirmPassword: e.target.value })}
							/>
						</FormControl>
						<Button
							mt={4}
							variant="primary"
							isDisabled={
								!input.password ||
								!input.confirmPassword ||
								input.password !== input.confirmPassword ||
								!isValidPassword(input.password)
							}							
							onClick={handleResetPassword}
						>
							Reset Password
						</Button>
					</>
				)}
			</LoadingErrorDataWrapper>
			<Button
				mt={4}
				variant="text"
				onClick={() => handlePageChange("/admin/login")}
			>
				Back to Login
			</Button>
		</Flex>
	
	);

};

export default ResetPassword;
