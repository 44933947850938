import React, { useState } from "react";
import { 
	Box, 
	Center, 
	Container, 
	SimpleGrid, 
	Stack, 
	Heading, 
	useDisclosure 
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import useGetAllPartners from "hooks/Partner/queries/useGetAllPartners";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import PartnersModal from "../modals/Partners/PartnersModal";

const MotionImage = motion.img;
const MotionHeading = motion(Heading);

const container = {
	hidden: { opacity: 1, scale: 0 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.2,
		},
	},
};

const item = {
	hidden: { y: 20, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
	},
};

export const PartnersPage = () => {

	const { loading, error, data: partners } = useGetAllPartners();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentPartner, setCurrentPartner] = useState(null);

	const handlePartnerClick = (partner) => {
		setCurrentPartner(partner);
		onOpen();
	};

	return (
		<Box
			minHeight={{
				base: "40vh",
				md: "80vh",
			}}
			w="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<Container
				maxW={{
					base: "container.sm",
					md: "container.xl",
				}}
				w="100%"
			>
				<Stack 
					spacing="0"
				>
					<MotionHeading
						spacing="8"
						textAlign="center"
						color="#1B1B1B"
						variants={container}
						initial="hidden"
						animate="visible"
						mt={{
							base: "8",
							md: "12",
						}}
						mb={{
							base: "8",
							md: "6",
						}}
						as="h1"
					>
            			Extended Taylor Fence Family 
					</MotionHeading>
					<LoadingErrorDataWrapper
						loading={loading}
						error={error?.message}
						data={partners}
					>
						<SimpleGrid
							gap={{
								base: "8",
								md: "10",
							}}
							columns={{
								base: 1,
								sm: 2,
								md: 4,
							}}
							w="100%"
							mt={{
								base: "8",
								md: "12",
							}}
							mb={{
								base: "8",
								md: "12",
							}}
						>
							{partners?.map((partner, index) => (
								<Center
									key={index}
									bg="bg-surface"
									py={{
										base: "4",
										md: "4",
									}}
									borderRadius="lg"
									onClick={() => handlePartnerClick(partner)} // open the modal when the logo is clicked
									boxShadow={{
										base: "lg",
										md: "sm",
									}}
									borderWidth={{
										base: "1px",
										md: "0px",
									}}
									borderColor={{
										base: "gray.400",
										md: "",
									}}
								>
									<MotionImage
										src={partner.image}
										alt="logo"
										variants={item}
										whileHover={{ 
											scale: 1.1,
											cursor: "pointer", 
										}}
										initial="hidden"
										animate="visible"
									/>
								</Center>
							))}
						</SimpleGrid>
					</LoadingErrorDataWrapper>
				</Stack>
			</Container>
			<PartnersModal isOpen={isOpen} onClose={onClose} currentPartner={currentPartner} />
		</Box>
	);
};

export default PartnersPage;
