/**
 * @File - CreateCategoryModal.js
 * 
 * @Description 
 * - This file is the modal for creating a new category in the Admin Panel/Category page
 * - This modal is called by the client/Categories.js component
 * - Chakra UI is used to create the modal.
 * - The modal is a form that takes in the category info via user inputs (below). 
 * - The form is submitted to the server via the handleSubmit function.
 * - The handleSubmit function is called by the onSubmit function in the form.
 * - The handleSubmit function is passed in as a prop to the modal.
 * - The handleSubmit function is defined in the client/Categories.js component.
 * 
 * @CategoryInputs - the inputs for the category form
 * - name: the name of the category
 * - description: the description of the category
 * - image: the image of the category
 * 
 */

import React, { useState } from "react";

// Chakra UI imports 
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Textarea,
	Button,
	Alert,
	AlertIcon,
	AlertDescription
} from "@chakra-ui/react";

import useCreateCategory from "hooks/Category/mutations/useCreateCategory";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import useUploadImage from "hooks/useUploadImage";

function CreateCategoryModal({ isOpen, onClose, parent, refetch }) {

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [child_descriptor, setChildDescriptor] = useState("");
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleChildDescriptionChange = (e) => {
		setChildDescriptor(e.target.value); 
	};

	const uploadImage = useUploadImage();

	const { create } = useCreateCategory();

	/**
     * @function onSubmit
     *
     * @param {*} e
     * @returns
     */
	const onSubmit = async (e) => {
		e.preventDefault();

		setErrorMsg(null);

		if(!validateInput()) return;
		
		setLoading(true);
	  
		let image_urls = [];
		try {
			for(let i = 0; i < images.length; i++){
				const image_url = await uploadImage(images[i]);
				image_urls.push(image_url);
			}
		} catch (err) {
		  setErrorMsg("Failed to upload image.");
		  setLoading(false);
		  return;
		}
	  
		const createCategoryInput = {
		  name,
		  description: description,
		  child_descriptor: child_descriptor,
		  ...(parent && { parent }),
		  ...(image_urls && { images: image_urls })
		};
	  
		try {
		  const response = await create(createCategoryInput);
		  if (response.error) {
				setErrorMsg(response.error.message);
				setLoading(false);
				return;
		  }
		  if (response.data) {
				setLoading(false);
				await refetch();
				onClose();
		  }
		} catch (err) {
		  setErrorMsg("Failed to create category.");
		  setLoading(false);
		}
	  };

	  const [formErrors, setFormErrors] = useState({});

	  const validateInput = () => {
		  let errors = {};
		  if(!name){
			  errors.name = "Name is required";
		  }
		  if(!child_descriptor){
			  errors.child_descriptor = "Description is required";
		  }
		  setFormErrors(errors);
		  return Object.keys(errors).length === 0;
	  	};	
	  

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size="2xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Category</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={onSubmit}>
					<ModalBody pb={6}>
						<FormControl
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input placeholder="Name" value={name} onChange={handleNameChange} />
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							mt={4}
						>
							<FormLabel>Description</FormLabel>
							<Textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
						</FormControl>
						<FormControl 
							mt={4}
							isInvalid={formErrors.child_descriptor}
						>
							<FormLabel>Child Description</FormLabel>
							<Input placeholder="Child Description" value={child_descriptor} onChange={handleChildDescriptionChange} />
							<FormErrorMessage>{formErrors.child_descriptor}</FormErrorMessage>
						</FormControl>
						<FormControl 
							mt={4}
						>
							<FormLabel>Images</FormLabel>
							<ImagesDropzone 
								imageFiles={images}
								setImageFiles={setImages}
								allowMultiple={true}
							/>
						</FormControl>
						{errorMsg &&
                            <Alert status="error" w="100%">
                            	<AlertIcon />
                            	<AlertDescription>{errorMsg}</AlertDescription>
                            </Alert>}
					</ModalBody>			
					<ModalFooter>
						<Button
							variant="primary"
							mr={3}
							type="submit"
							isLoading={loading}
							loadingText="Creating"
						>
                            Create
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}		

export default CreateCategoryModal;