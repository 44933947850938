/**
 * @file useReorderCategoryProducts
 */
import { useMutation } from "@apollo/client";
import { REORDER_CATEGORY_PRODUCTS } from "cache/Category/mutations";

/**
 * @hook useReorderCategoryProducts
 * 
 * @description
 * Custom hook that handles reordering a category's products.
 * This hook should be used whenever a category's products need to be reordered.
 * 
 * @returns
 * - reorderCategoryProducts: Function to execute the reorder category products mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderCategoryProducts = () => {
	const [reorderCategoryProductsMutation, { loading, error }] = useMutation(REORDER_CATEGORY_PRODUCTS);

	/**
	 * Reorders a category's products.
	 * 
	 * @param {String} _id - The ID of the category to update.
	 * @param {Array} productIds - The new product IDs array for the category.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderCategoryProducts = async (_id, productIds) => {
		try {
			const { data } = await reorderCategoryProductsMutation({
				variables: {
					_id,
					productIds
				}
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data.reorderCategoryProducts };
		} catch (error) {
			return { error };
		}
	};

	return { reorderCategoryProducts, loading, error };
};

export default useReorderCategoryProducts;
