/**
 * @file mutations.js is a file for all Customer mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_CUSTOMER - GraphQL mutation to create a customer.
 * 
 * @summary
 * - This mutation will create a new Customer and return the customer object if successful.
 * 
 * @param {Object} createCustomerInput - The input object for creating a customer.
 * @property {String} createCustomerInput.name - The name / company name of the customer.
 * @property {String} createCustomerInput.email - The email of the customer.
 * @property {String} createCustomerInput.phone - The phone number of the customer.
 * @property {Object} createCustomerInput.address - The address of the customer.
 * @property {String} createCustomerInput.address.line1 - The first line of the address.
 * @property {String} createCustomerInput.address.line2 - The second line of the address.
 * @property {String} createCustomerInput.address.city - The city of the address.
 * @property {String} createCustomerInput.address.state - The state of the address.
 * @property {String} createCustomerInput.address.zip - The zip code of the address.
 * @property {String} createCustomerInput.address.country - The country of the address.
 */
export const CREATE_CUSTOMER = gql`
    mutation createCustomer($input: CreateCustomerInput!) {
        createCustomer(input: $input) {
            _id
			name
            email
            phone
            address {
                _id
                line1
                line2
                city
                state
                zip
                country
            }
            createdAt
            updatedAt
        }
    }
`;

/**
 * 
 * @const {Object} UPDATE_CUSTOMER - GraphQL mutation to update a customer.
 * 
 * @summary
 * - This mutation will update a Customer and return the customer object if successful.
 * 
 * @param {Object} updateCustomerInput - The input object for updating a customer.
 * @property {String} updateCustomerInput._id - The id of the customer.
 * @property {String} updateCustomerInput.name - The name / company name of the customer.
 * @property {String} updateCustomerInput.email - The email of the customer.
 * @property {String} updateCustomerInput.phone - The phone number of the customer.
 * @property {Object} updateCustomerInput.address - The address of the customer.
 * @property {String} updateCustomerInput.address.line1 - The first line of the address.
 * @property {String} updateCustomerInput.address.line2 - The second line of the address.
 * @property {String} updateCustomerInput.address.city - The city of the address.
 * @property {String} updateCustomerInput.address.state - The state of the address.
 * @property {String} updateCustomerInput.address.zip - The zip code of the address.
 * @property {String} updateCustomerInput.address.country - The country of the address.
 */
export const UPDATE_CUSTOMER = gql`
    mutation updateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input)
    }
`;

/**
 * @const {Object} DELETE_CUSTOMER - GraphQL mutation to delete a customer.
 * 
 * @summary
 * - This mutation will delete a Customer and return the boolean true if successful.
 * 
 * @param {String} id - The id of the customer.
 * 
 * @returns {Boolean} - Returns true if successful.
 * 
 */
export const DELETE_CUSTOMER = gql`
    mutation deleteCustomer($id: ID!) {
        deleteCustomer(id: $id)
    }
`;

/**
 * @const {Object} SEND_AUTHENTICATION_TOKEN_EMAIL - GraphQL mutation to send an authentication token email.
 * 
 * @summary
 * - This mutation will send an authentication token email to the customer and return the boolean true if successful.
 * 
 * @param {String} email - The email of the customer.
 */
export const SEND_AUTHENTICATION_TOKEN_EMAIL = gql`
	mutation sendAuthenticationTokenEmail($email: String!) {
		sendAuthenticationTokenEmail(email: $email)
	}
`;
