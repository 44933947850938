/**
 * @file useReorderBrochures.js
 */

import { useMutation } from "@apollo/client";
import { REORDER_BROCHURES } from "cache/Brochure/mutations";

/**
 * @hook useReorderBrochures
 *
 * @description
 * Custom hook that handles the reorderBrochures mutation functionality.
 *
 * @returns
 * - reorderBrochures: Function to execute the reorderBrochures mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderBrochures = () => {

	const [reorderMutation, { loading, error }] = useMutation(REORDER_BROCHURES);

	/**
	 * Custom mutation to reorder brochures.
	 *
	 * @param {Array} input - An array of objects specifying brochure reorder details.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderBrochures = async (input) => {
		try {
			const { data } = await reorderMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.reorderBrochures };
		} catch (error) {
			return { error };
		}
	};

	return { reorderBrochures, loading, error };
};

export default useReorderBrochures;
