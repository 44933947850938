/**
 * @file AddSuggestedProducts.js
 * 
 * @author Webify Services
 */
import React, { useState, useEffect } from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalFooter,
	HStack,
	VStack,
	Box,
	Input
} from "@chakra-ui/react";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import ProductDisplay from "../components/ProductDisplay";

import { TextCell } from "components/Admin/Dashboard/components/TableCell";
import useAdminSearchProducts from "hooks/Product/queries/useAdminSearchProducts";
import BackendPagination from "components/Admin/Dashboard/components/BackendPagination";

/**
 * @function AddSuggestedProducts
 * 
 * @summary A modal that allows the user to add suggested products to a product.
 * 
 * @param {Object} props - React props
 * @param {boolean} props.isOpen - Whether or not the modal is open
 * @param {function} props.onClose - Function to close the modal
 * 
 */
const AddSuggestedProducts = ({ isOpen, onClose, product, updateSimilarProducts }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(5);
	const [searchTerm, setSearchTerm] = useState("");
	const { loading: productsLoading, error: productsError, data } = useAdminSearchProducts({offset, limit, term: searchTerm});

	const [suggestedProducts, setSuggestedProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		if(product && product.similar_products){
			let temp_similar_products = [];
			for(let i = 0; i < product.similar_products.length; i++){
				temp_similar_products.push(product.similar_products[i]);
			}
			setSuggestedProducts(temp_similar_products);
		}
	}, [product]);

	useEffect(() => {
		if (selectedProduct) {
			setSuggestedProducts([...suggestedProducts, selectedProduct]);
			setSelectedProduct(null);
		}
	}, [selectedProduct, suggestedProducts]);

	const handleAddSuggestedProducts = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		let suggestedProductIds = [];
		for(let i = 0; i < suggestedProducts.length; i++){
			suggestedProductIds.push(suggestedProducts[i]._id);
		}

		await updateSimilarProducts(suggestedProductIds);
		setSelectedProduct(null);
		onClose();
	};
    
	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			size="4xl" 
			isCentered
			scrollBehavior="inside"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader
					fontSize="2xl"
				>
                    Add Suggested Products
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack 
						spacing={6} 
						align="stretch"
					>
						{
							suggestedProducts?.map((prod, idx) => (
								<HStack
									key={idx}
									spacing={4}
								>
									<TextCell>{prod.name}</TextCell>
									<Button
										variant="primary"
										onClick={() => {
											setSuggestedProducts(suggestedProducts.filter((suggestedProduct) => suggestedProduct._id !== prod._id));
										}}
									>
										Remove
									</Button>
								</HStack>
							))
						}
					</VStack>
					<LoadingErrorDataWrapper 
						loading={productsLoading} 
						error={productsError} 
						data={data}
					>
						<VStack 
							spacing={6} 
							align="stretch"
						>
							<Box 
								p={4} 
								borderRadius="md" 
								w="100%" 
								h="100%"
							>
								<Input
									placeholder="Search Products"
									defaultValue={searchTerm}
									w="70%"
									mr="5%"
									id={`searchTerm${product._id}`}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											let term = document.getElementById(`searchTerm${product._id}`).value;
											setSearchTerm(term);
											setOffset(0);
										}
									}}
								/>
								<Button
									variant="primary"
									onClick={() => {
										let term = document.getElementById(`searchTerm${product._id}`).value;
										setSearchTerm(term);
										setOffset(0);
									}}
									w="25%"
								>
									Search
								</Button>
								<VStack spacing={4}>
									{data?.items?.map((prod, index) => {
										//Check if product is already in suggested products
										if(product._id === prod._id) return null;
										let alreadySuggested = false;
										for(let i = 0; i < suggestedProducts.length; i++){
											if(suggestedProducts[i]._id === prod._id){
												alreadySuggested = true;
												break;
											}
										}
										if(alreadySuggested) return null;
										return(
											<ProductDisplay
												key={index}
												product={prod}
												setSelectedProduct={setSelectedProduct} // Pass setSelectedProduct as a prop
											/>
										);
									})}
								</VStack>
							</Box>
						</VStack>
						<BackendPagination
							offset={offset}
							setOffset={setOffset}
							limit={limit}
							setLimit={setLimit}
							totalItems={data?.totalItems}
							hasNextPage={data?.hasNextPage}
							hasPreviousPage={data?.hasPreviousPage}
						/>
					</LoadingErrorDataWrapper>
				</ModalBody>
				<ModalFooter>
					<Button 
						onClick={handleAddSuggestedProducts}
                        
					>
                        Submit Suggested Products
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AddSuggestedProducts;
