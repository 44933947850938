/**
 * @file Partners.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
	Stack,
} from "@chakra-ui/react";

import PartnersTable from "./Table";

import CreatePartner from "./modals/CreatePartner";

import useGetAllPartners from "hooks/Partner/queries/useGetAllPartners";
import useReorderPartners from "hooks/Partner/mutations/useReorderPartners";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { FooterContainer } from "../../components/TableContainer";

import ReorderItemsButton from "../../components/ReorderItemsButton";

const Partners = () => {

	const { loading, error, data, refetch: partnersRefetch } = useGetAllPartners();

	const [partners, setPartners] = useState(data);

	useEffect(() => {
		if(data) {
			setPartners(data);
		}
	}, [data]);

	const [showCreatePartner, setShowCreatePartner] = useState(false);
	const openCreatePartnerModal = () => setShowCreatePartner(true);
	const closeCreatePartnerModal = () => setShowCreatePartner(false);

	const sort = (field, order) => {
		let sortedPartners = [...partners];
		sortedPartners.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setPartners(sortedPartners);
	};

	const search = (field, searchInput) => {
		let searchedPartners = [...data];
		searchedPartners = searchedPartners.filter((partner) => {
			let fieldValue = partner[field];
			return fieldValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setPartners(searchedPartners);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setPartners(data);
		setClearToggle(!clearToggle);
	};

	const [currentItems, setCurrentItems] = useState([]);

	const { reorderPartners } = useReorderPartners();

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={partners}
			>
				<PartnersTable
					partners={currentItems}
					partnersRefetch={partnersRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={partners}
						setItems={setCurrentItems}
					/>
					{
						partners?.length !== data?.length &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Stack
						direction={{
							base: "column",
							md: "row"
						}}
						spacing="1rem"
					>
						<Button
							variant="primary"
							onClick={openCreatePartnerModal}
						>
							Create Partner
						</Button>
						<ReorderItemsButton
							items={partners}
							reorderMutation={reorderPartners}
							refetch={partnersRefetch}
						/>
					</Stack>	
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreatePartner
				isOpen={showCreatePartner}
				onClose={closeCreatePartnerModal}
				refetch={partnersRefetch}
			/>
		</Flex>
	);

};

export default Partners;