import React, { useState } from "react";

import {
	Button,
	useToast
} from "@chakra-ui/react";

import ReorderModal from "./ReorderModal";

const ReorderItemsButton = ({ items, reorderMutation, refetch }) => {

	const [showReorderModal, setShowReorderModal] = useState(false);
	const openReorderModal = () => setShowReorderModal(true);
	const closeReorderModal = () => setShowReorderModal(false);

	const [loading, setLoading] = useState(false);

	const toast = useToast();

	const reorder = async (items) => {
		setLoading(true);
		const { data, error } = await reorderMutation(items);
		if (error) {
			toast({
				title: "Error reordering.",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		if(data){
			await refetch();
			toast({
				title: "Reordered successfully.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		}
		else{
			toast({
				title: "Error reordering.",
				description: "Something went wrong. Please try again.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		setLoading(false);
		return;
	};

	return (
		<>
			<Button
				variant="primary"
				onClick={openReorderModal}
				isLoading={loading}
				loadingText="Reordering"
			>
				Reorder
			</Button>
			<ReorderModal
				initialItems={items}
				onSubmitReorder={reorder}
				isOpen={showReorderModal}
				onClose={closeReorderModal}
			/>
		</>
	);
};

export default ReorderItemsButton;
