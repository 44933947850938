import React, { useState, useEffect, useContext } from "react";

import { ScrollContext } from "contexts/ScrollContext";

import {
	Flex,
	SimpleGrid,
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import useGetPartner from "hooks/Partner/queries/useGetPartner";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import GalleryHero from "Blueprints/GalleryHero";

import BrochureDisplay from "./BrochureDisplay";

const PartnerPage = () => {

	const { scrollToTop } = useContext(ScrollContext);

	const { partner } = useParams();
	const { loading, error, data } = useGetPartner(partner);

	const [galleryHeroProps, setGalleryHeroProps] = useState({});

	useEffect(() => {
		scrollToTop();
	}, [partner]);

	useEffect(() => {
		if(data){
			let tempProps = {};
			if(data.images.length > 0){
				tempProps = {
					images: data.images,
					objectFit: "cover",
					objectPosition: "center",
				};
			}
			else{
				tempProps = {
					images: [data.image],
					objectFit: "fill",
					objectPosition: "center",
					shouldRepeatImage: true,
					shouldUseOverlay: false,
				};
			}
			setGalleryHeroProps(tempProps);
		}
	}, [data]);


	return (
		<>
			<GalleryHero
				title={data?.name}
				boxHeight={{
					base: "340px",
					md: "md",
				}}
				boxPaddingY="28"
				subHeadingSize="lg"
				{...galleryHeroProps}
			/>
			<Flex
				direction="column"
				alignItems="center"
				justifyContent="center"
				width="100%"
				maxWidth="1200px"
				height="100%"
				p={12}
			>
				<LoadingErrorDataWrapper 
					loading={loading} 
					error={error?.message} 
					data={data}
				>
					<SimpleGrid 
						columns={{ base: 1, md: 2, lg: 3 }} 
						spacing={{ base: "8", md: "12" }}
					>
						{data?.brochures?.map((brochure) => (
							<BrochureDisplay
								key={brochure._id}
								brochure={brochure}
							/>
						))}
					</SimpleGrid>
				</LoadingErrorDataWrapper>
			</Flex>
		</>
	);
};

export default PartnerPage;
