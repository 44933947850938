/**
 * @file useDeleteProduct.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT } from "cache/Product/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteProduct
 *
 * @description
 * Custom hook that handles deleting a product.
 *
 * @returns
 * - delete function
 */
const useDeleteProduct = () => {

	const [deleteProductMutation, { loading, error }] = useMutation(DELETE_PRODUCT);

	const deleteProduct = async (productId) => {

		try {
			const { data } = await deleteProductMutation({ 
				variables: { productId: productId }
			});
			if (error) {
				throw new Error(error);
			}
			let deleteProductSuccess = data.deleteProduct;
			if (!deleteProductSuccess) {
				throw new Error("Deletion failed");
			} else {
				return { success: true };
			}
		} catch (error) {
			return { error };
		}

	};

	return { deleteProduct, loading, error };

};

export default useDeleteProduct;
