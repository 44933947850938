/**
 * @file Authentication.js 
 */
import React, { useState, useContext } from "react";

import {
	Flex,
	Button,
	ButtonGroup,
	Collapse,
	Heading,
	Box,
	FormControl,
	Input,
	Stack,
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useSendAuthenticationTokenEmail from "hooks/Customer/mutations/useSendAuthenticationTokenEmail";
import { PageChangeContext } from "contexts/PageChangeContext";

/**
 * @component Authentication
 * 
 * @description
 * - This component is used to authenticate a user before they can view their invoices
 * - This component is rendered at the top level of the Payments page
 * - This component will render two buttons:
 * 		- Send Authentication token
 * 		- Verify Authentication token
 * - This component will render a form to input the authentication token along with the users email
 * - This component will render a form to input the users email
 * 
 * @param {*} props 
 */
const Authentication = () => {

	const { handlePageChange } = useContext(PageChangeContext);

	const [showSendToken, setShowSendToken] = useState(true);
	const [showVerifyToken, setShowVerifyToken] = useState(false);

	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const handleUpdateEmail = (e) => {
		setEmail(e.target.value);
	};

	const handleUpdateToken = (e) => {
		setToken(e.target.value);
	};


	const { sendAuthenticationTokenEmail } = useSendAuthenticationTokenEmail();

	const handleSendToken = async (e) => {
		e.preventDefault();
		setError(null);
		setLoading(true);
		const { error, data } = await sendAuthenticationTokenEmail(email);
		if(error){
			setError(error.message);
			setLoading(false);
			return;
		}
		if(data){
			setLoading(false);
			setShowSendToken(false);
			setShowVerifyToken(true);
			setSuccess("Authentication token sent to email!");
		}
		else{
			setError("Something went wrong. Please try again later.");
			setLoading(false);
		}
	};

	const handleVerifyToken = (e) => {
		e.preventDefault();
		
		if(!token){
			setError("Please enter the authentication token you received.");
			return;
		}
		if(!email){
			setError("Please enter the email associated with your invoices.");
			return;
		}
		setError(null);

		handlePageChange("payments/invoices", {
			email,
			token,
		});

		return;
	};

	return (
		<Flex
			w={{
				base: "90%",
				md: "100%",
			}}
			h={{
				base: "90vh",
				md: "100vh",
			}}
			flexDir="column"
			justify="center"
			align="center"
			// p="2rem"
			// bg="#f5f5f5"
		>
			<Box	
				w={{
					base: "100%",
					md: "auto",
				}}
				minH="auto"
				flexDir="column"
				justify="center"
				align="center"
				borderWidth="1px"
				borderRadius="lg"
				borderColor="gray.300"
				boxShadow="xl"
				py={{
					base: "0",
					md: "2.8rem",
				}}
				px={{
					base: "0",
					md: "8rem",
				}}
				mb={{
					base: "12rem",
					md: "4rem",
				}}

			>
				<Collapse 
					in={showSendToken} 
					animateOpacity
				>
					<Stack
						direction="column"
						justify="center"
						align="center"
						spacing={4}
						w={{
							base: "80%",
							md: "100%",
						}}
						h="100%"
						px={{
							base: "0",
							md: "1rem",
						}}
						py={{
							base: "1rem"
						}}
					>
						<Heading
							size={"lg"}
							fontWeight="extrabold"
							textAlign="center"
							color="primary.800"
						>
							Taylor Fence Co.
						</Heading>
						<Heading
							size={{
								base: "sm",
								md: "md",
							}}
							fontWeight="bold"
							textAlign="center"
							color="primary.800"
							mb={{
								base: "0.4rem",
								md: "1rem",
							}}
						>
							Invoice Portal
						</Heading>
						<FormControl
							id="email"
							isRequired
						>
							{/* <FormLabel>
								Enter your email to receive an authentication token
							</FormLabel> */}
							<Input
								type="email"
								value={email}
								onChange={handleUpdateEmail}
								placeholder="Enter your email"
								alignSelf="center"
								mt={6} mb={6}
								borderRadius="lg"
								borderColor="gray.300"
								borderWidth="1px"
								boxShadow="sm"
							/>
						</FormControl>
						<Stack
							direction="row"
							justify="center"
							align="center"
							spacing={4}
							p={{
								base: "0",
								md: "1rem",
							}}
						>
							<ButtonGroup
								mb={{
									base: "1rem",
								}}
								size={{
									base: "sm",
									md: "md",
								}}
							>
								<Button
									variant="primary"
									onClick={handleSendToken}
									isLoading={loading}
									size={{
										base: "sm",
										md: "md",
									}}
								>
									Send Auth Token
								</Button>
								<Button
									variant="primary"
									onClick={() => {
										setShowSendToken(!showSendToken);
										setShowVerifyToken(!showVerifyToken);
									}}
									size={{
										base: "sm",
										md: "md",
									}}
								>
									{showSendToken ? "Verify Token" : "Send Token"}
								</Button>
							</ButtonGroup>
						</Stack>
					</Stack>
				</Collapse>
				<Collapse in={showVerifyToken} animateOpacity>
					<Stack
						direction="column"
						justify="center"
						align="center"
						spacing={4}
						w="100%"
						h="100%"
						px="1rem"
						py="2rem"
					>
						<Heading
							size={"md"}
							fontWeight="extrabold"
							textAlign="center"
							color="primary.800"
							mb="1rem"
						>
							Email Authentication
						</Heading>
						<FormControl
							id="email"
							isRequired
						>
							<Input
								type="email"
								value={email}
								onChange={handleUpdateEmail}
								placeholder="Enter your email"
							/>
						</FormControl>
						<FormControl
							id="token"
							isRequired
						>
							<Input
								type="text"
								value={token}
								onChange={handleUpdateToken}
								placeholder="Enter your authentication token"
							/>
						</FormControl>
						{/* New Stack for Buttons */}
						<Stack
							direction="row"
							justify="center"
							align="center"
							spacing={4}
						>
							<ButtonGroup
								mb={{
									base: "1rem",
								}}
								size={{
									base: "sm",
									md: "md",
								}}
							>
								<Button
									variant="primary2"
									onClick={() => {
										setShowSendToken(true);
										setShowVerifyToken(false);
									}}
								>
								Back
								</Button>
								<Button
									variant="primary"
									onClick={handleVerifyToken}
									isLoading={loading}
								>
								Verify Authentication Token
								</Button>
							</ButtonGroup>
			
							{/* New Back Button */}
						</Stack>
					</Stack>
				</Collapse>
				{
					error && (
						<Alert
							status="error"
							mt={4}
						>
							<AlertIcon />
							<AlertDescription>
								{error}
							</AlertDescription>
						</Alert>
					)
				}
				{
					success && (
						<Alert
							status="success"
							mt={4}
						>
							<AlertIcon />
							<AlertDescription>
								{success}
							</AlertDescription>
						</Alert>
					)
				}
			</Box>
		</Flex>
	);
};

export default Authentication;