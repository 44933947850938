/**
 * @file useGetCategoryTree.js
 */
import { useQuery } from "@apollo/client";
import { GET_CATEGORY_TREE } from "cache/Category/queries"; // Update the path as per your project structure

/**
 * @hook useGetCategoryTree
 *
 * @description
 * Custom hook that handles getting the category tree data.
 * Used in the Category component.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Category tree data
 * - refetch: Function to refetch the category tree data
 * - networkStatus: Network status information
 */
const useGetCategoryTree = () => {

	const { loading, error, data, refetch, networkStatus } = useQuery(GET_CATEGORY_TREE, {
		notifyOnNetworkStatusChange: true
	});

	let snaggedData = data?.getCategoryTree;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetCategoryTree;
