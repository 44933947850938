/* eslint-disable no-unused-vars */

/**
 * @file EditVariant.js
 * 
 * @author Steven Secreti
 */

import React, { useState } from "react";

// Chakra UI imports 
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Button,
	Checkbox, 
} from "@chakra-ui/react";

const AddVariation = ({ isOpen, onClose, onAdd, variationOptions }) => {

	const [input, setInput] = useState({
		sku: "",
		isDefaultVariation: false,
	});

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const handleIsDefaultVariationChange = (e) => {
		setInput({
			...input,
			isDefaultVariation: e.target.checked,
		});
	};

	const [formErrors, setFormErrors] = useState({});

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Variation</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl
						isInvalid={formErrors.sku}
					>
						<FormLabel>SKU</FormLabel>
						<Input
							name="sku"
							value={input.sku}
							onChange={handleInputChange}
						/>
						<FormErrorMessage>{formErrors.sku}</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={formErrors.isDefaultVariation}
					>
						<FormLabel>Default Variant</FormLabel>
						<Checkbox
							name="isDefaultVariation"
							value={input.isDefaultVariation}
							onChange={handleIsDefaultVariationChange}
						/>
						<FormErrorMessage>{formErrors.isDefaultVariation}</FormErrorMessage>
					</FormControl>
					{/* <FormControl
						isInvalid={formErrors.options}
					>
						<FormLabel>Options</FormLabel>
						{optionKeys.map((key, index) => (
							<FormControl
								key={index}
							>
								<FormLabel>{key}</FormLabel>
								<Input
									name={key}
									value={input.options[key]}
									onChange={handleOptionChange}
								/>
								<FormErrorMessage>{formErrors[key]}</FormErrorMessage>
							</FormControl>
						))}
					</FormControl> */}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary2"
						onClick={async () => {
							await onAdd(input);
							onClose();
						}}
					>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

};

export default AddVariation;
