import React from "react";

import { 
	Box, 
	Container, 
	Heading, 
	Image, 
	Stack, 
	Text 
} from "@chakra-ui/react";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
import useGetOneReview from "hooks/Review/queries/useGetOneReview";

import ReviewBoxBg from "../../../../assets/Home/components/ReviewBoxBg.jpg";
import WoodReviewImg from "../../../../assets/Home/components/WoodReviewImg.png";
import InfoMsg from "Blueprints/InfoMsg";

export const HomeReviews = () => {

	const { loading, error, data: review } = useGetOneReview();

	return (
		<Box
			backgroundImage={`url(${ReviewBoxBg})`}
			bgSize="cover"
			bgPosition="center"
			bgRepeat="no-repeat"
			minHeight="30vh"
			h="100%"
			minWidth="90%"
			maxW="90%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			rounded="md"
			boxShadow="dark-lg"
			mt="8rem"
			mb="8rem"
		>
			<Container
				py={{
					base: "16",
					md: "20",
				}}
				maxW="container.2xl" // Adjust the width as needed
				h="100%"
			>
				<Stack
					direction={{
						base: "column",
						lg: "row",
					}}
					spacing={{
						base: "4",
						lg: "16",
					}}
				>
					<Image
						width="100%"
						height={{
							base: "sm",
							lg: "lg",
						}}
						objectFit="cover"
						borderRadius="4px"
						borderWidth="4px"
						borderColor="white"
						boxShadow="dark-lg"
						src={WoodReviewImg}
						alt="Wood Review"
						ml={{ lg: "16" }}
						mb={{ base: "12", md: "10" }}
						display="center"
					/>
					<Stack
						spacing={{
							base: "8",
							md: "10",
						}}
						width="full"
						justify="center"
					>
						<Stack
							spacing={{
								base: "4",
								md: "6",
							}}
							direction={{
								base: "column",
								md: "column",
							}}
							justify="center"
							align="center"
						>
							<LoadingErrorDataWrapper
								loading={loading}
								error={error?.message}
								data={review}
							>
								{
									review ?
										<>
											<Heading
												size="lg"
												color="white"
												textShadow="2px 2px 4px rgba(0, 0, 0, 0.4)"
											>
              								"{review?.title}"
											</Heading>
											<Text
												fontSize="2xl"
												color="white"
												textShadow="1px 1px 2px rgba(0, 0, 0, 0.4)"
											>
              								{review?.comment}
											</Text>
											<Text
												fontSize="xl"
												color="white"
												textShadow="1px 1px 2px rgba(0, 0, 0, 0.4)"
											>
              								- {review?.author}
											</Text>
										</>
										:
										<InfoMsg
											info={"Check back here later to see reviews!"}
										>
											No reviews available at this time.
										</InfoMsg>
								}
							</LoadingErrorDataWrapper>
							
						</Stack>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
};

export default HomeReviews;
