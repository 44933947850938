/**
 * @file useReorderProductImages
 */
import { useMutation } from "@apollo/client";
import { REORDER_PRODUCT_IMAGES } from "cache/Product/mutations";

/**
 * @hook useReorderProductImages
 * 
 * @description
 * Custom hook that handles reordering a product's images.
 * This hook should be used whenever a product's images need to be reordered.
 * 
 * @returns
 * - reorderProductImages: Function to execute the reorder product images mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderProductImages = () => {
	const [reorderProductImagesMutation, { loading, error }] = useMutation(REORDER_PRODUCT_IMAGES);

	/**
	 * Reorders a product's images.
	 * 
	 * @param {String} productId - The ID of the product to update.
	 * @param {Array} images - The new images array for the product.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderProductImages = async (productId, images) => {
		try {
			const { data } = await reorderProductImagesMutation({
				variables: {
					productId,
					images
				}
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data.reorderProductImages };
		} catch (error) {
			return { error };
		}
	};

	return { reorderProductImages, loading, error };
};

export default useReorderProductImages;
