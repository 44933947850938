import React from "react";
import { Box, Collapse, Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const Question = ({ title, children }) => {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<MotionBox
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
			marginBottom={{
				base: "0",
				md: "8",
			}}
			p={{
				base: "6",
				md: "2",
			}}
			px={{
				base: "4",
				md: "8",
			}}
			borderWidth={1}
			borderRadius="md"
			borderColor='gray.400'
			width="100%"
			_hover={{
				shadow: "lg",
				transform: "translateY(-2px)",
				borderRadius: "md",
				borderColor: "gray.300",
				borderWidth: "1px",
				cursor: "pointer",
			}}
			cursor="pointer"
			onClick={(e) => {
				e.stopPropagation();
				onToggle();
			}}
			mt={{
				base: "4",
				md: "6",
			}}
			mx={{
				base: "0",
			}}
		>
			<Flex justify="space-between" align="center">
				<Text fontWeight="semibold" as="h3">{title}</Text>
				<IconButton
					icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
					aria-label="Toggle"
					variant="ghost"
					onClick={(e) => {
						e.stopPropagation();
						onToggle();
					}}
				/>
			</Flex>
			<Collapse in={isOpen}>
				<Text mt={4} mb={6} whiteSpace="pre-line">
					{children}
				</Text>
			</Collapse>
		</MotionBox>
	);
};

const Category = ({ title, faqs }) => {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<MotionBox
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
			marginBottom={6}
			p={{
				base: "2",
				md: "4",
			}}
			borderWidth={1}
			borderRadius="md"
			borderColor="gray.500"
			boxShadow="md"
			width={{
				base: "100%",
				md: "100%",
			}}
			align={{
				base: "center",
			}}
			_hover={{
				shadow: "xl",
				borderRadius: "md",
				borderColor: "gray.400",
				transform: "translateY(-2px)",
				borderWidth: "1px",
				cursor: "pointer",
			}}
			cursor="pointer"
			onClick={onToggle}
			mt={{
				base: "4",
				md: "4",
			}}
		>
			<Flex 
				justify={{
					base: "space-between",
					md: "space-between",
				}}
				align={{
					base: "center",
					md: "center",
				}}
			>
				<Text 
					as="h2"
					fontWeight="semibold"
					px={{
						base: "4",
					}}
				>
					{title}
				</Text>
				<IconButton
					icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
					aria-label="Toggle"
					variant="ghost"
					onClick={onToggle}
				/>
			</Flex>
			<Collapse in={isOpen}>
				<Box mt={4}>
					{faqs.map((faq, i) => (
						<Question key={i} title={faq.question}>
							{faq.answer}
						</Question>
					))}
				</Box>
			</Collapse>
		</MotionBox>
	);
};

const FAQ = ({ faqs }) => {
	return (
		<Box mt={10} my={10} width="100%" maxWidth="1200px" m="0 auto">
			{faqs.map((category, i) => (
				<Category key={i} title={category.category} faqs={category.faqs} />
			))}
		</Box>
	);
};

export default FAQ;
