/**
 * @file Table.js is a file for the Partners Table component.
 */
import React, { useState, useEffect } from "react";

import {
	Button,
	Image
} from "@chakra-ui/react";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

import UpdatePartner from "./modals/UpdatePartner";
import DeletePartner from "./modals/DeletePartner";

/**
 * @component PartnerRow
 */
const PartnerRow = (props) => {

	const partner = props.partner;

	const [showUpdatePartner, setShowUpdatePartner] = useState(false);
	const openUpdatePartnerModal = (e) => {
		e.stopPropagation();
		setShowUpdatePartner(true);
	};
	const closeUpdatePartnerModal = () => setShowUpdatePartner(false);

	const [showDeletePartner, setShowDeletePartner] = useState(false);
	const openDeletePartnerModal = (e) => {
		e.stopPropagation();
		setShowDeletePartner(true);
	};
	const closeDeletePartnerModal = () => setShowDeletePartner(false);

	return (
		<RowContainer>
			<TextCell>{partner.name}</TextCell>
			<TableCell>
				<Image
					src={partner.image}
					alt={partner.name}
					maxH={{
						base: "auto",
						md: "100px",
					}}
					maxW={{
						base: "auto",
						md: "100px",
					}}
					objectFit="cover"
					borderRadius="lg"
					borderWidth="1px"
					borderColor="#CBD5E0"
				/>
			</TableCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openUpdatePartnerModal}
					mr={2}
				>
					Update
				</Button>
				<Button variant="danger" onClick={openDeletePartnerModal}>
					Delete
				</Button>
			</TableCell>
			<UpdatePartner
				isOpen={showUpdatePartner}
				onClose={closeUpdatePartnerModal}
				partner={partner}
				refetch={props.refetch}
			/>
			<DeletePartner
				isOpen={showDeletePartner}
				onClose={closeDeletePartnerModal}
				partner={partner}
				refetch={props.refetch}
			/>
		</RowContainer>
	);
};

/**
 * @component PartnersTable
 * 
 * @returns PartnersTable component.
 */
const PartnersTable = (props) => {
	const [partners, setPartners] = useState(props.partners);

	useEffect(() => {
		if (props.partners) setPartners(props.partners);
	}, [props.partners]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("name", order)}
					search={(value) => props.search("name", value)}
					clearToggle={props.clearToggle}
				>
					Name
				</TextCell>
				<TextCell>Logo</TextCell>
				<TextCell>Actions</TextCell>
			</HeaderContainer>
			{partners.map((partner) => (
				<PartnerRow
					key={partner._id}
					partner={partner}
					refetch={props.partnersRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default PartnersTable;
