/**
 * @file useUpdateBrochure.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_BROCHURE } from "cache/Brochure/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateBrochure
 *
 * @description
 * Custom hook that handles the functionality to update an existing brochure.
 * Use this hook wherever updating a brochure's details is required.
 *
 * @returns
 * - updateBrochure: Handler to execute the updateBrochure mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateBrochure = () => {

	const [updateBrochureMutation, { loading, error }] = useMutation(UPDATE_BROCHURE);

	/**
	 * Custom mutation for updating a brochure.
	 *
	 * @param {String} _id - The ID of the brochure to be updated.
	 * @param {Object} input - Input object containing the new details of the brochure.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateBrochure = async (_id, input) => {
		try {
			const { data } = await updateBrochureMutation({
				variables: {
					_id,
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.updateBrochure };
		} catch (error) {
			return { error };
		}
	};

	return { updateBrochure, loading, error };

};

export default useUpdateBrochure;
