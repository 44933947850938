import { Button, HStack, Icon, Text } from "@chakra-ui/react";

/**
 * @component NavButton
 * 
 * @description - Renders the NavButton component
 * 
 * @param {*} props 
 * @returns 
 */
export const NavButton = (props) => {
	const { icon, label, handleClick, isActive, value, ...buttonProps } = props;

	const clicked = () => {
		handleClick(value);
	};

	return (
		<Button 
			variant={isActive ? "primary" : "ghost"} 
			justifyContent="start"
			{...buttonProps} // spread the rest of the props to the button
			onClick={clicked}
			aria-label={label}
			role="button"
			transition=".15s ease"
			overflow="hidden" // prevents the button from overflowing its container
			_hover={{
				bg: "gray.100",
				color: "gray.900",
			}}
			w={{
				base: "100%",
				md: "auto",
			}}
			
		>
			<HStack spacing="3">
				<Icon 
					as={icon}
					boxSize="6"
					color="fg.subtle"
					_groupHover={{
						color: "gray.300",
					}}
				/>
				<Text
					fontSize={{
						base: "sm",
						md: "md"
					}}
				>
					{label}
				</Text>
			</HStack>
		</Button>
	);
};
