/**
 * @file PasswordPopover.js
 */
import React, { useEffect, useState } from "react";

import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	IconButton,
	Box,
	Text,
} from "@chakra-ui/react";

import { HiOutlineQuestionMarkCircle, HiXCircle, HiCheckCircle } from "react-icons/hi";

const Requirement = ({ check, description }) => (
	<Box display="flex" alignItems="center">
	  { check 
		  ? <HiCheckCircle color="green" /> 
		  : <HiXCircle color="tf_red" />
	  }
	  <Text marginLeft="8px">{description}</Text>
	</Box>
);

/**
 * @component PasswordPopover
 * 
 * @description
 * - This component is used to display a popover with password requirements
 * - This component is used in the login and reset password screens
 * 
 * @param {*} props
 * @returns
 * <PasswordPopover
 * 	placement="right"
 * 	password={input.password}
 * />
 */
const PasswordPopover = ({ password }) => {

	const [lengthValid, setLengthValid] = useState(false);
	const [containsLetter, setContainsLetter] = useState(false);
	const [containsNumber, setContainsNumber] = useState(false);
	const [validChars, setValidChars] = useState(false);

	useEffect(() => {
		setLengthValid(isLengthValid(password));
		setContainsLetter(hasLetter(password));
		setContainsNumber(hasNumber(password));
		setValidChars(hasValidChars(password));
	}, [password]);

	return (
		<Popover 
			placement="top-start" 
			closeOnBlur={false}
			trigger="hover"
		>
			<PopoverTrigger>
				<IconButton
					aria-label="Password Requirements"
					icon={<HiOutlineQuestionMarkCircle />}
					size="md"
					variant="link"
				/>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverHeader>Password Requirements</PopoverHeader>
				<PopoverBody>
					<Requirement check={lengthValid} description="At least 8 characters long" />
					<Requirement check={containsLetter} description="Contains at least one letter" />
					<Requirement check={containsNumber} description="Contains at least one number" />
					<Requirement check={validChars} description="Valid characters only: ! $ % @ # £ € * ? &" />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
	
};

export default PasswordPopover;

export const isLengthValid = password => password.length >= 8;
export const hasLetter = password => /[A-Za-z]/.test(password);
export const hasNumber = password => /\d/.test(password);
export const hasValidChars = password => /^[A-Za-z\d!$%@#£€*?&]*$/.test(password);
