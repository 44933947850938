import { 
	Box, 
	Text, 
	Table, 
	Thead, 
	Tbody, 
	Tr, 
	Th, 
	Td, 
	VStack, 
	Button,
	Image, 
	HStack, 
	Link, 
	Flex 
} from "@chakra-ui/react";

import TaylorFenceLogo from "../../../../assets/Logo/TaylorFenceLogo.png";

const companyInfo = "1246 NJ-33 \n Howell Township NJ 07731";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
import useGetStripeInvoiceLink from "hooks/Invoice/queries/useGetStripeInvoiceLink";

import { convertDateToReadableFormat } from "utils/utils";

const Invoice = (props) => {

	const invoice = props.invoice;

	let invoiceDisabled = false;
	if(invoice.status === "paid" || invoice.status === "void"){
		invoiceDisabled = true;
	}

	const { loading, error, data } = useGetStripeInvoiceLink(invoice._id);

	if(data?.url === null){
		invoiceDisabled = true;
	}

	const InvoiceStatusPending = () => (
		<Link
			href={data?.url}
			isExternal
		>
			<Button
				mt={28}
				variant={"delgato"}
				w="200px"
				h="60px"
				isDisabled={invoiceDisabled}
			>
					Pay Invoice  
			</Button>
		</Link>
	);

	const InvoiceStatusCancelled = () => (
		<Flex
			flexDir="column"
			w="100%"
			justifyContent="center"
			alignItems="center"
		>
			<Text
				variant="cancelled_text"
				fontSize="2xl"
			>
				CANCELLED
			</Text>
			<Text>
				This invoice has been cancelled. If you think this is a mistake, please contact us.
			</Text>
			<Link
				href={data?.url}
				isExternal
			>
				<Button
					mt={28}
					variant={"delgato"}
					w="200px"
					h="60px"
				>
						View Invoice
				</Button>
			</Link>
		</Flex>
	);

	const InvoiceStatusPartiallyPaid = () => (
		<Flex
			flexDir="column"
			w="100%"
			justifyContent="center"
			alignItems="center"
		>
			<Text
				fontWeight="bold"
				color="yellow.500"
				fontSize="2xl"
			>
				PARTIALLY PAID
			</Text>
			<Text>
				This invoice has been partially paid. Please pay the remaining balance.
			</Text>
			<Link
				href={data?.url}
				isExternal
			>
				<Button
					mt={28}
					variant={"delgato"}
					w="200px"
					h="60px"
				>
					Pay Invoice
				</Button>
			</Link>
		</Flex>
	);

	const InvoiceStatusPaid = () => (
		<Flex
			flexDir="column"
			w="100%"
			justifyContent="center"
			alignItems="center"
		>
			<Text
				fontWeight="bold"
				color="green.500"
				fontSize="2xl"
			>
				PAID
			</Text>
			<Text
				fontWeight="bold"
				color="green.500"
				fontSize="2xl"
			>
				Thank you for your payment!
			</Text>
			<Link
				href={data?.url}
				isExternal
			>
				<Button
					mt={28}
					variant={"delgato"}
					w="200px"
					h="60px"
				>
						View Receipt
				</Button>
			</Link>
		</Flex>
	);

	const InvoiceStatusRefunded = () => (
		<Flex
			flexDir="column"
			w="100%"
			justifyContent="center"
			alignItems="center"
		>
			<Text
				fontWeight="bold"
				color="red.500"
				fontSize="2xl"
			>
				REFUNDED
			</Text>
			<Text>
				Payment Refunded On: {convertDateToReadableFormat(invoice?.refund?.refundedOn)}
			</Text>
			<Link
				href={invoice?.refund?.receiptUrl}
				isExternal
			>
				<Button
					mt={28}
					variant={"delgato"}
					w="200px"
					h="60px"
				>
						View Receipt
				</Button>
			</Link>
		</Flex>
	);


	const renderInvoiceStatus = () => {
		switch(invoice.status){
		case "pending":
			return <InvoiceStatusPending />;
		case "cancelled":
			return <InvoiceStatusCancelled />;
		case "partially_paid":
			return <InvoiceStatusPartiallyPaid />;
		case "paid":
			return <InvoiceStatusPaid />;
		case "refunded":
			return <InvoiceStatusRefunded />;
		default:
			return <InvoiceStatusPending />;
		}
	};

	return (
		<VStack
			spacing={4}
			align="stretch"
			mb={28} 
			mt={12}
			p={{
				base: "2",
				md: "8",
			}}
			borderWidth={1}
			borderRadius="lg"
			minH={{
				base: "100vh",
				md: "100vh",
			}}
			w={{
				base: "90%",
				md: "50%",
			}}
			borderColor="gray.200"
			boxShadow="dark-lg"
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				overflow={{
					base: "scroll",
					md: "hidden",
				}}
			>
				<Image
					h="100%"
					w={{
						base: "40%",
						md: "210px",
					}}
					objectFit="cover"
					px={4}
					py={4}
					src={TaylorFenceLogo}
					mb={12}
					alt="Company Logo"
				/>
				{/* ------------------------------------------------------- Invoice Date and Number ------------------------------------------------------- */}
				<Box>
					<Box
						display="flex"
						justifyContent="flex-end"
					>
						<Text mx={2} fontWeight="bold">Invoice no:</Text>
						<Text>{invoice._id}</Text>
					</Box>
					<Box
						display="flex"
						justifyContent="flex-end"
					>
						<Text fontWeight="bold" mr={2}>Invoice date:</Text>
						<Text>{convertDateToReadableFormat(invoice.createdAt)}</Text>
					</Box>
					<Box
						display="flex"
						justifyContent="flex-start"
					>
						{companyInfo.split("\n").map((line, i) => (
							<Text fontWeight="bold" color="#EF3737" key={i}>{line}</Text>
						))}
					</Box>
				</Box>
			</Box>
			<HStack spacing={5} align="start">
				{/* ------------------------------------------------------- Company Info ------------------------------------------------------- */}
				{/* ---------------------------------------------------------- Billed To ---------------------------------------------------------- */}
				<Box>
					<Text fontWeight="bold">Billed to:</Text>
					<Text fontWeight="md-bold" color="blackAlpha.800">
						{`${invoice.customer.name}`}
					</Text>
					<Text fontWeight="md-bold" color="blackAlpha.800">
						{invoice.customer.email}
					</Text>
					{
						invoice.customer.address &&
						<>
							<Text fontWeight="md-bold" color="blackAlpha.800">
								{invoice.customer.address.line1}
							</Text>
							<Text fontWeight="md-bold" color="blackAlpha.800">
								{invoice.customer.address?.line2}
							</Text>
							<Text fontWeight="md-bold" color="blackAlpha.800">
								{invoice.customer.address.city}, {invoice.customer.address.state} {invoice.customer.address.zip}
							</Text>
						</>
					}
				</Box>
			</HStack>
			{/* ---------------------------------------------------------- Items ---------------------------------------------------------- */}
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th>Item</Th>
						<Th>Qty</Th>
						<Th>Rate</Th>
						<Th>Amount</Th>
					</Tr>
				</Thead>
				<Tbody>
					{invoice.items.map((item) => (
						<Tr key={item._id}>
							<Td>{item.item}</Td>
							<Td>{item.quantity}</Td>
							<Td>{item.rate}</Td>
							<Td>{item.amount}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			{
				invoice.discount && invoice.discount !== 0 &&
				<Box
					display="flex"
					justifyContent="flex-end"
				>
					<Text fontWeight="bold">Discount:</Text>
					<Text ml={2}>{invoice.discount}%</Text>
				</Box>
			}
			{/* ------------------------------------------------------- Total ------------------------------------------------------- */}
			<Box
				display="flex"
				justifyContent="flex-end"
			>
				<Text fontWeight="bold">Total:</Text>
				<Text ml={2}>${parseFloat(invoice.total_amount).toFixed(2)}</Text>
			</Box>
			{/* ------------------------------------------------------- Pay Button ------------------------------------------------------- */}
			<Box w="100%" display="flex" justifyContent="center">
				<LoadingErrorDataWrapper
					loading={loading}
					error={error?.message}
					data={data}
				>
					{renderInvoiceStatus()}
				</LoadingErrorDataWrapper>	
			</Box>
		</VStack>
	);
};

export default Invoice;
