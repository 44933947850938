/** 
 * @file mutations.js is a file for all Partner mutations 
 */
import { gql } from "@apollo/client";

/**
 * @constant CREATE_PARTNER
 * 
 * @summary GraphQL mutation that creates a partner.
 */
export const CREATE_PARTNER = gql`
	mutation createPartner($input: CreatePartnerInput!) {
		createPartner(input: $input)
	}
`;

/**
 * @constant UPDATE_PARTNER
 * 
 * @summary GraphQL mutation that updates a partner.
 */
export const UPDATE_PARTNER = gql`
	mutation updatePartner($_id: ID!, $input: UpdatePartnerInput!) {
		updatePartner(_id: $_id, input: $input)
	}
`;

/**
 * @constant REORDER_PARTNERS
 * 
 * @summary GraphQL mutation that reorders partners.
 */
export const REORDER_PARTNERS = gql`
	mutation reorderPartners($input: [ReorderInput!]!) {
		reorderPartners(input: $input)
	}
`;

/**
 * @constant DELETE_PARTNER
 * 
 * @summary GraphQL mutation that deletes a partner.
 */
export const DELETE_PARTNER = gql`
	mutation deletePartner($_id: ID!) {
		deletePartner(_id: $_id)
	}
`;
