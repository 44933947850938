/**
 * @file Table.js is a file for the Brochures Table component.
 */
import React, { useState, useEffect } from "react";

import {
	Button,
	Image
} from "@chakra-ui/react";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

import UpdateBrochure from "./modals/UpdateBrochure";
import DeleteBrochure from "./modals/DeleteBrochure";

/**
 * @component BrochureRow
 */
const BrochureRow = (props) => {

	const brochure = props.brochure;

	const [showUpdateBrochure, setShowUpdateBrochure] = useState(false);
	const openUpdateBrochureModal = (e) => {
		e.stopPropagation();
		setShowUpdateBrochure(true);
	};
	const closeUpdateBrochureModal = () => setShowUpdateBrochure(false);

	const [showDeleteBrochure, setShowDeleteBrochure] = useState(false);
	const openDeleteBrochureModal = (e) => {
		e.stopPropagation();
		setShowDeleteBrochure(true);
	};
	const closeDeleteBrochureModal = () => setShowDeleteBrochure(false);

	return (
		<RowContainer>
			<TextCell>{brochure.name}</TextCell>
			<TableCell>
				<Image
					src={brochure.image}
					alt={brochure.name}
					maxH={{
						base: "auto",
						md: "100px",
					}}
					maxW={{
						base: "auto",
						md: "100px",
					}}
					objectFit="cover"
					borderRadius="lg"
					borderWidth="1px"
					borderColor="#CBD5E0"
				/>
			</TableCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openUpdateBrochureModal}
					mr={2}
				>
					Update
				</Button>
				<Button variant="danger" onClick={openDeleteBrochureModal}>
					Delete
				</Button>
			</TableCell>
			<UpdateBrochure
				isOpen={showUpdateBrochure}
				onClose={closeUpdateBrochureModal}
				brochure={brochure}
				refetch={props.refetch}
			/>
			<DeleteBrochure
				isOpen={showDeleteBrochure}
				onClose={closeDeleteBrochureModal}
				brochure={brochure}
				refetch={props.refetch}
			/>
		</RowContainer>
	);
};

/**
 * @component BrochuresTable
 * 
 * @returns BrochuresTable component.
 */
const BrochuresTable = (props) => {
	const [brochures, setBrochures] = useState(props.brochures);

	useEffect(() => {
		if (props.brochures) setBrochures(props.brochures);
	}, [props.brochures]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("name", order)}
					search={(value) => props.search("name", value)}
					clearToggle={props.clearToggle}
				>
					Name
				</TextCell>
				<TextCell>Image</TextCell>
				<TextCell>Actions</TextCell>
			</HeaderContainer>
			{brochures.map((brochure) => (
				<BrochureRow
					key={brochure._id}
					brochure={brochure}
					refetch={props.brochuresRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default BrochuresTable;
