/**
 * @file useDeleteInvoice.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_INVOICE } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteInvoice
 * 
 * @description
 * Custom hook to handle the deletion of an existing invoice.
 * The hook will return a Boolean to indicate the success or failure of the operation.
 * 
 * @returns
 * - deleteInvoice: Method to execute the deletion of the invoice
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeleteInvoice = () => {
	
	// Initialize Apollo useMutation for the DELETE_INVOICE mutation
	const [deleteInvoiceMutation, { loading, error }] = useMutation(DELETE_INVOICE);

	/**
	 * Method to execute the deletion of the invoice.
	 * 
	 * @param {String} id - The ID of the invoice to delete.
	 * 
	 * @returns {Object} - An object containing the mutation result data (Boolean) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteInvoice = async (id) => {

		try {
			const { data } = await deleteInvoiceMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			return { data: data.deleteInvoice };
		} catch (error) {
			return { error };
		}
	};

	return { deleteInvoice, loading, error };
};

export default useDeleteInvoice;