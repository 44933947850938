/**
 * @module Chainlink
 * @description - Main Chainlink page for the Showroom section of the website.
 * @author Webify Services, LTD.
 */
import GalleryHero from "Blueprints/GalleryHero";
import Section from "./components/Section";

import { Flex, Divider } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";

import ChainlinkImage1 from "assets/TaylorFenceImg/Resources/ChainLink_BlackChainlinkFence.png";
import ChainlinkImage2 from "assets/Aluminum Black Fence.svg";
import ChainlinkCategories from "./components/Category";

import useGetShowroomCategory from "hooks/Category/queries/useGetShowroomCategory";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

/**
 * @function Chainlink
 * 
 * @returns {React.ReactElement}
 * 
 * @utilizes 
 * - GalleryHero (HOC/Blueprint)
 * - Section (component)
 */
const Chainlink = () => {

	const { loading, error, data } = useGetShowroomCategory("Chainlink");

	const chainlink_images = [
    	ChainlinkImage1,
		ChainlinkImage2,
	];

	const features = [
		{ name: "Height", icon: FiCheckCircle },
		{ name: "Weight",icon: FiCheckCircle },
		{ name: "Layout Drawing", icon: FiCheckCircle },
		{ name: "Line Post Measurements", icon: FiCheckCircle },
		{ name: "Top Rail & Gate Frame Measurements", icon: FiCheckCircle },
		{ name: "Galvanized or Vinyl Coated", icon: FiCheckCircle },
	];

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
			h="100%"
			gap={4}
		>
			<LoadingErrorDataWrapper loading={loading} error={error?.message} data={data} >
				<GalleryHero
					images={data?.images || chainlink_images}
					title={"Chainlink"}
					description={data?.description || "Chainlink fencing offers both durability and cost-effectiveness, making it an ideal choice for those seeking a straightforward yet attractive look. Available in an array of colors, it also provides the option to add privacy slats. This versatile design could be the ideal enhancement for your outdoor area, combining practicality with aesthetic appeal."}
					imageHeight={{
						sm: "180px",
						md: "100%",
					}}
					imageWidth="100%"
					boxHeight={{
						sm: "140px",
						md: "500px",
					}}
					boxPaddingY="10"
					subHeadingSize="lg"
				/>
				<Divider w="calc(100% - 8rem)"/>
				<Section features={features} />
				<ChainlinkCategories />
			</LoadingErrorDataWrapper>
		</Flex>
	);
};

export default Chainlink;
