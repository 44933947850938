/**
 * @file
 * This is the NotFound component.
 * It is displayed when the user tries to navigate to a page that doesn't exist.
 * After a delay, it automatically redirects the user to the home page.
 *
 * @summary 404 page with automatic redirect.
 */

import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";
import { Flex, Heading, Text, Button, Box } from "@chakra-ui/react";

import { TF_RED, TF_AMARA, TF_BLACK} from "chakraStyles";

/**
 * NotFound component is shown when a user navigates to a page that doesn't exist.
 * After 5 seconds, it automatically redirects the user to the home page.
 *
 * @param {object} props - The props that were defined by the caller of this component.
 * @returns {HTMLElement} - A Flex component styled as a 404 error page.
 */

const NotFound = () => {

	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<Flex
			direction="column"
			align="center"
			justify="center"
			w="100%"
			h="100%"
			color="white"
			py="28"
			position="relative"
			zIndex={0}
		>
			<Box textAlign="center" py={10} px={6}>
				<Heading
					display="inline-block"
					as="h2"
					size="2xl"
					bgGradient={`linear(to-r, ${TF_RED}, ${TF_AMARA})`}
					backgroundClip="text"
				>
					404
				</Heading>
				<Text 
					fontSize="18px" 
					mt={3}
					mb={2}
					color={TF_BLACK}
				>
					Page Not Found
				</Text>
				<Text color={"gray.500"} mb={6}>
					The page you&apos;re looking for does not seem to exist
				</Text>
				<Button
					bgGradient={`linear(to-l, ${TF_AMARA}, ${TF_BLACK})`}
					color="white"
					variant="primary"
					onClick={() => handlePageChange("home")}	
				>
					Go to Home
				</Button>
			</Box>
		</Flex>
	);
};

export default NotFound;