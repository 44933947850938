import React, { useState } from "react";
import {
	FormControl,
	FormLabel,
	Input,
	Button,
	Box,
	Flex,
	useToast,
} from "@chakra-ui/react";

import useSendContactFormEmail from "hooks/Contact/useSendContactFormEmail";

import ErrorMsg from "Blueprints/ErrorMsg";

const ContactForm = () => {

	const [input, setInput] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const toast = useToast();
	
	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const { sendContactEmail } = useSendContactFormEmail();

	// handle form submission here
	const handleSubmit = async (e) => {
		e.preventDefault();

		setErrorMsg(null);
		setLoading(true);

		let contactInput = {
			subject: "New Simple Contact Form Submission",
			formData: {
				name: input.name,
				email: input.email,
				message: input.message,
			},
		};

		const { success } = await sendContactEmail(contactInput);
		if(success){
			setInput({
				name: "",
				email: "",
				message: "",
			});
			toast({
				title: "Message Sent",
				description: "Your message has been sent successfully! We will get back to you soon.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		}
		else {
			toast({
				title: "Error",
				description: "An unexpected error occurred while sending your message. Please try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		setLoading(false);
	};

	return (
		<Box w={{ base: "100%", md: "30%" }} align="center" p={{ base: 4, md: 0 }}>
			<Box w={{ base: "100%", md: "250px" }}>
				<Flex direction="column" alignItems="center" py={4}>
					<Box as="h3" fontWeight="bold" color="gray.700" textAlign="center" mb={4}>
          				Get in touch!
					</Box>
					<FormControl id="name" mb={2}>
						<FormLabel srOnly>Name</FormLabel>
						<Input 
							name="name"
							value={input.name}
							onChange={handleUpdateInput}
							type="text" 
							placeholder="Name" 
							boxShadow="md" 
							bg="white" 
						/>
					</FormControl>
					<FormControl id="email" mb={2}>
						<FormLabel srOnly>Email address</FormLabel>
						<Input 
							name="email"
							value={input.email}
							onChange={handleUpdateInput}
							type="email" 
							placeholder="Email" 
							boxShadow="md" 
							bg="white" 
						/>
					</FormControl>
					<FormControl id="message" mb={4}>
						<FormLabel srOnly>Message</FormLabel>
						<Input 
							name="message"
							value={input.message}
							onChange={handleUpdateInput}
							type="text" 
							placeholder="Message" 
							boxShadow="md" 
							h="60px" 
							bg="white" 
						/>
					</FormControl>
					<Button 
						variant="delgato" 
						w="250px" 
						boxShadow="md" 
						onClick={handleSubmit}
						isLoading={loading}
						loadingText="Sending"
					>
						Submit
					</Button>
					{errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
				</Flex>
			</Box>
		</Box>
	);
};

export default ContactForm;
