/**
 * @file - AdminController.js
 * - Routing between login and dashboard screen.
 * - Rendered from MainContents.js.
 * 
 * @Summary - Admin routing controller.
 */

import React from "react";

import { AdminProvider } from "contexts/AdminContext";

import Login from "./Login/Login";
import DashboardController from "./Dashboard/DashboardController";
import ResetPassword from "./ResetPassword/ResetPassword";

import ProtectedRoute from "wrappers/ProtectedRoute";

import { Routes, Route } from "react-router-dom";



import "./AdminController.scss";

/**
 * @component AdminController
 * 
 * @description
 * - This component is the main router for the Admin dashboard
 * - This component will render the login screen if the admin is not logged in
 * - This component will render the dashboard if the admin is logged in
 * 
 * @param {*} props 
 * @returns 
 */
const AdminController = () => {
	return (
		<AdminProvider
			className="admin-controller"
		>
			<Routes>
				<Route path="/" element={
					<Login/>
				}/>
				<Route path="/login" element={
					<Login/>
				}/>
				<Route path="/dashboard/*" element={
					<ProtectedRoute element={
						<DashboardController/>
					}/>
				}/>
				<Route path="/reset-password" element={
					<ResetPassword/>
				}/>
			</Routes>
		</AdminProvider>
	);
};

export default AdminController;