/** 
 * @file mutations.js is a file for all Brochure mutations 
 */
import { gql } from "@apollo/client";

/**
 * @constant CREATE_BROCHURE
 * 
 * @summary GraphQL mutation that creates a brochure.
 */
export const CREATE_BROCHURE = gql`
	mutation createBrochure($input: CreateBrochureInput!) {
		createBrochure(input: $input)
	}
`;

/**
 * @constant UPDATE_BROCHURE
 * 
 * @summary GraphQL mutation that updates a brochure.
 */
export const UPDATE_BROCHURE = gql`
	mutation updateBrochure($_id: String!, $input: UpdateBrochureInput!) {
		updateBrochure(_id: $_id, input: $input)
	}
`;

/**
 * @constant REORDER_BROCHURES
 * 
 * @summary GraphQL mutation that reorders brochures.
 */
export const REORDER_BROCHURES = gql`
	mutation reorderBrochures($input: [ReorderInput!]!) {
		reorderBrochures(input: $input)
	}
`;


/**
 * @constant DELETE_BROCHURE	
 * 
 * @summary GraphQL mutation that deletes a brochure.
 */
export const DELETE_BROCHURE = gql`
	mutation deleteBrochure($_id: String!) {
		deleteBrochure(_id: $_id)
	}
`;

