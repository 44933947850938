/**
 * @file DeletePartner.js is responsible for rendering the delete partner modal
 */
import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
	Alert,
	AlertIcon,
	AlertDescription,
	useToast
} from "@chakra-ui/react";

import useDeletePartner from "hooks/Partner/mutations/useDeletePartner"; // Update the path as per your project structure

/**
 * @function DeletePartner
 * @summary - Modal to delete a partner
 */
const DeletePartner = ({ isOpen, onClose, partner, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deletePartner } = useDeletePartner();
	const toast = useToast();

	/**
	 * @function handleDeletePartner
	 * 
	 * @description - This function is used to delete a gallery image from the database.
	 * 
	 */
	const handleDeletePartner = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await deletePartner(partner._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			toast({
				title: "Partner deleted.",
				description: "The partner has been deleted.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Partner</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to delete this partner?</Text>
					<Text fontWeight="bold" mt={2}>This action cannot be undone.</Text>
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
					<Text mt={4}>Please type <Text as="span" fontWeight="bold">DELETE</Text> to confirm.</Text>
					<Input
						mt={2}
						placeholder="DELETE"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleDeletePartner}
						isLoading={loading}
						isDisabled={confirmText !== "DELETE"}
					>
						Delete
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeletePartner;
