import { Box, Container, Heading, Stack, Text, Flex } from "@chakra-ui/react";
import { HelpCenterFeature } from "../modals/HelpModal";

export const HelpCTA = () => (
	<Box as="section" bg="bg.accent.default" color="fg.accent.default" py={{ base: "16", md: "23" }}>
		<Container
			maxW={{ base: "xl", md: "7xl" }}
			px={{ base: "6", md: "8" }}
			mx="auto"
		>
			<Stack spacing="3">
				<Heading mt={4} size={{ base: "md", md: "lg" }} fontWeight="bold" as="h1">
              Help Center
				</Heading>
				<Text fontSize={{ base: "lg", md: "lg" }} as="h2">
			  Find answers to commonly asked questions.
				</Text>
			</Stack>
			<Flex
				w="100%"
				justify="center"
				mt="8"
			>
				<HelpCenterFeature />
			</Flex>
		</Container>
	</Box>
);
