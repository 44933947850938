/**
 * @file - LoginForm.js
 */
import React, { useState, useEffect, useContext } from "react";

import { AdminContext } from "contexts/AdminContext";
import { PageChangeContext } from "contexts/PageChangeContext";

import useAdminLogin from "hooks/Admin/mutations/useAdminLogin";

import { FcLike } from "react-icons/fc";

import {
	Button,
	Container,
	FormControl,
	FormLabel,
	Heading,
	VStack,
	Input,
	Stack,
	Box,
	Text,
	Alert,
	AlertIcon
} from "@chakra-ui/react";

import ForgotPasswordModal from "./ForgotPasswordModal";

import PasswordPopover from "Blueprints/Popovers/PasswordPopover";
  
const LoginForm = () => {

	const { handlePageChange } = useContext(PageChangeContext);
	const { admin, adminRefetch } = useContext(AdminContext);

	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const openForgotPasswordModal = () => setShowForgotPassword(true);
	const closeForgotPasswordModal = () => setShowForgotPassword(false);

	//If the admin is already logged in, redirect them to the admin dashboard
	useEffect(() => {
		if(admin && admin._id !== null){
			handlePageChange("/admin/dashboard");
		}
	}, [admin]);

	const [input, setInput] = useState({
		email: "",
		password: "",
	});

	const [errorMsg, setErrorMsg] = useState(null);
	const [loading, setLoading] = useState(false);

	const { login } = useAdminLogin();

	/**
	 * @function updateInput
	 * 
	 * @description
	 * - Updates the input state when a user types in the login form.
	 * 
	 * @param {*} e 
	 */
	const updateInput = (e) => {
		const { name, value } = e.target;
		const updatedInput = { ...input, [name]: value };
		setInput(updatedInput);
	};

	/**
	 * @function handleLogin
	 * 
	 * @description
	 * - Handles the login form submission.
	 * - Calls the Login mutation.
	 * - Redirects the admin to the dashboard if the login is successful.
	 * 
	 * @returns {void}
	 */
	const handleLogin = async (e) => {
		e.preventDefault();
		setErrorMsg(null);
		setLoading(true);
        
		const { email, password } = input;

		const loginInput = {
			email: email,
			password: password,
		};

		let response = await login(loginInput);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			await adminRefetch();
			handlePageChange("/admin/dashboard");
			setLoading(false);	
		}
		else{
			setErrorMsg("The email or password you entered is incorrect. Please try again.");
			setLoading(false);
			return;
		}
	};

	return (
		<VStack
			spacing="8"
			w="full"
			maxW={{
				base: "md",
				xs: "md",
				sm: "lg",
				md: "xl",
			}}
			mx="auto"
			px={{
				base: "6",
				md: "8",
			}}
			py={{
				base: "6",
				md: "20",
			}}
			alignItems="center"
		>
			<Heading
				size={"lg"}
				fontWeight="extrabold"
				textAlign="center"
				color="primary.800"
			>
				Taylor Fence Co.
			</Heading>
			<Container
				maxW={{
					base: "md",
					xs: "md",
					sm: "lg",
					md: "xl",
				}}
				py={{
					base: "4",
					md: "18",
				}}
				mt={{
					base: "2",
					md: "8",
				}}
				boxShadow="xl"
				rounded="2xl"
				borderWidth="1px"
				borderColor="gray.300"
				bg="#FFFFFF"
			>
				<Stack
					spacing="8"
					p="8"
				>
					<Stack
						spacing="6"
					>
						<Heading
							size={"lg"}
							fontWeight="semibold"
							textAlign="center"
							color="primary.800"
						>
							Admin Portal
						</Heading>
					</Stack>
					<Stack
						spacing="6"
						as="form"
						onSubmit={handleLogin}
					>
						<Stack
							spacing="5"
						>
							<FormControl>
								<FormLabel
									htmlFor="email"
								>
									Email
								</FormLabel>
								<Input
									id="email"
									placeholder="Enter your email"
									type="email"
									name="email"
									onChange={updateInput} />
							</FormControl>
							<FormControl>
								<FormLabel
									htmlFor="password"
								>
									Password
									<PasswordPopover password={input.password} />
								</FormLabel>
								<Input
									id="password"
									placeholder="********"
									type="password"
									name="password"
									onChange={updateInput} />
							</FormControl>
						</Stack>
						<Stack spacing="2">
							<Button
								variant="primary2"
								type="submit"
								isLoading={loading}
								loadingText="Signing in..."
							>
								Sign in
							</Button>
							<Button
								variant="text"
								alignSelf="center"
								size="sm"
								onClick={openForgotPasswordModal}
							>
								Forgot password
							</Button>
						</Stack>
						{errorMsg && (
							<Alert
								status="error"
								variant="left-accent"
								borderColor="red.500"
								color="red.500"
								fontWeight="medium"
							>
								<AlertIcon />
								{errorMsg}
							</Alert>
						)}
					</Stack>
				</Stack>
				<ForgotPasswordModal
					isOpen={showForgotPassword}
					onClose={closeForgotPasswordModal}
				/>
			</Container>
			<Text
				fontSize="sm"
				color="gray.500"
				mt={1}
			>
				Made with <Box as={FcLike} display="inline" /> by Webify Services.
			</Text>
		</VStack>
	);
};

export default LoginForm;
