/**
 * @file - Vinyl.js
 * @description - Chainlink/Vinyl page for the Showroom section of the website.
 * @module Pages/Showroom/Chainlink/Vinyl
 * @author Webify Services, LTD.
 */
import GalleryHero from "Blueprints/GalleryHero";
import CTA from "../components/CTA";
import CTAStacked from "../components/CTAStacked";
import List from "../components/List";

import { Flex, Divider, Box, Heading } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";

import ChainlinkImage1 from "assets/TaylorFenceImg/Resources/ChainLink_BlackChainlinkFence.png";
import ChainlinkImage2 from "assets/Aluminum Black Fence.svg";
import SlatColors from "assets/TaylorFenceImg/Resources/Slats2.JPG";
import SlatsColor1 from "assets/TaylorFenceImg/Resources/WingedSlats.JPG";
import HedgeSlat from "assets/TaylorFenceImg/Resources/HedgeSlats.JPG";
import BlackVinyl from "assets/TaylorFenceImg/Resources/BlackVinyl.png";
import Vinyl0 from "assets/TaylorFenceImg/Resources/Vinyl.png";
import Vinyl2 from "assets/TaylorFenceImg/Resources/Vinyl2.jpg";
import Vinyl3 from "assets/TaylorFenceImg/Resources/Vinyl3.jpg";
import Vinyl4 from "assets/TaylorFenceImg/Resources/Vinyl4.png";


/**
 * @function Vinyl
 * 
 * @returns {React.ReactElement}
 * 
 * @utilizes
 * - GalleryHero (HOC/Blueprint)
 * - List (component)
 * - CTA (component)
 */
const Vinyl = () => {

	const chainlink_images = [
		ChainlinkImage1,
		ChainlinkImage2,
		Vinyl0,
		Vinyl2,
		Vinyl3,
		Vinyl4,
	];

	const listMesh = [
		{ title: "1-1/4″ Mesh", description: "Most commonly used for poolcode requirements." },
		{ title: "1-3/4″ Mesh", description: "Most commonly used for tennis court requirements." },
		{ title: "2″ Mesh", description: "Used for a wide array of applications." },
	];

	const wireGaugeData = [
		{ title: "11G (.120)", description: "Most economical." },
		{ title: "9G (.148)", description: "Standard for industrial and commercial use." },
	];

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
			h="100%"
			gap={4}
		>
			<GalleryHero
				images={chainlink_images}
				title={"Vinyl Chainlink Fencing"}
				description={"If the traditional silver appearance of chain-link isn't to your liking, consider our vinyl-coated chain-link options. They provide a visually appealing aesthetic while maintaining the strength and security that chain-link fences are known for."}
				imageHeight={{
					sm: "180px",
					md: "100%",
				}}
				imageWidth="100%"
				boxHeight={{
					sm: "140px",
					md: "500px",
				}}
				boxPaddingY="10"
				subHeadingSize="lg"
			/>
			<Flex
				flex="1"
				minW="100%"
				w="100%"
				px={{
					base: "4",
					md: "12",
				}}
				flexDirection={{ base: "column", md: "row" }} 
				alignItems="flex-start"
				justifyContent="space-between"
				gap="4"
			>
				<Box 
					flex="1" 
					px={{
						base: "4",
						md: "4",
					}}
				>					
					<List header="Mesh Sizes" items={listMesh} />
				</Box>
				<Box 
					flex="1" 
					px="4"
					mr={{
						base: "10",
					}}
				>	
					<List header="Wire Gauges" items={wireGaugeData} />
				</Box>
			</Flex>
			<Flex
				flex="1"
				w="100%"
				px={{
					base: "4",
					md: "6",
				}}
				flexDirection={{ base: "column", md: "row" }} 
				alignItems="center"
				justifyContent="space-between"
				gap="4"
			>
				<Heading
					fontSize={{ base: "md", md: "xl" }}
					mb={{
						base: "2",
						md: "4",
					}}
					mt={{ base: "2", md: "4" }}
					alignSelf="center"
					px={{
						base: "4",
						md: "24",
					}}
					
				>
					There are arrays of different sizes available upon request. Please inquire as needed.
				</Heading>
			</Flex>
			<Divider color="blackAlpha.800" w="calc(100% - 8rem)" my="4"/>
			<CTA 
				imageUrls={[BlackVinyl]} // Wrap BlackVinyl in an array
				listData={{
					header: "Colors Available",
					description: "Choose from a range of colors to match your style:",
					items: [
						{ title: "Black", icon: FaCircle, iconColor: "black" },
						{ title: "Brown", icon: FaCircle, iconColor: "#3B1402" },
						{ title: "Green", icon: FaCircle, iconColor: "#2F8A32" },
					]
				}}
			/>
			<Divider w="calc(100% - 8rem)" my="4"/>
			<Heading
				fontSize={{ base: "md", md: "4xl" }}
				mb={{
					base: "2",
					md: "0",
				}}
				mt={{ base: "2", md: "0" }}
				alignSelf="start"
				px={{
					base: "4",
					md: "24",
				}}
			>
                Add privacy and style to your chainlink fence.
			</Heading>
			<CTAStacked 
				title="Top Lock Slats"
				imageUrls={[SlatColors]}
				listData={{
					header: "",
					description: "",
					items: [
						// ... your items ...
					]
				}}
			/>
			<Divider w="calc(100% - 8rem)" my="4"/>

			<CTAStacked 
				title="Winged Slats"
				imageUrls={[SlatsColor1]}
				listData={{
					header: "",
					description: "",
					items: [
						// ... your items ...
					]
				}}
			/>
			<Divider w="calc(100% - 8rem)" my="4"/>
			<CTAStacked 
				title="Hedge/Evergreen Slats"
				imageUrls={[HedgeSlat]}
				listData={{
					header: "",
					items: [] // Provide an empty array if no items are available
				}}
			/>
		</Flex>
	);
}; 

export default Vinyl;
