/**
 * @file UpdatePartnersModal.js
 * 
 * @author TechBoyWonders
 */
import React, { useState, useEffect } from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalFooter,
	VStack,
	useToast,
	Box,
	Text,
	Checkbox,
} from "@chakra-ui/react";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import useUpdateCategoryPartners from "hooks/Category/mutations/useUpdateCategoryPartners";
import useGetAllPartners from "hooks/Partner/queries/useGetAllPartners";

/**
 * @function UpdatePartnersModal
 * 
 * @summary A modal that allows the user to update the partners of a category.
 * 
 * @param {Object} props - React props
 * @param {boolean} props.isOpen - Whether or not the modal is open
 * @param {function} props.onClose - Function to close the modal
 * 
 */
const UpdatePartnersModal = ({ isOpen, onClose, category, refetch }) => {
	const [partners, setPartners] = useState([]);
	const [selectedPartners, setSelectedPartners] = useState(category.child_partners);
	const [loading, setLoading] = useState(false);

	const { loading: partnersLoading, error: partnersError, data } = useGetAllPartners();

	const { updateCategoryPartners } = useUpdateCategoryPartners();
	const toast = useToast();

	useEffect(() => {
		if (data) {
			setPartners(data);
		}
	}, [data]);

	const handlePartnerSelect = (partner) => {
		if (selectedPartners.includes(partner._id)) {
			let temp_selected_partners = selectedPartners.filter((p) => p !== partner._id);
			setSelectedPartners(temp_selected_partners);
		} else {
			setSelectedPartners([...selectedPartners, partner._id]);
		}
	};

	const handleUpdatePartners = async () => {
		setLoading(true);

		const { data, error } = await updateCategoryPartners(category._id, selectedPartners);
		if(error){
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
		else if(data){
			toast({
				title: "Success",
				description: "Successfully updated partners.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			refetch();
			onClose();
			setLoading(false);
			return;
		}
		else{
			toast({
				title: "Error",
				description: "An unexpected error occurred. Please try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Partners</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack spacing={4} align="stretch">
						<LoadingErrorDataWrapper
							loading={partnersLoading}
							error={partnersError}
							data={data}
						>
							{partners.map((partner) => (
								<Box key={partner._id}>
									<Checkbox
										onChange={() => handlePartnerSelect(partner)}
										isChecked={selectedPartners.includes(partner._id)}
									>
										<Text fontSize="md">{partner.name}</Text>
									</Checkbox>
								</Box>
							))}
						</LoadingErrorDataWrapper>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleUpdatePartners}
						isLoading={loading}
					>
						Update Partners
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

};

export default UpdatePartnersModal;
