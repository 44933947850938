/**
 * @file useUpdateCategoryPartners.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_CATEGORY_PARTNERS } from "cache/Category/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateCategoryPartners
 *
 * @description
 * Custom hook that handles updating category partners.
 * This hook should be used whenever a category's partners need to be updated.
 *
 * @returns
 * - updateCategoryPartners: Function to execute the update category partners mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateCategoryPartners = () => {
	const [updateCategoryPartnersMutation, { loading, error }] = useMutation(UPDATE_CATEGORY_PARTNERS);

	/**
	 * Updates a category's partners.
	 *
	 * @param {String} _id - The ID of the category to update.
	 * @param {Array} partners - The new partners array for the category.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateCategoryPartners = async (_id, partners) => {
		try {
			const { data } = await updateCategoryPartnersMutation({
				variables: {
					_id,
					partners
				}
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data.updateCategoryPartners };
		} catch (error) {
			return { error };
		}
	};

	return { updateCategoryPartners, loading, error };
};

export default useUpdateCategoryPartners;
