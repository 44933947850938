/**
 * @file
 * This is the AboutController component.
 * It is used to manage routing for the About section of the website.
 *
 * @summary Routing component for the About section.
 */

import React from "react";
import { Route } from "react-router-dom";

import RoutesWrapper from "wrappers/RoutesWrapper";

import "./About.scss";
import AboutUsPage from "./About";
import Location from "./Location/Location";
import Help from "./Help/Help";
import Testimonials from "./Testimonials/Testimonials";

/**
 * AboutController component is a routing component.
 * It determines which component to render based on the current URL path.
 * There are four routes in total: AboutUsPage, Location, Help, and Testimonials.
 * The route paths are "/", "/location", "/help", and "/testimonials" respectively.
 *
 * @returns {HTMLElement} A Routes component with four Route components.
 */

const AboutController = () => {
    
	return(
		<div className="about-wrapper">
			<RoutesWrapper>
				<Route path="/" element={<AboutUsPage />} />
				<Route path="/location" element={<Location />} />
				<Route path="/help" element={<Help />} />
				<Route path="/testimonials" element={<Testimonials />} />
			</RoutesWrapper>
		</div>
	);

};

export default AboutController;