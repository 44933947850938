/**
 * @file useCancelInvoice.js
 */

import { useMutation } from "@apollo/client";
import { CANCEL_INVOICE } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useCancelInvoice
 *
 * @description
 * Custom hook to handle the cancellation of an existing invoice.
 * The hook will return a Boolean to indicate the success or failure of the operation.
 *
 * @returns
 * - cancelInvoice: Method to execute the cancellation of the invoice
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCancelInvoice = () => {

	// Initialize Apollo useMutation for the CANCEL_INVOICE mutation
	const [cancelInvoiceMutation, { loading, error }] = useMutation(CANCEL_INVOICE);

	/**
	 * Method to execute the cancellation of the invoice.
	 *
	 * @param {String} id - The ID of the invoice to cancel.
	 *
	 * @returns {Object} - An object containing the mutation result data (Boolean) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const cancelInvoice = async (id) => {

		try {
			const { data } = await cancelInvoiceMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			return { data: data.cancelInvoice };
		} catch (error) {
			return { error };
		}
	};

	return { cancelInvoice, loading, error };
};

export default useCancelInvoice;
