import React, { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Button
} from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { motion } from "framer-motion";

const MotionModal = motion(Modal);

const ModalImageCarousel = ({ images }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		onOpen();
	}, [onOpen]);

	return (
		<>
			<Button onClick={onOpen}>Open Carousel</Button>

			<MotionModal 
				isOpen={isOpen} 
				size="3xl"
				maxW={{
					base: "100%",
					md: "100%",
				}} 
				maxH={{
					base: "100%",
					md: "100%",
				}}
				onClose={onClose}
				motionPreset='slideInBottom'
				closeOnOverlayClick={true}
				closeOnEsc={true}

			>
				<ModalOverlay />
				<ModalContent
					mx={2}
					my={20}
					rounded="lg"
					// w="100%"
				>
					<ModalHeader
						textAlign="center"
					>
                Onsite Wood Showroom
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Carousel>
							{images.map((image, index) => (
								<div key={index}>
									<img src={image.src} alt={image.alt} />
								</div>
							))}
						</Carousel>
					</ModalBody>
				</ModalContent>
			</MotionModal>
		</>
	);
};

export default ModalImageCarousel;
