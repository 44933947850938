/**
 * @file
 * This file defines the ScrollContext which is used to manage scrolling behaviors throughout the application.
 *
 * @summary ScrollContext definition.
 */

import React, { createContext, useCallback, useRef } from "react";

/**
 * The context is initialized here and the context object is exported so it can be used by other components.
 */
export const ScrollContext = createContext();

/**
 * This context provider is used to provide the scroll functions to components further down the tree.
 *
 * @param {object} props The properties passed to the context provider.
 * @param {React.ReactNode} props.children The children components to be rendered.
 */
export const ScrollProvider = ({ children }) => {

	// Using React ref to create a mutable object whose .current property can be changed
	const topOfPage = useRef(null);

	// useCallback will return a memoized version of the callback that only changes if one of the dependencies has changed
	const scrollToTop = useCallback(() => {
		topOfPage.current.scrollIntoView({ 
			behavior: "smooth"
		});
	}, [topOfPage]);

	/**
     * The provider component that wraps the children and provides scroll functionality.
     */
	return (
		<ScrollContext.Provider 
			value={{ topOfPage, scrollToTop }}
		>
			<div ref={topOfPage} />
			{children}
		</ScrollContext.Provider>
	);
};
