/**
 * @file Defines the AdminContext provider and exports the AdminContext.
 */
import React, { createContext, useState, useEffect } from "react";

import useGetAdmin from "hooks/Admin/queries/useGetAdmin";

/**
 * @component AdminContext
 * @category Contexts
 * @subcategory AdminContext
 * @returns {Object} - the AdminContext object
 * @description - the AdminContext object that fetches the admin data from the server and provides it to the children components, if the admin is logged in
 * @property admin: the admin object. If the admin is not logged in, the admin object is null
 * @property adminRefetch: the adminRefetch function. This function refetches the admin data from the server
 * @property loading: the loading boolean. This boolean is true if the admin data is being fetched from the server
 * @example
 * import { AdminContext } from './AdminContext';
 * 
 * const { admin, adminRefetch, loading } = useContext(AdminContext);
 * 
 * if(loading) return <div>Loading Admin...</div>
 * 
 * if(admin){
 *  return <div>{admin.adminname}</div>
 * }
 * 
 * return (
 *     <div>
 *          Not logged in
 *          <button onClick={adminRefetch}>Refetch</button>
 *     </div>
 * )
 * 
 */
export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {

	const [admin, setAdmin] = useState(null);
    
	const [loading, setLoading] = useState(true);

	const { data: adminData, refetch: adminRefetch, loading: adminLoading } = useGetAdmin();

	useEffect(() => {
		if(adminData){
			if(adminData._id !== null){
				setAdmin(adminData);
			}
			else{
				setAdmin(null);
			}
		}
		else{
			setAdmin(null);
		}
	}, [adminData]);

	useEffect(() => {
		if(adminLoading === false){
			setLoading(false);
		}
	}, [adminLoading]);



	return (
		<AdminContext.Provider
			value={{ admin, adminRefetch, loading }}
		>
			{children}
		</AdminContext.Provider>
	);
};
