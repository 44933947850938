/**
 * @file UpdateCategory.js is a file for the UpdateCategory component.
 */
import React, { useState, useEffect } from "react";

// Chakra UI imports 
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Textarea,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	Grid,
	Box,
	Image
} from "@chakra-ui/react";

import useUpdateCategory from "hooks/Category/mutations/useUpdateCategory";

import useUploadImage from "hooks/useUploadImage";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

/**
 * @component UpdateCategory
 * 
 * @description 
 * - The UpdateCategory component is a modal that allows the user to update a category.
 * 
 * @param {*} props
 */
const UpdateCategory = ({ isOpen, onClose, category, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [input, setInput] = useState({
		name: category.name,
		description: category.description,
		child_descriptor: category.child_descriptor,
	});

	const [imageURLs, setImageURLs] = useState(category.images);
	const [imageFiles, setImageFiles] = useState([]);

	useEffect(() => {
		setImageURLs(category.images);
	}, [category]);

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const handleDeleteImage = (imageURL) => {
		setImageURLs(imageURLs.filter((url) => url !== imageURL));
	};

	const { update } = useUpdateCategory();

	const uploadImage = useUploadImage();

	/**
	 * @function handleSubmit
	 * 
	 * @param {*} e 
	 */
	const handleSubmit = async (e) => {
		e.preventDefault();

		setErrorMsg(null);

		if(!validateInput()) return;

		setLoading(true);

		let updateCategoryInput = {
			_id: category._id,
		};

		let image_urls = new Array();
		for(let i = 0; i < imageFiles.length; i++){
			try{
				const currentImage = imageFiles[i];
				let url = await uploadImage(currentImage);
				image_urls.push(url);
			} catch(err){
				setErrorMsg("Failed to upload image.");
				setLoading(false);
				return;
			}
		}
		updateCategoryInput.images = [...imageURLs, ...image_urls];

		if (input.name !== category.name) {
			updateCategoryInput.name = input.name;
		}
		if (input.child_descriptor !== category.child_descriptor) {
			updateCategoryInput.child_descriptor = input.child_descriptor;
		}

		if(input.description !== category.description){
			if(input.description === "") input.description = "REMOVE";
			updateCategoryInput.description = input.description;
		}

		let response = await update(updateCategoryInput);
		if (response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data){
			setLoading(false);
			refetch();
			onClose();
			return;
		}
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if (!input.name) {
			errors.name = "Name is required.";
		}
		if (!input.child_descriptor) {
			errors.child_descriptor = "Child Description is required.";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Category</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<form onSubmit={handleSubmit}>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input
								name="name"
								type="text"
								placeholder="Name"
								value={input.name}
								onChange={handleUpdateInput}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							mt={4}
							id="description"
						>
							<FormLabel>Description</FormLabel>
							<Textarea
								name="description"
								placeholder="Description"
								value={input.description}
								onChange={handleUpdateInput}
							/>
						</FormControl>
						<FormControl 
							id="child_descriptor" 
							isRequired
							isInvalid={formErrors.description}
							mt={4}
						>
							<FormLabel>Child Description</FormLabel>
							<Input
								name="child_descriptor"
								type="text"
								placeholder="Child Description"
								value={input.child_descriptor}
								onChange={handleUpdateInput}
							/>
							<FormErrorMessage>{formErrors.description}</FormErrorMessage>
						</FormControl>
						<FormControl 
							my={4}
						>
							<FormLabel>Images</FormLabel>
							<ImagesDropzone 
								imageFiles={imageFiles}
								setImageFiles={setImageFiles}
								allowMultiple={true}
							/>
						</FormControl>
						<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
							{imageURLs.map((imageURL, i) => (
								<Box key={i} position="relative">
									<Image src={imageURL} alt={`${category._id}_image_${i}`} objectFit="cover" borderRadius="md"/>
									<Button
										position="absolute"
										right="5px"
										top="5px"
										size="sm"
										variant="delete"
										onClick={() => handleDeleteImage(imageURL)}
									>
										Delete
									</Button>
								</Box>
							))}
						</Grid>
						<Button
							mt={4}
							variant="primary"
							isLoading={loading}
							type="submit"
						>
							Update Category
						</Button>
					</form>
				</ModalBody>
				<ModalFooter>
					{errorMsg && (
						<Alert status="error" w="100%">
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

};

export default UpdateCategory;