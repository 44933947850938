/**
 * @file useDeleteCategory.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_CATEGORY } from "cache/Category/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteCategory
 *
 * @description
 * Custom hook that handles deleting a category.
 *
 * @returns
 * - delete function
 */
const useDeleteCategory = () => {

	const [deleteCategoryMutation, { loading, error }] = useMutation(DELETE_CATEGORY);

	const deleteCategory = async (_id) => {

		try {
			const { data } = await deleteCategoryMutation({ 
				variables: { _id: _id }
			});
			if (error) {
				throw new Error(error);
			}
			let deleteCategorySuccess = data.deleteCategory;
			if (!deleteCategorySuccess) {
				throw new Error("Deletion failed");
			} else {
				return { success: true };
			}
		} catch (error) {
			return { error };
		}

	};

	return { deleteCategory, loading, error };

};

export default useDeleteCategory;
