import {
	Box,
	Divider,
	Heading,
	SimpleGrid,
	Stack,
	useColorModeValue,
} from "@chakra-ui/react";
import { ReviewItem } from "./Reviews/ReviewItem";

import "./Reviews.scss";

import useGetAllReviews from "hooks/Review/queries/useGetAllReviews";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

const Reviews = () => {
	const { loading, error, data: reviews } = useGetAllReviews();

	return (
		<Box
			maxW="7xl"
			mx={{
				base: "4",
				md: "8",
			}}
			px={{
				base: "4",
				md: "8",
				lg: "12",
			}}
			py={{
				base: "6",
				md: "8",
				lg: "12",
			}}
		>
			<Stack 
				spacing="12"
				direction={{
					base: "column",
					md: "block",
				}}
        
			>
				<Stack
					direction={{
						base: "column",
						md: "block",
					}}
					spacing="4"
					alignItems={{
						base: "start",
						md: "block",
					}}
				>
					<Stack
						spacing="2"
						display={{
							base: "none",
							md: "block",
						}}
					>
						<Heading
							fontSize={{
								base: "1.25rem",
								md: "1.5rem",
							}}
							fontWeight="semibold"
							color={useColorModeValue("black", "white")}
							px={{
								base: "4",
								md: "4",
							}}
						>
              				Customer reviews
						</Heading>
					</Stack>
				</Stack>
				<Divider
					display={{
						base: "none",
						md: "flex",
					}}
				/>
				<LoadingErrorDataWrapper
					loading={loading}
					error={error?.message}
					data={reviews}
				>
					<SimpleGrid
						columns={{
							base: 1,
							md: 2,
						}}
						columnGap="12"
						rowGap={{
							base: "10",
							md: "12",
						}}
					>
						{reviews?.map((review) => (
							<ReviewItem key={review.id} review={review} />
						))}
					</SimpleGrid>
				</LoadingErrorDataWrapper>
			</Stack>
		</Box>
	);
};

export default Reviews;
