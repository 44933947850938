/**
 * @file useGetGalleryImages.js
 */

import { useQuery } from "@apollo/client";
import { GET_GALLERY_IMAGES } from "cache/GalleryImage/queries"; // Update the path as per your project structure

/**
 * @hook useGetGalleryImages
 *
 * @description
 * Custom hook that handles the functionality of getting all gallery images.
 * This hook is used in components where gallery images need to be fetched and displayed.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query (array of gallery images)
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetGalleryImages = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_GALLERY_IMAGES);

	// Safely extract the gallery images data
	let galleryImagesData = data?.getGalleryImages;

	return { loading, error, data: galleryImagesData, refetch, networkStatus };
};

export default useGetGalleryImages;
