/**
 * @file useReorderGalleryImages.js
 */

import { useMutation } from "@apollo/client";
import { REORDER_GALLERY_IMAGES } from "cache/GalleryImage/mutations"; // Update the path as per your project structure

/**
 * @hook useReorderGalleryImages
 * 
 * @description
 * Custom hook that handles the functionality of reordering gallery images.
 * It can be utilized whenever there's a need to reorder the images 
 * in the gallery, ensuring that the latest ordering is reflected in the 
 * backend.
 *
 * @returns
 * - reorderGalleryImages: Function to execute the reorder gallery images mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useReorderGalleryImages = () => {

	const [reorderMutation, { loading, error }] = useMutation(REORDER_GALLERY_IMAGES);

	/**
	 * Executes the reorder mutation.
	 *
	 * @param {Array} input - An array of objects describing how to reorder the gallery images.
	 * Each object should have the required fields needed for the ReorderInput type.
	 *
	 * @returns {Object} - An object containing the mutation result (true/false) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const reorderGalleryImages = async (input) => {
		try {
			const { data } = await reorderMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}

			return { data: data.reorderGalleryImages };
		} catch (error) {
			return { error };
		}
	};

	return { reorderGalleryImages, loading, error };
};

export default useReorderGalleryImages;
