import React from "react";
import ProductRow from "./ProductRow";

import { TableContainer, HeaderContainer } from "../../components/TableContainer";
import { TextCell } from "../../components/TableCell";


const ProductTable = (props) => {

	const products = props.products;

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell>Image</TextCell>
				<TextCell>
					Product Name
				</TextCell>
				<TextCell>
					Category
				</TextCell>
				<TextCell>
					Partner
				</TextCell>
				<TextCell>Actions</TextCell>
			</HeaderContainer>
			{products &&
				products?.map((product) => (
					<ProductRow key={product._id} product={product} refetch={props.refetch} />
				))
			}
		</TableContainer>
	);
};

export default ProductTable;
