/**
 * @file useUpdateFeaturedDeal.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_FEATURED_DEAL } from "cache/FeaturedDeal/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateFeaturedDeal
 *
 * @description
 * Custom hook that handles the functionality to update a featured deal.
 * This hook can be used wherever the application needs to update an existing featured deal.
 *
 * @returns
 * - updateFeaturedDeal: Function to execute the update featured deal mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateFeaturedDeal = () => {

	const [updateFeaturedDealMutation, { loading, error }] = useMutation(UPDATE_FEATURED_DEAL);

	/**
	 * Custom mutation to update a featured deal.
	 *
	 * @param {Object} input - The input object for updating a featured deal.
	 * @property {String} input._id - The id of the featured deal.
	 * @property {String} input.name - The name of the featured deal.
	 * @property {String} input.description - The description of the featured deal.
	 * @property {String} input.expires - The expiration date of the featured deal.
	 * @returns {Object} - An object containing a boolean result or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateFeaturedDeal = async (input) => {
		try {
			const { data } = await updateFeaturedDealMutation({
				variables: {
					input // Pass the entire input object as a variable
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return the boolean result of the update operation
			return { success: data.updateFeaturedDeal };
		} catch (error) {
			return { error };
		}
	};

	return { updateFeaturedDeal, loading, error };
};

export default useUpdateFeaturedDeal;
