/**
 * @file useUpdateAdminInfo.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_ADMIN_INFO } from "cache/Admin/mutations";

/**
 * @hook useUpdateAdminInfo
 *
 * @description
 * Custom hook that handles updating an admin's information.
 *
 * @returns
 * - update function
 */
const useUpdateAdminInfo = () => {

	const [updateAdminInfoMutation, { loading, error }] = useMutation(UPDATE_ADMIN_INFO);

	const update = async (adminId, updateAdminInfoInput) => {

		try {
			const { data } = await updateAdminInfoMutation({ 
				variables: { adminId: adminId, input: updateAdminInfoInput }
			});
			if(error){
				throw new Error(error);
			}
			let updateAdminData = data.updateAdminInfo;
			if(updateAdminData !== true){
				throw new Error("Update failed");
			}
			else{
				return { success: true };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { update, loading, error };

};

export default useUpdateAdminInfo;
