/**
 * @File - Categories.js
 * 
 * @Description 
 * - Categories page for Taylor Fence - Admin Panel 
 * - This page will display all the categories in the database
 * - This page will allow the user to add a new category
 * - This page will allow the user to edit a category
 * - This page will allow the user to delete a category
 * - This page will allow the user to search for a category
 * 
 * @CategoryOverview
 * - Main Categories: 
 *    - Categories
 */

import React, { useState } from "react";
import {
	Flex,
	Button,
} from "@chakra-ui/react";
import { MdAddCircleOutline } from "react-icons/md";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
import CreateCategoryModal from "./Modals/CreateCategoryModal";
import CategoryTable from "./CategoryTable";
import useGetTopLevelCategories from "hooks/Category/queries/useGetTopLevelCategories";


const Categories = () => {

	const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);

	const { loading, error: errorMsg, data: topLevelCategories, refetch: topLevelCategoriesRefetch } = useGetTopLevelCategories();

	const openCreateCategoryModal = () => {
		setShowCreateCategoryModal(true);
	};
	const closeCreateCategoryModal = () => {
		setShowCreateCategoryModal(false);
	};

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"	
		>
			<LoadingErrorDataWrapper loading={loading} error={errorMsg?.message} data={topLevelCategories}>
				{topLevelCategories && topLevelCategories.length > 0 && (
					<CategoryTable categories={topLevelCategories} refetch={topLevelCategoriesRefetch} />
				)}
				<Flex
					flexDir={{
						base: "column",
						sm: "row",
					}}
					justify="flex-end"
					align="center"
					w="100%"
					px="5%"
					mb={4}
				>
					<Button
						leftIcon={<MdAddCircleOutline />}
						variant="primary"
						onClick={openCreateCategoryModal}
						w={{
							base: "100%",
							md: "25%",
							lg: "25%",
							xl: "25%",
						}}
						fontSize={{
							base: "sm",
							lg: "md",
						}}
						textAlign="center"
					>
							Add Category
					</Button>
				</Flex>
			</LoadingErrorDataWrapper>
		  <CreateCategoryModal isOpen={showCreateCategoryModal} onClose={closeCreateCategoryModal} refetch={topLevelCategoriesRefetch} />
		</Flex>
	  );
};
	
export default Categories;