/**
 * @file useAdminLogout.js
 */

import { useMutation } from "@apollo/client";
import { LOGOUT } from "cache/Admin/mutations"; // Make sure to update the path to the correct location of LOGOUT mutation

/**
 * @hook useAdminLogout
 *
 * @description
 * Custom hook that handles admin logout functionality.
 *
 * @returns
 * - logout function
 */
const useAdminLogout = () => {

	const [logoutMutation, { loading, error }] = useMutation(LOGOUT);

	const logout = async () => {

		try {
			const { data } = await logoutMutation();
			if(error){
				throw new Error(error);
			}
			let logoutData = data.logout;
			if(logoutData !== true){
				throw new Error("Logout failed");
			}
			else{
				return { success: true };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { logout, loading, error };

};

export default useAdminLogout;
