/**
 * @file ChainlinkController.js
 */

import React from "react";

import { Route } from "react-router-dom";
import RoutesWrapper from "wrappers/RoutesWrapper";
import Chainlink from "./Chainlink";

import Galvanized from "./pages/Galvanized";

import Vinyl from "./pages/Vinyl";

const ChainlinkController = () => {
    
	return(
		<RoutesWrapper>
			<Route path="/galvanized" element={<Galvanized />} />
			<Route path="/vinyl" element={<Vinyl />} />
			<Route path="/" element={<Chainlink />} />
		</RoutesWrapper>
	);
};

export default ChainlinkController;
