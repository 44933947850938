/**
 * @file updateAdminModal.js
 * 
 * @fileoverview This file contains all the functions that are used to update existing admins.
 * 
 * @description 
 * - This file contains the functions to update an existing admin. 
 * - ChakraUI Modal is used to create a new admin.
 * - The modal is opened when the user clicks on the edit button in the admin table.
 * - The modal is closed when the user clicks on the close button.
 * - The modal is closed when the user clicks on the cancel button.
 * - The modal is closed when the user clicks on the save button.
 * - The modal is closed when the user clicks on the background.
 * - The Update button within the modal will update the associated admin.
 * - The Cancel button within the modal will close the modal.
 * 
 */

import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormErrorMessage,
	Input,
	FormLabel,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	CloseButton,
} from "@chakra-ui/react";

import {
	isValidEmail
} from "utils/utils";

import useUpdateAdminInfo from "hooks/Admin/mutations/useUpdateAdminInfo";

/**
 * @brief Function that returns the Update Admin Modal
 * 
 * @returns The Update Admin Modal
 */

const UpdateAdminModal = ({ isOpen, onClose, selectedAdmin, adminsRefetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [admin, setAdmin] = useState({
		firstName: selectedAdmin.firstName,
		lastName: selectedAdmin.lastName,
		email: selectedAdmin.email
	});


	useEffect(() => {
		setLoading(false);
		setErrorMsg(null);
		setAdmin({
			firstName: selectedAdmin.firstName,
			lastName: selectedAdmin.lastName,
			email: selectedAdmin.email
		});
	}, []);

	useEffect(() => {
		setAdmin({
			firstName: selectedAdmin.firstName,
			lastName: selectedAdmin.lastName,
			email: selectedAdmin.email
		});
	}, [selectedAdmin]);

	const [formErrors, setFormErrors] = useState({});

	const handleChange = (event) => {
		setAdmin({
			...admin,
			[event.target.name]: event.target.value,
		});
	};

	const validateInput = () => {
		let errors = {};
		if(!admin.firstName){
			errors.firstName = "First name is required";
		}
		if(!admin.lastName){
			errors.lastName = "Last name is required";
		}
		if(!admin.email){
			errors.email = "Email is required";
		}
		if(admin.email && !isValidEmail(admin.email)){
			errors.email = "Invalid email";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};
	
	const { update } = useUpdateAdminInfo();

	const handleUpdate = async (event) => {
		event.preventDefault();
		setErrorMsg(null);
		setLoading(true);

		if(!validateInput()){
			setLoading(false);
			return;
		}

		if(!selectedAdmin){
			setErrorMsg("No admin selected");
			setLoading(false);
			return;
		}

		let updateAdminInfoInput = {};
		if(admin.firstName !== selectedAdmin.firstName){
			updateAdminInfoInput.firstName = admin.firstName;
		}
		if(admin.lastName !== selectedAdmin.lastName){
			updateAdminInfoInput.lastName = admin.lastName;
		}
		if(admin.email !== selectedAdmin.email){
			updateAdminInfoInput.email = admin.email;
		}

		let response = await update(selectedAdmin._id, updateAdminInfoInput);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success) {
			setLoading(false);
			await adminsRefetch();
			onClose();	
			return;
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Admin</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl isInvalid={formErrors.firstName}>
						<FormLabel>First Name</FormLabel>
						<Input
							name="firstName"
							value={admin.firstName}
							onChange={handleChange}
						/>
						<FormErrorMessage>{formErrors.firstName}</FormErrorMessage> 
					</FormControl>
					<FormControl isInvalid={formErrors.lastName}>
						<FormLabel>Last Name</FormLabel>
						<Input
							name="lastName"
							value={admin.lastName}
							onChange={handleChange}
						/>
						<FormErrorMessage>{formErrors.lastName}</FormErrorMessage> 
					</FormControl>
					<FormControl isInvalid={formErrors.email}>
						<FormLabel>Email</FormLabel>
						<Input
							name="email"
							value={admin.email}
							onChange={handleChange} // Step 4
						/>
						<FormErrorMessage>{formErrors.email}</FormErrorMessage> 
					</FormControl>
					{
						errorMsg && (
							<Alert status="error" mt={4}>
								<AlertIcon />
								<AlertTitle mr={2}>Error!</AlertTitle>
								<AlertDescription>{errorMsg}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setErrorMsg(null)}
								/>
							</Alert>
						)
					}
				</ModalBody>
				<ModalFooter>
					<Button 
						variant="primary" 
						mr={3} 
						onClick={handleUpdate}
						isLoading={loading}
						loadingText={"Updating"}
						isDisabled={Object.keys(formErrors).length !== 0}
					>
                        Update
					</Button>
					<Button variant="ghost" onClick={onClose}>
                        Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default UpdateAdminModal;