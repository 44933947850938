/**
 * @file UpdateCustomer.js is used to update customer information
 */
import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormErrorMessage,
	Input,
	FormLabel,
	Button,
	Flex,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
} from "@chakra-ui/react";

import useUpdateCustomer from "hooks/Customer/mutations/useUpdateCustomer";


const UpdateCustomer = ({ isOpen, onClose, selectedCustomer, customersRefetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);


	const [customer, setCustomer] = useState({
		name: selectedCustomer.name,
		email: selectedCustomer.email,
		phone: selectedCustomer.phone,
		address: {
			line1: selectedCustomer.address?.line1,
			line2: selectedCustomer.address?.line2,
			city: selectedCustomer.address?.city,
			state: selectedCustomer.address?.state,
			zip: selectedCustomer.address?.zip,
			country: selectedCustomer.address?.country,
		},
	});

	const { updateCustomer } = useUpdateCustomer();

	useEffect(() => {
		setLoading(false);
		setErrorMsg(null);
		setCustomer({
			name: selectedCustomer.name,
			email: selectedCustomer.email,
			phone: selectedCustomer.phone,
			address: {
				line1: selectedCustomer.address?.line1,
				line2: selectedCustomer.address?.line2,
				city: selectedCustomer.address?.city,
				state: selectedCustomer.address?.state,
				zip: selectedCustomer.address?.zip,
				country: selectedCustomer.address?.country,
			},
		});
	}, []);

	useEffect(() => {
		setCustomer({
			name: selectedCustomer.name,
			email: selectedCustomer.email,
			phone: selectedCustomer.phone,
			address: {
				line1: selectedCustomer.address?.line1,
				line2: selectedCustomer.address?.line2,
				city: selectedCustomer.address?.city,
				state: selectedCustomer.address?.state,
				zip: selectedCustomer.address?.zip,
				country: selectedCustomer.address?.country,
			},
		});
	}, [selectedCustomer]);

	const handleUpdateInput = (e) => {
		setCustomer({
			...customer,
			[e.target.name]: e.target.value,
		});
	};

	const handleUpdateAddress = (e) => {
		setCustomer({
			...customer,
			address: {
				...customer.address,
				[e.target.name]: e.target.value,
			},
		});
	};

	const handleUpdateCustomer = async () => {
		setLoading(true);

		if(!validateInput()) return;
		
		setErrorMsg(null);
		let updatedCustomer = {
			_id: selectedCustomer._id,
		};
		if (customer.name !== selectedCustomer.name) updatedCustomer.name = customer.name;
		if (customer.email !== selectedCustomer.email) updatedCustomer.email = customer.email;
		if (customer.phone !== selectedCustomer.phone) updatedCustomer.phone = customer.phone;
		if (customer.address.line1 !== selectedCustomer.address?.line1) updatedCustomer.address = customer.address;
		if (customer.address.line2 !== selectedCustomer.address?.line2) updatedCustomer.address = customer.address;
		if (customer.address.city !== selectedCustomer.address?.city) updatedCustomer.address = customer.address;
		if (customer.address.state !== selectedCustomer.address?.state) updatedCustomer.address = customer.address;
		if (customer.address.zip !== selectedCustomer.address?.zip) updatedCustomer.address = customer.address;
		if (customer.address.country !== selectedCustomer.address?.country) updatedCustomer.address = customer.address;

		const { data, error } = await updateCustomer(updatedCustomer);
		if (error) {
			setErrorMsg(error.message);
			setLoading(false);
			return;
		}
		if (data) {
			setLoading(false);
			await customersRefetch();
			onClose();
			return;
		}
		validateInput();
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if (!customer.name) {
			errors.name = "Name/Company is required";
		}
		if (!customer.email) {
			errors.email = "Email is required";
		}
		if (customer.email && !(customer.email)) {
			errors.email = "Invalid email";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			isCentered
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Customer</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{errorMsg && (
						<Alert status="error" mb={4}>
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
							<CloseButton
								position="absolute"
								right="8px"
								top="8px"
								onClick={() => setErrorMsg(null)}
							/>
						</Alert>
					)}
					<Flex
						gap="4"
					>
						<FormControl 
							mb="4"
							isInvalid={formErrors.name}
						>
							<FormLabel>Name/Company</FormLabel>
							<Input
								name="name"
								type="text"
								value={customer.name}
								onChange={handleUpdateInput}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex
						gap="4"
					>
						<FormControl 
							flex="2" 
							mb="4"
							isInvalid={formErrors.email}
						>
							<FormLabel>Email</FormLabel>
							<Input
								name="email"
								type="email"
								value={customer.email}
								onChange={handleUpdateInput}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.email}</FormErrorMessage>
						</FormControl>
						<FormControl 
							flex="1" 
							mb="4"
							isInvalid={formErrors.phone}
						>
							<FormLabel>Phone Number</FormLabel>
							<Input
								name="phone"
								type="text"
								value={customer.phone}
								onChange={handleUpdateInput}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.phone}</FormErrorMessage>
						</FormControl>
					</Flex>
					<FormControl 
						mb="4"
						isInvalid={formErrors.line1}
					>
						<FormLabel>Address Line 1</FormLabel>
						<Input
							name="line1"
							type="text"
							value={customer.address.line1}
							onChange={handleUpdateAddress}
							borderWidth="1px"
							borderColor="gray.300"
							boxShadow="sm"
						/>
						<FormErrorMessage>{formErrors.line1}</FormErrorMessage>
					</FormControl>
					<Flex
						gap="4"
					>
						<FormControl 
							flex="2" 
							mb="4"
							isInvalid={formErrors.city}
						>
							<FormLabel>City</FormLabel>
							<Input
								name="city"
								type="text"
								value={customer.address.city}
								onChange={handleUpdateAddress}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.city}</FormErrorMessage>
						</FormControl>
						<FormControl 
							flex="1" 
							mb="4"
							isInvalid={formErrors.state}
						>
							<FormLabel>State</FormLabel>
							<Input
								name="state"
								type="text"
								value={customer.address.state}
								onChange={handleUpdateAddress}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.state}</FormErrorMessage>
						</FormControl>
						<FormControl 
							mb="4"
							flex="2"
							isInvalid={formErrors.zip}
						>
							<FormLabel>Zip Code</FormLabel>
							<Input
								name="zip"
								type="text"
								value={customer.address.zip}
								onChange={handleUpdateAddress}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.zip}</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex
						gap="4"
					>
						<FormControl 
							mb="4"
							flex="2"
							isInvalid={formErrors.country}
						>
							<FormLabel>Country</FormLabel>
							<Input
								name="country"
								type="text"
								value={customer.address.country}
								onChange={handleUpdateAddress}
								borderWidth="1px"
								borderColor="gray.300"
								boxShadow="sm"
							/>
							<FormErrorMessage>{formErrors.country}</FormErrorMessage>
						</FormControl>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleUpdateCustomer}
						isLoading={loading}
						loadingText="Updating Customer"
						isDisabled={Object.keys(formErrors).length !== 0}
					>
						Update Customer
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
				
};

export default UpdateCustomer;