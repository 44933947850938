/**
 * @file useDeleteCustomer.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_CUSTOMER } from "cache/Customer/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteCustomer
 *
 * @description
 * Custom hook that handles the functionality to delete a customer.
 * This hook can be used wherever the application needs to delete a customer.
 *
 * @returns
 * - deleteCustomer: Function to execute the delete customer mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeleteCustomer = () => {

	const [deleteCustomerMutation, { loading, error }] = useMutation(DELETE_CUSTOMER);

	/**
	 * Custom mutation to delete a customer.
	 *
	 * @param {String} id - The id of the customer to be deleted.
	 * @returns {Object} - An object containing a boolean value indicating success or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteCustomer = async (id) => {
		try {
			const { data } = await deleteCustomerMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return the boolean indicating success
			return { success: data.deleteCustomer };
		} catch (error) {
			return { error };
		}
	};

	return { deleteCustomer, loading, error };
};

export default useDeleteCustomer;
