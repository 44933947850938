/**
 * @file index.js is the root file for the frontend.
 * 
 * @author Webify Services, LTD.
 */
import React from "react";

import ReactDOM from "react-dom/client";

import "./index.scss";

import { ChakraProvider } from "@chakra-ui/react";

import { TF_THEME } from "./chakraStyles";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

import { ApolloProvider, ApolloClient, InMemoryCache} from "@apollo/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ErrorFallback from "./Blueprints/ErrorFallback";

const cache = new InMemoryCache({
	dataIdFromObject: object => `${object.__typename}:${object._id}`,
	typePolicies: {
		Invoice: {
			fields: {
				refund: {
					merge(existing, incoming) {
						if(existing) return [...existing, ...incoming];
					}
				}
			}
		},
		InvoiceLink: {
			fields: {
				url: {
					merge(existing, incoming) {
						if(existing) return [...existing, ...incoming];
					}
				}
			}
		},
		PaginatedProducts: {
			fields: {
				items: {
					merge(existing, incoming) {
						if(existing) return [...existing, ...incoming];
					}
				}
			}
		},
		ProductCategory: {
			fields: {
				breadcrumbs: {
					merge(existing, incoming) {
						if(existing) return [...existing, ...incoming];
					}
				},
			},
		},
		Category: {
			fields: {
				child_partners: {
					merge(existing, incoming) {
						if(existing) return [...existing, ...incoming];
					}
				},
				
			}
		}
	},
});
// eslint-disable-next-line no-undef
const BACKEND_LOCATION = process.env.REACT_APP_BACKEND_LOCATION; //Setup in .env and HEROKU config vars
const client = new ApolloClient({
	uri: BACKEND_LOCATION,
	credentials: "include",
	cache: cache,
});

const Root = () => {
	return (
		<App />
	);
};

const router = createBrowserRouter([
	{ 
		path: "*",
		element: <Root/>,
		errorElement: <ErrorFallback />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<ChakraProvider theme={TF_THEME}>
			<ApolloProvider client={client}>
				<RouterProvider 
					router={router}
					fallbackElement={<div>Loading...</div>}  
				/>
			</ApolloProvider>
		</ChakraProvider>
	</React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

