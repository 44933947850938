/**
 * @file mutations.js is a file for all Review mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_REVIEW
 * 
 * @summary
 * - This mutation will create a new review.
 * - This mutation will return a boolean, true if the review was created, false if not.
 * 	
 * @param {Object} input - The input object containing the review data.
 */
export const CREATE_REVIEW = gql`
	mutation createReview($input: CreateReviewInput!) {
		createReview(input: $input)
	}
`;

/**
 * @const {Object} UPDATE_REVIEW
 * 
 * @summary
 * - This mutation will update a review.
 * - This mutation will return a boolean, true if the review was updated, false if not.
 * 
 * @param {String} _id - The ID of the review to update
 * @param {Object} input - The input object containing the review data.
 */
export const UPDATE_REVIEW = gql`
	mutation updateReview($_id: ID!, $input: UpdateReviewInput!) {
		updateReview(_id: $_id, input: $input)
	}
`;

/**
 * @const {Object} REORDER_REVIEWS
 *
 * @summary
 * - This mutation will reorder reviews.
 * - This mutation will return a boolean, true if the reviews were reordered, false if not.
 */
export const REORDER_REVIEWS = gql`
	mutation reorderReviews($input: [ReorderInput!]!) {
		reorderReviews(input: $input)
	}
`;

/**
 * @const {Object} DELETE_REVIEW
 * 
 * @summary
 * - This mutation will delete a review.
 * - This mutation will return a boolean, true if the review was deleted, false if not.
 * 
 * @param {String} _id - The ID of the review to delete
 */
export const DELETE_REVIEW = gql`
	mutation deleteReview($_id: ID!) {
		deleteReview(_id: $_id)
	}
`;
