/**
 * @file useResetAdminPassword.js
 */

import { useMutation } from "@apollo/client";
import { RESET_ADMIN_PASSWORD } from "cache/Admin/mutations"; // Update the path as per your project structure

/**
 * @hook useResetAdminPassword
 *
 * @description
 * Custom hook to handle the functionality of resetting an admin's password.
 * This hook should be used in components where admin password reset logic is needed.
 *
 * @returns
 * - resetPassword: Function to execute the reset password mutation.
 * - loading: Boolean indicating the loading state.
 * - error: Error object if there's an error.
 */
const useResetAdminPassword = () => {

	// Initialize Apollo mutation hook
	const [resetPasswordMutation, { loading, error }] = useMutation(RESET_ADMIN_PASSWORD);

	/**
	 * Executes the reset password mutation.
	 *
	 * @param {String} adminId - The ID of the admin.
	 * @param {String} resetToken - The reset token for the admin.
	 * @param {String} password - The new password.
	 * @returns {Object} - An object containing a boolean result (true if successful, false otherwise) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const resetPassword = async (adminId, resetToken, password) => {
		try {
			const { data } = await resetPasswordMutation({
				variables: {
					adminId,
					resetToken,
					password
				}
			});
			// If any error occurred during mutation, throw it
			if (error) {
				throw new Error(error);
			}
			// Return the boolean result
			return { data: data.resetAdminPassword };
		} catch (error) {
			// Return the error object in case of an exception
			return { error };
		}
	};

	// Return the custom function, loading state, and error object
	return { resetPassword, loading, error };

};

export default useResetAdminPassword;
