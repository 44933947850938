/**
 * @file useDeleteFeaturedDeal.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_FEATURED_DEAL } from "cache/FeaturedDeal/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteFeaturedDeal
 *
 * @description
 * Custom hook that handles the functionality to delete a featured deal.
 * This hook can be used wherever the application needs to delete an existing featured deal.
 *
 * @returns
 * - deleteFeaturedDeal: Function to execute the delete featured deal mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeleteFeaturedDeal = () => {

	const [deleteFeaturedDealMutation, { loading, error }] = useMutation(DELETE_FEATURED_DEAL);

	/**
	 * Custom mutation to delete a featured deal.
	 *
	 * @param {String} id - The id of the featured deal.
	 * @returns {Object} - An object containing a boolean result or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteFeaturedDeal = async (id) => {
		try {
			const { data } = await deleteFeaturedDealMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return the boolean result of the delete operation
			return { success: data.deleteFeaturedDeal };
		} catch (error) {
			return { error };
		}
	};

	return { deleteFeaturedDeal, loading, error };
};

export default useDeleteFeaturedDeal;
