/**
 * @file useGetOneReview.js
 */

import { useQuery } from "@apollo/client";
import { GET_ONE_REVIEW } from "cache/Review/queries"; 

/**
 * @hook useGetOneReview
 *
 * @description
 * Custom hook that handles the functionality to get one review.
 *
 * @param {string} id - The ID of the review to be retrieved.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetOneReview = (id) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ONE_REVIEW, {
		variables: { id }
	});

	let snaggedData = data?.getOneReview;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetOneReview;
