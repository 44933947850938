/**
 * @file useGetS3Url.js
 */

import { useMutation } from "@apollo/client";
import { GET_S3_URL } from "cache/s3/mutations"; // Update the path as per your project structure

/**
 * @hook useGetS3Url
 *
 * @description
 * Custom hook that handles fetching an S3 URL.
 *
 * @returns
 * - getS3Url function
 */
const useGetS3Url = () => {

	const [getS3UrlMutation, { loading, error }] = useMutation(GET_S3_URL);

	const getS3Url = async (isPDF) => {

		try {
			const { data } = await getS3UrlMutation({ 
				variables: { isPDF: isPDF }
			});
			if (error) {
				throw new Error(error);
			}
			let s3UrlData = data.getS3URL;
			if (!s3UrlData) {
				throw new Error("Fetching S3 URL failed");
			} else {
				return { url: s3UrlData.url };
			}
		} catch (error) {
			return { error };
		}

	};

	return { getS3Url, loading, error };

};

export default useGetS3Url;
