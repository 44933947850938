/**
 * @file queries.js is a file for all Invoice queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_ALL_INVOICES
 * 
 * @summary
 * - This query will return an array of invoice objects.
 * - This query will return null if no invoices are found.
 */
export const GET_ALL_INVOICES = gql`
	query getAllInvoices {
		getAllInvoices {
			_id
			customer {
				_id
				name
				email
				phone
				address {
					_id
					line1
					line2
					city
					state
					zip
					country
				}
				createdAt
				updatedAt
			}
			service_date
			service_description
			total_amount
			tax_fee
			transaction_fee
			items {
				_id
				item
				quantity
				rate
				amount
				isTaxable
			}
			discount
			status
			stripeInvoiceId
			refund {
				receiptUrl
				refundedOn
			}
			createdAt
			updatedAt
		}
	}
`;

/**
 * @const {Object} GET_INVOICES_FOR_CUSTOMER
 * 
 * @summary
 * - This query will return an array of invoice objects.
 * - This query will return null if no invoices are found.
 * 
 * @param {String} email - The email of the customer to get invoices for.
 * @param {String} token - The token of the customer to get invoices for.
 */
export const GET_INVOICES_FOR_CUSTOMER = gql`
	query getInvoicesForCustomer($email: String!, $token: String!) {
		getInvoicesForCustomer(email: $email, token: $token) {
			_id
			customer {
				_id
				name
				email
				phone
				address {
					_id
					line1
					line2
					city
					state
					zip
					country
				}
				createdAt
				updatedAt
			}
			service_date
			service_description
			total_amount
			tax_fee
			transaction_fee
			items {
				_id
				item
				quantity
				rate
				amount
				isTaxable
			}
			discount
			status
			stripeInvoiceId
			refund {
				receiptUrl
				refundedOn
			}
			createdAt
			updatedAt
		}
	}
`;

/**
 * @const {Object} GET_STRIPE_INVOICE_LINK
 * 
 * @summary
 * - This query will return a Stripe Invoice Link URL.
 * 
 * @param {String} id - The ID of the invoice to get a Stripe Invoice Link for
 */
export const GET_STRIPE_INVOICE_LINK = gql`
	query getStripeInvoiceLink($id: String!) {
		getStripeInvoiceLink(id: $id) {
			url
		}
	}
`;
