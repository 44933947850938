import React, { useState, useEffect, useRef } from "react";

import {
	Flex,
	IconButton,
	useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const MAX_VISIBILITY = 2.5;

import "./Carousel.scss";

const Carousel = ({ height, children, showArrowsOnMobile=false }) => {

	const [active, setActive] = useState(0);
	const [isPaused, setIsPaused] = useState(false); // New state variable
	const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

	const count = React.Children.count(children);

	const SLIDES_INTERVAL_TIME = 3000;
	const ANIMATION_DIRECTION = "right";

	// useRef to keep track of the active card DOM element
	const activeCardRef = useRef(null);

	const shuffleBackToStart = () => {
		// Time delay between each shuffle animation
		const SHUFFLE_INTERVAL = 350; // in milliseconds
		let i = count - 1;
	  
		const shuffleAnimation = () => {
		  // Set the active index
		  setActive(i);
	  
		  // Decrease index to move to the previous card
		  i--;
	  
		  // If first card is reached, stop the timeout loop
		  if (i < 0) {
				return;
		  }
	  
		  // Schedule next animation
		  setTimeout(shuffleAnimation, SHUFFLE_INTERVAL);
		};
	  
		// Initialize shuffle animation
		setTimeout(shuffleAnimation, SHUFFLE_INTERVAL);
	};

	const handleKeyboardNavigation = (event) => {
		if (event.key === "ArrowLeft") {
			// Move to the previous slide
			if (active === 0) {
				setActive(count - 1);
			} else {
				setActive((prevActive) => prevActive - 1);
			}
		} else if (event.key === "ArrowRight") {
			// Move to the next slide
			if (active === count - 1) {
				shuffleBackToStart();
			} else {
				setActive((prevActive) => prevActive + 1);
			}
		}
	};

	// useEffect to add the keyboard event listener
	useEffect(() => {
		window.addEventListener("keydown", handleKeyboardNavigation);
	
		// Cleanup: Remove the event listener
		return () => {
		  window.removeEventListener("keydown", handleKeyboardNavigation);
		};
	  }, [active, count]); // Rerun this effect if active or count changes

	useEffect(() => {
		if(!isPaused){
			const interval = setInterval(() => {
				if (ANIMATION_DIRECTION === "right") {
					if(active === count - 1) shuffleBackToStart();
					else setActive(i => i + 1);
				} else {
					if(active === 0) setActive(count - 1);
					else setActive(i => i - 1);
				}
			}, SLIDES_INTERVAL_TIME);
			return () => clearInterval(interval);
		}
	}, [active, isPaused, count]);

	  // useEffect to add hover event listeners to the active card
	useEffect(() => {
		const activeCard = activeCardRef.current;

		if (activeCard) {
			const pauseCarousel = () => setIsPaused(true);
			const resumeCarousel = () => setIsPaused(false);

			activeCard.addEventListener("mouseenter", pauseCarousel);
			activeCard.addEventListener("mouseleave", resumeCarousel);

			return () => {
				activeCard.removeEventListener("mouseenter", pauseCarousel);
				activeCard.removeEventListener("mouseleave", resumeCarousel);
			};
		}

	}, [active]); // Rerun this effect whenever the active card changes

	const handlePrevious = () => {
		if (active === 0) {
			setActive(count - 1);
		} else {
			setActive((prevActive) => prevActive - 1);
		}
	};

	const handleNext = () => {
		if (active === count - 1) {
			shuffleBackToStart();
		} else {
			setActive((prevActive) => prevActive + 1);
		}
	};
	
	return(
		<Flex
			flexDirection="row"
			margin="0 auto"
			position="relative"
			width="100%"
			maxWidth="100%"
			height={height ? height : "auto"}
			minHeight="23rem"
			justifyContent="center"
			alignItems="center"
			style={{
				perspective: "1000px",
				transformStyle: "preserve-3d",
			}}
		>

			{React.Children.map(children, (child, i) => {

				let isPrevious = i === active - 1;
				let isNext = i === active + 1;

				const handleClick = () => {
					if (isPrevious) {
						setActive(i => i - 1);
					} else if (isNext) {
						setActive(i => i + 1);
					}
				};

				return(
					<div 
						className='card-container'
						ref={i === active ? activeCardRef : null} 
						style={{
							"--active": i === active ? 1 : 0,
							"--offset": (active - i) / 3,
							"--direction": Math.sign(active - i),
							"--abs-offset": Math.abs(active - i) / 3,
							"opacity": Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
							"display": Math.abs(active - i) > MAX_VISIBILITY ? "none" : "flex",
							"cursor": isPrevious || isNext ? "pointer" : "auto",
						}}
						onClick={handleClick}
					>
						{child}
					</div>
				);
			})}
			{isMobile && showArrowsOnMobile && (
				<Flex
					position="absolute"
					bottom="10px"
					width="100%"
					justifyContent="space-between"
					padding="0 10px"
				>
					<IconButton
						icon={<ArrowBackIcon />}
						onClick={handlePrevious}
						aria-label="Previous Slide"
					/>
					<IconButton
						icon={<ArrowForwardIcon />}
						onClick={handleNext}
						aria-label="Next Slide"
					/>
				</Flex>
			)}
		</Flex>
	);

};

export default Carousel;