/** 
 * @file queries.js is a file for all Brochure queries 
 * 
 * @author Steven Secreti
 */
import { gql } from "@apollo/client";

/**
 * @constant GET_ALL_BROCHURES
 * 
 * @summary GraphQL query that gets all brochures.
 */
export const GET_ALL_BROCHURES = gql`
	query getAllBrochures {
		getAllBrochures {
			_id
			name
			image
			url
			order
		}
	}
`;
