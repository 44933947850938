import React from "react";

import { Routes, Route } from "react-router-dom";

import NotFound from "../components/Pages/Static/404/404";

const RoutesWrapper = ({ children }) => {
	return (
		<Routes>
			{children}
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default RoutesWrapper;
