/**
 * @file ProtectedRoute.js is a higher order component that checks if the admin is logged in or not.
 */
import { useContext } from "react";

import Loader from "../components/Loader";

import { AdminContext } from "contexts/AdminContext";
import { PageChangeContext } from "contexts/PageChangeContext";

/**
 * ProtectedRoute is a higher order component that checks if the admin is logged in or not.
 * 
 * @description 
 *  - This component is used to protect routes that require the admin to be logged in.
 * 
 * @returns {React.Component} A component that checks if the admin is logged in or not.
 *  - If the admin is logged in, the component is rendered.
 *  - If the admin is not logged in, the admin is redirected to the login page.
 */
const ProtectedRoute = ({ element: Component }) => {

	const { admin, loading } = useContext(AdminContext);
	const { handlePageChange } = useContext(PageChangeContext);

	if(loading){ // If the admin is still being fetched from the database, show a loading screen.
		return <Loader/>;
	}
	else{ // If the admin has been fetched from the database, check if the admin is logged in.
		if(admin){ // If the admin is logged in, render the component.
			return Component;
		}
		else{ // If the admin is not logged in, redirect the admin to the login page.
			handlePageChange("/admin/login");
			return null; // Return null so that the component is not rendered.
		}
	}
};

export default ProtectedRoute;