/* eslint-disable react/display-name */
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { forwardRef } from "react";

/**
 * @module NavigateButton
 * @category Components
 */

/**
 * @component LinkButton
 * @description A button that uses Chakra UI's "link" variant.
 */
const NavigateButton = forwardRef(({ to, children, isExternal, ...props }, ref) => {
	return (
		<Button
			as={Link}
			variant="primary"
			to={to}
			target={isExternal ? "_blank" : undefined}
			ref={ref}
			{...props}
		>
			{children}
		</Button>
	);
});

export default NavigateButton;
