/**
 * @file Reviews.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
	Stack
} from "@chakra-ui/react";

import ReviewsTable from "./Table";

import CreateReview from "./modals/CreateReview";

import useGetAllReviews from "hooks/Review/queries/useGetAllReviews";
import useReorderReviews from "hooks/Review/mutations/useReorderReviews";


import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";
import { convertDateToReadableFormat } from "utils/utils";
import { FooterContainer } from "../../components/TableContainer";

import ReorderItemsButton from "../../components/ReorderItemsButton";
/**
 * @component Reviews
 * 
 * @description Component that displays the reviews page.
 */
const Reviews = () => {

	const { loading, error, data, refetch: reviewsRefetch } = useGetAllReviews();

	const [reviews, setReviews] = useState(data);

	useEffect(() => {
		setReviews(data);
	}, [data]);

	const [showNewReviewModal, setShowNewReviewModal] = useState(false);
	const openNewReviewModal = () => setShowNewReviewModal(true);
	const closeNewReviewModal = () => setShowNewReviewModal(false);

	const sort = (field, order) => {
		const sorted = [...reviews].sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setReviews(sorted);
	};

	const search = (field, searchInput) => {
		const filtered = [...data].filter((item) => {
			let value = item[field];
			if (field === "reviewDate") {
				value = convertDateToReadableFormat(value);
			}
			return value.toLowerCase().includes(searchInput.toLowerCase());
		});
		setReviews(filtered);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setClearToggle(!clearToggle);
		setReviews(data);
	};

	const [currentItems, setCurrentItems] = useState([]);

	const { reorderReviews } = useReorderReviews();

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={true}
			>
				<ReviewsTable
					reviews={currentItems}
					reviewsRefetch={reviewsRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					{reviews &&
						<Pagination
							items={reviews}
							setItems={setCurrentItems}
						/>
					}
					{
						reviews?.length !== data?.length &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Stack
						direction={{
							base: "column",
							md: "row"
						}}
						spacing="1rem"
					>
						<Button
							variant="primary"
							onClick={openNewReviewModal}
						>
							New Review
						</Button>
						<ReorderItemsButton
							items={reviews}
							reorderMutation={reorderReviews}
							refetch={reviewsRefetch}
						/>
					</Stack>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreateReview
				isOpen={showNewReviewModal}
				onClose={closeNewReviewModal}
				reviewsRefetch={reviewsRefetch}
			/>
		</Flex>
		
	);

};

export default Reviews;
