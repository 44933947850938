/**
 * @file useDeletePartner.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_PARTNER } from "cache/Partner/mutations"; // Update the path as per your project structure

/**
 * @hook useDeletePartner
 *
 * @description
 * Custom hook that handles the functionality to delete an existing partner.
 * Use this hook wherever deleting a partner is required.
 *
 * @returns
 * - deletePartner: Handler to execute the deletePartner mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeletePartner = () => {

	const [deletePartnerMutation, { loading, error }] = useMutation(DELETE_PARTNER);

	/**
	 * Custom mutation handler for deleting a partner.
	 *
	 * @param {String} _id - The ID of the partner to be deleted.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deletePartner = async (_id) => {
		try {
			const { data } = await deletePartnerMutation({
				variables: {
					_id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.deletePartner };
		} catch (error) {
			return { error };
		}
	};

	return { deletePartner, loading, error };

};

export default useDeletePartner;
