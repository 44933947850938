import React, { useEffect, useContext } from "react";

import { ScrollContext } from "contexts/ScrollContext";

import { useParams } from "react-router-dom";

import useGetShowroomCategory from "hooks/Category/queries/useGetShowroomCategory";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import GalleryHero from "Blueprints/GalleryHero";

import ChildCategoriesGrid from "./ChildCategoriesGrid";
import ChildProductsStack from "./ChildProductsStack";
import ChildPartnersGrid from "./ChildPartnersGrid";

const SearchGrid = () => {

	const { scrollToTop } = useContext(ScrollContext);

	const { category } = useParams();

	const { loading, error, data } = useGetShowroomCategory(category);

	useEffect(() => {
		scrollToTop();
	}, [category]);

	return (
		<LoadingErrorDataWrapper loading={loading} error={error?.message} data={data}> 
		  	<GalleryHero
				images={data?.images}
				title={data?.name}
				description={data?.description}
				boxHeight={{
					base: "340px",
					md: "md",
				}}
				boxPaddingY="28"
				subHeadingSize="lg"
			/>
			{data && data?.child_categories?.length > 0 && <ChildCategoriesGrid categories={data?.child_categories} />}
			{data && data?.products?.length > 0 &&
				<ChildProductsStack products={data?.products} />
			}
			{data && data?.child_partners?.length > 0 && <ChildPartnersGrid partners={data?.child_partners} />}
		</LoadingErrorDataWrapper>
	  );
};


export default SearchGrid;
