/**
 * @file mutations.js is a file for holding the getS3URL mutation.
 */

import { gql } from "@apollo/client";

export const GET_S3_URL = gql`
    mutation getS3URL($isPDF: Boolean) {
        getS3URL(isPDF: $isPDF) {
            url
        }
    }
`;