import { useState } from "react";
import { HStack, Icon, useColorModeValue } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

const Rating = ({ defaultValue = 0, max = 5, size = "md", rootProps }) => {
	const [rating, setRating] = useState(defaultValue);
	const color = useColorModeValue("gray.200", "gray.600");
	const activeColor = useColorModeValue("tf_red", "red.200");

	const handleRatingChange = (newRating) => {
		setRating(newRating);
	};

	return (
		<HStack spacing="0.5" {...rootProps}>
			{Array.from({ length: max }).map((_, index) => index + 1).map((index) => (
				<Icon
					key={index}
					as={FaStar}
					fontSize={size}
					color={index <= rating ? activeColor : color}
					cursor="pointer"
					onClick={() => handleRatingChange(index)}
				/>
			))}
		</HStack>
	);
};

export default Rating;
