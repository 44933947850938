import React from "react";
import { Image, ButtonGroup, IconButton, VStack } from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import TaylorFenceLogo from "../../../assets/Logo/TaylorFenceLogo.png";
import Badges from "../../../assets/AFA/Badges";

const LogoAndSocialIcons = () => (
	<VStack 
		spacing={4}
		w={{ base: "100%", md: "30%" }}
		align="center"
		p={{ base: 4, md: 0 }}
	> 
		<Image 
			src={TaylorFenceLogo} 
			alt="Logo" 
			width={{
				base: "200px", // width on mobile view
				sm: "180px",   // width on small devices
				md: "170px",   // width on medium devices
				lg: "200px",   // width on large devices
				xl: "200px"    // width on extra-large devices
			}}
			height={{
				base: "100px", // height on mobile view
				sm: "125px",   // height on small devices
				md: "120px",   // height on medium devices
				lg: "140px",   // height on large devices
				xl: "130px"    // height on extra-large devices
			}}
		/>
		<Badges />
		<ButtonGroup spacing={2}>
			<IconButton
				as="a"
				bg="transparent"
				href="https://www.facebook.com/TaylorFenceNJ/"
				aria-label="Facebook"
				icon={<FaFacebook fontSize="25px" />}
				_hover={{ 
					textDecoration: "bold",
					transform: "translateY(-2px)",
					color: "tf_red",
					cursor: "pointer"
				}}
			/>
			<IconButton
				as="a"
				bg="transparent"
				href="https://www.instagram.com/taylorfencenj/"
				aria-label="Instagram"
				icon={<FaInstagram fontSize="25px" />}
				_hover={{ 
					textDecoration: "bold",
					transform: "translateY(-2px)",
					color: "tf_red",
					cursor: "pointer"
				}}
			/>
		</ButtonGroup>
	</VStack>
);

export default LogoAndSocialIcons;
