
import React from "react";

import {
	Alert,
	AlertIcon,
	AlertDescription
} from "@chakra-ui/react";

import Loader from "../components/Loader";

/**
 * @component - LoadingErrorDataWrapper
 * 
 * @description 
 * - A wrapper component that handles loading, error, and data states.
 * - If loading, display a loader.
 * - If error is not null, display the error as an alert description
 * - If data is not null, display the children.
 * 
 */
const LoadingErrorDataWrapper = ({ loading, error, data, children }) => {

	if (loading) {
		return <Loader />;
	}
	else if (error) {
		return (
			<Alert status="error" variant="subtle" zIndex={"docked"}>
				<AlertIcon />
				<AlertDescription>{error}</AlertDescription>
			</Alert>
		);
	}
	else if (data) {
		return (<>{children}</>);
	}

	// If neither loading, error, or data, return null or some default state
	return null;

};

export default LoadingErrorDataWrapper;