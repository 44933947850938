/**
 * @file
 * This is the Search component of the application.
 * It's responsible for displaying the search page, including the sidebar and the search results grid.
 *
 * @summary Search page for the application.
 */

import React from "react";
import {
	Box,
	Container,
} from "@chakra-ui/react";

import SearchGrid from "./components/CategoriesGrid";// Import the SearchGrid component

import "./Search.scss"; // Importing the SCSS file

/**
 * Search component is responsible for rendering the search page of the application.
 * It includes a sidebar and a grid for displaying search results.
 * Search parameters are read from the URL's search parameters.
 *
 * @param {object} props - The properties passed down from the parent component (not used in this component, but retained for possible future use)
 * 
 * @returns {HTMLElement} - A Container component from Chakra UI that includes Breadcrumbs, Sidebar and SearchGrid components.
 */
const Search = () => {

	return (
		<Container
			maxW={"100%"}
			//pb={4} 
			px={0}
			w="100%"
		>
			{/* ---------------------------------------------- SearchBar ------------------------------------------- */}
			<Box
				display="flex"
				justifyContent="center"
				alignItems="flex-start"
				h="100%"
				w="100%"
				flexDirection={{
					base: "column",
					md: "row",
				}}
				className="search-container"
			>
				{/* ---------------------------------------------- Sidebar ------------------------------------------- */}
				{/* <Sidebar /> */}
				<Box
					flex="1"
					h="100%"
					w="100%"
					className="search-results"
				>
					{/* ---------------------------------------------- SearchGrid ------------------------------------------- */}
					<SearchGrid />
				</Box>
			</Box>
		</Container>
	);
};

export default Search;

