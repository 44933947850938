/**
 * @file useGetAdmin.js
 */
import { useQuery } from "@apollo/client";
import { GET_ADMIN } from "cache/Admin/queries";

/**
 * @hook useGetAdmin
 *
 * @description
 * Custom hook that handles getting admin information.
 * Used in the Admin component.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Admin information
 * - refetch: Function to refetch the admin information
 * - networkStatus: Network status information
 */
const useGetAdmin = () => {

	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ADMIN, {
		notifyOnNetworkStatusChange: true
	});

	let snaggedData = data?.getAdmin;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetAdmin;
