/**
 * @file - CategorySelect.js
 * 
 * @summary
 * This file contains the CategorySelect component, which is a component that displays a list of categories
 */

import { 
	Box, 
	Text, 
	Flex, 
	Heading,
	useBreakpointValue
} from "@chakra-ui/react";

import "./Category.scss";

import { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import useGetTopLevelCategories from "hooks/Category/queries/useGetTopLevelCategories";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
/**

 * @returns {HTMLElement} - Returns the CategorySelect component
 */

const CategorySelection = () => {

	const { loading, error, data: categories } = useGetTopLevelCategories();

	// Responsive grid layout and box size
	//const boxSize = useBreakpointValue({ base: { h: "260px", w: "full" }, md: { h: "280px", w: "400px" } });

	// Responsive font size
	//const fontSize = useBreakpointValue({ base: "md", md: "xl" });

	const { handlePageChange } = useContext(PageChangeContext);

	const handleCategorySelection = async (category) => {    
		//setSearchParams and navigate to /showroom/search
		//If category.name is Chainlink, redirect to showroom/chainlink
		if(category.name === "Chainlink"){
			handlePageChange("showroom/chainlink");
			return;
		}
		if(category.name.toLowerCase() === "wood"){
			handlePageChange("showroom/6539a77531eefc17f8a3d3ca"); //Styles Category ID
			return;
		}
		handlePageChange(`showroom/${category._id}`);
	};

	return (
		<Flex
			direction="column"
			w="100%"
			h="100%"
			justifyContent="center"
			alignItems="center"
		>
			<Heading
				variant="main_heading"
				my={10}
				className="animated jackInTheBox"
			>
				Browse by Category
			</Heading>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={categories}
			>
				<Flex
					w="100%"
					h="100%"
					justifyContent="center"
					alignItems="center"
					flexWrap="wrap"
					_hover={{
					}}
				>
					{
						(categories?.map((category, index) => {
							return (
								<CategoryRow
									key={`cat${index}`}
									category={category}
									index={index}
									onClick={() => handleCategorySelection(category)}
								/>
							);
						}
						))
					}
				</Flex>
			</LoadingErrorDataWrapper>
		</Flex>
	);
};

const CategoryRow = ({ category, index, onClick }) => {

	const flexDir = useBreakpointValue({ base: "column", md: index % 2 === 0 ? "row" : "row-reverse" });
	
	const gradientDirection = index % 2 === 0 ? "to right, " : "to left, ";

	const bgImage = (category.images && category.images.length > 0) ? category.images[0] : "";

	return (
		<Flex
			direction={flexDir}
			w="90%"
			h="100%"
			justifyContent="center"
			alignItems="center"
			onClick={onClick}
			
			ml={index % 2 === 0 ? "0rem" : "2.4rem"}
			mr={index % 2 === 0 ? "2rem" : "0rem"}
			mb={{
				base: "1rem",
				md: "0.5rem",
			}}
		>
			<Box
				position="relative"
				w={{
					base: "80%",
					sm: "85%",
					md: "90%",
				}}
				h={{
					base: "125px",
					sm: "300px",
					md: "220px",
				}}
				background={`linear-gradient(${gradientDirection} rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0)), url(${bgImage})`}
				backgroundSize="cover"
				backgroundPosition="center 40%"
				borderWidth="1px"
				borderColor="gray.300"
				borderRadius="lg"
				boxShadow="xl"
				radius="lg"
				display="flex"
				justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
				alignItems="center"
				px={"2rem"}
				my={"1rem"}
				mb={"2rem"}
				color="blackAlpha.700"
				transition="all 1s cubic-bezier(.08,.52,.52,1)"
				_hover={{
					transform: "scale(1.01)",
					background: `linear-gradient(${gradientDirection} rgba(0,0,0,1), rgba(255,255,255,0)), url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
					cursor: "pointer",
					color: "white"
				}}
			>
				{/* Category Name */}
				<Text
					fontSize={{
						base: "xl",
						sm: "2xl",
						md: "3xl",
					}}
					fontWeight="bold"
				>
					{category.name}
				</Text>
			</Box>
		</Flex>
	);
};

export default CategorySelection;
