/**
 * @file mutations.js is a file for all Featured Deal mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_FEATURED_DEAL - GraphQL mutation to create a featured deal.
 * 
 * @summary
 * - This mutation will create a new Featured Deal and return the featured deal object if successful.
 * 
 * @param {Object} createFeaturedDealInput - The input object for creating a featured deal.
 * @property {String} createFeaturedDealInput.name - The name of the featured deal.
 * @property {String} createFeaturedDealInput.description - The description of the featured deal.
 * @property {Array} createFeaturedDealInput.images - The images of the featured deal.
 * @property {String} createFeaturedDealInput.expires - The expiration date of the featured deal.
 * 
 * @returns
 * - _id: ID
 * - name: String
 * - description: String
 * - images: [String]
 * - expires: String
 * - createdAt: String
 * - updatedAt: String
 */
export const CREATE_FEATURED_DEAL = gql`
	mutation createFeaturedDeal($input: CreateFeaturedDealInput!) {
		createFeaturedDeal(input: $input) {
			_id
			name
			description
			images
			expires
			createdAt
			updatedAt
			order
		}
	}
`;

/**
 * @const {Object} UPDATE_FEATURED_DEAL - GraphQL mutation to update a featured deal.
 * 
 * @summary
 * - This mutation will update a Featured Deal and return true if successful.
 * 
 * @param {Object} updateFeaturedDealInput - The input object for updating a featured deal.
 * @property {String} updateFeaturedDealInput._id - The id of the featured deal.
 * @property {String} updateFeaturedDealInput.name - The name of the featured deal.
 * @property {String} updateFeaturedDealInput.description - The description of the featured deal.
 * @property {String} updateFeaturedDealInput.expires - The expiration date of the featured deal.
 * 
 * @returns
 * - Boolean
 */
export const UPDATE_FEATURED_DEAL = gql`
	mutation updateFeaturedDeal($input: UpdateFeaturedDealInput!) {
		updateFeaturedDeal(input: $input)
	}
`;

/**
 * @const {Object} UPDATE_FEATURED_DEAL_IMAGES - GraphQL mutation to update a featured deal's images.
 * 
 * @summary
 * - This mutation will update a Featured Deal's images and return true if successful.
 * 
 * @param {String} featuredDealId - The id of the featured deal.
 * @param {Array} images - The images of the featured deal.
 * 
 * @returns
 * - Boolean
 */
export const UPDATE_FEATURED_DEAL_IMAGES = gql`
	mutation updateFeaturedDealImages($featuredDealId: ID!, $images: [String]!) {
		updateFeaturedDealImages(featuredDealId: $featuredDealId, images: $images)
	}
`;

/**
 * @const REORDER_FEATURED_DEALS
 * 
 * @summary
 * - This mutation will reorder the featured deals.
 */
export const REORDER_FEATURED_DEALS = gql`
	mutation reorderFeaturedDeals($input: [ReorderInput!]!) {
		reorderFeaturedDeals(input: $input)
	}
`;

/**
 * @const {Object} DELETE_FEATURED_DEAL - GraphQL mutation to delete a featured deal.
 * 
 * @summary
 * - This mutation will delete a Featured Deal and return the featured deal object if successful.
 * 	
 * @param {String} id - The id of the featured deal.
 * 
 * @returns
 * - Boolean
 */
export const DELETE_FEATURED_DEAL = gql`
	mutation deleteFeaturedDeal($id: ID!) {
		deleteFeaturedDeal(id: $id)
	}
`;
