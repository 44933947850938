/**
 * @file FeaturedDeals.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
	Stack
} from "@chakra-ui/react";

import DealTable from "./Table";

import CreateFeaturedDeal from "./modals/CreateFeaturedDeal";

import useGetFeaturedDeals from "hooks/FeaturedDeal/queries/useGetAllFeaturedDeals";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import ReorderItemsButton from "../../components/ReorderItemsButton";
import useReorderFeaturedDeals from "hooks/FeaturedDeal/mutations/useReorderFeaturedDeals";

import Pagination from "../../components/Pagination";
import { convertDateToReadableFormat } from "utils/utils";
import { FooterContainer } from "../../components/TableContainer";

/**
 * @component FeaturedDeals
 * 
 * @description Component that displays the featured deals page.
 * 
 * @returns 
 */
const FeaturedDeals = () => {

	const { loading, error, data, refetch: featuredDealsRefetch } = useGetFeaturedDeals();

	const [featuredDeals, setFeaturedDeals] = useState(data);

	useEffect(() => {
		if(data) {
			setFeaturedDeals(data);
		}
	}, [data]);

	const [showNewFeaturedDealModal, setShowNewFeaturedDealModal] = useState(false);
	const openNewFeaturedDealModal = () => setShowNewFeaturedDealModal(true);
	const closeNewFeaturedDealModal = () => setShowNewFeaturedDealModal(false);

	const sort = (field, order) => {
		const sorted = [...featuredDeals].sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setFeaturedDeals(sorted);
	};

	const search = (field, searchInput) => {
		const filtered = [...data].filter((deal) => {
			let fieldValue = deal[field];
			if(field === "expires"){
				fieldValue = convertDateToReadableFormat(fieldValue);
			}
			return fieldValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setFeaturedDeals(filtered);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setClearToggle(!clearToggle);
		setFeaturedDeals(data);
	};

	const [currentItems, setCurrentItems] = useState([]);

	const { reorderFeaturedDeals } = useReorderFeaturedDeals();

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={featuredDeals}
			>
				<DealTable
					deals={currentItems}
					dealsRefetch={featuredDealsRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={featuredDeals}
						setItems={setCurrentItems}
					/>
					{
						(featuredDeals?.length !== data?.length) && 
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Stack
						direction={{
							base: "column",
							md: "row"
						}}
						spacing="1rem"
					>

						<Button
							variant="primary"
							onClick={openNewFeaturedDealModal}
						>
							New Featured Deal
						</Button>
						<ReorderItemsButton
							items={featuredDeals}
							reorderMutation={reorderFeaturedDeals}
							refetch={featuredDealsRefetch}
						/>

					</Stack>
				</FooterContainer>
				
			</LoadingErrorDataWrapper>
			<CreateFeaturedDeal
				isOpen={showNewFeaturedDealModal}
				onClose={closeNewFeaturedDealModal}
				dealsRefetch={featuredDealsRefetch}
			/>
		</Flex>
	);
};

export default FeaturedDeals;
