/**
 * @file - Section.js
 * @description - common component used throughout the Chainlink pages.
 * @author Webify Services, LTD.
 */
import {
	Box,
	Container,
	Heading,
	Icon,
	SimpleGrid,
	Square,
	HStack,
	Text,
} from "@chakra-ui/react";

/**
 * Renders a section with a heading, description, and a grid of features.
 * @param {Object} props The component props.
 * @param {Array} props.features An array of feature objects to display in the section. Each feature should have a name and an icon.
 * @returns {JSX.Element} The Section component.
 */
const Section = ({ features }) => (
	<Box as="section" bg="bg.surface">
		<Container
			py={{
				base: "2",
				md: "4",
			}}
			px={{
				base: "2",
				md: "8",
			}}
			maxW="container.2xl"
		>
			<Heading
				size={{
					base: "md",
					md: "lg",
				}}
				fontWeight="extrabold"
				textAlign="start"
				px={{
					base: "0",
					md: "2",
				}}
				py="4"
			>
				Interested in a Chain-Link Fence? Here's How to Get Started:
			</Heading>
			<Text
				color="fg.muted"
				fontSize={{
					base: "md",
					md: "lg",
				}}
				fontWeight="medium"
				mb={{
					base: "4",
					md: "8",
				}}
				textAlign="start"
				px="2"
			>
				To initiate the process of obtaining a quote for your chain-link fence, simply reach out to us. You can contact us through a phone call, email, or by visiting us directly. <br></br>
				Once we establish communication, we'll gather some essential details from you to provide an accurate quote for your project!
			</Text>
			<SimpleGrid
				columns={{
					base: 1,
					md: 2,
					lg: 3,
				}}
				columnGap={{
					base: 2,
					md: 8,
				}}
				rowGap={{
					base: 4,
					md: 16,
				}}
			>
				{features.map((feature) => (
					<HStack
						key={feature.name}
						spacing={{
							base: "2",
							md: "5",
						}}
						align="center"
					>
						<Square
							size={{
								base: "10",
								md: "6",
							}}
							bg="accent"
							color="fg.inverted"
							borderRadius="lg"
						>
							<Icon
								as={feature.icon}
								boxSize={{
									base: "5",
									md: "6",
								}}
								color="#EF3737"
							/>
						</Square>
						<Text
							fontSize={{
								base: "md",
								md: "lg",
							}}
							fontWeight="medium"
						>
							{feature.name}
						</Text>
					</HStack>
				))}
			</SimpleGrid>
		</Container>
	</Box>
);
  
export default Section;
  