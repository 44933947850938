import React from "react";

import {
	Grid,
} from "@chakra-ui/react";

import PartnerDisplay from "./PartnerDisplay";

const ChildPartnersGrid = ({ partners }) => {

	return (
		<Grid
			templateColumns={{
				base: "repeat(1, 1fr)",
				sm: "repeat(2, 1fr)",
				md: "repeat(2, 1fr)",
				lg: "repeat(3, 1fr)",
				llg: "repeat(4, 1fr)",
			}}
			gap={8}
			p={12}
			w="100%"
		>
			{partners?.map((partner, index) => (
				<PartnerDisplay
					key={index}
					partner={partner}
				/>
			))}
		</Grid>
	);
};

export default ChildPartnersGrid;
