import React from "react";
import { Box, List, ListItem, ListIcon, VStack, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { MdAvTimer, MdPhonelinkRing, MdMarkAsUnread } from "react-icons/md";

const MotionBox = motion(Box);

const Location = () => {
	return (
		<MotionBox 
			initial="hidden" 
			animate="visible" 
			exit="exit" 
			p={{
				base: "8",
				md: "5",
			}}
			w="100%" 
			h={{
				base: "auto",
				md: "auto",
			}}
			// mt={{
			// 	base: "0",
			// 	md: "0",
			// }}
			mb={{
				base: "2rem",
				md: "10rem",
			}}
			mt={{
				base: "0",
				md: "2rem",
			}}
		>
			<Heading 
				as="h1"
				mb={{
					base: "0.5rem",
					md: "4rem",
				}} 
				textAlign="center"
			>
				Location & Hours
			</Heading>
			<SimpleGrid 
				columns={{ base: 1, md: 2 }} 
				spacing={10}
				mt={{
					base: "0.9rem",
					md: "2rem",
				}}
			>
				<Box 
					borderWidth="1px" 
					borderRadius="lg" 
					borderColor="gray.300" 
					boxShadow="md" 
					overflow="hidden" 
					p={4}
				>
					<Heading 
						size="md" 
						mb={{
							base: "0",
							md: "1rem",
						}}
					>
						Contact Information & Hours
					</Heading>
					<Flex 
						direction={{ base: "column", md: "row" }} 
						justify="space-between"
						mt={{
							base: "1rem",
							md: "2rem",
						}}
						mb={{
							base: "1rem",
							md: "2rem",
						}}
						p={4}
					>
						<VStack 
							spacing={4} 
							mt={{
								base: "0",
								md: "2rem",
							}} 
							mb={{
								base: "1rem",
								md: "2.5rem",
							}}
							align="center" 
							flex="1"
						>
							<Heading 
								size="sm" 
								mb={{
									base: "0",
									md: "1rem",
								}}
							>
								Contact Us
							</Heading>
							<List 
								spacing={2} 
								mt={{
									base: "1rem",
									md: "2rem",
								}}
								mb={{
									base: "1rem",
									md: "2rem",
								}}
							>
								<ListItem><ListIcon as={MdPhonelinkRing}/>(732) 747-5498</ListItem>
								<ListItem><ListIcon as={MdMarkAsUnread}/>Info@TaylorFenceNJ.com</ListItem>
							</List>
						</VStack>
						<VStack 
							spacing={4} 
							mt={{
								base: "0",
								md: "2rem",
							}} 
							mb={{
								base: "1rem",
								md: "2.5rem",
							}}
							align="center" 
							flex="1"
						>
							<Heading 
								size="sm" 
								mb={{
									base: "0",
									md: "1rem",
								}}
							>
								Our Hours
							</Heading>
							<List 
								spacing={2} 
								mt={2}
							>
								<ListItem><ListIcon as={MdAvTimer}/>Monday - Friday: 8:00am - 5:00pm</ListItem>
								<ListItem><ListIcon as={MdAvTimer}/>Saturday: 8:00am - 4:00pm or 1:00pm Seasonally</ListItem>
								<ListItem><ListIcon as={MdAvTimer}/>Sunday: Closed</ListItem>
							</List>
						</VStack>
					</Flex>
				</Box>
				<Box borderWidth="1px" borderRadius="lg" borderColor="gray.300" boxShadow="lg">
					<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1523.1247383716764!2d-74.15835!3d40.225755!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c22bb2940bb189%3A0xaf1f976600143c33!2sTaylor%20Fence%20Company!5e0!3m2!1sen!2sus!4v1687110096736!5m2!1sen!2sus" width="100%" height="400" style={{ border: "0", borderRadius: "8px" }} allowFullScreen="" loading="lazy" title="Google Maps"></iframe>
				</Box>
			</SimpleGrid>
		</MotionBox>
	);
};

export default Location;

