/**
 * @file useDeleteBrochure.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_BROCHURE } from "cache/Brochure/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteBrochure
 *
 * @description
 * Custom hook that handles the functionality to delete an existing brochure.
 * Use this hook wherever the deletion of a brochure is required.
 *
 * @returns
 * - deleteBrochure: Handler to execute the deleteBrochure mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeleteBrochure = () => {

	const [deleteBrochureMutation, { loading, error }] = useMutation(DELETE_BROCHURE);

	/**
	 * Custom mutation handler for deleting a brochure.
	 *
	 * @param {String} _id - The ID of the brochure to be deleted.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteBrochure = async (_id) => {
		try {
			const { data } = await deleteBrochureMutation({
				variables: {
					_id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.deleteBrochure };
		} catch (error) {
			return { error };
		}
	};

	return { deleteBrochure, loading, error };

};

export default useDeleteBrochure;
