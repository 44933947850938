/**
 * @file Invoices.js
 */
import React, { useContext, useState } from "react";

import {
	Flex,
	Button,
	Heading,
} from "@chakra-ui/react";

import { useSearchParams } from "react-router-dom";

import useGetInvoicesForCustomer from "hooks/Invoice/queries/useGetInvoicesForCustomer";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";
import { PageChangeContext } from "contexts/PageChangeContext";

import Invoice from "./Invoice";

import { TextCell } from "components/Admin/Dashboard/components/TableCell";
import { displayDollarAmount } from "utils/utils";
/**
 * @component Invoices
 * 
 * @description
 * - This component is used to display a list of invoices for a customer
 * - The component will use the email and token provided in the url to fetch the invoices
 * - The component will render a table of invoices
 * 
 * @returns 
 */
const Invoices = () => {
	
	const { handlePageChange } = useContext(PageChangeContext);

	const [searchParams] = useSearchParams();

	const email = searchParams.get("email");
	const token = searchParams.get("token");

	if(!email || !token) {
		handlePageChange("/payments");
		return null;
	};

	const { loading, error, data: invoices } = useGetInvoicesForCustomer(email, token);

	const [selectedInvoice, setSelectedInvoice] = useState(null);

	const handleBack = () => {
		setSelectedInvoice(null);
	};

	const handleSelectInvoice = (invoice) => {
		setSelectedInvoice(invoice);
	};

	const InvoicesMain = () => (
		<>
			<Heading>
				{invoices?.length} Invoices
			</Heading>
			<Flex
				flexDir="column"
				justifyContent="center"
				alignItems="center"
				w="100%"
				p="5rem"
			>
				<Flex
					w="100%"
					h="100%"
					flexDir="column"
					borderWidth="1px"
					borderColor="gray.200"
					borderRadius="md"
					overflow="hidden"
					boxShadow="md"
					bg="white"
					mt="6"
					mb="8"
					display="table"
					justifyContent="space-between"
				>
					<Flex
						w="100%"
						h="100%"
						bg="gray.50"
						flexDir="row"
						alignItems="center"
						justifyContent="space-between"
						px={5}
						py={3}
						borderBottom="1px solid darkgray"
					>
						<TextCell>
							Invoice ID
						</TextCell>
						<TextCell>
							Status
						</TextCell>
						<TextCell>
							Total Amount
						</TextCell>
						<TextCell>
							Service Description
						</TextCell>
					</Flex>
					{invoices.map((invoice, index) => (
						<InvoiceRow
							key={index}
							invoice={invoice}
						/>
					))}
				</Flex>
			</Flex>
		</>
	);

	const InvoiceRow = ({ invoice }) => (
		<Flex
			w="100%"
			h="100%"
			bg="gray.50"
			flexDir="row"
			_hover={{
				transform: "scale(1.01)",
				transition: "transform .5s cubic-bezier(.08,.52,.52,1)",
				borderColor: "#CBD5E0",
				boxShadow: "xs",
				bg: "gray.100",
				cursor: "pointer",
			}}
			alignItems="center"
			justifyContent="space-between"
			px={5}
			py={3}
			onClick={() => handleSelectInvoice(invoice)}
		>
			<TextCell>
				{invoice._id}
			</TextCell>
			<TextCell>
				{invoice.status}
			</TextCell>
			<TextCell>
				{displayDollarAmount(invoice.total_amount)}
			</TextCell>
			<TextCell>
				{invoice.service_description}
			</TextCell>
		</Flex>
	);

	const InvoiceSelected = () => (
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			w="100%"
		>
			<Button
				variant="primary"
				onClick={handleBack}
			>
				Back
			</Button>
			<Invoice
				invoice={selectedInvoice}
			/>
		</Flex>
	);

	return (
		<Flex
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			w="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={invoices}
			>
				{
					selectedInvoice ?
						<InvoiceSelected />
						:
						<InvoicesMain />
				}
			</LoadingErrorDataWrapper>
		</Flex>
		
	);

};

export default Invoices;