/**
 * @file useCreateAdmin.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_ADMIN } from "cache/Admin/mutations";

/**
 * @hook useCreateAdmin
 *
 * @description
 * Custom hook that handles admin creation functionality.
 *
 * @returns
 * - createAdmin function
 */
const useCreateAdmin = () => {

	const [createAdminMutation, { loading, error }] = useMutation(CREATE_ADMIN);

	const create = async (input) => {

		try {
			const { data } = await createAdminMutation({ 
				variables: { input: input }
			});
			if(error){
				throw new Error(error);
			}
			let createAdminData = data.createAdmin;
			if(createAdminData?.error){
				throw new Error(createAdminData.error);
			}
			else{
				return { data: createAdminData };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { create, loading, error };

};

export default useCreateAdmin;
