/**
 * @file Customers.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
} from "@chakra-ui/react";

import CustomerTable from "./Table";

import CreateCustomer from "./modals/CreateCustomer";

import useGetAllCustomers from "hooks/Customer/queries/useGetAllCustomers";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { FooterContainer } from "../../components/TableContainer";

const Customers = () => {

	const { loading, error, data, refetch: customersRefetch } = useGetAllCustomers();

	const [customers, setCustomers] = useState(data);

	useEffect(() => {
		if(data) {
			setCustomers(data);
		}
	}, [data]);

	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const openCreateCustomerModal = () => setShowCreateCustomer(true);
	const closeCreateCustomerModal = () => setShowCreateCustomer(false);

	const sort = (field, order) => {
		let sortedCustomers = [...customers];
		sortedCustomers.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setCustomers(sortedCustomers);
	};

	const search = (field, searchInput) => {
		let filteredCustomers = [...data];
		filteredCustomers = filteredCustomers.filter((customer) => {
			let customerValue = customer[field];
			return customerValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setCustomers(filteredCustomers);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setCustomers(data);
		setClearToggle(!clearToggle);
	};

	const [currentItems, setCurrentItems] = useState([]);

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={customers}
			>
				<CustomerTable
					customers={currentItems}
					customersRefetch={customersRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={customers}
						setItems={setCurrentItems}
					/>
					{
						(customers?.length !== data?.length) &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Button
						variant="primary"
						onClick={openCreateCustomerModal}
					>
						New Customer
					</Button>
				</FooterContainer>
				<CreateCustomer
					isOpen={showCreateCustomer}
					onClose={closeCreateCustomerModal}
					customersRefetch={customersRefetch}
				/>
			</LoadingErrorDataWrapper>
		</Flex>
	);

};

export default Customers;