import Rating from "./Rating";
import { Heading, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import { convertDateToReadableFormat } from "utils/utils";

export const ReviewItem = (props) => {
	const { review } = props;
	return (
		<Stack
			w="100%"
			direction="column"
			alignItems="center"
			justifyContent="center"
			spacing={{
				base: "4",
				md: "2",
			}}
      
		>
			<Heading size="sm" fontWeight="medium">
				{review.title}
			</Heading>
			<Rating 
				defaultValue={review.rating} 
				size="sm"
				isReadOnly
			/>
			<Text color={useColorModeValue("gray.600", "gray.400")} fontSize="sm">
        		by {review.author}, {convertDateToReadableFormat(review.reviewDate)}
			</Text>
			<Text>{review.comment}</Text>
		</Stack>
	);
};