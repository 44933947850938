
import React, { useState } from "react";

import {
	Collapse,
	Image,
	IconButton,
	Tooltip,
	Button,
} from "@chakra-ui/react";

import { FiEdit, FiTrash2, FiList } from "react-icons/fi";

import { MdAddLink } from "react-icons/md";

import useUpdateProduct from "hooks/Product/mutations/useUpdateProduct";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import UpdateImages from "./Modals/UpdateImages";
import UpdateProduct from "./Modals/UpdateProduct";

import VariationsDisplay from "./components/VariationsDisplay";

import DeleteProduct from "./Modals/DeleteProduct";

import AddSuggestedProducts from "./Modals/AddSuggestedProducts";

import ReorderProductImagesModal from "./Modals/ReorderProductImagesModal";

import { ComplexRowContainer, ComplexRowContentContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

const ProductRow = (props) => {
	const product = props.product;
	const variations = product?.variations;
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [hasSuggestedProducts, setHasSuggestedProducts] = useState(false);
	const [showVariations, setShowVariations] = useState(false);

	const toggleShowVariations = () => {
		setShowVariations(!showVariations);
	};

	/**
	 * @function updateVariations
	 * @param {*} updatedVariations 
	 */
	const updateVariations = async (updatedVariations) => {
		await handleUpdateProduct({ variations: updatedVariations });
	};

	const updateSimilarProducts = async(updatedSimilarProducts) => {
		await handleUpdateProduct({ similar_products: updatedSimilarProducts });
	};
	
	const { update } = useUpdateProduct();

	const handleUpdateProduct = async (updateProductInput) => {
		setLoading(true);
		setErrorMsg(null);
		
		let response = await update(product._id, updateProductInput);
		if(response.error){
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success){
			setLoading(false);
			await props.refetch();
			return;
		}
	};

	const [showUpdateImages, setShowUpdateImages] = useState(false);
	const openShowUpdateImages = () => {setShowUpdateImages(true);};
	const closeShowUpdateImages = () => {setShowUpdateImages(false);};

	const [showUpdateProduct, setShowUpdateProduct] = useState(false);
	const openShowUpdateProduct = () => {setShowUpdateProduct(true);};
	const closeShowUpdateProduct = () => {setShowUpdateProduct(false);};

	const [showDeleteProduct, setShowDeleteProduct] = useState(false);
	const openDeleteProduct = () => {setShowDeleteProduct(true);};
	const closeDeleteProduct = () => {setShowDeleteProduct(false);};

	const [showAddSuggestedProducts, setShowAddSuggestedProducts] = useState(false);
	const openAddSuggestedProducts = () => {
		setShowAddSuggestedProducts(true);
		setHasSuggestedProducts(true);
	};
	const closeAddSuggestedProducts = () => {setShowAddSuggestedProducts(false);};

	const [showReorderProductImages, setShowReorderProductImages] = useState(false);
	const openReorderProductImages = () => {setShowReorderProductImages(true);};
	const closeReorderProductImages = () => {setShowReorderProductImages(false);};

	return (
		<ComplexRowContainer>
			<LoadingErrorDataWrapper
				loading={loading}
				error={errorMsg}
				data={product}
			>
				<ComplexRowContentContainer
					onClick={toggleShowVariations}
					showChildren={showVariations}
				>
					<TableCell>
						{product?.images && product?.images.length > 0 ? (
							<Image
								src={product.images[0]}
								alt={product.name}
								maxH={{
									base: "auto",
									md: "100px",
								}}
								maxW={{
									base: "auto",
									md: "100px",
								}}
								objectFit="cover"
								borderRadius="lg"
								borderWidth="1px"
								borderColor="#CBD5E0"
								_hover={{
									borderColor: "#718096",
									transform: "scale(1.05)",
									transition: "transform .2s",
								}}
								onClick={(e) => {
									e.stopPropagation();
									openShowUpdateImages();
								}}
							/>
						) : (
							<Button
								variant="primary"
								onClick={(e) => {
									e.stopPropagation();
									openShowUpdateImages();
								}}
								w="50%"
							>
								Add Image
							</Button>
						)
						
						}
					</TableCell>
					<TextCell>
						{product.name}
					</TextCell>
					<TextCell>
						{product?.category?.name}
					</TextCell>
					<TextCell>
						{product?.partner}
					</TextCell>
					<TableCell>
						<Tooltip
							label="Edit"
							aria-label="Edit"
							openDelay={500}
							bg="gray.500"
							borderRadius="lg"
							placement='bottom-start'
						>
							<IconButton
								variant="text_admin"
								icon={<FiEdit />}
								size="lg"
								onClick={(e) => {
									e.stopPropagation();
									openShowUpdateProduct();
								}}
							></IconButton>
						</Tooltip>
						<Tooltip
							label="Delete"
							aria-label="Delete"
							openDelay={500}
							bg="gray.500"
							borderRadius="lg"
							placement='bottom'
						>
							<IconButton
								variant="text_admin"
								size="lg"
								isDisabled={variations && variations.length > 0 ? true : false}
								onClick={(e) => {
									e.stopPropagation();
									openDeleteProduct();
								}}
								icon={<FiTrash2 />}
							></IconButton>
						</Tooltip>
						<Tooltip
							label="Suggested Products"
							aria-label="Suggested Products"
							openDelay={500}
							bg="gray.500"
							borderRadius="lg"
						>
							<IconButton
								variant="text_admin"
								fontSize="2xl"
								icon={<MdAddLink />}
								color={hasSuggestedProducts ? "green" : ""}
								onClick={(e) => {
									e.stopPropagation();
									openAddSuggestedProducts();
								}}
							></IconButton>
						</Tooltip>
						{
							product?.images && product?.images.length > 0 &&
							<Tooltip
								label="Reorder Images"
								aria-label="Reorder Images"
								openDelay={500}
								bg="gray.500"
								borderRadius="lg"
							>
								<IconButton
									variant="text_admin"
									fontSize="2xl"
									icon={<FiList />}
									onClick={(e) => {
										e.stopPropagation();
										openReorderProductImages();
									}}
								></IconButton>
							</Tooltip>
						}
					</TableCell>
				</ComplexRowContentContainer>
			</LoadingErrorDataWrapper>
			<Collapse in={showVariations} animateOpacity>
				<VariationsDisplay
					variations={variations}
					variants={product.variants}
					updateVariants={props.updateVariants}
					updateVariations={updateVariations}
				/>
			</Collapse>
			<UpdateImages
				isOpen={showUpdateImages}
				onClose={closeShowUpdateImages}
				product={product}
				refetch={props.refetch}
			/>
			<UpdateProduct
				isOpen={showUpdateProduct}
				onClose={closeShowUpdateProduct}
				product={product}
				refetch={props.refetch}
			/>
			<DeleteProduct
				isOpen={showDeleteProduct}
				onClose={closeDeleteProduct}
				product={product}
				refetch={props.refetch}
			/>
			<AddSuggestedProducts
				isOpen={showAddSuggestedProducts}
				onClose={closeAddSuggestedProducts}
				product={product}
				refetch={props.refetch}
				updateSimilarProducts={updateSimilarProducts}
			/>
			{
				showReorderProductImages && 
				<ReorderProductImagesModal
					isOpen={showReorderProductImages}
					onClose={closeReorderProductImages}
					product={product}
					refetch={props.refetch}
				/>
			}
		</ComplexRowContainer>
	);
	
};

export default ProductRow;
