import { Flex, Box, Text, Image, Heading } from "@chakra-ui/react";
import OldTaylor from "./OldTaylor.jpg";
import TaylorSign from "./TaylorSign.jpg";
import Badges from "./AFA/Badges";

const OurStory = () => (
	<Box
		as="section"
		id="our-story"
		py={{
			base: 6,
			md: 28,
		}}
		px={{
			base: 4,
			md: 6,
		}}
		mx="auto"
	>
		<Flex 
			flexDirection={["column", "column", "row"]} 
			align="center"
			justify="space-between"
		>
			<Box 
				flex="1" 
				mr={[0, 0, 3]}
				mb={[4, 4, 0]}
			>
				<Heading
					as="h2"
					fontSize={{ base: "3xl", md: "5xl" }}
					fontStyle="italic"
					textAlign={{
						base: "center",
						md: "left",
					}}
					px={{
						base: 0,
						md: 10,
					}}
					mb={{
						base: 5,
						md: 10,
					}}
					fontWeight="bold"
				>
					About Us
				</Heading>
				<Text
					fontSize={{ base: "lg", md: "2xl" }}
					fontWeight="medium"
					textAlign={{ base: "center", md: "left" }}
					mx={{
						base: 0,
						md: 10,
					}}
					lineHeight={{ md: "tall" }}
				>
                    In 1959, Taylor Fence began serving the public with quality fence installations. From humble beginnings through today, our mission is a spirited disciplined approach to provide materials, and services to the best practice of the trade.
				</Text>
			</Box>
			<Box 
				flex="1"
				maxW="600px"
				p={7}
				mx={12}
			>
				<Image 
					src={OldTaylor}
					alt="Taylor Fence Company in 1959"
					borderRadius={10}
					boxShadow="dark-lg"
				/>
			</Box>
		</Flex>
		<Flex 
			flexDirection={["column", "column", "row-reverse"]} 
			align="center" 
			justify="space-between"
		>
			<Box 
				flex="1" 
				ml={[0, 0, 3]} 
				mb={[4, 4, 12]}
			>
				<Text
					fontSize={{ base: "lg", md: "2xl" }}
					fontWeight="medium"
					textAlign={{ base: "center", md: "left" }}
					mx={{
						base: 0,
						md: 10,
					}}
					lineHeight={{ md: "tall" }}
				>
					This has guided Taylor Fence in evolving to be New Jersey's leading name in the industry. We are ready to assist you in satisfying your fence needs. See you soon!
				</Text>
			</Box>
			<Box 
				flex="1" 
				maxW="600px"
				p={7}
				mx={12}
			>
				<Image 
					src={TaylorSign} 
					alt="Taylor Fence Custom Company Sign"
					borderRadius={10}
					boxShadow="dark-lg"
				/>
			</Box>
		</Flex>
		<Badges />
	</Box>
);

export default OurStory;

