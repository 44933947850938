/**
 * @file useCreateCustomer.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "cache/Customer/mutations"; // Update the path as per your project structure

/**
 * @hook useCreateCustomer
 *
 * @description
 * Custom hook that handles the functionality to create a new customer.
 * This hook can be used wherever the application needs to create a new customer.
 *
 * @returns
 * - createCustomer: Function to execute the create customer mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCreateCustomer = () => {

	const [createCustomerMutation, { loading, error }] = useMutation(CREATE_CUSTOMER);

	/**
	 * Custom mutation to create a new customer.
	 *
	 * @param {Object} createCustomerInput - The input object for creating a customer.
	 * @property {String} createCustomerInput.name - The name / company name of the customer.
	 * @property {String} createCustomerInput.email - The email of the customer.
	 * @property {String} createCustomerInput.phone - The phone number of the customer.
	 * @property {Object} createCustomerInput.address - The address of the customer.
	 * @property {String} createCustomerInput.address.line1 - The first line of the address.
	 * @property {String} createCustomerInput.address.line2 - The second line of the address.
	 * @property {String} createCustomerInput.address.city - The city of the address.
	 * @property {String} createCustomerInput.address.state - The state of the address.
	 * @property {String} createCustomerInput.address.zip - The zip code of the address.
	 * @property {String} createCustomerInput.address.country - The country of the address.
	 * @returns {Object} - An object containing the newly created customer object or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const createCustomer = async (createCustomerInput) => {
		try {
			const { data } = await createCustomerMutation({
				variables: {
					input: createCustomerInput
				}
			});
			if (error) {
				throw new Error(error);
			}
			return { data: data.createCustomer };
		} catch (error) {
			return { error };
		}
	};

	return { createCustomer, loading, error };
};

export default useCreateCustomer;
