/**
 * @file
 * This is the main App component of the application.
 *
 * @summary Main application component.
 * 
 * @author Webify Services, LTD.
 */

import "./App.scss";

import { PageChangeProvider } from "contexts/PageChangeContext";
import { ScrollProvider } from "contexts/ScrollContext";
import { ScaleProvider } from "contexts/ScaleContext";

import ControlPanel from "components/ControlPanel";

/**
 * App component.
 * This component wraps the whole application and provides the necessary context providers.
 *
 * @returns {HTMLElement} A div element that wraps the main application.
 */
const App = () => {
	return (
		<div className="App">
			<ScrollProvider>
				<PageChangeProvider>
					<ScaleProvider>
						<ControlPanel />
					</ScaleProvider>
				</PageChangeProvider>
			</ScrollProvider>
		</div>
	);
};

export default App;

