/**
 * @file CreateReview.js
 */
import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Textarea,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useCreateReview from "hooks/Review/mutations/useCreateReview";

/**
 * @component CreateReview
 * 
 * @description Component that displays the modal for creating a new review.
 * 
 * @returns
 */
const CreateReview = ({ isOpen, onClose, reviewsRefetch }) => {

	const [input, setInput] = useState({
		author: "",
		reviewDate: "",
		title: "",
		comment: "",
		rating: 5.0,
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
		validateInput();
	};

	const { createReview } = useCreateReview();

	const validateInput = () => {
		if (input.author === "" || input.reviewDate === "" || input.title === "" || input.comment === "" || input.rating === "") {
			setError("Please fill out all fields.");
			return false;
		}
		try{
			parseFloat(input.rating);
		}
		catch{
			setError("Rating must be a number.");
			return false;
		}
		setError(null);
		return true;
	};

	const handleSubmit = async () => {
		if (!validateInput()) return;
		setLoading(true);
		let { error } = await createReview({
			...input,
			rating: parseFloat(input.rating),
		});
		if (error) {
			setError(error.message);
		} else {
			await reviewsRefetch();
			onClose();
		}
		setLoading(false);
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose}
			size='3xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Review</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing={4}>
						{error && <ErrorMsg error={error} />}
						<FormControl isRequired>
							<FormLabel>Author</FormLabel>
							<Input
								name="author"
								placeholder="Author"
								value={input.author}
								onChange={handleUpdateInput}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Review Date</FormLabel>
							<Input
								name="reviewDate"
								placeholder="Review Date"
								value={input.reviewDate}
								onChange={handleUpdateInput}
								type="date"
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Title</FormLabel>
							<Input
								name="title"
								placeholder="Title"
								value={input.title}
								onChange={handleUpdateInput}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Comment</FormLabel>
							<Textarea
								name="comment"
								placeholder="Comment"
								value={input.comment}
								onChange={handleUpdateInput}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Rating</FormLabel>
							<Input
								name="rating"
								placeholder="Rating"
								value={input.rating}
								onChange={handleUpdateInput}
								type="number"
								min={0}
								max={5}
								step={0.1}		
							/>
						</FormControl>
						<Button
							variant="primary"
							isLoading={loading}
							loadingText="Creating Review"
							onClick={handleSubmit}
						>
							Create Review
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CreateReview;

