import { Box, Container, SimpleGrid, Image, Heading, Link } from "@chakra-ui/react";
import { motion } from "framer-motion";
import AFA from "./AFA.png";
import GSFA from "./GSFA.png";

const MotionBox = motion(Box);

const stats = [
	{
		label: "AFA",
		url: "https://www.americanfenceassociation.com/",
		img: AFA,
	},
	{
		label: "GSFA",
		url: "https://gsafa.org/",
		img: GSFA,
	},
];

const Badges = () => (
	<Box 
		as="section" 
		py={{ base: "4", md: "8" }} 
		mb={{
			base: "4",
			md: "8",
		}}
    
	>
		<Heading 
			fontStyle="italic"
			mt={{
				base: "4",
				md: "8",
			}}
		>
      Proud Members
		</Heading>
		<Container 
			maxW={{
				base: "container.md",
				md: "container.lg",
			}}
			mb={{
				base: "6",
				md: "8",
			}}
		>
			<SimpleGrid 
				columns={{ base: 1, md: 2 }} 
				gap={{ base: "2", md: "100" }}
			>
				{stats.map(({ label, url, img }) => (
					<MotionBox
						key={label}
						textAlign="center"
						whileHover={{
							scale: 1.05,
							cursor: "pointer",
						}}
						transition={{ duration: 0.3 }}
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -20 }}
					>
						<Link href={url} isExternal>
							<Image 
								src={img} 
								alt={label} 
								mb="3" 
								maxW={{
									base: "auto",
									md: "300",
								}} 
								h="auto" 
								mx="auto" 
							/>
						</Link>
					</MotionBox>
				))}
			</SimpleGrid>
		</Container>
	</Box>
);

export default Badges;
