import { 
	Box, 
	Heading, 
	Center, 
} from "@chakra-ui/react";

const Welcome = () => {

	return (
		<Center
			className="welcome"
			bgGradient="linear(to-t, #FFFFFF, #FFFDFD, #FFFBFB, #FFFAFA)"
			w="100%"
			h="100vh"
			color="white"
			flexDirection="column"
			p={{
				base: 4,
				md: 8,
			}}
		>
			<Box
				width={{
					base: "100%",
					md: "70%",
				}}
				minH={{
					base: "100%",
					md: "30%",
				}}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				borderRadius="2xl"
				borderWidth="1px"
				borderColor="gray.200"
				boxShadow="md"
				bgGradient="linear(to-t, #F03F3F, #F24747, #F34F4F, #F55757, #F65F5F, #F86666)"
				py={{
					base: 4,
					md: 8,
				}}
				px={{
					base: 4,
					md: 8,	
				}}
			>
				<Heading 
					as="h1" 
					size={{
						base: "xl",
						md: "3xl",
					}}
					mb={4}
					style={{
						textShadow: "0px 0px 3px rgba(0,0,0,0.3)", 
						color: "white", 
					}}
					fontWeight="bold"
				>
					Welcome!
				</Heading>
				<Heading 
					as="h2" 
					size={{
						base: "md",
						md: "xl",
					}}
					style={{
						textShadow: "0px 0px 3px rgba(0,0,0,0.3)",
						color: "white",
					}}
					mt={4}
					fontWeight="normal"
				>
					Click a tab on the left to get started
				</Heading>
			</Box>
		</Center>
	);
};

export default Welcome;
