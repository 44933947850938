import { 
	Center, 
	Container, 
	SimpleGrid, 
	Stack, 
	Link, 
	Heading, 
	Button, 
	useDisclosure,
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
} from "@chakra-ui/react";

import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";



import Jerith_Logo from "../../../../assets/Home/Brands/Jerith_Logo.png";
import DnDHardware_Logo from "../../../../assets/Home/Brands/DnDHardware_Logo.png";
import Bufftech_Logo from "../../../../assets/Home/Brands/Bufftech_Logo.png";


const logos = [
	{ src: Jerith_Logo, href: "https://www.jerith.com/" },
	{ src: DnDHardware_Logo, href: "https://www.dndfencing.com/" },
	{ src: Bufftech_Logo, href: "https://www.certainteed.com/fence/" },
];
  

const MotionImage = motion.img;
const MotionHeading = motion(Heading);

const container = {
	hidden: { opacity: 1, scale: 0 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.2
		}
	}
};

const item = {
	hidden: { y: 20, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1
	}
};

const Partners = () => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentLogo, setCurrentLogo] = useState(null);

	const handleLogoClick = (logo) => {
		setCurrentLogo(logo);
		onOpen();
	};

	return (
		<Container
			py={{
				base: "12",
				md: "32",
			}}
			maxW="container.xl"
		>
			<Stack spacing="8">
				<MotionHeading 
					spacing="8" 
					textAlign="center" 
					color="#1B1B1B"
					variants={container}
					initial="hidden"
					animate="visible"
				>
            Quality Fence Brands for Every Style and Budget
				</MotionHeading>
				<Center>
					<SimpleGrid
						gap={{
							base: "8",
							md: "6",
						}}
						columns={{
							base: 1,
							sm: 2,
							md: 3,
						}}
					>
						{logos.map((logo, index) => (
							<Center
								key={index}
								bg="bg-surface"
								py={{
									base: "4",
									md: "4",
								}}
								borderRadius="lg"
								onClick={() => handleLogoClick(logo)} 
								boxShadow={{
									base: "lg",
									md: "sm",
								}}
								borderWidth={{
									base: "1px",
									md: "0",
								}}
								borderColor={{
									base: "gray.400",
									md: "0",
								}}
							>
								<MotionImage
									src={logo.src}
									alt="logo"
									variants={item}
									whileHover={{ 
										scale: 1.1,
										cursor: "pointer", 
									}}
									initial="hidden"
									animate="visible"
								/>
							</Center>
						))}
					</SimpleGrid>
				</Center>
				<Modal 
					isOpen={isOpen} 
					onClose={onClose}
					size="xl"
					isCentered
				>
					<ModalOverlay />
					<ModalContent>
						<ModalCloseButton />
						<ModalBody>
							<Center>
								<Link href={currentLogo?.href} isExternal>
									<MotionImage
										src={currentLogo?.src}
										alt="logo"
										variants={item}
										whileHover={{ 
											scale: 1.1,
											transition: {
												duration: 0.5
											}
										}}
										initial="hidden"
										animate="visible"
									/>
								</Link>
							</Center>
						</ModalBody>
						<ModalFooter
							display="flex"
							justifyContent="center"
							alignItems="center"
							mx={2}
						>
							<Button 
								bg="#EC1C24"
								color="white"
								w="40%"
								_hover={{
									bg: "white",
									color: "#EC1C24",
									border: "1px solid gray.200",
									transform: "translateY(-2px)",
									boxShadow: "lg",
								}}
								mx={2}
							>
                  Available Products
							</Button>
							<Button 
								bg="#EC1C24"
								color="white"
								w="40%"
								_hover={{
									bg: "white",
									color: "#EC1C24",
									border: "1px solid gray.200",
									transform: "translateY(-2px)",
									boxShadow: "lg",
								}}
								mx={2}
							>
                  View Brochures
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<Center>
					<Button
						variant="primary"
						w="40%"
						onClick={() => navigate("/resources")}
					>
            View All Brands
					</Button>
				</Center>
			</Stack>
		</Container>
	);
};

export default Partners;

