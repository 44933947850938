import { Box, Heading, SimpleGrid, Text, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
// import { brochures } from "./data";

import useGetAllBrochures from "hooks/Brochure/queries/useGetAllBrochures";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

const MotionBox = motion(Box);

const BrochureSelect = () => {

	const { loading, error, data: brochures } = useGetAllBrochures();

	return (
		<Box 
			mt={{
				base: "0",
				md: "2rem",
			}} 
			mb={{
				base: "2rem",
				md: "10rem",
			}}
			width="100%" 
			maxWidth="1200px"
			p={{
				base: "2",
			}}
		>
			<Heading
				as="h1"
				mb={{
					base: "2rem",
					md: "4rem",
				}}
				mt={{
					base: "1rem",
					md: "2rem",
				}}
				textAlign="center"
				fontSize={{ base: "2xl", md: "3xl", lg: "3xl" }}
				fontWeight={{
					base: "semibold",
					lg: "bold",
				}}
				fontFamily="heading"
			>
        		Explore Our Comprehensive Fence Product Brochures
			</Heading>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={brochures}
			>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: "8", md: "12" }}>
					{brochures?.map((brochure) => (
						<MotionBox
							key={brochure._id}
							p={4}
							mb={6}
							boxShadow="lg"
							borderRadius="md"
							borderColor="gray.400"
							borderWidth="1px"
							_hover={{
								textDecoration: "none",
								transition: "all 0.2s ease-in-out",
								transform: "scale(1.025) translateY(-2px)",
								cursor: "pointer",
							}}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.8 }}
							as="a" // Make it a link
							href={brochure.url} // Set the brochure URL
							target="_blank" // Open in a new tab
							rel="noopener noreferrer" // Security measure
						>
							<Image src={brochure.image} alt={brochure.name} mb={4} borderRadius="md" w="100%" h="100%" />
							<Text>{brochure.name}</Text>
						</MotionBox>
					))}
				</SimpleGrid>
			</LoadingErrorDataWrapper>
		</Box>
	);
};

export default BrochureSelect;
