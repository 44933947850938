import { Box, Flex, Container, Stack, Text, Icon, Square } from "@chakra-ui/react";
import guideData from "./guideData";  // Import your guideData

export const IconKey = () => (
	<Box
		as="section"
	>
		<Container maxW="8xl">
			<Box
				bg="bg.surface"
				boxShadow="sm"
				borderRadius="lg"
			>
				<Stack spacing="5">
					{guideData.map((category, index) => (
						<Stack 
							spacing="5" 
							key={index}
							p={{
								base: "0",
								md: "4",
							}}
						>
							<Text textStyle="lg" fontWeight="bold">
								{category.category}
							</Text>
							{category.subcategories.map((sub, subIndex) => (
								<Box
									borderWidth={{
										base: "0",
										md: "1px",
									}}
									p={{
										base: "0",
										md: "4",
									}}
									borderRadius="lg"
									boxShadow="md"
									key={subIndex}
								>
									<Flex justify="space-between" align="center">
										<Text textStyle="lg" fontWeight="medium">
											{sub.iconName}
										</Text>
										<Box flex="1" ml="4">
											<Text color="emphasized" fontWeight="medium">
												 {sub.description}
											</Text>
											<Text textStyle="sm" fontSize="sm" color="fg.muted">
												{sub.references}
											</Text>
										</Box>
										<Square size="10" bg="bg.subtle" borderRadius="lg">
											<Icon as={sub.icon} boxSize="5" />
										</Square>
									</Flex>
								</Box>
							))}
						</Stack>
					))}
				</Stack>
			</Box>
		</Container>
	</Box>
);
