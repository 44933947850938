/**
 * @file Brochures.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
	Stack,
} from "@chakra-ui/react";

import BrochuresTable from "./Table";

import CreateBrochure from "./modals/CreateBrochure";

import ReorderItemsButton from "../../components/ReorderItemsButton";

import useReorderBrochures from "hooks/Brochure/mutations/useReorderBrochures";
import useGetAllBrochures from "hooks/Brochure/queries/useGetAllBrochures";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { FooterContainer } from "../../components/TableContainer";


const Brochures = () => {

	const { loading, error, data, refetch: brochuresRefetch } = useGetAllBrochures();

	const [brochures, setBrochures] = useState(data);

	useEffect(() => {
		if(data) {
			setBrochures(data);
		}
	}, [data]);

	const [showCreateBrochure, setShowCreateBrochure] = useState(false);
	const openCreateBrochureModal = () => setShowCreateBrochure(true);
	const closeCreateBrochureModal = () => setShowCreateBrochure(false);

	const sort = (field, order) => {
		let sortedBrochures = [...brochures];
		sortedBrochures.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setBrochures(sortedBrochures);
	};

	const search = (field, searchInput) => {
		let searchedBrochures = [...data];
		searchedBrochures = searchedBrochures.filter((brochure) => {
			let fieldValue = brochure[field];
			return fieldValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setBrochures(searchedBrochures);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setBrochures(data);
		setClearToggle(!clearToggle);
	};

	const [currentItems, setCurrentItems] = useState([]);

	const { reorderBrochures } = useReorderBrochures();

	

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={brochures}
			>
				<BrochuresTable
					brochures={currentItems}
					brochuresRefetch={brochuresRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={brochures}
						setItems={setCurrentItems}
					/>
					{
						brochures?.length !== data?.length &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Stack
						direction={{
							base: "column",
							md: "row"
						}}
						spacing="1rem"
					>
						<Button
							variant="primary"
							onClick={openCreateBrochureModal}
						>
							Create Brochure
						</Button>
						<ReorderItemsButton
							items={brochures}
							reorderMutation={reorderBrochures}
							refetch={brochuresRefetch}
						/>
					</Stack>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreateBrochure
				isOpen={showCreateBrochure}
				onClose={closeCreateBrochureModal}
				refetch={brochuresRefetch}
			/>
		</Flex>
	);

};

export default Brochures;