/**
 * @file UpdatePartner.js
 * 
 * @description This module exports the UpdatePartner component.
 * 
 * @exports UpdatePartner
 */
import React, { useState, useEffect } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useToast,
	Select,
	Grid,
	Image,
	Box,
} from "@chakra-ui/react";

import useUpdatePartner from "hooks/Partner/mutations/useUpdatePartner";
import useUploadImage from "hooks/useUploadImage";

import useGetAllBrochures from "hooks/Brochure/queries/useGetAllBrochures";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";
import ErrorMsg from "Blueprints/ErrorMsg";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

/**
 * @function UpdatePartner
 * 
 * @description A modal component that allows the user to update a partner.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Determines whether the modal is open or not.
 * @param {function} props.onClose - The function to close the modal.
 * @param {function} props.refetch - The function to refetch the data.
 * @param {Object} props.partner - The data of the partner being updated.
 * 
 * @returns {JSX.Element} The UpdatePartner component.
 */
const UpdatePartner = ({ isOpen, onClose, refetch, partner }) => {

	const { loading: loadingBrochures, error: errorBrochures, data: brochures } = useGetAllBrochures();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [name, setName] = useState("");
	const [image, setImage] = useState([]);
	const [websiteUrl, setWebsiteUrl] = useState("");
	const [selectedBrochures, setSelectedBrochures] = useState([]);

	const [imageFiles, setImageFiles] = useState([]);
	const [imageUrls, setImageUrls] = useState(partner.images);

	const handleDeleteImage = (imageToDelete) => {
		setImageUrls(
			imageUrls.filter(image => image !== imageToDelete)
		);
	};

	const handleSetImage = (imageFiles) => {
		setImage(imageFiles);
	};

	const handleAddBrochure = (e) => {
		//Add the current selected brochure to the selectedBrochures array
		const brochure = brochures.find(brochure => brochure._id === e.target.value);
		if(!brochure) return;
		//If brochure is already selected, return
		if(selectedBrochures.find(selectedBrochure => selectedBrochure._id === brochure._id)) return;
		setSelectedBrochures(
			[...selectedBrochures, brochure]
		);
	};

	const handleRemoveBrochure = (removedBrochure) => {
		//Remove the current selected brochure from the selectedBrochures array
		setSelectedBrochures(
			selectedBrochures.filter(brochure => brochure._id !== removedBrochure._id)
		);
	};

	const handleClose = () => {
		setName("");
		setImage([]);
		setWebsiteUrl("");
		setSelectedBrochures([]);
		setError(null);
		setLoading(false);
		onClose();
	};

	useEffect(() => {
		if (partner && isOpen) {
			setName(partner.name);
			setImage([]);
			setWebsiteUrl(partner.website);
			setSelectedBrochures(partner.brochures || []);
			setImageFiles([]);
			setImageUrls(partner.images);
		}
	}, [partner, isOpen]);


	const uploadImage = useUploadImage();
	const { updatePartner } = useUpdatePartner();

	const toast = useToast();

	const handleUpdate = async (e) => {  // Renamed from handleCreate to handleUpdate
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_url;
		if (image.length) {
			try {
				image_url = await uploadImage(image[0]);
			} catch (error) {
				setLoading(false);
				setError("Unexpected error while uploading image. Please try again.");
				return;
			}
		} else {
			image_url = partner.image;
		}

		let image_urls = [];
		try {
			for(let i = 0; i < imageFiles.length; i++){
				const image_url = await uploadImage(imageFiles[i]);
				image_urls.push(image_url);
			}
		}
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading images. Please try again.");
			return;
		}

		const selectedBrochureIds = selectedBrochures.map(brochure => brochure._id);

		const input = {
			name,
			image: image_url,
			images: [...imageUrls, ...image_urls],
			website: websiteUrl,
			brochures: selectedBrochureIds
		};

		const { data, error } = await updatePartner(partner._id, input);
		if (error) {
			setLoading(false);
			setError(error.message);
			return;
		}
		if (data) {
			setLoading(false);
			toast({
				title: "Partner updated.",
				description: "Partner successfully updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			handleClose();
			refetch();
			return;
		}
		setError("Unexpected error while updating partner. Please try again.");
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!name) {
			errors.name = "Name is required.";
		}
		if (!websiteUrl) {
			errors.websiteUrl = "Website URL is required.";
		}
		setFormErrors(errors);
		//Return true if errors has no keys
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Partner</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="image" 
							isRequired
							isInvalid={formErrors.image}
						>
							<FormLabel>Logo</FormLabel>
							<ImagesDropzone
								imageFiles={image}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						<FormControl 
							my={4}
						>
							<FormLabel>Images</FormLabel>
							<ImagesDropzone 
								imageFiles={imageFiles}
								setImageFiles={setImageFiles}
								allowMultiple={true}
							/>
						</FormControl>
						<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
							{imageUrls.map((imageURL, i) => (
								<Box key={i} position="relative">
									<Image src={imageURL} alt={`${partner._id}_image_${i}`} objectFit="cover" borderRadius="md"/>
									<Button
										position="absolute"
										right="5px"
										top="5px"
										size="sm"
										variant="delete"
										onClick={() => handleDeleteImage(imageURL)}
									>
										Delete
									</Button>
								</Box>
							))}
						</Grid>
						<FormControl 
							id="websiteUrl" 
							isRequired
							isInvalid={formErrors.websiteUrl}
						>
							<FormLabel>Website URL</FormLabel>
							<Input
								type="text"
								value={websiteUrl}
								onChange={(e) => setWebsiteUrl(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.websiteUrl}</FormErrorMessage>
						</FormControl>
						<FormControl>
							<FormLabel>Associated Brochures</FormLabel>
							<LoadingErrorDataWrapper
								loading={loadingBrochures}
								error={errorBrochures?.message}
								data={brochures}
							>
								<Select
									placeholder="Select brochures"
									onChange={handleAddBrochure}
									value={"Select brochures"}
								>
									{brochures?.map(brochure => (
										<option key={`${partner._id}dropdown${brochure._id}`} value={brochure._id}>
											{brochure.name}
										</option>
									))}
								</Select>
							</LoadingErrorDataWrapper>
							<Stack
								direction="row"
								flexWrap="wrap"
								justify="flex-start"
								align="center"
								spacing={4}
								mt={2}
							>
								{selectedBrochures?.map(brochure => (
									<Button
										key={`${partner._id}selected${brochure._id}`}
										variant="outline"
										colorScheme="red"
										onClick={() => handleRemoveBrochure(brochure)}
									>
										{brochure.name}
									</Button>
								))}
							</Stack>
						</FormControl>
						{
							error &&
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							onClick={handleUpdate}
							variant="primary"
							isLoading={loading}
							loadingText="Creating..."
						>
							Update
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default UpdatePartner;
