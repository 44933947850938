/**
 * @file VariationsDisplay.js
 */

import React, { useState, useEffect } from "react";

import {
	Box,
	FormLabel,
	Button,
	Stack,
	Divider,
} from "@chakra-ui/react";

import VariationDisplay from "./VariationDisplay";

import VariantDisplay from "./VariantDisplay";

import AddVariation from "../Modals/AddVariation";
/**
 * @component VariationsDisplay
 */
const VariationsDisplay = (props) => {

	let variations_temp = new Array();
	for(let i = 0; i < props.variations?.length; i++){
		variations_temp.push(
			{
				sku: props.variations[i].sku,
				isDefaultVariation: props.variations[i].isDefaultVariation,
				price: props.variations[i].price,
				quantity: props.variations[i].quantity,
			}
		);
	}

	const [variations, setVariations] = useState(variations_temp);
	
	useEffect(() => {
		if(props.variations){
			let variations_temp = new Array();
			for(let i = 0; i < props.variations.length; i++){
				variations_temp.push(
					{
						sku: props.variations[i].sku,
						isDefaultVariation: props.variations[i].isDefaultVariation,
						price: props.variations[i].price,
						quantity: props.variations[i].quantity,
					}
				);
			}
			setVariations(variations_temp);
		}
	}, [props.variations]);


	const [showAddVariation, setShowAddVariation] = useState(false);
	const openAddVariation = () => {setShowAddVariation(true);};
	const closeAddVariation = () => {setShowAddVariation(false);};

	const handleAddVariation = async (input) => {
		//Add new variation to variations array
		const newVariations = [...variations];
		newVariations.push(input);
		//Update variations array in parent component
		await props.updateVariations(newVariations);
	};

	const handleUpdateVariation = async (input, index) => {
		//First find the corresponding variation
		const variation = variations[index];
		//Check which fields have changed
		let update = {};
		if(input.sku !== variation.sku){
			update.sku = input.sku;
		}
		if(input.isDefaultVariation !== variation.isDefaultVariation){
			update.isDefaultVariation = input.isDefaultVariation;
		}
		if(input.price !== variation.price){
			update.price = input.price;
		}
		
		//If no fields have changed, return
		if(Object.keys(update).length === 0){
			return;
		}

		//Make new variations array with updated variation
		const newVariations = [...variations];
		if(update.isDefaultVariation){
			for(let i = 0; i < newVariations.length; i++){
				if(i !== index){
					newVariations[i].isDefaultVariation = false;
				}
			}
		};
		newVariations[index] = {
			...variation,
			...update,
		};

		await props.updateVariations(newVariations);
	};

	const handleDeleteVariation = async (index) => {
		//Make new variations array without deleted variation
		const newVariations = [...variations];
		newVariations.splice(index, 1);
		//Update variations array in parent component
		await props.updateVariations(newVariations);
	};


	// const handleUpdateVariationOptions = async (variationOptions) => {
	// 	//Check to see if there are any changes between the old and new variation options
	// 	let newOptions = new Array();
	// 	let deletedOptions = new Array();
	// 	for(let i = 0; i < variationOptions.length; i++){
	// 		if(!props.variationOptions.includes(variationOptions[i])){
	// 			newOptions.push(variationOptions[i]);
	// 		}
	// 	}
	// 	for(let i = 0; i < props.variationOptions.length; i++){
	// 		if(!variationOptions.includes(props.variationOptions[i])){
	// 			deletedOptions.push(props.variationOptions[i]);
	// 		}
	// 	}
	// 	//If there are no changes, return
	// 	if(newOptions.length === 0 && deletedOptions.length === 0){
	// 		return;
	// 	}
	// 	//For every option in new options, add that field to each variations options
	// 	let newVariations = [...variations];
	// 	for(let i = 0; i < newOptions.length; i++){
	// 		for(let j = 0; j < newVariations.length; j++){
	// 			let updatedOptions = {...newVariations[j].options};
	// 			updatedOptions[newOptions[i]] = "";
	// 			newVariations[j].options = updatedOptions;
	// 		}
	// 	}
	// 	//For every option in deleted options, delete that field from each variations options
	// 	for(let i = 0; i < deletedOptions.length; i++){
	// 		for(let j = 0; j < newVariations.length; j++){
	// 			let updatedOptions = {...newVariations[j].options};
	// 			delete updatedOptions[deletedOptions[i]];
	// 			newVariations[j].options = updatedOptions;
	// 		}
	// 	}
	// 	//Update variations array in parent component
	// 	await props.updateVariationOptions(variationOptions, newVariations);
	// };

	return(
		<>
			<AddVariation
				isOpen={showAddVariation}
				onClose={closeAddVariation}
				onAdd={handleAddVariation}
			/>
			<Box
				as="section"
				pt={{
					base: "4",
					md: "8",
				}}
				pb={{
					base: "2",
					md: "5",
				}}
				px={10}
				border="1px solid red"
				borderRadius="lg"
				borderWidth="1px"
				boxShadow="lg"
				borderColor="#E2E8F0"
			>
				<Stack
					display="flex"
					direction={{
						base: "column",
						md: "row",
					}}
					justifyContent="space-between"
					alignItems="center"
					spacing={4}
				>
					<FormLabel
						fontSize="2xl"
						fontWeight="medium"
						mb={2}
						alignSelf="center"
					>
						Variants
					</FormLabel>
				</Stack>
				<Stack
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
					spacing={4}
				>
					<VariantDisplay variants={props.variants} />
				</Stack>
				<Divider  my={4}/>
				<FormLabel
					textStyle="md"
					fontWeight="medium"
					mb={2}
					alignSelf="center"
				>
                    Variations
				</FormLabel>
				{
					variations.map((variation, index) => (
						<VariationDisplay
							variation={variation}
							index={index}
							key={index}
							onSave={handleUpdateVariation}
							onDelete={handleDeleteVariation}
						/>
					))
				}
				<Button
					onClick={openAddVariation}
					variant="primary2"
					mt={4}
				>
					Add Variation
				</Button>
				<AddVariation
					isOpen={showAddVariation}
					onClose={closeAddVariation}
					onAdd={handleAddVariation}
				/>
			</Box>
		</>
	);
};

export default VariationsDisplay;