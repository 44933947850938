import React from "react";
import { Box, Button, Heading, Text, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import FeaturedDealCard from "./FeatureDealCard";
import useGetFeaturedDeals from "hooks/FeaturedDeal/queries/useGetAllFeaturedDeals";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Carousel from "Blueprints/Carousel/Carousel";
import InfoMsg from "Blueprints/InfoMsg";


const FeatureDeals = () => {
	const { loading, error, data: featuredDeals } = useGetFeaturedDeals();

	return (
		<Box 
			bg="white"
			w="90%"
			px={{
				base: "1rem",
				sm: "1rem",
				md: "4",
			}}
			p={10}
			mt="8rem"
			mb="8rem"
			borderRadius="lg"
			boxShadow="xl"
			borderWidth="1px"
			borderColor="gray.300"
			overflow="hidden"
		>
			<Flex 
				flexDirection={{ base: "column", lg: "row" }} 
				alignItems="center" 
				justifyContent="space-between"
			>
				<Box 
					flex="1" 
					mb={{ base: 6, md: 0 }}
					zIndex={1}
				>
					<Heading
					 	as={"h3"} 
						fontSize={{
							base: "xl",
							md: "3xl",
							lg: "4xl",
						}}
						fontWeight="bold" 
						mb={4}
					>
						Featured Deals
					</Heading>
					<Text 
						fontSize={{
							base: "lg",
							md: "xl",
						}}
						mt={4}
						mb={{
							base: 4,
							md: 8,
						}}
						noOfLines={3}
					>
						Your budget-friendly solution to a beautiful fence.
					</Text>
					<Button 
						as={Link}
						variant="primary2"
						to="/showroom" 
						size="lg"
					>
						View Now
					</Button>
				</Box>
				<Flex
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
					flex="1"
					px="1rem"
					zIndex={0}
					w="100%"
				>
					<LoadingErrorDataWrapper 
						loading={loading} 
						error={error?.message} 
						data={featuredDeals}
					>
						{
							featuredDeals?.length >= 1 &&
							<Carousel>
								{featuredDeals?.map((deal) => (
									<Box 
										key={deal._id}
										display="flex"
										w="100%"
										h="100%"
										px={{
											base: "1rem",
											sm: "1rem",
											md: "1.45rem",
										}}
										py={{
											base: "1rem",
											sm: "1rem",
											md: "2rem",
										}}
									>
										<FeaturedDealCard deal={deal} />
									</Box>
								))}
							</Carousel>
						}	
						{
							featuredDeals?.length <= 0 &&
							<InfoMsg>
								There are currently no featured deals, please check back later.
							</InfoMsg>
						}
					</LoadingErrorDataWrapper>
				</Flex>
			</Flex>
		</Box>
	);
};

export default FeatureDeals;
