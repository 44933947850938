/**
 * @file useUpdateFeaturedDealImages.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_FEATURED_DEAL_IMAGES } from "cache/FeaturedDeal/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateFeaturedDealImages
 *
 * @description
 * Custom hook that handles the functionality to update a featured deal's images.
 * This hook can be used wherever the application needs to update the images of an existing featured deal.
 *
 * @returns
 * - updateFeaturedDealImages: Function to execute the update featured deal images mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateFeaturedDealImages = () => {

	const [updateFeaturedDealImagesMutation, { loading, error }] = useMutation(UPDATE_FEATURED_DEAL_IMAGES);

	/**
	 * Custom mutation to update a featured deal's images.
	 *
	 * @param {String} featuredDealId - The id of the featured deal.
	 * @param {Array} images - The images of the featured deal.
	 * @returns {Object} - An object containing a boolean result or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateFeaturedDealImages = async (featuredDealId, images) => {
		try {
			const { data } = await updateFeaturedDealImagesMutation({
				variables: {
					featuredDealId,
					images
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return the boolean result of the update operation
			return { success: data.updateFeaturedDealImages };
		} catch (error) {
			return { error };
		}
	};

	return { updateFeaturedDealImages, loading, error };
};

export default useUpdateFeaturedDealImages;
