/**
 * @file useCreateProduct.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT } from "cache/Product/mutations"; // Update the path as per your project structure

/**
 * @hook useCreateProduct
 *
 * @description
 * Custom hook that handles product creation functionality.
 *
 * @returns
 * - create function
 */
const useCreateProduct = () => {

	const [createProductMutation, { loading, error }] = useMutation(CREATE_PRODUCT);

	const create = async (input) => {

		try {
			const { data } = await createProductMutation({ 
				variables: { input: input }
			});
			if (error) {
				throw new Error(error);
			}
			let createProductData = data.createProduct;
			if (createProductData?.error) {
				throw new Error(createProductData.error);
			} else {
				return { data: createProductData };
			}
		} catch (error) {
			return { error };
		}

	};

	return { create, loading, error };

};

export default useCreateProduct;
