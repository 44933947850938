import React from "react";
import ModalImageCarousel from "../modals/Gallery/GalleryModal";
import WoodDisplay1 from "./assets/WoodDisplay1.JPG";
import WoodDisplay2 from "./assets/WoodDisplay2.JPG";
import WoodDisplay3 from "./assets/WoodDisplay3.JPG";
import WoodDisplay4 from "./assets/WoodDisplay4.JPG";
import WoodDisplay5 from "./assets/WoodDisplay5.JPG";
import WoodDisplay6 from "./assets/WoodDisplay6.JPG";
import WoodDisplay7 from "./assets/WoodDisplay7.JPG";
import WoodDisplay8 from "./assets/WoodDisplay8.JPG";
import WoodDisplay9 from "./assets/WoodDisplay9.JPG";
import WoodDisplay from "./assets/WoodDisplay.JPG";

const Gallery = () => {
	// Dummy images data
	const images = [
		{ src: WoodDisplay1, alt: "Image 1" },
		{ src: WoodDisplay2, alt: "Image 2" },
		{ src: WoodDisplay3, alt: "Image 3" },
		{ src: WoodDisplay4, alt: "Image 4" },
		{ src: WoodDisplay5, alt: "Image 5" },
		{ src: WoodDisplay6, alt: "Image 6" },
		{ src: WoodDisplay7, alt: "Image 7" },
		{ src: WoodDisplay8, alt: "Image 8" },
		{ src: WoodDisplay9, alt: "Image 9" },
		{ src: WoodDisplay, alt: "Image 0" },

		// Add more images as needed
	];

	return(
		<div className="gallery-wrapper">
			<h1>Gallery</h1>
			<ModalImageCarousel images={images} />
		</div>
	);
};

export default Gallery;
