
import React, { useState } from "react";

import { 
	FormControl, 
	FormLabel, 
	Input, 
	Textarea, 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalCloseButton, 
	ModalBody, 
	Stack,
	useToast,
	Heading,
	Divider,
	Select
} from "@chakra-ui/react";

import useSendContactFormEmail from "hooks/Contact/useSendContactFormEmail";
import ErrorMsg from "Blueprints/ErrorMsg";

const ContactFormModal = ({ isOpen, onClose }) => {

	const [first, setFirstName] = useState("");
	const [last, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [township, setTownship] = useState("");
	const [phone, setPhone] = useState("");
	const [feet, setFeet] = useState("");
	const [comments, setComments] = useState("");
	const [selectedFenceType, setSelectedFenceType] = useState("Aluminum");
	const [selectedServiceType, setSelectedServiceType] = useState("Installation");

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const fenceType = [
		{ value: "Aluminum", label: "Aluminum" }, // #0
		{ value: "Chainlink", label: "Chainlink" }, // #1
		{ value: "Wood", label: "Wood" }, // #2
		{ value: "Vinyl", label: "Vinyl" }, // #3
		{ value: "Other", label: "Other" }, // #4
	];

	const serviceType = [
		{ value: "Installation", label: "Installation" }, // #0
		{ value: "Repair", label: "Repair" }, // #1
		{ value: "Material Purchase", label: "Material Purchase" } // #2
	]; 

	const { sendContactEmail } = useSendContactFormEmail();

	const toast = useToast();

	// handle form submission here
	const handleSubmit = async (e) => {
		e.preventDefault();

		setErrorMsg(null);
		setLoading(true);

		let input = {
			subject: "New Detailed Contact Form Submission",
			formData: {
				firstName: first,
				lastName: last,
				email: email,
				township: township,
				phone: phone,
				fence_type: selectedFenceType,
				total_footage: feet,
				service: selectedServiceType,
				message: comments
			}
		};

		const { error, success } = await sendContactEmail(input);
		if(error){
			setErrorMsg(error.message);
			setLoading(false);
		} else if(success){
			setFirstName("");
			setLastName("");
			setEmail("");
			setTownship("");
			setPhone("");
			setFeet("");
			setComments("");
			setSelectedFenceType("Aluminum");
			setSelectedServiceType("Installation");
			setLoading(false);
			toast({
				title: "Success!",
				description: "Your message has been sent successfully! We'll get back to you as soon as possible.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size={{
			base: "xs",
			md: "md",
			lg: "4xl",
		}}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
						direction="column"
						as="form"
						onSubmit={handleSubmit}
					>
						{errorMsg && <ErrorMsg error={errorMsg}/>}
						<Heading as="h2" variant="main_heading" textAlign={{ base: "center", md: "center" }} mb={6}>
        				How can we help?
						</Heading>
						<Divider 
							mt={8} 
							mb={8} 
							borderColor="gray.400"
							w="100%"
							mx="auto"
						/>
						<FormControl 
							isRequired
							mt={5}
							mb={5}
						>
							<FormLabel>First Name</FormLabel>
							<Input 
								value={first} 
								onChange={e => setFirstName(e.target.value)} 
								placeholder="First Name" 
								boxShadow="md" 
								bg="white"
								borderWidth="1px"
								borderColor="gray.300"
							/>
						</FormControl>

						{/* Last Name */}
						<FormControl 
							isRequired
							mt={5}
							mb={5}
						>
							<FormLabel>Last Name</FormLabel>
							<Input 
								value={last} 
								onChange={e => setLastName(e.target.value)} 
								placeholder="Last Name" 
								boxShadow="md" 
								bg="white" 
								borderWidth="1px"
								borderColor="gray.300"
							/>
						</FormControl>
						{/* Township - Required input field */}
						<FormControl 
							isRequired
							mt={5}
							mb={5}
						>
							<FormLabel>Township</FormLabel>
							<Input 
								type="text"
								value={township}
								placeholder="Township" 
								boxShadow="md" 
								bg="white" 
								borderWidth="1px"
								borderColor="gray.300"
								onChange={e => setTownship(e.target.value)}
							/>
						</FormControl>
						<Stack
							direction={{ base: "column", md: "row" }}
							mb={5}
							w="100%"
						>
							{/* Phone Number */}
							<FormControl 
								isRequired
							>
								<FormLabel>Phone Number</FormLabel>
								<Input 
									value={phone} 
									onChange={e => setPhone(e.target.value)} 
									placeholder="Phone Number" 
									boxShadow="md" 
									bg="white" 
									borderWidth="1px"
									borderColor="gray.300"
								/>
							</FormControl>
							{/* Email */}
							<FormControl isRequired>
								<FormLabel>Email Address</FormLabel>
								<Input 
									value={email} 
									onChange={e => setEmail(e.target.value)} 
									placeholder="Email Address" 
									boxShadow="md" 
									bg="white"
									borderWidth="1px"
									borderColor="gray.300"
								/>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							mb={5}
							w="100%"
						>
							{/* Fence Type */}
							<FormControl>
								<FormLabel>Fence Type</FormLabel>
								<Select 
									boxShadow="md" 
									bg="white" 
									onChange={e => setSelectedFenceType(e.target.value)}
									placeholder="Fence Type"
									color="gray.500"
									borderWidth="1px"
									borderColor="gray.300"
								>
									{fenceType.map((type, index) => (
										<option key={index} value={type.value}>
											{type.label}
										</option>
									))}
								</Select>
							</FormControl>
							<FormControl>
								<FormLabel>Service Type</FormLabel>
								<Select 
									color="gray.500" 
									boxShadow="md" 
									bg="white"
									borderWidth="1px"
									borderColor="gray.300"
									onChange={e => setSelectedServiceType(e.target.value)}
								>
									{serviceType.map((type, index) => (
										<option key={index} value={type.value}>
											{type.label}
										</option>
									))}
								</Select>
							</FormControl>

							{/* Total Linear Feet */}
							<FormControl 
								mt={5}
								mb={5}
							>
								<FormLabel>Total Linear Feet</FormLabel>
								<Input 
									value={feet} 
									onChange={e => setFeet(e.target.value)} 
									placeholder="Measurements" 
									boxShadow="md" 
									bg="white"
									borderWidth="1px"
									borderColor="gray.300"
								/>
							</FormControl>
						</Stack>

						{/* Message */}
						<FormControl isRequired mt={5} mb={10}>
							<FormLabel>Message</FormLabel>
							<Textarea 
								name="message" 
								value={comments} 
								onChange={e => setComments(e.target.value)} 
								bg="white" 
								boxShadow="md" 
								placeholder="Enter your message here..." 
								minH="170px" 
								borderWidth="1px"
								borderColor="gray.300"
							/>
						</FormControl>

						<Button mt={{ base: "0", md: "2" }} mb={{ base: "0", md: "4" }} mx={4} variant="primary2" w={{ base: "full", md: "20%" }} type="submit" isLoading={loading}>
        				Submit
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ContactFormModal;

