/**
 * @file Gallery.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
	Stack,
} from "@chakra-ui/react";

import GalleryTable from "./Table";

import CreateGalleryImage from "./modals/CreateGalleryImage";

import useReorderGalleryImages from "hooks/GalleryImage/mutations/useReorderGalleryImages";
import useGetGalleryImages from "hooks/GalleryImage/queries/useGetGalleryImages";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { FooterContainer } from "../../components/TableContainer";

import ReorderItemsButton from "../../components/ReorderItemsButton";

const Gallery = () => {

	const { loading, error, data, refetch: galleryImagesRefetch } = useGetGalleryImages();

	const [galleryImages, setGalleryImages] = useState(data);

	useEffect(() => {
		if(data) {
			setGalleryImages(data);
		}
	}, [data]);

	const [showCreateGalleryImage, setShowCreateGalleryImage] = useState(false);
	const openCreateGalleryImageModal = () => setShowCreateGalleryImage(true);
	const closeCreateGalleryImageModal = () => setShowCreateGalleryImage(false);

	const sort = (field, order) => {
		let sortedGalleryImages = [...galleryImages];
		sortedGalleryImages.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setGalleryImages(sortedGalleryImages);
	};

	const search = (field, searchInput) => {
		let searchedGalleryImages = [...data];
		searchedGalleryImages = searchedGalleryImages.filter((galleryImage) => {
			let fieldValue = galleryImage[field];
			return fieldValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setGalleryImages(searchedGalleryImages);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setGalleryImages(data);
		setClearToggle(!clearToggle);
	};

	const [currentItems, setCurrentItems] = useState([]);

	const { reorderGalleryImages } = useReorderGalleryImages();

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={galleryImages}
			>
				<GalleryTable
					galleryImages={currentItems}
					galleryImagesRefetch={galleryImagesRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={galleryImages}
						setItems={setCurrentItems}
					/>
					{
						galleryImages?.length !== data?.length &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Stack
						direction={{
							base: "column",
							md: "row"
						}}
						spacing="1rem"
					>
						<Button
							variant="primary"
							onClick={openCreateGalleryImageModal}
						>
							Create Gallery Image
						</Button>
						<ReorderItemsButton
							items={galleryImages}
							reorderMutation={reorderGalleryImages}
							refetch={galleryImagesRefetch}
						/>
					</Stack>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreateGalleryImage
				isOpen={showCreateGalleryImage}
				onClose={closeCreateGalleryImageModal}
				refetch={galleryImagesRefetch}
			/>
		</Flex>
	);

};

export default Gallery;