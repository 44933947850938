/**
 * @file useAdminLogin.js
 */
import { useMutation } from "@apollo/client";
import { LOGIN } from "cache/Admin/mutations";

/**
 * @hook useAdminLogin
 *
 * @description
 * Custom hook that handles admin login functionality.
 *
 * @returns
 * - login function
 */
const useAdminLogin = () => {

	const [loginMutation, { loading, error }] = useMutation(LOGIN);

	const login = async (input) => {

		try {
			const { data } = await loginMutation({ 
				variables: { input }
			});
			if(error){
				throw new Error(error);
			}
			let loginData = data.login;
			if(loginData?.error){
				throw new Error(loginData.error);
			}
			else{
				return { data: loginData };
			}
		}
		catch (error) {
			return { error };
		}

	};

	return { login, loading, error };

};

export default useAdminLogin;
