/**
 * @file PermissionsModal.js
 * 
 * @description 
 * - This file contains the functions that are used to update the permissions of an admin.
 * - this modal is created using Chakra UI.
 * - This modal is used in the admin page. 
 * - This modal is used to update the permissions of individual admin accounts.
 * 
 */

import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Stack,
	Button,
	Checkbox,
	CheckboxGroup,
	SimpleGrid,
} from "@chakra-ui/react";

import useUpdateAdminPermissions from "hooks/Admin/mutations/useUpdateAdminPermissions";

import { ACCESS_LEVELS } from "../accessLevels";

import ErrorMsg from "Blueprints/ErrorMsg";
/**
 * @function UpdateAdminPermissions
 * 
 * @description 
 * - This function is used to update the permissions of an admin.
 * - This function is used in the admin page.
 * - This function is used to update the permissions of individual admin accounts.
 * - This function is used to create a modal using Chakra UI.
 * - This function is used to update the permissions of an admin account.
 */
    
const UpdateAdminPermissions = ({ isOpen, onClose, admin, refetch }) => {

	const [errorMsg, setErrorMsg] = useState(null);
	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState({
		accessLevel: admin.accessLevel,
	});

	useEffect(() => {
		if(admin){
			setInput({
				accessLevel: admin.accessLevel,
			});
		}
	}, [admin]);

	const { update } = useUpdateAdminPermissions();

	const updatePermissions = (e) => {
		const permission = e.target.name;
		const newPermissions = input.accessLevel.includes(permission) ? input.accessLevel.filter(perm => perm !== permission) : [...input.accessLevel, permission];
		setInput(prevInput => ({
			...prevInput,
			accessLevel: newPermissions,
		}));
	};    
	
	const toggleAllPermissions = () => {
		const newAccessLevels = input.accessLevel.length === ACCESS_LEVELS.length ? [] : ACCESS_LEVELS;
		setInput(prevInput => ({
			...prevInput,
			accessLevel: newAccessLevels,
		}));
	};

	const handlePermissionsUpdate = async (e) => {
		e.stopPropagation();
		e.preventDefault();
		setErrorMsg(null);
		setLoading(true);

		//Make sure at least one permission is selected
		if (input.accessLevel.length === 0) {
			setErrorMsg("Please select at least one permission.");
			setLoading(false);
			return;
		}

		let response = await update(admin._id, input.accessLevel);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success) {
			setLoading(false);
			await refetch();
			onClose();
			return;	
		}

	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Admin Permissions</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing={4}>
						<Button
							variant="admin_inverse"
							mt={4}
							onClick={toggleAllPermissions}
						>
							Toggle All Access Levels
						</Button>
						<CheckboxGroup 
							defaultValue={[]}
						>
							<SimpleGrid
								minChildWidth="40%"
								spacing={5}
							>
								{
									ACCESS_LEVELS.map((perm, index) => (
										<Checkbox
											variant="primary"
											key={index}
											name={perm}
											id={perm}
											onChange={updatePermissions}
											isChecked={input.accessLevel.includes(perm)}
										>
											{perm.charAt(0).toUpperCase() + perm.slice(1)}
										</Checkbox>
									))
								}
							</SimpleGrid>
						</CheckboxGroup>
						{errorMsg &&
							<ErrorMsg>
								{errorMsg}
							</ErrorMsg>
						}
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary2"
						mr={3}
						onClick={handlePermissionsUpdate}
						isLoading={loading}
					>
                        Save
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default UpdateAdminPermissions;