/**
 * @file
 * This is the ShowroomController component of the application.
 * It is responsible for managing the routes within the Showroom section of the application.
 *
 * @summary Showroom routing controller.
 */

import React from "react";

import { Route } from "react-router-dom";

import "./Showroom.scss";

import Showroom from "./Showroom";
import Search from "./Search/Search";
import PartnerPage from "./Partner/PartnerPage";
import ChainlinkController from "./Chainlink/ChainlinkController";
import RoutesWrapper from "wrappers/RoutesWrapper";
// import Product from "./Product/Product";

/**
 * ShowroomController component is responsible for the routing of the Showroom section of the website.
 * It includes routes for the main Showroom page, the Search page, and individual Product pages.
 *
 * @param {object} props - The properties passed down from the parent component (not used in this component, but retained for possible future use)
 * 
 * @returns {HTMLElement} - A div component that includes a Routes component which contains Route components for each of the pages in the Showroom section.
 */
const ShowroomController = () => {
    
	return(
		<div className="showroom-wrapper">
			<RoutesWrapper>
				<Route path="/" element={<Showroom />} />
				{/* <Route path="/search/*" element={<Search />} /> */}
				<Route path="/chainlink/*" element={<ChainlinkController />} />
				<Route path="/partner/:partner/*" element={<PartnerPage />} />
				<Route path="/:category/*" element={<Search />} />
				{/* <Route path="/product/:productName" element={<Product />} /> */}
			</RoutesWrapper>
		</div>
	);
};

export default ShowroomController;
