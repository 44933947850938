import React from "react";

import {
	Flex,
	Container,
} from "@chakra-ui/react";

import { IconKey } from "./components/IconKey";

const Documentation = () => {
	return (
		<Flex
			flexDir="column"
			justify="center"
			align="center"
			w="100%"
			h="100%"
		>
			<Container
				maxW="container.xl"
			>
				<IconKey />
			</Container>
		</Flex>
	);
};

export default Documentation;