/**
 * @file queries.js is a file for all Admin queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_ADMIN - GraphQL query to get the admin.
 * 
 * @summary
 * - This query will return the admin object.
 * - This query will return null if the admin is not logged in.
 */
export const GET_ADMIN = gql`
	query getAdmin {
		getAdmin {
			_id
			username
			email
			firstName
			lastName
			accessLevel
			createdAt
			updatedAt
		}
	}
`;


/**
 * @const {Object} GET_ALL_ADMINS - GraphQL query to get all admins.
 * 
 * @summary
 * - This query will return an array of admin objects.
 * - This query will return null if the admin is not logged in.
 * - This query will return null if the admin does not have permission to read admins.
 */
export const GET_ALL_ADMINS = gql`
	query getAllAdmins {
		getAllAdmins {
			_id
			username
			email
			firstName
			lastName
			accessLevel
			createdAt
			updatedAt
		}
	}
`;
