/**
 * @file useGetPartner.js
 */

import { useQuery } from "@apollo/client";
import { GET_PARTNER } from "cache/Partner/queries"; // Update the path as per your project structure

/**
 * @hook useGetPartner
 *
 * @description
 * Custom hook to handle the GET_PARTNER query for fetching partner data by ID.
 * This hook can be used in components where partner information is required based on their ID.
 *
 * @param {String} partnerId - ID of the partner to be fetched.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - partner: Partner data from the query
 * - refetch: Function to refetch the partner data
 * - networkStatus: Network status information
 */
const useGetPartner = (partnerId) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_PARTNER, {
		variables: { _id: partnerId }
	});

	let partner = data?.getPartner;

	return { loading, error, data: partner, refetch, networkStatus };
};

export default useGetPartner;
