/**
 * @file UpdateGalleryImage.js
 * 
 * @author Steven Secreti
 */
import React, { useState, useEffect } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Image,
} from "@chakra-ui/react";

import useUpdateGalleryImage from "hooks/GalleryImage/mutations/useUpdateGalleryImage";
import useUploadImage from "hooks/useUploadImage";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import ErrorMsg from "Blueprints/ErrorMsg";

/**
 * @component UpdateGalleryImage
 */
const UpdateGalleryImage = ({ isOpen, onClose, refetch, galleryImage }) => {

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [title, setTitle] = useState(galleryImage.title);
	const [description, setDescription] = useState(galleryImage.description);
	const [imageFiles, setImageFiles] = useState([]);

	const resetState = () => {
		setTitle("");
		setDescription("");
		setImageFiles([]);
		setLoading(false);
		setError(null);
	};

	const handleClose = () => {
		resetState();
		onClose();
	};

	useEffect(() => {
		setTitle(galleryImage.title);
		setDescription(galleryImage.description);
		setImageFiles([]);
	}, [galleryImage]);
	  
	const handleSetImage = (imageFiles) => {
		setImageFiles(imageFiles);
	};

	const uploadImage = useUploadImage();
	const { updateGalleryImage } = useUpdateGalleryImage();

	const handleUpdate = async (e) => {
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let updateGalleryImageInput = {};
		if(imageFiles && imageFiles.length > 0){
			let image_url;
			try {
				image_url = await uploadImage(imageFiles[0]);
			}
			catch(error) {
				setLoading(false);
				setError("Unexpected error while uploading image. Please try again.");
				return;
			}
			updateGalleryImageInput.image = image_url;
		}
		if(title !== galleryImage.title){
			updateGalleryImageInput.title = title;
		}
		if(description !== galleryImage.description){
			updateGalleryImageInput.description = description;
		}

		if(Object.keys(updateGalleryImageInput).length === 0){
			setLoading(false);
			setError("No changes detected.");
			return;
		}

		let response = await updateGalleryImage(galleryImage._id, updateGalleryImageInput);
		if(response.error){
			setLoading(false);
			setError(response.error);
			return;
		}
		if(response.data){
			refetch();
			handleClose();
			return;
		}
		setLoading(false);
		setError("Unexpected error while updating gallery image. Please try again.");
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!title) {
			errors.title = "Title is required.";
		}
		if (!description) {
			errors.description = "Description is required.";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Gallery Image</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl 
							id="title" 
							isRequired
							isInvalid={formErrors.title}
						>
							<FormLabel>Title</FormLabel>
							<Input
								type="text"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.title}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="description"
							isInvalid={formErrors.description}
						>
							<FormLabel>Description</FormLabel>	
							<Input
								type="text"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.description}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="image"
							isInvalid={formErrors.image}
						>
							<FormLabel>Image</FormLabel>
							<Image
								src={galleryImage.image}
								alt={title}
								w="50%"
								borderRadius="xl"
								mb={4}
							/>
							<ImagesDropzone
								imageFiles={imageFiles}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						{
							error && 
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							variant="primary"
							onClick={handleUpdate}
							isLoading={loading}
						>
							Update
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default UpdateGalleryImage;