import React, { useState, useEffect } from "react";
import { 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalCloseButton, 
	ModalBody, 
	Button, 
	Box, 
	Flex,
	ModalFooter
} from "@chakra-ui/react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
	TF_ADMIN_SOFTRED,
	TF_AMARA,
} from "chakraStyles";

const grid = 1;
const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: "1rem",
	margin: `0 0 ${grid}px 0`,
  
	// change background colour if dragging
	background: isDragging ? TF_AMARA : "transparent",
	borderRadius: "1rem",
  
	// styles we need to apply on draggables
	...draggableStyle
});
const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? TF_ADMIN_SOFTRED : TF_AMARA,
	padding: grid,
	width: "100%",
	borderRadius: "8px",
	borderColor: "gray.200",
	boxShadow: "lg",
});

const ReorderModal = ({ isOpen, onClose, initialItems, onSubmitReorder }) => {

	const [reorderedItems, setReorderedItems] = useState({});

	useEffect(() => {
		const initialState = {
			"column": initialItems
		};
		setReorderedItems(initialState);
	}, [initialItems]);

	const onDragEnd = (result) => {
		if (!result.destination) return;
    
		const itemsCopy = Array.from(reorderedItems["column"]);
		const [movedItem] = itemsCopy.splice(result.source.index, 1);
		itemsCopy.splice(result.destination.index, 0, movedItem);
    
		setReorderedItems({"column": itemsCopy});
	};

	const handleSave = async () => {
		let reorderedItemsArray = reorderedItems["column"];
		let newOrderedItems = [];
		for (let i = 0; i < reorderedItemsArray.length; i++) {
			let currentItem = {};
			currentItem._id = reorderedItemsArray[i]._id;
			currentItem.order = i;
			newOrderedItems.push(currentItem);
		};
		await onSubmitReorder(newOrderedItems);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Reorder Items</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Column
						initialState={reorderedItems}
						onDragEnd={onDragEnd}
						updateState={setReorderedItems}
					/>
				</ModalBody>
				<ModalFooter>
					<Button 
						mt={4} 
						onClick={handleSave}
						variant="primary"
					>
						Save Order
					</Button>
				</ModalFooter>
				
			</ModalContent>
		</Modal>
	);
};

const Column = ({ initialState, onDragEnd }) => {

	const [state, setState] = useState({});

	useEffect(() => {
		setState(initialState);
	}, [initialState]);

	return (
		<DragDropContext 
			onDragEnd={onDragEnd}
			// set the class for styling
			className="kanban-board"
		>
			{
				//Map Initial Tasks Key-Value Pairs to Kanban Columns
				Object.keys(state).map((column, ind) => {
					return (
						<Flex
							key={ind}
							bg="white"
						>
							<Droppable 
								droppableId={column} 
								key={ind}
								className="kanban-column"
							>
								{(provided, snapshot) => (
									<Flex
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
										{...provided.droppableProps}
										direction="column"
									>
										{state[column].map((item, index) => (
											<Draggable 
												key={item._id} 
												draggableId={item._id} 
												index={index}
												color="white"
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
														className="kanban-item-wrapper"
													>
														<Box
															p={4}
															mb={2}
															boxShadow="sm"
															borderRadius="md"
															bg="white"
														>
															{item.name ? item.name : item.title}
														</Box>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</Flex>
								)}
							</Droppable>
						</Flex>
					); 
				})
			}      
		</DragDropContext>
	);

};

export default ReorderModal;
