/**
 * @file DeleteGalleryImage.js is responsible for rendering the delete gallery image modal
 */
import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useDeleteGalleryImage from "hooks/GalleryImage/mutations/useDeleteGalleryImage"; // Update the path as per your project structure

/**
 * @function DeleteGalleryImage
 * @summary - Modal to delete a gallery image
 */
const DeleteGalleryImage = ({ isOpen, onClose, galleryImage, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteGalleryImage } = useDeleteGalleryImage();

	/**
	 * @function handleDeleteGalleryImage
	 * 
	 * @description - This function is used to delete a gallery image from the database.
	 * 
	 */
	const handleDeleteGalleryImage = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await deleteGalleryImage(galleryImage._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Gallery Image</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to delete this gallery image?</Text>
					<Text fontWeight="bold" mt={2}>This action cannot be undone.</Text>
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
					<Text mt={4}>Please type <Text as="span" fontWeight="bold">DELETE</Text> to confirm.</Text>
					<Input
						mt={2}
						placeholder="DELETE"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleDeleteGalleryImage}
						isLoading={loading}
						isDisabled={confirmText !== "DELETE"}
					>
						Delete
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteGalleryImage;
