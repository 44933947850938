/**
 * @file TableContainer.js
 */
import React from "react";

import {
	Box,
	Container,
	Flex,
	Stack,
} from "@chakra-ui/react";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const TableContainer = ({ children }) => {

	return (
		<Box
			w="100%"
			h="100%"
			flex="1"
			px={{
				base: 4,
				sm: 4,
				md: 4,
				lg: 8,
				xl: 8,
			}}
			pt={4}
		>
			<Container
				maxW="100%"
				centerContent
				p={2}
			>	
				<Flex 
					// Controls the actual table itself, including the header and rows
					w="100%"
					h="100%"
					maxH="75vh"
					overflowY="auto"
					flexDir="column"
					borderWidth="1px"
					borderColor="gray.200"
					borderRadius="md"
					boxShadow="md"
					bg="white"
					mt="6"
					mb="8"
					justifyContent="space-between"
					overflowX={{
						base: "scroll",
						md: "hidden",
					}}
				>
					{children}
				</Flex>
			</Container>
		</Box>
	);

};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const HeaderContainer = ({ children }) => (
	<Flex
		w="100%"
		h="100%"
		bg="gray.50"
		flexDir={{
			base: "column",
			md: "row",
		}}
		alignItems="flex-start"
		justifyContent="space-between"
		px={5}
		py={3}
		borderBottom="1px solid darkgray"
		//Make this component sticky
		position="sticky"
		top="0"
		zIndex="sticky"
		// display={{
		// 	base: "none",
		// 	sm: "flex",
		// }}
	>
		{children}
	</Flex>
);

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const RowContainer = ({ onClick, children }) => (
	<Flex
		w="100%"
		h="100%"
		bg="gray.50"
		flexDir={{
			base: "column",
			md: "row",
		}}
		_hover={{
			transform: "scale(1.01)",
			transition: "transform .2s",
			borderColor: "#CBD5E0",
			boxShadow: "xs",
			bg: "gray.100",
			cursor: "pointer",
		}}
		alignItems="center"
		justifyContent="space-between"
		px={5}
		py={3}
		borderBottom={{
			base: "1px solid darkgray",
			sm: "none",
		}}
		onClick={onClick}
	>
		{children}
	</Flex>
);

export const FooterContainer = ({ children }) => (
	<Flex
		flexDir={{
			base: "column",
			sm: "row",
		}}
		justify="space-between"
		align="center"
		w="100%"
		px="5%"
		mb={4}
	>
		{children}
	</Flex>
);

export const ComplexRowContainer = ({ px, children }) => (
	<Box
		as="section"
		w="100%"
		px={px || 0}
	>
		<Container
			w="100%"
			maxW="100%"
			h="100%"
			px={0}
		>
			{children}
		</Container>
	</Box>
);

export const ComplexRowContentContainer = ({ onClick, showChildren, children }) => {

	return (
		<Flex
			w="100%"
			h="100%"
			bg={showChildren ? "#F5F5F5" : "#FFFFFF"}
			flexDir={{
				base: "column",
				md: "row",
			}}
			_hover={{
				transform: "scale(1.01)",
				transition: "transform .2s",
				borderColor: "#CBD5E0",
				boxShadow: "xs",
				bg: "gray.100",
				cursor: "pointer",
			}}
			alignItems="center"
			justifyContent="space-between"
			px={5}
			py={3}
			borderBottom={{
				base: "1px solid darkgray",
				sm: "none",
			}}
			onClick={onClick}
			boxShadow="lg"
			borderRadius="lg"
			borderWidth="1px"
			borderColor={showChildren ? "#CBD5E0" : "#E2E8F0"}
		>
			{children}
		</Flex>
	);
};

export const ButtonBox = ({ children }) => {

	return (
		<Box 
			flex="1"
			w="100%" 
			textAlign="right"
		> 
			<Stack 
				direction={{
					base: "column",
					md: "row",
				}}
				spacing="2"
			>
				{children}
			</Stack>
		</Box>
	);

};

export default TableContainer;