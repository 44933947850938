/** 
 * @file queries.js is a file for all Partner queries 
 * 
 * @author Steven Secreti
 */
import { gql } from "@apollo/client";

/**
 * @constant GET_PARTNER
 * 
 * @summary GraphQL query that gets a partner by its ID.
 */
export const GET_PARTNER = gql`
	query getPartner($_id: ID!) {
		getPartner(_id: $_id) {
			_id
			name
			image
			images
			website
			brochures {
				_id
				name
				image
				url
			}
			order
		}
	}
`;

/**
 * @constant GET_ALL_PARTNERS
 * 
 * @summary GraphQL query that gets all partners.
 */
export const GET_ALL_PARTNERS = gql`
	query getAllPartners {
		getAllPartners {
			_id
			name
			image
			images
			website
			brochures {
				_id
				name
				image
				url
			}
			order
		}
	}
`;
