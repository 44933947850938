import { FiEdit, FiTrash2 } from "react-icons/fi";

import { MdAddLink, MdAddCircleOutline, MdCheckBox } from "react-icons/md";

const guideData = [
	{
		category: "Icons",
		subcategories: [
			{
				iconName: "Add",
				icon: MdAddCircleOutline,
				description: "Add a new item.",
			},
			{
				iconName: "Edit",
				icon: FiEdit,
				description: "Edit an item.",
			},
			{
				iconName: "Delete",
				icon: FiTrash2,
				description: "Delete an item.",
			},
			{
				iconName: "Link",
				icon: MdAddLink,
				description: "Link an item to another item.",
			},
			// Add more subcategories under 'Documents' here
		],
	},
	{
		category: "Access Levels",
		subcategories: [
			{
				iconName: "Admin",
				icon: MdCheckBox,
				description: "Ability to Manage all aspects of the site, including Admins.",
			},
			{
				iconName: "Invoice Management",
				icon: MdCheckBox,
				description: "Ability to Manage Invoices and Customers.",
			},
			{
				iconName: "Store Management",
				icon: MdCheckBox,
				description: "Ability to Manage Categories, Products, and Featured Deals",
			},
			{
				iconName: "Content Management",
				icon: MdCheckBox,
				description: "Ability to Manage Gallery, Reviews, Brochures, and Partners",
			}
		],
	}
	// {
	// 	category: "Media",
	// 	subcategories: [
	// 		{
	// 			iconName: "Video",
	// 			icon: FiEdit, // Change to appropriate icon
	// 			description: "Videos for promotional use.",
	// 			fileName: "Promo_2022.mp4",
	// 			fileSize: "45MB",
	// 		},
	// 		// Add more subcategories under 'Media' here
	// 	]
	// },
	// Add more categories here
];

export default guideData;