/**
 * @file useCreateInvoice.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_INVOICE } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useCreateInvoice
 *
 * @description
 * Custom hook that handles the CREATE_INVOICE mutation functionality.
 * This hook creates a new invoice and returns the invoice object.
 * It returns null if the invoice could not be created.
 *
 * @returns
 * - createInvoice: Custom mutation to create the invoice
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCreateInvoice = () => {

	// Custom mutation for creating an invoice
	const [createInvoiceMutation, { loading, error }] = useMutation(CREATE_INVOICE);

	/**
	 * Custom mutation to create an invoice.
	 *
	 * @param {Object} input - The input object containing the invoice data.
	 * @returns {Object} - An object containing the invoice result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const createInvoice = async (input) => {
		try {
			const { data } = await createInvoiceMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.createInvoice };
		} catch (error) {
			return { error };
		}
	};

	return { createInvoice, loading, error };
};

export default useCreateInvoice;
