/**
 * @file useCheckInvoiceStatus.js
 */

import { useMutation } from "@apollo/client";
import { CHECK_INVOICE_STATUS } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useCheckInvoiceStatus
 *
 * @description
 * Custom hook that handles the CHECK_INVOICE_STATUS mutation functionality.
 * This hook is designed to check the status of an existing invoice.
 *
 * @returns
 * - checkInvoiceStatus: Function to execute the checkInvoiceStatus mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCheckInvoiceStatus = () => {

	// Initialize Apollo's useMutation hook for CHECK_INVOICE_STATUS mutation
	const [checkInvoiceStatusMutation, { loading, error }] = useMutation(CHECK_INVOICE_STATUS);

	/**
	 * Executes the checkInvoiceStatus mutation.
	 *
	 * @param {String} id - The ID of the invoice to check the status of.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const checkInvoiceStatus = async (id) => {
		try {
			const { data } = await checkInvoiceStatusMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.checkInvoiceStatus };
		} catch (error) {
			return { error };
		}
	};

	return { checkInvoiceStatus, loading, error };
};

export default useCheckInvoiceStatus;
