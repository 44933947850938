const ACCESS_LEVELS = [
	"Admin", //Access to everything, including `Admins`
	"Invoice Management", //Access to `Customers` and `Invoices`
	"Store Management", //Access to `Categories`, `Products`, `Featured Deals`,
	"Content Management", //Access to `Gallery`, `Reviews`, `Brochures`, `Partners`
];

const PERMISSION_TO_ACCESS_LEVEL = {
	Category: "Store Management",
	Product: "Store Management",
	Deal: "Store Management",
	Customer: "Invoice Management",
	Invoice: "Invoice Management",
	Gallery: "Content Management",
	Review: "Content Management",
	Brochure: "Content Management",
	Partner: "Content Management",
	Admin: "Admin",
};

export { ACCESS_LEVELS, PERMISSION_TO_ACCESS_LEVEL };
