/**
 * @file DeleteFeaturedDeal.js
 */
import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useDeleteFeaturedDeal from "hooks/FeaturedDeal/mutations/useDeleteFeaturedDeal";

/**
 * @function DeleteFeaturedDeal
 * 
 * @summary
 * - Modal to delete a featured deal
 * 
 */
const DeleteFeaturedDeal = ({ isOpen, onClose, deal, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteFeaturedDeal } = useDeleteFeaturedDeal();

	/**
	 * @function handleDeleteFeaturedDeal
	 * @description - This function is used to delete a featured deal from the database.
	 * 
	 * @param {*} e - The event object
	 */
	const handleDeleteFeaturedDeal = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await deleteFeaturedDeal(deal._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success) {
			refetch();
			onClose();
		}
	};

	return(
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Featured Deal</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
					<Text>Are you sure you want to delete this featured deal?</Text>
					<Text>Enter the name of the deal to confirm.</Text>
					<Input
						placeholder="Deal Name"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" mr={3} onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDeleteFeaturedDeal}
						isLoading={loading}
						disabled={confirmText !== deal.name}
					>
						Delete
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteFeaturedDeal;
