/**
 * @file useRequestAdminPasswordResetEmail.js
 */

import { useMutation } from "@apollo/client";
import { REQUEST_ADMIN_PASSWORD_RESET_EMAIL } from "cache/Admin/mutations"; // Update the path as per your project structure

/**
 * @hook useRequestAdminPasswordResetEmail
 *
 * @description
 * Custom hook that handles requesting a password reset email for an admin.
 * This hook can be used wherever admin password reset functionality is required.
 *
 * @returns
 * - requestPasswordReset: Function to execute the request for admin password reset email.
 * - loading: Boolean indicating the loading state.
 * - error: Error object if there's an error.
 */
const useRequestAdminPasswordResetEmail = () => {

	// Initialize the Apollo mutation hook
	const [requestMutation, { loading, error }] = useMutation(REQUEST_ADMIN_PASSWORD_RESET_EMAIL);

	/**
	 * Function to request a password reset email for an admin.
	 *
	 * @param {String} email - The email address of the admin.
	 * @returns {Object} - An object containing the boolean result (true if successful, false otherwise) or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const requestPasswordReset = async (email) => {
		try {
			const { data } = await requestMutation({
				variables: {
					email // Only one variable required for this mutation
				}
			});
			// If any error occurred during mutation, throw it
			if (error) {
				throw new Error(error);
			}
			// Return the boolean result
			return { data: data.requestAdminPasswordResetEmail };
		} catch (error) {
			// Return the error object in case of an exception
			return { error };
		}
	};

	// Return the custom function, loading state, and error object
	return { requestPasswordReset, loading, error };
};

export default useRequestAdminPasswordResetEmail;
