/**
 * @file
 * This is the Home component of the application.
 * It represents the home page of the website and includes several child components.
 * It uses framer-motion for page transitions.
 * 
 * @summary Home page component.
 */
import React from "react";

import { motion } from "framer-motion";

import Hero from "Blueprints/Hero";

import CategorySelection from "./components/CategorySelection";

import { HomeReviews } from "./components/HomeReviews";

import CustomFenceCTA from "./components/CustomFenceCTA";

import Partners from "./components/Partners";

import HomeHero from "../../../assets/TaylorFenceImg/Home/TaylorHero.webp";

import "./Home.scss";

/**
 * The Home component displays the home page of the website.
 *
 * @param {object} props - The properties passed to the component.
 * @returns {HTMLElement} - A motion.div component from framer-motion that includes child components for the home page.
 */
const Home = () => {

	const pageVariants = {
		initial: {
			opacity: 0,
		},
		in: {
			opacity: 1,
		},
		out: {
			opacity: 0,
		}
	};

	const pageTransition = {
		type: "tween",
		ease: "easeInOut",
		duration: 0.65
	};

	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
			className="home"
		>
			<Hero 
				title=""
				image_url={HomeHero}
				objectPosition="center 34%"
				imageHeight={{
					sm: "180px",
					md: "100%",
				}}
				imageWidth="100%"
				boxHeight={{
					base: "160px",
					md: "300px",
					lg: "500px"
				}}
				boxPaddingY="10"
			/>
			<CategorySelection />
			<HomeReviews />
			<CustomFenceCTA/>
			<Partners />
		</motion.div>
	);
};

export default Home;

