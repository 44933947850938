/**
 * @file useGetAllInvoices.js
 */

import { useQuery } from "@apollo/client";
import { GET_ALL_INVOICES } from "cache/Invoice/queries"; // Update the path as per your project structure

/**
 * @hook useGetAllInvoices
 *
 * @description
 * Custom hook that handles the GET_ALL_INVOICES query functionality.
 * This hook fetches all invoices and returns them as an array of objects.
 * It returns null if no invoices are found.
 *
 * @param {Object} options - Additional options that may be required for the query.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query (array of invoices or null)
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetAllInvoices = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_ALL_INVOICES);
    
	let snaggedData = data?.getAllInvoices; // This will be the array of invoices or null

	// Handling any additional error scenarios or customization can be added here.

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetAllInvoices;
