/**
 * @file ReorderProductImagesModal.js
 * 
 * @author Webify Services, Ltd.
 */
import React from "react";

import {
	useToast,
} from "@chakra-ui/react";

import ReorderImagesModal from "components/Admin/Dashboard/components/ReorderImagesModal";

import useReorderProductImages from "hooks/Product/mutations/useReorderProductImages";

/**
 * @function ReorderProductImagesModal
 * 
 * @summary A modal that allows the user to reorder the images of a product.
 * 
 * @param {Object} props - React props
 * @param {boolean} props.isOpen - Whether or not the modal is open
 * @param {function} props.onClose - Function to close the modal
 * @param {Object} props.product - The product to reorder images for
 */
const ReorderProductImagesModal = ({ isOpen, onClose, product, refetch }) => {
	const toast = useToast();

	const { reorderProductImages } = useReorderProductImages();

	const handleReorder = async (orderedImages) => {
		const response = await reorderProductImages(product._id, orderedImages);
		if (response && response.data) {
			await refetch();
			toast({
				title: "Images reordered",
				description: "The images have been reordered successfully.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			onClose();
		} else {
			toast({
				title: "Error",
				description: "There was an error reordering the images, please refresh or try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		return;
	};

	return (
		<ReorderImagesModal
			isOpen={isOpen}
			onClose={onClose}
			initialImages={product.images}
			onSubmitReorder={handleReorder}
		/>
	);
};

export default ReorderProductImagesModal;
