/**
 * @file - Table.js
 * 
 * @NoteByBryan
 * 
 * - Could be pretty cool to have the ppermissions be displayed per each admin then when they click on the admin, the modal pops up with the permissions they can edit?
 * 
 */

import React, { useEffect, useState } from "react";

import {
	Button,
} from "@chakra-ui/react";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";
import UpdateAdminModal from "./Modals/UpdateAdminModal";
import UpdateAdminPermissions from "./Modals/PermissionsModal";
import DeleteAdminModal from "./Modals/DeleteAdminModal";

const AdminRow = (props) => {

	const admin = props.admin;

	const adminsRefetch = props.adminsRefetch;

	const [showUpdateAdminInfo, setShowUpdateAdminInfo] = useState(false);
	
	const openUpdateAdminInfo = () => {
		setShowUpdateAdminInfo(true);
	};

	const closeUpdateAdminInfo = () => {
		setShowUpdateAdminInfo(false);
	};

	const [showUpdateAdminPermissions, setShowUpdateAdminPermissions] = useState(false);

	const openUpdateAdminPermissions = (e) => {
		e.stopPropagation();
		setShowUpdateAdminPermissions(true);
	};

	const closeUpdateAdminPermissions = () => {
		setShowUpdateAdminPermissions(false);
	};

	const [showDeleteAdmin, setShowDeleteAdmin] = useState(false);

	const openDeleteAdminModal = (e) => {
		e.stopPropagation();
		setShowDeleteAdmin(true);
	};

	const closeDeleteAdminModal = () => {
		setShowDeleteAdmin(false);
	};

	return (
		<RowContainer
			onClick={openUpdateAdminInfo}
		>
			<TextCell>
				{`${admin.firstName} ${admin.lastName}`}
			</TextCell>
			<TextCell>
				{admin.email}
			</TextCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openUpdateAdminPermissions}
					fontSize={{
						base: "xs",
						sm: "xs",
						md: "xs",
						lg: "sm",
						xl: "sm",
					}}
				>
                    Edit Permissions
				</Button>
			</TableCell>
			<TableCell>
				<Button
					variant="primary"
					onClick={openDeleteAdminModal}
					fontSize={{
						base: "xs",
						lg: "sm",
					}}
				>
                    Delete
				</Button>
			</TableCell>
			<UpdateAdminModal
				isOpen={showUpdateAdminInfo}
				onClose={closeUpdateAdminInfo}
				selectedAdmin={admin}
				adminsRefetch={adminsRefetch}
			/>
			<UpdateAdminPermissions
				isOpen={showUpdateAdminPermissions}
				onClose={closeUpdateAdminPermissions}
				admin={admin}
				refetch={adminsRefetch}
			/>
			<DeleteAdminModal
				isOpen={showDeleteAdmin}
				onClose={closeDeleteAdminModal}
				admin={admin}
				adminsRefetch={adminsRefetch}
			/>
		</RowContainer>
	);
};

const AdminTable = (props) => {

	const [admins, setAdmins] = useState(props.admins);

	useEffect(() => {
		setAdmins(props.admins);
	}, [props.admins]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("name", order)}
					search={(value) => props.search("name", value)}
					clearToggle={props.clearToggle}
				>
					Name
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("email", order)}
					search={(value) => props.search("email", value)}
					clearToggle={props.clearToggle}
				>
					Email
				</TextCell>
				<TableCell>
					Permissions
				</TableCell>
				<TableCell>
					Actions
				</TableCell>
			</HeaderContainer>
			{admins.map((admin, index) => (
				<AdminRow
					key={index}
					admin={admin}
					adminsRefetch={props.adminsRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default AdminTable;
