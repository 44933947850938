/**
 * @file DeleteInvoice.js is responsible for rendering the delete invoice modal
 */
import React, { useState } from "react";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useDeleteInvoice from "hooks/Invoice/mutations/useDeleteInvoice";

/**
 * @function DeleteInvoice
 * 
 * @summary - Modal to delete an invoice
 */
const DeleteInvoice = ({ isOpen, onClose, invoice, refetch }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteInvoice } = useDeleteInvoice();

	/**
	 * @function handleDeleteInvoice
	 * 
	 * @description - This function is used to delete an invoice from the database.
	 */
	const handleDeleteInvoice = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		const { data, error } = await deleteInvoice(invoice._id);
		if (data) {
			refetch();
			setLoading(false);
			onClose();
		}
		else {
			const errMsg = error?.message || "An error occurred while deleting the invoice.";
			setErrorMsg(errMsg);
			setLoading(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Invoice</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to delete this invoice?</Text>
					<Text>This action cannot be undone.</Text>
					<Input
						mt={2}
						placeholder="Type DELETE to confirm"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
					{errorMsg && <ErrorMsg errorMsg={errorMsg} />}
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" onClick={onClose}>Cancel</Button>
					<Button 
						colorScheme="red" 
						onClick={handleDeleteInvoice}
						isLoading={loading}
						isDisabled={confirmText !== "DELETE"}
					>
						Delete
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteInvoice;