/**
 * @file utils.js
 * 
 * @description Provides utility functions for the admin dashboard
 * 
 * @author Steven Secreti
 */
import * as EmailValidator from "email-validator";


/**
 * @function isValidEmail
 * 
 * @description
 *  - This function checks if the email address is valid or not, according to the following rules:
 *     - The email address must contain an @ symbol.
 *     - The email address must contain a . symbol.
 *     - The email address must contain at least 2 characters after the . symbol.
 *     - The email address must not contain any special characters.
 *     - The email address must not contain any spaces.
 * 
 * @param {string} email The email address to be validated.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
export function isValidEmail(email) {
	// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	// let isValid = emailRegex.test(email);
	let isValid = EmailValidator.validate(email);
	return isValid;
}

/**
 * @function isValidUsername
 * 
 * @description
 * - This function checks if the username is valid or not, according to the following rules:
 *      - The username must only contain letters, numbers, and underscores.
 *      - The username must be between 3 and 30 characters long.
 *      - The username must not contain any spaces.
 *  
 * @param {string} username The username to be validated.
 * @returns {boolean} True if the username is valid, false otherwise.
 */
export function isValidUsername(username) {
	const usernameRegex = /^[a-zA-Z0-9_]{3,30}$/; // Only allows letters, numbers, and underscores with a length of 3 to 30 characters
	let isValid = usernameRegex.test(username);
	return isValid;
}

/**
 * @function isValidPassword
 * 
 * @description
 * - This function checks if the password is valid or not, according to the following rules:
 *      - The password must be at least 8 characters long.
 *      - The password must contain at least one letter.
 *      - The password must contain at least one number.
 *      - The password can also contain special characters ! $ % @ # £ € * ? &
 * 
 * @param {string} password The password to be validated.
 * @returns {boolean} True if the password is valid, false otherwise.
 */
export function isValidPassword(password) {
	const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/;
	let isValid = passwordRegex.test(password);
	return isValid;
}


/**
 * @function convertDateToReadableFormat
 * 
 * @description
 * - Converts the date to a readable format
 * 
 * @param {*} date 
 * - Date string in Unix time format
 * 
 * @returns
 * - Date in readable format e.g. 12/12/2020
 * - Converts the date to GMT time
 * - Returns empty string if the date is invalid
 * 
 */
export function convertDateToReadableFormat(date) {
	try{
		let dateObj = new Date(parseInt(date));
		let day = dateObj.getUTCDate();
		let month = dateObj.getUTCMonth() + 1;
		if(day < 10) {
			day = `0${day}`;
		}
		if(month < 10) {
			month = `0${month}`;
		}
		let year = dateObj.getUTCFullYear();
		return `${month}/${day}/${year}`;
	}
	catch(err) {
		return "";
	}
}

/**
 * @function convertDateToInputFormat
 * 
 * @description
 * - Converts the date to a format that can be used in the input field
 * 
 * @param {*} date
 * - Date string in Unix time format
 * 
 * @returns
 * - Date in input format e.g. 2020-12-12
 * - Converts the date to GMT time
 * - Returns empty string if the date is invalid
 */
export function convertDateToInputFormat(date) {
	try{
		let dateObj = new Date(parseInt(date));
		let day = dateObj.getUTCDate();
		let month = dateObj.getUTCMonth() + 1;
		if(day < 10) {
			day = `0${day}`;
		}
		if(month < 10) {
			month = `0${month}`;
		}
		let year = dateObj.getUTCFullYear();
		return `${year}-${month}-${day}`;
	}
	catch(err) {
		return "";
	}
}

/**
 * @function convertDateToRFC2822Format
 * 
 * @description
 * - Converts the date to a format that can be used in the input field
 * 
 * @param {*} date
 * - Date string in Unix time format
 * 
 * @returns
 * - Date in RFC2822 format e.g. Sat, 12 Dec 2020 00:00:00 GMT
 */
export function convertDateToRFC2822Format(date) {
	try{
		let dateObj = new Date(parseInt(date));
		return dateObj.toUTCString();
	}
	catch(err) {
		return "";
	}
}

export function isValidDate(dateString){
	const regEx = /^\d{4}-\d{2}-\d{2}$/;
	if(!dateString.match(regEx)) return false;  // Invalid format
	const d = new Date(dateString);
	const dNum = d.getTime();
	if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
	return d.toISOString().slice(0,10) === dateString; // Check against original string
};

export function convertDateToUTC(dateString){
	const d = new Date(dateString);
	return d.toUTCString();
}

export function displayDollarAmount(amount){
	try{
		amount = parseFloat(amount);
		return `$${amount.toFixed(2)}`;
	}
	catch(err){
		return "N/A";
	}
}
