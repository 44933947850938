/**
 * @file
 * This is the MainContents component.
 * It controls the main routing for the application.
 *
 * @summary Main routing control for the application.
 */

import React, { useContext, useEffect } from "react";
import Home from "./Home/Home";
import ContactPage from "./Static/Contact/Contact";
import AboutController from "./Static/About/AboutController";
import ResourcesController from "./Static/Resources/ResourcesController";
import ShowroomController from "./Showroom/ShowroomController";
import PaymentsController from "./Payments/PaymentsController";
import ImageGallery from "./Static/Gallery/ImgGallery";

import RoutesWrapper from "../../wrappers/RoutesWrapper";

import { PageChangeContext } from "contexts/PageChangeContext";

import "./MainContents.scss";

import { Route } from "react-router-dom";

/**
 * MainContents component.
 * This component controls the main routing for the application.
 * It consists of the Routes component from 'react-router-dom' and routes to different sections of the website.
 *
 * @returns {HTMLElement} A div element with routing configuration for the main content of the website.
 */

const MainContents = () => {

	const { page, handlePageChange } = useContext(PageChangeContext);

	useEffect(() => {
		if(page === ""){
			handlePageChange("home");
		}
	}, [page]);

	return (
		<div className="main-contents">
			<RoutesWrapper>
				<Route path="/home?" element={
					<Home/>
				} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/about/*" element={<AboutController />} />
				<Route path="/resources/*" element={<ResourcesController />} />
				<Route path="/showroom/*" element={<ShowroomController />} />
				<Route path="/payments/*" element={<PaymentsController />} />
				<Route path="/gallery" element={<ImageGallery />} />
			</RoutesWrapper>
		</div>
	);
};

export default MainContents;
