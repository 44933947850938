/**
 * @file useSendAuthenticationTokenEmail.js
 */

import { useMutation } from "@apollo/client";
import { SEND_AUTHENTICATION_TOKEN_EMAIL } from "cache/Customer/mutations"; // Update the path as per your project structure

/**
 * @hook useSendAuthenticationTokenEmail
 *
 * @description
 * Custom hook that handles sending an authentication token email functionality.
 * Used to send an authentication token email to the customer.
 *
 * @returns
 * - sendAuthenticationTokenEmail: Method to execute the send authentication token email
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useSendAuthenticationTokenEmail = () => {

	// Mutation for sending the authentication token email
	const [sendAuthenticationTokenEmailMutation, { loading, error }] = useMutation(SEND_AUTHENTICATION_TOKEN_EMAIL);

	/**
	 * Send authentication token email.
	 *
	 * @param {String} email - The email of the customer.
	 * @returns {Object} - An object containing a Boolean value representing the success or failure of the email sending.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const sendAuthenticationTokenEmail = async (email) => {
		try {
			const { data } = await sendAuthenticationTokenEmailMutation({
				variables: { email }
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.sendAuthenticationTokenEmail };
		} catch (error) {
			return { error };
		}
	};

	return { sendAuthenticationTokenEmail, loading, error };

};

export default useSendAuthenticationTokenEmail;
