/**
 * @file DeleteReview.js is responsible for rendering the delete review modal
 */
import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
} from "@chakra-ui/react";

import useDeleteReview from "hooks/Review/mutations/useDeleteReview"; // Update the path as per your project structure
import ErrorMsg from "Blueprints/ErrorMsg";
/**
 * @component DeleteReview
 * @summary - Modal to delete a review
 */
const DeleteReview = ({ isOpen, onClose, review, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteReview } = useDeleteReview();

	/**
   * @function handleDeleteReview
   * 
   * @description - This function is used to delete a review from the database.
   * 
   */
	const handleDeleteReview = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await deleteReview(review._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Review</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to delete this review?</Text>
					<Text fontWeight="bold" mt={2}>This action cannot be undone.</Text>
					{errorMsg && (
						<ErrorMsg>
							{errorMsg}
						</ErrorMsg>
					)}
					<Text mt={4}>Please type <Text as="span" fontWeight="bold">DELETE</Text> to confirm.</Text>
					<Input
						mt={2}
						placeholder="DELETE"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleDeleteReview}
						isLoading={loading}
						isDisabled={confirmText !== "DELETE"}
					>
            			Delete
					</Button>
					<Button variant="ghost" onClick={onClose}>
            			Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteReview;
