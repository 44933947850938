/**
 * @fileoverview This file contains the functions to create a new admin.
 * 
 * @version 1.0
 * 
 * @description 
 * - This file contains the functions to create a new admin.
 * - ChakraUI Modal is used to create a new admin.
 * - The modal is opened when the "Add Admin" button is clicked.
 * - The modal is closed when the "Cancel" button is clicked.
 * - The modal is closed when the "Create" button is clicked.
 * - The Create button is disabled when the input fields are empty.
 * - The Create button is disabled when the input fields are invalid.
 * - The Create button is disabled when the input fields are valid.
 * - The Create button is disabled when the input fields are valid but the email already exists.
 * - The Create button is disabled when the input fields are valid but the username already exists.
 * - The Create button is disabled when the input fields are valid but the username and email already exists.
 * - The Create button is disabled when the input fields are valid but the username and email already exists.
 * 
 */

import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Collapse,
	Checkbox,
	CheckboxGroup,
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	CloseButton,
	FormErrorMessage,
	SimpleGrid,
} from "@chakra-ui/react";

import useCreateAdmin from "hooks/Admin/mutations/useCreateAdmin";

import { 
	isValidEmail,
	isValidUsername,
	isValidPassword,
} from "utils/utils";

import { ACCESS_LEVELS } from "../accessLevels";

// Path: src/components/admin/newAdminModal.js

/**
 * @component NewAdminModal
 * 
 * @brief Creates a new admin.
 * 
 * @param {*} param0 
 * @returns 
 */
const NewAdminModal = ({ isOpen, onClose, adminsRefetch }) => {

	const [showPermissions, setShowPermissions] = useState(false);
	const initialRef = React.useRef();

	const [input, setInput] = useState({
		email: "",
		username: "",
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "",
		accessLevel: [],
	});
	
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const { create } = useCreateAdmin();

	const updateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
	};

	const updatePermissions = (e) => {
		const permission = e.target.name;
		const newAccessLevels = input.accessLevel.includes(permission) ? input.accessLevel.filter(perm => perm !== permission) : [...input.accessLevel, permission];
		setInput({
			...input,
			accessLevel: newAccessLevels,
		});
	};

	const toggleAllPermissions = () => {
		const newAccessLevels = input.accessLevel.length === ACCESS_LEVELS.length ? [] : ACCESS_LEVELS;
		
		setInput({
			...input,
			accessLevel: newAccessLevels,
		});
	};

	const [formErrors, setFormErrors] = useState({});

	/**
	 * @function validateInput
	 * 
	 * @description
	 * Validate the user input for creating a new admin.
	 * 
	 * Improvements:
	 * 1. Instead of setting an error message directly via `setErrorMsg`, we collect all error messages in an `errors` object. 
	 *    This allows us to handle multiple validation errors at once, providing better feedback to the user.
	 * 
	 * 2. Each validation error is specifically linked to a form field, making it easier to display errors next to the respective input fields.
	 * 
	 * 3. At the end, we update the component's state with `setFormErrors(errors)` to reflect all collected errors.
	 * 
	 * 4. The function returns a boolean indicating the validity of the entire form. This can be used to control the form submission process.
	 * 
	 * @returns {boolean} - Returns true if the input is valid, otherwise false.
	 */
	const validateInput = () => {
		let errors = {};
		// Validate Email
		if (!isValidEmail(input.email)) {
		  errors.email = "Invalid Email";
		}
		// Validate Username
		if (!isValidUsername(input.username)) {
		  errors.username = "Invalid Username";
		}
		// Validate Password
		if (!isValidPassword(input.password)) {
		  errors.password = "Invalid Password";
		}
		// Validate Confirm Password
		if (input.password !== input.confirmPassword) {
		  errors.confirmPassword = "Passwords do not match";
		}
		// Validate Permissions
		if (!input.accessLevel) {
		  errors.permissions = "Invalid Permissions";
		} else if (input.accessLevel.length === 0) {
		  errors.permissions = "Please select at least one permission";
		}
		// Update the form's error state with the collected errors
		setFormErrors(errors);
		// Return the validity of the form as a boolean
		return Object.keys(errors).length === 0;
	};

	const handleCreate = async (e) => {
		e.preventDefault();
		setLoading(true);
		setErrorMsg(null);

		if(!validateInput()) {
			setLoading(false);
			return;
		}

		let createAdminInput = {
			email: input.email,
			username: input.username,
			firstName: input.firstName,
			lastName: input.lastName,
			password: input.password,
			accessLevel: input.accessLevel,
		};
		
	
		let response = await create(createAdminInput);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			setLoading(false);
			await adminsRefetch();
			onClose();	
		}
		
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			initialFocusRef={initialRef}
			isCentered
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create New Admin</ModalHeader>
				<ModalCloseButton />
				<ModalBody
					maxHeight="90%"
					overflowY="auto"
				>
					<Stack spacing={4}>
						<FormControl 
							id="email" 
							isRequired
							isInvalid={formErrors.email}
						>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								placeholder="Email"
								value={input.email}
								name="email"
								onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
							<FormErrorMessage>{formErrors.email}</FormErrorMessage>
						</FormControl>
						<FormControl id="username" isRequired isInvalid={formErrors.username}>
							<FormLabel>Username</FormLabel>
							<Input
								type="text"
								placeholder="Username"
								value={input.username}
								name="username"
								    onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
							<FormErrorMessage>{formErrors.username}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="firstName" 
							isRequired
							isInvalid={formErrors.firstName}
						>
							<FormLabel>First Name</FormLabel>
							<Input
								type="text"
								placeholder="First Name"
								value={input.firstName}
								name="firstName"
								    onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
							<FormErrorMessage>{formErrors.firstName}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="lastName" 
							isRequired
							isInvalid={formErrors.lastName}
						>
							<FormLabel>Last Name</FormLabel>
							<Input
								type="text"
								placeholder="Last Name"
								value={input.lastName}
								name="lastName"
								    onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
							<FormErrorMessage>{formErrors.lastName}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="password" 
							isRequired
						>
							<FormLabel>Password</FormLabel>
							<Input
								type="password"
								placeholder="Password"
								value={input.password}
								name="password"
								    onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
						</FormControl>
						<FormControl 
							id="confirmPassword" 
							isRequired
						>
							<FormLabel>Confirm Password</FormLabel>
							<Input
								type="password"
								placeholder="Confirm Password"
								value={input.confirmPassword}
								name="confirmPassword"
								    onChange={(e) => {
									updateInput(e);
									validateInput();
								}}
							/>
						</FormControl>
						<Button
							variant="primary"
							mt={4}
							onClick={() => setShowPermissions(!showPermissions)}
						>
							Access Level
						</Button>
						<Collapse in={showPermissions} animateOpacity>
							<Stack 
								spacing={4}
								px="1rem"	
							>
								<Button
									variant="admin_inverse"
									mt={4}
									onClick={toggleAllPermissions}
								>
									Toggle All Access Levels
								</Button>
								<CheckboxGroup defaultValue={[]}>
									<SimpleGrid
										minChildWidth="40%"
										spacing={5}
									>
										{
											ACCESS_LEVELS.map((category, idx) => {
												return(
													<Checkbox 
														variant="primary"
														key={idx}
														name={category}
														id={category}
														onChange={updatePermissions}
														isChecked={input.accessLevel.includes(category)}	
													>
														{category.charAt(0).toUpperCase() + category.slice(1)}
													</Checkbox>
												);
											})
										}
									</SimpleGrid>
								</CheckboxGroup>
							</Stack>
						</Collapse>
					</Stack>
					{
						errorMsg && (
							<Alert status="error" mt={4}>
								<AlertIcon />
								<AlertTitle mr={2}>Error!</AlertTitle>
								<AlertDescription>{errorMsg}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setErrorMsg(null)}
								/>
							</Alert>
						)
					}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						onClick={handleCreate}
						isLoading={loading}
						loadingText="Creating Admin"
					>
                        Create
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default NewAdminModal;
