/**
 * @file queries.js is a file for all GalleryImage queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_GALLERY_IMAGES - GraphQL query to get all gallery images.
 *
 * @summary
 * - This query will return an array of gallery images.
 * 
 * @returns
 * - _id: ID
 * - title: String
 * - description: String
 * - image: String
 * - createdAt: String
 * - updatedAt: String
 */
export const GET_GALLERY_IMAGES = gql`
	query getGalleryImages {
		getGalleryImages {
			_id
			title
			description
			image
			order
		}
	}
`;
