/**
 * @file Invoices.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
} from "@chakra-ui/react";

import InvoiceTable from "./Table";

import CreateInvoice from "./modals/CreateInvoice";

import useGetAllInvoices from "hooks/Invoice/queries/useGetAllInvoices";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { displayDollarAmount } from "utils/utils";

import { FooterContainer } from "../../components/TableContainer";

const Invoices = () => {

	const { loading, error, data, refetch: invoicesRefetch } = useGetAllInvoices();

	const [invoices, setInvoices] = useState(data);

	useEffect(() => {
		if(data) {
			setInvoices(data);
		}
	}, [data]);

	const [showCreateInvoice, setShowCreateInvoice] = useState(false);
	const openCreateInvoiceModal = () => setShowCreateInvoice(true);
	const closeCreateInvoiceModal = () => setShowCreateInvoice(false);

	const sort = (field, order) => {
		let sortedInvoices = [...invoices];
		sortedInvoices.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if(field === "customer"){
				a_value = a_value.name;
				b_value = b_value.name;
			}
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setInvoices(sortedInvoices);
	};
	
	const search = (field, searchInput) => {
		let filteredInvoices = new Array();
		for (let i = 0; i < data.length; i++) {
			let value = data[i][field];
			if(field === "customer"){
				value = value.name;
			}
			if(field === "total_amount"){
				value = displayDollarAmount(value);
			}
			if (value.toLowerCase().includes(searchInput.toLowerCase())) {
				filteredInvoices.push(data[i]);
			}
		}
		setInvoices(filteredInvoices);
	};

	const [clearToggle, setClearToggle] = useState(false);

	const clearFilters = () => {
		setInvoices(data);
		setClearToggle(!clearToggle);
	};

	const [currentItems, setCurrentItems] = useState([]);

	return (
		<Flex
			flexDir="column"
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={invoices}
			>
				<InvoiceTable
					invoices={currentItems}
					invoicesRefetch={invoicesRefetch}
					sort={sort}
					search={search}
					clearToggle={clearToggle}
				/>
				<FooterContainer>
					<Pagination
						items={invoices}
						setItems={setCurrentItems}
					/>
					{
						(invoices?.length !== data?.length) &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Button
						variant="primary"
						onClick={openCreateInvoiceModal}
					>
						Create Invoice
					</Button>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<CreateInvoice
				isOpen={showCreateInvoice}
				onClose={closeCreateInvoiceModal}
				invoicesRefetch={invoicesRefetch}
			/>
		</Flex>
	);
};

export default Invoices;