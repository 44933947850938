/**
 * @file ForgotPasswordModal.js
 */
import React, { useState } from "react";

import useRequestAdminPasswordResetEmail from "hooks/Admin/mutations/useRequestAdminPasswordResetEmail";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	Text,
	ButtonGroup,
	Flex
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

const ForgotPasswordModal = ({ isOpen, onClose }) => {

	const [email, setEmail] = useState("");

	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const { requestPasswordReset } = useRequestAdminPasswordResetEmail();

	const handleRequestPasswordReset = async () => {
		setError(null);
		setLoading(true);
		const { error } = await requestPasswordReset(email);
		if(error){
			setError(error.message);
		}
		else{
			setSuccess(true);
		}
		setLoading(false);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Forgot Password</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{success ? (
						<Text>
							An email has been sent to {email} with instructions on how to reset your password.
						</Text>
					) : (
						<Flex
							w="100%"
							h="100%"
							direction="column"
							justifyContent="center"
							alignItems="center"
							rowGap={3}
						>
							<Text>
								Enter your email address below and we'll send you a link to reset your password.
							</Text>
							<FormControl mt={4}>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input
									id="email"
									type="email"
									name="email"
									placeholder="Enter your email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							{error && 
								<ErrorMsg>
									{error}
								</ErrorMsg>
							}
						</Flex>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<Button
							variant="primary"
							onClick={handleRequestPasswordReset}
							isLoading={loading}
							loadingText="Sending..."
							isDisabled={success || !email}
						>
							Send
						</Button>
						<Button variant="text" onClick={onClose}>
							Cancel
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ForgotPasswordModal;
