/**
 * @file List.js
 * @description 
 * - This component displays a vertical list of items, each with a heading, icon, and text. 
 * - It is designed to be reusable and adaptable for various content types.
 * @author Webify Services, LTD.
 */
import React from "react";
import { VStack, HStack, Text, Icon, Heading } from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";

/**
 * Renders a vertical list with optional header, description, and multiple items.
 * @param {Object} props - The component props.
 * @param {string} props.header - The header text for the list.
 * @param {string} props.description - A brief description or introductory text for the list.
 * @param {Array<Object>} props.items - An array of item objects to display in the list.
 * @returns {JSX.Element} The List component with dynamically rendered items.
 */
const List = ({ header, description, items }) => {

	const defaultIconColor = "#EF3737";

	return (
		<VStack align="stretch" spacing={4}>
			{header && (
				<>
					<Heading
						size={{
							base: "md",
							md: "lg",
							lg: "xl",
						}}
						mb={{
							base: "2",
							md: "4",
						}}
						textAlign="start"
						px={{
							base: "4",
							md: "4",
						}}
					>
						{header}
					</Heading>
					<Text
						fontSize={{
							base: "sm",
							md: "lg",
						}}
						textAlign="start"
						mb={4}
						px={{
							base: "4",
							md: "2",
						}}
					>
						{description}
					</Text>
				</>
			)}
			{items.map((item, index) => (
				<HStack 
					key={index} 
					spacing={4} 
					align="start"
					px={{
						base: "4",
						md: "12",
					}}
				>
					<Icon 
						as={item.icon || MdCheckCircle} 
						color={item.iconColor || defaultIconColor}
						w={6} 
						h={6}
					/>
					<VStack align="start">
						<Text fontSize={{ base: "md", md: "lg" }} fontWeight="medium">{item.title}</Text>
						<Text color="gray.600" fontSize={{ base: "sm", md: "md" }}>{item.description}</Text>
					</VStack>
				</HStack>
			))}
		</VStack>
	);
};

export default List;
