/**
 * @file useUploadImage.js
 */
import useGetS3Url from "./s3/mutations/useGetS3Url";

/**
 * @hook useUploadImage
 *
 * @description
 * Custom hook that handles image upload functionality.
 *
 * @returns
 * - uploadImage function
 */
const useUploadImage = () => {

	const { getS3Url } = useGetS3Url();

	const uploadImage = async (imageFile) => {
		let image_url = "";

		let response = await getS3Url(false);
		if(response.error){
			throw new Error(response.error);
		}
		if (response.url) {
			let url = response.url;
			const result = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				body: imageFile,
			});
			if(!result.ok){
				throw new Error("Image upload failed");
			}
			image_url = url.split("?")[0];
		}

		return image_url;
	};

	return uploadImage;
};

export default useUploadImage;