/**
 * @file TextCell.js
 * 
 * @description
 * - Generic cell used to render text in a table
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	IconButton,
	SimpleGrid,
	Text,
	Collapse,
	Input,
	InputGroup,
} from "@chakra-ui/react";

import {
	ArrowDownIcon,
	ArrowUpIcon,
	UpDownIcon,
} from "@chakra-ui/icons";

/**
 * @component TextCell
 * 
 * @description
 * - Generic cell used to render text in a table
 * - Will truncate overflow to fit in cell and show ellipsis
 */
export const TextCell = ({ heading, sort, search, children, clearToggle, ...props }) => {

	const [sortOrder, setSortOrder] = useState("none");

	const [expandSearch, setExpandSearch] = useState(false);
	const handleExpandSearch = () => setExpandSearch(!expandSearch);

	const [searchInput, setSearchInput] = useState("");


	useEffect(() => {
		//When component rerenders, reset sort order and clear search input
		setSortOrder("none");
		setSearchInput("");
		setExpandSearch(false);
	}, [clearToggle]);

	const handleSort = () => {
		if (sortOrder === "none") {
			setSortOrder("asc");
			sort("asc");
		} else if (sortOrder === "asc") {
			setSortOrder("desc");
			sort("desc");
		} else {
			setSortOrder("none");
			sort("none");
		}
	};

	const getIcon = () => {
		if (sortOrder === "none") {
			return <UpDownIcon />;
		} else if (sortOrder === "asc") {
			return <ArrowUpIcon />;
		} else {
			return <ArrowDownIcon />;
		}
	};

	const handleSearch = (input) => {
		search(input);
	};

	return (
		<Flex
			flexDir="column"
			alignItems={{
				base: "center",
				sm: "flex-start"
			}}
			justify={{
				base: "center",
				sm: "flex-start"
			}}
			w="100%"
			minH="100%"
			overflow="hidden"
			{...props}
		>
			<Flex
				dir="row"
				align="center"
				justify={{
					base: "center",
					sm: "flex-start"
				}}
				w="100%"
				h="100%"
				overflow="hidden"
				p={2}
				{...props}
			>
				<Text
					whiteSpace="nowrap"
					overflow="hidden"
					textOverflow="ellipsis"
					onClick={() => {if(heading && search) handleExpandSearch();}}
					cursor={heading && search ? "pointer" : "default"}
				>
					{children}
				</Text>
				{
					heading &&
				<IconButton
					aria-label="Sort"
					icon={getIcon()}
					onClick={handleSort}
					ml={2}
					variant="link"
					size="sm"
				/>
				}
			</Flex>
			{
				heading &&
				<Collapse 
					in={expandSearch} 
					animateOpacity
					style={{
						width: "75%",
					}}
					transition={"all 0.2s cubic-bezier(.08,.52,.52,1)"}
				>
					<InputGroup
						size="sm"
						w="100%"
						mt={2}
					>
						<Input
							placeholder="Search"
							variant="outline"
							value={searchInput}
							onChange={(event) => {
								setSearchInput(event.target.value);
								handleSearch(event.target.value);
							}}
						/>
					</InputGroup>
				</Collapse>
			}
		</Flex>
		
	);

};

/**
 * @component TableCell
 * 
 * @description
 * - Generic cell used to render any children in a table
 */
export const TableCell = ({ children, ...props }) => {
	
	return (
		<Flex
			dir="row"
			align="center"
			justify="flex-start"
			w="100%"
			overflow="hidden"
			p={2}
			{...props}
		>
			<SimpleGrid
				w="100%"
				minChildWidth={{
					base: "50%",
					md: "100%",
					xl: "50%"
				}}
			>
				{children}
			</SimpleGrid>
		</Flex>
	);

};
