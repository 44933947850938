/**
 * @file CreateProductModal.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

// Chakra UI imports 
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Divider,
	Input,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	Stack, 
	Checkbox,
	Flex,
	Select,
	IconButton,
	Text,
	useToast
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

import useCreateProduct from "hooks/Product/mutations/useCreateProduct";

import CategoryDropdown from "../components/CategoryDropdown";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";

import useGetAllPartners from "hooks/Partner/queries/useGetAllPartners";
import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import VariantInput from "../components/VariantInput";

import useUploadImage from "hooks/useUploadImage";

const CreateProduct = ({ isOpen, onClose, refetch }) => {

	const { loading: partnersLoading, error: partnersError, data: partners } = useGetAllPartners();

	const [selectedCategory, setSelectedCategory] = useState(null);

	const handleCategoryChange = (selected) => {
		if(selected) setSelectedCategory(selected);
	};

	const toast = useToast();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [images, setImages] = useState("");
	const [variations, setVariations] = useState([]);
	// You will need to add more state variables for each field in your product form
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [partner, setPartner] = useState("");

	const [variants, setVariants] = useState({});

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if(!name){
			errors.name = "Name is required";
		}
		if(!description){
			errors.description = "Description is required";
		}
		if(!selectedCategory){
			errors.category = "Category is required";
		}
		if(!images || images.length === 0){
			errors.images = "Please upload at least one image";
		}
		if(!partner){
			errors.partner = "Partner is required";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const handlePartnerChange = (e) => {
		setPartner(e.target.value);
	};

	const handleAddVariation = (e) => {
		e.preventDefault();
		setVariations([...variations, {
			sku: "",
			isDefaultVariation: false,
			price: {},
		}]);
	};

	const handlePriceChange = (index, key, value) => {
		let newVariations = [...variations];
		if (!newVariations[index].price) {
		  newVariations[index].price = {};
		}
		newVariations[index].price[key] = parseFloat(value) || 0;
		setVariations(newVariations);
	};

	const handlePriceDelete = (index, key) => {
		let newVariations = [...variations];
		if (newVariations[index].price && newVariations[index].price[key] !== undefined) {
		  delete newVariations[index].price[key];
		}
		setVariations(newVariations);
	};
	  

	const [newPriceKey, setNewPriceKey] = useState("");
	const [newPriceValue, setNewPriceValue] = useState("");

	const uploadImage = useUploadImage();

	const { create } = useCreateProduct();
	// function to handle the submit of the form
	const onSubmit = async (e) => {
		e.preventDefault();
		setErrorMsg(null);

		//If formErrors is not empty, do not submit
		if(!validateInput()){
			toast({
				title: "Error",
				description: "Please fill out all required fields before submitting",
				status: "error",
				duration: 3000,
				isClosable: true,
				containerStyle: {
					top: 80,
					right: 20,
					backgroundColor: "red.500",
					color: "black"
				}
			});
			return;
		}

		setLoading(true);

		let image_urls = [];
		try{
			for(let i = 0; i < images.length; i++){
				const imageUrl = await uploadImage(images[i]);
				image_urls.push(imageUrl);
			}
		}
		catch(err){
			setLoading(false);
			setErrorMsg("Error uploading images. Please try again later.");
			return;
		}

		let createProductInput = {
			name: name,
			description: description,
			category: selectedCategory,
			images: image_urls,
			variants: variants,
			variations: variations,
			partner: partner
		};

		let response = await create(createProductInput);
		if(response.error){
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data){
			setLoading(false);
			await refetch();
			onClose();
			return;
		}
	};

	


	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose} 
			isCentered
			size="full"
		>
			<ModalOverlay />
			<ModalContent
				height="100%"
				maxHeight="100vh"
				overflowY="auto"
			>
				<ModalHeader
					fontSize="2xl"
					fontWeight="bold"
					px={6}
					align="center"
				>
					Create Product
				</ModalHeader>
				<ModalCloseButton />
				<form
					onSubmit={onSubmit}
				>
					<ModalBody 
						h="100%"
						overflowY="auto"
						px={14}
						mx={4}
					>
						<FormControl
							isRequired={true}
						>
							<FormLabel>Name</FormLabel>
							<Input 
								placeholder="Spruce Stockade Fence Panel" 
								value={name} 
								onChange={handleNameChange}
								borderWidth="1px"
								borderColor="gray.400"
								boxShadow="inner"
								_hover={{
									transform: "translateY(-2px)",
									transition: "all 0.2s ease-in-out",
									cursor: "pointer"
								}}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							mt={4}
						>
							<FormLabel>Description</FormLabel>
							<Input 
								placeholder="Describe your product" 
								value={description} 
								onChange={handleDescriptionChange} 
								borderWidth="1px"
								borderColor="gray.400"
								boxShadow="inner"
								_hover={{
									transform: "translateY(-2px)",
									transition: "all 0.2s ease-in-out",
									cursor: "pointer"
								}}
							/>
						</FormControl>
						<FormControl 
							mt={4}
							isInvalid={formErrors.category}
							label="Category"
						>
							<FormLabel>Category</FormLabel>
							<CategoryDropdown onChange={handleCategoryChange} />
							<FormErrorMessage>{formErrors.category}</FormErrorMessage>
						</FormControl>

						<FormControl 
							mt={4}
							isInvalid={formErrors.images}
						>
							<FormLabel>Images</FormLabel>
							<ImagesDropzone 
								imageFiles={images}
								setImageFiles={setImages}
								allowMultiple={true}
								borderWidth="1px"
								borderColor="gray.400"
								py={8}
								px={5}
							/>
							<FormErrorMessage>{formErrors.images}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="partner"
							isInvalid={formErrors.partner}
						>
							<FormLabel>Partner</FormLabel>
							<LoadingErrorDataWrapper
								loading={partnersLoading}
								error={partnersError?.message}
								data={partners}
							>
								<Select
									placeholder={"Select a Partner..."}
									value={partner}
									onChange={handlePartnerChange}
								>
									{partners?.map((partner) => (
										<option key={`createProduct${partner.name}`} value={partner.name}>
											{partner.name}
										</option>
									))}
								</Select>
							</LoadingErrorDataWrapper>
						</FormControl>
						<FormControl mt={4}>
							<FormLabel>Variants</FormLabel>
							<VariantInput 
								variants={variants}
								setVariants={setVariants}
							/>
						</FormControl>
						{/* <FormControl mt={4}>
							<FormLabel>Variation Options</FormLabel>
							<Input 
								placeholder="Variation Options" 
								value={currentVariationOption} 
								onChange={(e) => setCurrentVariationOption(e.target.value)} 
								onKeyDown={(e) => {
									if(e.key === "Enter" && currentVariationOption !== ""){
										setVariationOptions([...variationOptions, currentVariationOption]);
										setCurrentVariationOption("");
									}
								}}
								borderWidth="1px"
								borderColor="gray.400"
								boxShadow="inner"
								_hover={{
									transform: "translateY(-2px)",
									transition: "all 0.2s ease-in-out",
									cursor: "pointer"
								}}
							/>
							<HStack spacing={4} marginTop={2}>
								{
									variationOptions.map((item, index) => (
										<Tag size="md" key={index} variant="solid" colorScheme="blue">
											<TagLabel>{item}</TagLabel>
											<TagCloseButton onClick={() => {
												//Remove item from the list
												let newVariationOptions = [...variationOptions];
												newVariationOptions.splice(index, 1);
												setVariationOptions(newVariationOptions);
											}} />
										</Tag>
									))
								}
							</HStack>
						</FormControl> */}
						{/* Add other form controls here */}
						<Divider 
							my={4}
							border="1px solid #e2e8f0"
							borderBottomWidth="1px"
							borderBottomColor="gray.400"
							borderTopWidth="1px"
							borderTopColor="gray.400"
							borderLeftWidth="0px"
							borderRightWidth="0px"
							borderStyle="solid"
						/>
						<FormControl isRequired>
							<FormLabel>Variations</FormLabel>
							{
								variations.map((variation, index) => {
									return (
										<Flex key={index} 
											alignItems="center" 
											justifyContent="space-between" 
											direction="column"
											gap={"1rem"}
											borderWidth="1px"
											borderColor="gray.300"
											boxShadow="md"
											rounded="md"
											py={8}
											px={5}
											mb={4}
										>
											<Stack
												direction={["column", "row"]}
												width="100%"
											>
												<Text>Variation {index+1}</Text>
												<IconButton 
													icon={<DeleteIcon />} 
													aria-label="Delete Variation" 
													variant="ghost"
													onClick={() => {
														let newVariations = [...variations];
														newVariations.splice(index, 1);
														setVariations(newVariations);
													}}
												/>
											</Stack>
											<FormControl>
												<FormLabel>
													SKU
												</FormLabel>
												<Input
													placeholder="SKU"
													value={variation.sku}
													onChange={(e) => {
														let newVariations = [...variations];
														newVariations[index].sku = e.target.value;
														setVariations(newVariations);
													}}
													borderWidth="1px"
													borderColor="gray.400"
													boxShadow="inner"
													_hover={{
														transform: "translateY(-2px)",
														transition: "all 0.2s ease-in-out",
														cursor: "pointer"
													}}
												/>
											</FormControl>
											{/* <FormControl>
												<FormLabel>
													Price
												</FormLabel>
												<Input
													placeholder="Price"
													value={variation.price}
													onChange={(e) => {
														let newVariations = [...variations];
														let price = parseFloat(e.target.value);
														if(isNaN(price)){
															price = 0;
														}
														newVariations[index].price = price;
														setVariations(newVariations);
													}}
													borderWidth="1px"
													borderColor="gray.400"
													boxShadow="inner"
													_hover={{
														transform: "translateY(-2px)",
														transition: "all 0.2s ease-in-out",
														cursor: "pointer"
													}}
												/>
											</FormControl> */}
											<FormControl>
												<FormLabel>
    												Price
												</FormLabel>
												{Object.keys(variation.price).map((key) => (
													<Flex 
														key={key} 
														mb={2}
														gap={2}	
													>
														<Input
															flex={1}
															placeholder="Key"
															value={key}
															readOnly
														/>
														<Input
															flex={1}
															placeholder="Value"
															type="number"
															value={variation.price[key]}
															onChange={(e) => handlePriceChange(index, key, e.target.value)}
														/>
														<IconButton 
															icon={<DeleteIcon />} 
															aria-label="Delete Price" 
															variant="ghost"
															onClick={() => handlePriceDelete(index, key)}
														/>
													</Flex>
												))}
												<Flex 
													mb={2}
													gap={2}	
												>
													<Input
														flex={1}
														placeholder="New Key"
														value={newPriceKey}
														onChange={(e) => setNewPriceKey(e.target.value)}
													/>
													<Input
														flex={1}
														placeholder="New Value"
														type="number"
														value={newPriceValue}
														onChange={(e) => setNewPriceValue(e.target.value)}
													/>
												</Flex>
												{(newPriceKey !== "" && newPriceValue !== "") &&
													<Button
														variant="primary"
														onClick={() => {
															handlePriceChange(index, newPriceKey, newPriceValue);
															setNewPriceKey("");
															setNewPriceValue("");
														}}
													>
														Add Price
													</Button>
												}
											</FormControl>
											<FormControl>
												<Checkbox
													isChecked={variation.isDefaultVariation}
													onChange={(e) => {
														let newVariations = [...variations];
														newVariations[index].isDefaultVariation = e.target.checked;
														//If this is the default variation, set all other variations to not be the default variation
														if(e.target.checked){
															newVariations.forEach((variation, i) => {
																if(i !== index){
																	variation.isDefaultVariation = false;
																}
															});
														}
														setVariations(newVariations);
													}}
												>
													Default Variation?
												</Checkbox>
											</FormControl>
										</Flex>
									);
								})
							}
							<Button
								onClick={handleAddVariation}
								variant="primary"
								mt={4}
							>
								Add Variation
							</Button>
						</FormControl>
						{
							errorMsg &&
                            <Alert status="error" w="100%">
                            	<AlertIcon />
                            	<AlertDescription>{errorMsg}</AlertDescription>
                            </Alert>
						}
					</ModalBody>
					<ModalFooter>
						<Button 
							variant="primary" 
							mr={3} 
							type="submit"
							isLoading={loading}
							loadingText="Creating"
						>
                            Create
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};

export default CreateProduct;