/**
 * @file useUpdateReview.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_REVIEW } from "cache/Review/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateReview
 *
 * @description
 * Custom hook that provides the functionality to update an existing review in the Review collection.
 * This hook can be used in components that require the ability to update a review.
 *
 * @returns
 * - updateReview: Method to execute the updateReview mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useUpdateReview = () => {

	// Initialization of the Apollo Client mutation hook
	const [updateReviewMutation, { loading, error }] = useMutation(UPDATE_REVIEW);

	/**
	 * Custom mutation to update a review.
	 *
	 * @param {String} _id - The ID of the review to update.
	 * @param {Object} input - The input object containing the updated review data.
	 * @returns {Object} - An object containing a boolean indicating the success of the review update.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const updateReview = async (_id, input) => {
		try {
			const { data } = await updateReviewMutation({
				variables: {
					_id,
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.updateReview };
		} catch (error) {
			return { error };
		}
	};

	return { updateReview, loading, error };

};

export default useUpdateReview;
