/**
 * @file
 * This is the ResourcesController component.
 * It is used to control the routing for the resources section of the website.
 *
 * @summary Resources section router.
 */

import React from "react";
import { Route } from "react-router-dom";
import RoutesWrapper from "wrappers/RoutesWrapper";
import "./Resources.scss";
import ResourcesPage from "./Resources";
import Brochures from "./Brochures/Brochures";
import Gallery from "./Gallery/Gallery";

/**
 * ResourcesController component.
 * This component returns a JSX element that controls the routing for the resources section of the website.
 * It consists of Routes component from 'react-router-dom' and routes to 'ResourcesPage', 'Brochures', and 'Gallery'.
 *
 * @returns {HTMLElement} A div component with routing configuration for the resources section of the website.
 */

//Expecting URL to be www.taylorfence.com/resources{PATH}
const ResourcesController = () => {
    
	return(
		<div className="resources-wrapper">
			<RoutesWrapper>
				<Route path="/*" element={<ResourcesPage />} />
				<Route path="/brochures" element={<Brochures />} />
				<Route path="/gallery" element={<Gallery />} />
			</RoutesWrapper>
		</div>
	);

};

export default ResourcesController;