import React from "react";
// import Hero from "Blueprints/Hero";
import MotionPage from "Blueprints/MotionPage";
import FAQ from "./components/FAQ"; 
import { faqs } from "./components/data";
import { HelpCTA } from "./components/HelpCTA";

// import HelpCenter from "../../../../../assets/TaylorFenceImg/Wood_ CedarSpindle.png";

const Help = () => {
	return (
		<MotionPage>
			<div className="help">
				{/* <Hero
					title="How can we help?"
					image_url={HelpCenter}
					objectPosition="center 58%"
				/> */}
				<div>
					<HelpCTA />
				</div>
				<div
					style = {{
						width:"100%",
						marginBottom: "6rem",
					}}
				>
					<FAQ faqs={faqs} />
				</div>
			</div>
		</MotionPage>
	);
};

export default Help;
