/**
 * @file useUpdateProduct.js
 */

import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT } from "cache/Product/mutations"; // Update the path as per your project structure

/**
 * @hook useUpdateProduct
 *
 * @description
 * Custom hook that handles updating a product's information.
 *
 * @returns
 * - update function
 */
const useUpdateProduct = () => {

	const [updateProductMutation, { loading, error }] = useMutation(UPDATE_PRODUCT);

	const update = async (productId, input) => {

		try {
			const { data } = await updateProductMutation({ 
				variables: { productId: productId, input: input }
			});
			if (error) {
				throw new Error(error);
			}
			let updateProductSuccess = data.updateProduct;
			if (!updateProductSuccess) {
				throw new Error("Update failed");
			} else {
				return { success: true };
			}
		} catch (error) {
			return { error };
		}

	};

	return { update, loading, error };

};

export default useUpdateProduct;
