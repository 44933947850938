/**
 * @file ReorderCategoryProductsModal.js
 * 
 * @author Webify Services, Ltd.
 */
import React from "react";

import {
	useToast,
} from "@chakra-ui/react";


import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import ReorderModal from "components/Admin/Dashboard/components/ReorderModal";

import useGetShowroomCategory from "hooks/Category/queries/useGetShowroomCategory";
import useReorderCategoryProducts from "hooks/Category/mutations/useReorderCategoryProducts";

/**
 * @function ReorderCategoryProductsModal
 * 
 * @summary A modal that allows the user to reorder the products of a category.
 * 
 * @param {Object} props - React props
 * @param {boolean} props.isOpen - Whether or not the modal is open
 * @param {function} props.onClose - Function to close the modal
 * @param {Object} props.category - The category to reorder products for
 */
const ReorderCategoryProductsModal = ({ isOpen, onClose, category, refetch }) => {
	const { loading: productsLoading, error: productsError, data: showroomCategory } = useGetShowroomCategory(category._id);

	const { reorderCategoryProducts } = useReorderCategoryProducts();
	const toast = useToast();

	const handleReorder = async (orderedProducts) => {
		//Get an array of only _id from orderedProducts
		const productIds = orderedProducts.map((product) => product._id);
		const response = await reorderCategoryProducts(category._id, productIds);
		if (response && response.data) {
			await refetch();
			toast({
				title: "Products reordered",
				description: "The products have been reordered successfully.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			onClose();
		} else {
			toast({
				title: "Error",
				description: "There was an error reordering the products, please refresh or try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		return;
	};

	return (
		<LoadingErrorDataWrapper
			loading={productsLoading}
			error={productsError}
			data={showroomCategory}
		>
			<ReorderModal
				initialItems={showroomCategory?.products || []}
				onSubmitReorder={handleReorder}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</LoadingErrorDataWrapper>
	);
};

export default ReorderCategoryProductsModal;
