/**
 * @file useCreatePartner.js
 */

import { useMutation } from "@apollo/client";
import { CREATE_PARTNER } from "cache/Partner/mutations"; // Update the path as per your project structure

/**
 * @hook useCreatePartner
 *
 * @description
 * Custom hook that handles the functionality to create a new partner.
 * Use this hook wherever the creation of a new partner is required.
 *
 * @returns
 * - createPartner: Handler to execute the createPartner mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useCreatePartner = () => {

	const [createPartnerMutation, { loading, error }] = useMutation(CREATE_PARTNER);

	/**
	 * Custom mutation handler for creating a partner.
	 *
	 * @param {Object} input - Input object containing partner details for creation.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const createPartner = async (input) => {
		try {
			const { data } = await createPartnerMutation({
				variables: {
					input
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.createPartner };
		} catch (error) {
			return { error };
		}
	};

	return { createPartner, loading, error };

};

export default useCreatePartner;
