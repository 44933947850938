/**
 * @file CTA.js
 * @description 
 * - This component serves as a Call To Action section, featuring a title, description, an image, and an optional list of items. 
 * - It's designed to be flexible and visually engaging, suitable for various content presentations.
 */
import { Box, Flex, Heading, Image, Text, HStack } from "@chakra-ui/react";
import List from "./List";

/**
 * Renders a Call To Action section with a title, description, image, and an optional list.
 * @param {Object} props - The component properties.
 * @param {string} props.title - The title text of the CTA section.
 * @param {string} props.description - A descriptive text providing more details about the CTA.
 * @param {string} props.imageUrl - The URL of the image to be displayed in the CTA section.
 * @param {Object} props.listData - Optional data for rendering a list within the CTA.
 * @returns {JSX.Element} The CTA component featuring a flexible layout that adapts to different screen sizes.
 */
const CTA = ({ title, description, imageUrls = [], listData, maxImagesToShow }) => {
	const imagesToShow = Array.isArray(imageUrls) 
		? (maxImagesToShow ? imageUrls.slice(0, maxImagesToShow) : imageUrls)
		: [];
  
	return (
		<Box bg="bg.surface" w="100%">
			<Flex
				direction={{ base: "column", md: "row" }}
				align="center"
				justify="space-between"
				p={{ base: "4", md: "24" }} // Adjusted padding
				w="100%"
			>
				<Box flex="1" w="100%">
					<Heading size={{ base: "md", md: "lg" }}>{title}</Heading> {/* Adjusted heading size */}
					<Text
						fontSize={{ base: "md", md: "xl" }}
						color="fg.muted"
						mt="2" // Added top margin
						mb="4" // Added bottom margin
					>
						{description}
					</Text>
					{listData && <List {...listData} />}
				</Box>
				<HStack spacing="4">
					{imagesToShow.map((url, index) => (
						<Image
							key={index}
							w={{ base: "100%", xl: "800px" }} // Responsive width
							h={{ base: "auto", xl: "400px" }} // Responsive height
							objectFit="cover"
							src={url}
							borderRadius="md"
							boxShadow="lg"
							alt="CTA Image"
						/>
					))}
				</HStack>
			</Flex>
		</Box>
	);
};
  
export default CTA;

