import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";
import { 
	Box, 
	Button, 
	Flex, 
	Stack, 
	useDisclosure,
	Image, 
	Drawer, 
	DrawerBody, 
	DrawerHeader, 
	DrawerOverlay, 
	DrawerContent, 
	DrawerCloseButton, 
	IconButton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";

import TaylorFenceLogo from "../../assets/Logo/TaylorFenceLogo.png";
import { NavigateButton } from "components/common";

import "./Header.scss";

const Header = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { handlePageChange } = useContext(PageChangeContext);

	// Set the width of the search bar based on the screen size
	// const searchBarWidth = isMediumScreen ? "200px" : "100%";
	function handleNavClick(pageSelected) {
		handlePageChange(pageSelected);
		onClose();
	}

	// const handleSearch = (event) => {
	// 	const query = event.target.value;
	// 	handlePageChange("showroom/search", { term: query });
	// };

	const NavButtons = () => {
		return (
			<Flex
				alignItems="center" // Vertically align items
				justifyContent="space-between" // Push items to the start and end of the container
				w="100%"
			>
				<Stack
					direction={{
						base: "column",
						llg: "row",
					}}
					spacing={6}
					display="flex"
					justifyContent="center" // Horizontal centering
					alignItems="center"     // Vertical centering
					mx="auto"
				>
					<NavigateButton
						variant="nav"
						to="home"
						_hover={{
							textDecoration: "bold",
							transform: "translateY(-2px)",
							color: "tf_red",
							cursor: "pointer"
						}}
					>
						Home
					</NavigateButton>
					<NavigateButton
						variant="nav"
						to="showroom"
						_hover={{
							textDecoration: "bold",
							transform: "translateY(-2px)",
							color: "tf_red",
							cursor: "pointer"
						}}
					>
						Showroom
					</NavigateButton>
					<Menu>
						<MenuButton
							as={Button}
							variant="nav"
							_hover={{
								textDecoration: "bold",
								transform: "translateY(-2px)",
								color: "tf_red",
								cursor: "pointer"
							}}
							rightIcon={<ChevronDownIcon />}
						>
							About
						</MenuButton>
						<MenuList
							className="dropdown-menu"
						>
							<MenuItem 
								as={NavigateButton} 
								to="about" 
								variant="nav" 							
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								About Taylor Fence
							</MenuItem>
							<MenuItem 
								as={NavigateButton} 
								to="about/location" 
								variant="nav"
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								Location & Hours
							</MenuItem>
							<MenuItem 
								as={NavigateButton} 
								to="about/help" 
								variant="nav"
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								Help Center
							</MenuItem>
							<MenuItem  
								as={NavigateButton} 
								to="about/testimonials"
								variant="nav"
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								Testimonials
							</MenuItem>
						</MenuList>
					</Menu>
					<Menu>
						<MenuButton
							as={Button}
							variant="nav"
							_hover={{
								textDecoration: "bold",
								transform: "translateY(-2px)",
								color: "tf_red",
								cursor: "pointer"
							}}
							rightIcon={<ChevronDownIcon />}
						>
							Resources
						</MenuButton>
						<MenuList
							className="dropdown-menu"
						>
							<MenuItem 
								as={NavigateButton}
								to="resources/partners"
								variant="nav"
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								Our Partners
							</MenuItem>
							<MenuItem 
								as={NavigateButton} 
								to="resources/brochures"
								variant="nav"
								_hover={{
									textDecoration: "bold",
									transform: "translateY(-2px)",
									color: "tf_red",
									cursor: "pointer"
								}}
							>
								Brochures
							</MenuItem>
						</MenuList>
					</Menu>
					<NavigateButton
						variant="nav"
						to="gallery"
						_hover={{
							textDecoration: "bold",
							transform: "translateY(-2px)",
							color: "tf_red",
							cursor: "pointer"
						}}
					>
						Gallery
					</NavigateButton>
					<NavigateButton
						variant="nav"
						to="contact"
						_hover={{
							textDecoration: "bold",
							transform: "translateY(-2px)",
							color: "tf_red",
							cursor: "pointer"
						}}
					>
						Contact
					</NavigateButton>
				</Stack>
			</Flex>
		);
	};

	return (
		<div className="header">
			<Flex
				alignItems="center" // Vertically align items
				justifyContent="space-between" // Push items to the start and end of the container
				w="100%"
			>
				<Box
					as="a"
					onClick={() => handleNavClick("home")}
					fontSize="lg"
					fontWeight="bold"
					position="absolute" // Absolute positioning for the logo
					left="2rem" // Position the logo to the left
					top="50%"
					transform="translateY(-50%)" // Vertically center the logo
					display={{ base: "block", md: "block" }}
					_hover={{
						cursor: "pointer"
					}}
				>
					<Image
						src={TaylorFenceLogo}
						alt="Logo"
						width={{
							base: "200px",
							xs: "210px",
							sm: "220px",
						}}
						height="auto"
					/>
				</Box>
				<Box
					display={{ base: "none", llg: "flex"}}
					flexBasis={{ base: "100%", llg: "auto" }}
					flexGrow={1}
				>
					<Flex justifyContent="center" flexGrow={1}>
						<NavButtons />
					</Flex>
				</Box>
				<Box 
					display={{
						base: "block",
						llg: "none"
					}}
					//Align all the way to right of the screen
					position="absolute"
					right="2rem"
					top="50%"
					transform="translateY(-50%)" // Vertically center the logo
				>
					<IconButton 
						onClick={onOpen} 
						icon={<HamburgerIcon />}
						color="dark_gray"
						bg="gray.200"
						borderColor="gray.300"
						borderWidth="1px"
						boxShadow="base"
						aria-label="Open Menu"
					/>
				</Box>
			</Flex>
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
			>
				<DrawerOverlay>
					<DrawerContent>
						<DrawerCloseButton 
							color="tf_red"
							display="flex"
							mt={1}
						/>
						<DrawerHeader
							fontSize="lg"
							fontWeight="bold"
							display="flex"
							color="tf_red"
						>
								Menu
						</DrawerHeader>

						<DrawerBody
							mb={4}
						>
							<NavButtons />
						</DrawerBody>

					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		</div>
	
	);
};

export default Header;
