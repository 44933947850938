/**
 * @file
 * This file defines the ScaleContext which is used to manage scaling of values according to the current breakpoint.
 *
 * @summary ScaleContext definition.
 */

import React, { createContext } from "react";

import {
	useBreakpointValue
} from "@chakra-ui/react";

import { breakpoints } from "chakraStyles";

/**
 * The context is initialized here and the context object is exported so it can be used by other components.
 */
export const ScaleContext = createContext();

/**
 * This context provider is used to provide the function to get scaled value to components further down the tree.
 *
 * @param {object} props The properties passed to the context provider.
 * @param {React.ReactNode} props.children The children components to be rendered.
 */
export const ScaleProvider = ({ children }) => {

	//sm breakpoint is going to be '1'
	//Find the value for sm from breakpoints and convert to number out of 'XXem' format

	const sm = parseInt(breakpoints.sm.slice(0, -2));

	// Breakpoint keys
	const breakpointKeys = Object.keys(breakpoints);
	// Breakpoint values
	let breakpointScale = {};
	for(let i = 0; i < breakpointKeys.length; i++){
		let currentScale = ((parseInt(breakpoints[breakpointKeys[i]].slice(0, -2))/sm));
		if(currentScale > 1){
			currentScale = currentScale - 1;
		}
		else{
			currentScale = 0;
		}
		breakpointScale[breakpointKeys[i]] = currentScale;
	}

	const scale = useBreakpointValue(breakpointScale);

	/**
     * Function to get the scaled value of a number, according to the current breakpoint
     * 
     * @param {Number} value The value to be scaled 
     * @param {Number} multiplier The multiplier to be applied to the scaled value, i.e. 0.5 would only scale the number by half of the breakpoint scale. Default is 1.
     * @returns {Number} The scaled value
     */
	function getScaledValue(value, multiplier = 1){
		return value * (1 + (scale * multiplier));
	}


	/**
     * The provider component that wraps the children and provides the function to get scaled value.
     */
	return (
		<ScaleContext.Provider value={{getScaledValue}}>
			{children}
		</ScaleContext.Provider>
	);
};
