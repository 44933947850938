/**
 * @file SendInvoice.js is responsible for rendering the send invoice modal
 */
import React, { useState } from "react";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useSendInvoice from "hooks/Invoice/mutations/useSendInvoice";

/**
 * @function SendInvoice
 * 
 * @summary - Modal to send an invoice via email
 */
const SendInvoice = ({ isOpen, onClose, invoice, refetch }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const { sendInvoice } = useSendInvoice();

	/**
	 * @function handleSendInvoice
	 * 
	 * @description - This function is used to send an invoice via email.
	 */
	const handleSendInvoice = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await sendInvoice(invoice._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		refetch();
		setLoading(false);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Send Invoice</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text mb={3}>
						Do you want to resend an email containing the invoice to the customer?
					</Text>
					{errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
				</ModalBody>

				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						isLoading={loading}
						onClick={handleSendInvoice}
					>
						Resend Invoice
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default SendInvoice;
