/**
 * @file CreatePartner.js
 * 
 * @description This module exports the CreatePartner component.
 * 
 * @exports CreatePartner
 */
import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useToast,
	Select
} from "@chakra-ui/react";

import useCreatePartner from "hooks/Partner/mutations/useCreatePartner";
import useUploadImage from "hooks/useUploadImage";

import useGetAllBrochures from "hooks/Brochure/queries/useGetAllBrochures";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";
import ErrorMsg from "Blueprints/ErrorMsg";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

/**
 * @function CreatePartner
 * 
 * @description A modal component that allows the user to create a new partner.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Determines whether the modal is open or not.
 * @param {function} props.onClose - The function to close the modal.
 * @param {function} props.refetch - The function to refetch the data.
 * 
 * @returns {JSX.Element} The CreatePartner component.
 */
const CreatePartner = ({ isOpen, onClose, refetch }) => {

	const { loading: loadingBrochures, error: errorBrochures, data: brochures } = useGetAllBrochures();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [name, setName] = useState("");
	const [image, setImage] = useState([]);
	const [images, setImages] = useState([]);
	const [websiteUrl, setWebsiteUrl] = useState("");
	const [selectedBrochures, setSelectedBrochures] = useState([]);

	const handleSetImage = (imageFiles) => {
		setImage(imageFiles);
	};

	const handleAddBrochure = (e) => {
		//Add the current selected brochure to the selectedBrochures array
		const brochure = brochures.find(brochure => brochure._id === e.target.value);
		if(!brochure) return;
		if(selectedBrochures.find(selectedBrochure => selectedBrochure._id === brochure._id)) return;
		setSelectedBrochures(
			[...selectedBrochures, brochure]
		);
	};

	const handleRemoveBrochure = (removedBrochure) => {
		//Remove the current selected brochure from the selectedBrochures array
		setSelectedBrochures(
			selectedBrochures.filter(brochure => brochure._id !== removedBrochure._id)
		);
	};

	const handleClose = () => {
		setName("");
		setImage([]);
		setWebsiteUrl("");
		setSelectedBrochures([]);
		setError(null);
		setLoading(false);
		onClose();
	};

	const uploadImage = useUploadImage();
	const { createPartner } = useCreatePartner();

	const toast = useToast();

	const handleCreate = async (e) => {
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_url;
		try{
			image_url = await uploadImage(image[0]);
		} 
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading image. Please try again.");
			return;
		}

		let image_urls = [];
		try {
			for(let i = 0; i < images.length; i++){
				const image_url = await uploadImage(images[i]);
				image_urls.push(image_url);
			}
		}
		catch(error){
			setLoading(false);
			setError("Unexpected error while uploading images. Please try again.");
			return;
		}

		const selectedBrochureIds = selectedBrochures.map(brochure => brochure._id);


		const input = {
			name,
			image: image_url,
			images: image_urls,
			website: websiteUrl,
			brochures: selectedBrochureIds
		};

		const { data, error } = await createPartner(input);
		if(error){
			setLoading(false);
			setError(error.message);
			return;
		}
		if(data){
			setLoading(false);
			toast({
				title: "Partner created.",
				description: "Partner successfully created.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			handleClose();
			refetch();
			return;
		}
		setError("Unexpected error while creating partner. Please try again.");
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!name) {
			errors.name = "Name is required.";
		}
		if (!image || image.length === 0) {
			errors.image = "Image is required.";
		}
		if (!websiteUrl) {
			errors.websiteUrl = "Website URL is required.";
		}
		setFormErrors(errors);
		//Return true if errors has no keys
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create Partner</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="image" 
							isRequired
							isInvalid={formErrors.image}
						>
							<FormLabel>Logo</FormLabel>
							<ImagesDropzone
								imageFiles={image}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="images" 
						>
							<FormLabel>Images</FormLabel>
							<ImagesDropzone
								imageFiles={images}
								setImageFiles={setImages}
								allowMultiple={true}
							/>
						</FormControl>
						<FormControl 
							id="websiteUrl" 
							isRequired
							isInvalid={formErrors.websiteUrl}
						>
							<FormLabel>Website URL</FormLabel>
							<Input
								type="text"
								value={websiteUrl}
								onChange={(e) => setWebsiteUrl(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.websiteUrl}</FormErrorMessage>
						</FormControl>
						<FormControl>
							<FormLabel>Associated Brochures</FormLabel>
							<LoadingErrorDataWrapper
								loading={loadingBrochures}
								error={errorBrochures?.message}
								data={brochures}
							>
								<Select
									placeholder="Select brochures"
									onChange={handleAddBrochure}
									//Value should always be null so that the placeholder is shown
									value={"Select brochures"}
								>
									{brochures?.map(brochure => (
										<option key={`dropdown${brochure._id}`} value={brochure._id}>
											{brochure.name}
										</option>
									))}
								</Select>
							</LoadingErrorDataWrapper>
							<Stack
								direction="row"
								flexWrap="wrap"
								justify="flex-start"
								align="center"
								spacing={4}
								mt={2}
							>
								{selectedBrochures?.map(brochure => (
									<Button
										key={`selected${brochure._id}`}
										variant="primary"
										onClick={() => handleRemoveBrochure(brochure)}
									>
										{brochure.name}
									</Button>
								))}
							</Stack>
						</FormControl>
						{
							error &&
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							onClick={handleCreate}
							variant="primary"
							isLoading={loading}
							loadingText="Creating..."
						>
							Create
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default CreatePartner;
