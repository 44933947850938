/**
 * @file queries.js is a file for all Review queries
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_ALL_REVIEWS
 * 
 * @summary
 * - This query will return an array of review objects.
 */
export const GET_ALL_REVIEWS = gql`
	query getAllReviews {
		getAllReviews {
			_id
			author
			reviewDate
			title
			comment
			rating
			order
		}
	}
`;

/**
 * @const {Object} GET_ONE_REVIEW
 * 
 * @summary
 * - This query will return a review object.
 */
export const GET_ONE_REVIEW = gql`
	query getOneReview {
		getOneReview {
			_id
			author
			reviewDate
			title
			comment
			rating
			order
		}
	}
`;

