import React, { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import { 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton, 
	Center,
	Link,
	Button,
	Image,
	Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionImage = motion(Image);

export const PartnersModal = ({isOpen, onClose, currentPartner }) => {

	const { handlePageChange } = useContext(PageChangeContext);

	const redirectToPartner = () => {
		handlePageChange(`showroom/partner/${currentPartner._id}`);
	};

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={onClose}
			size="xl"
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				w="100%"
			>
				<ModalCloseButton />
				<ModalBody
					w="100%"
				>
					<Center
						w="100%"
						p="2.5rem"
					>
						<Link 
							href={currentPartner?.website} 
							isExternal
							w="100%"	
						>
							<MotionImage
								src={currentPartner?.image}
								alt="logo"
								fit="cover"
								w="100%"
								transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
								whileHover={{ 
									scale: 1.1,
								}}
							/>
						</Link>
					</Center>
				</ModalBody>
				<ModalFooter
					display="flex"
					justifyContent="center"
					alignItems="center"
					mx={2}
					w="100%"
					h="100%"
				>
					<Flex
						w="100%"
						justifyContent="center"
						alignItems="center"
						direction="column"
						gap={4}
					>
						<Flex
							w="100%"
							justifyContent="center"
							alignItems="center"
							direction="row"
						>
							{currentPartner?.brochures && currentPartner.brochures.length > 0 && <Button 
								bg="#EC1C24"
								color="white"
								w="40%"
								onClick={redirectToPartner}
								_hover={{
									bg: "white",
									color: "#EC1C24",
									border: "1px solid gray.200",
									transform: "translateY(-2px)",
									boxShadow: "lg",
								}}
								mx={2}
							>
            					View Brochures
							</Button>}
						</Flex>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default PartnersModal;
