import React from "react";
import { Box, VStack, Heading, Image, Text } from "@chakra-ui/react";
import List from "./List"; // Adjust the import path as needed


/**
 * Renders a Call To Action section with a title above the image, followed by a description and an optional list.
 * @param {Object} props - The component properties.
 * @param {string} props.title - The title text of the CTA section.
 * @param {string} props.description - A descriptive text providing more details about the CTA.
 * @param {string} props.imageUrl - The URL of the image to be displayed in the CTA section.
 * @param {Object} props.listData - Optional data for rendering a list within the CTA.
 * @returns {JSX.Element} The CTAStacked component featuring a layout with the title above the image.
 */
const CTAStacked = ({ title, imageUrls, listData }) => {
	return (
		<Box bg="bg.surface" w="100%" px={{ base: "4", md: "24" }}>
			<VStack align="center" justify="center" spacing={4}>
				<Heading size={{ base: "md", md: "lg" }} alignSelf="start" fontWeight="medium">{title}</Heading>
				{imageUrls.map((url, index) => (
					<Image
						key={index}
						w={{ base: "auto", xl: "auto" }} // Responsive width
						h={{ base: "100%", xl: "100%" }} // Responsive height
						objectFit="cover"
						objectPosition="center"
						src={url}
						borderRadius="md"
						boxShadow="lg"
						alignSelf="start"
						alt="CTA Image"
					/>
				))}
				<Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
					{listData.description}
				</Text>
				<List {...listData} />
			</VStack>
		</Box>
	);
};
  
export default CTAStacked;
