// ProductDisplay.js
import React from "react";
import { Flex, Stack, Button } from "@chakra-ui/react";
import { TextCell, TableCell } from "components/Admin/Dashboard/components/TableCell";

const ProductDisplay = ({ product, setSelectedProduct }) => {

	const handleSetSelectedProduct = (product) => {
		setSelectedProduct(product);
	};

	return (
		<Flex
			direction="column"
			borderWidth="1px"
			borderColor="gray.400"
			borderRadius="lg"
			boxShadow="md"
			w="100%"
			px={{
				base: "1rem",
				md: "2rem",
			}}
		>
			<Stack
				direction={["column", "row"]}
				spacing={4}
				py={{
					base: "1rem",
					md: "0.75rem",
				}}
				px={{
					base: "1rem",
					md: "2rem",
				}}
			>
				<TextCell
					fontWeight="bold"
					fontSize="xl"
				>
					{product.name}
				</TextCell>
				<TableCell
					w="fit-content"
				>
					<Button
						p={5}
						variant="primary"
						onClick={() => {
							handleSetSelectedProduct(product);  
						}}
						borderRadius="md"
						borderWidth="1px"
						borderColor="gray.300"
						boxShadow="sm"
						_hover={{
							transform: "scale(1.01)",
							cursor: "pointer",
							bg: "gray.400",
						}}
					>
                        Select
					</Button>
				</TableCell>
			</Stack>
		</Flex>
	);
};

export default ProductDisplay;
