/**
 * @file useDeleteReview.js
 */

import { useMutation } from "@apollo/client";
import { DELETE_REVIEW } from "cache/Review/mutations"; // Update the path as per your project structure

/**
 * @hook useDeleteReview
 *
 * @description
 * Custom hook that provides the functionality to delete an existing review in the Review collection.
 * This hook can be used in components that require the ability to delete a review.
 *
 * @returns
 * - deleteReview: Method to execute the deleteReview mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useDeleteReview = () => {

	// Initialization of the Apollo Client mutation hook
	const [deleteReviewMutation, { loading, error }] = useMutation(DELETE_REVIEW);

	/**
	 * Custom mutation to delete a review.
	 *
	 * @param {String} _id - The ID of the review to delete.
	 * @returns {Object} - An object containing a boolean indicating the success of the review deletion.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const deleteReview = async (_id) => {
		try {
			const { data } = await deleteReviewMutation({
				variables: {
					_id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.deleteReview };
		} catch (error) {
			return { error };
		}
	};

	return { deleteReview, loading, error };

};

export default useDeleteReview;
