/**
 * @file useGetInvoicesForCustomer.js
 */

import { useQuery } from "@apollo/client";
import { GET_INVOICES_FOR_CUSTOMER } from "cache/Invoice/queries"; // Update the path as per your project structure

/**
 * @hook useGetInvoicesForCustomer
 *
 * @description
 * Custom hook that handles the GET_INVOICES_FOR_CUSTOMER query functionality.
 * This hook fetches all invoices for a specific customer using their email and token,
 * and returns them as an array of objects. It returns null if no invoices are found.
 *
 * @param {String} email - The email of the customer to get invoices for.
 * @param {String} token - The token of the customer to get invoices for.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query (array of invoices or null)
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetInvoicesForCustomer = (email, token) => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_INVOICES_FOR_CUSTOMER, {
		variables: {
			email,
			token
		}
	});

	let snaggedData = data?.getInvoicesForCustomer; // This will be the array of invoices or null

	// Handling any additional error scenarios or customization can be added here.

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetInvoicesForCustomer;
