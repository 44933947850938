/**
 * @file Pagination.js
 */

import React from "react";

import {
	Flex,
	Button, 
	Editable,
	EditableInput,
	EditablePreview,
} from "@chakra-ui/react";

import {
	ArrowBackIcon,
	ArrowForwardIcon,
} from "@chakra-ui/icons";

/**
 * @param {*} itemsPerPage
 * @param {*} totalItems
 * @param {*} paginate
 * @param {*} changeItemsPerPage
 * @returns - Pagination component
 */
const BackendPagination = ({ limit, setLimit, offset, setOffset, totalItems, hasNextPage, hasPreviousPage }) => {

	const currentPage = offset/limit + 1;

	let pageNumbers = [];

	for(let i = 1; i <= Math.ceil(totalItems / limit); i++) {
		pageNumbers.push(i);
	};

	// useEffect(() => {
	// 	//Recompute current page
	// 	setCurrentPage(offset/limit + 1);
	// }, [offset, limit]);

	const paginate = (pageNumber) => {
		setOffset((pageNumber - 1) * limit);
	};

	const handleClick = (event, number) => {
		event.preventDefault();

		// setCurrentPage(number);
		paginate(number);
	};

	const handleItemsPerPageChange = (numItems) => {
		setLimit(numItems);
	};

	// Only display 5 page options at a maximum
	if (pageNumbers.length > 5) {
		const maxLeft = currentPage - 2;
		const maxRight = currentPage + 2;
		if (maxLeft < 1) {
			pageNumbers = [1, 2, 3, 4, 5];
		} else if (maxRight > pageNumbers.length) {
			pageNumbers = [
				pageNumbers.length - 4,
				pageNumbers.length - 3,
				pageNumbers.length - 2,
				pageNumbers.length - 1,
				pageNumbers.length,
			];
		} else {
			pageNumbers = [
				currentPage - 2,
				currentPage - 1,
				currentPage,
				currentPage + 1,
				currentPage + 2,
			];
		}
	}

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			// mb={4}
			py={4}
		>
			<Button
				variant="primary2"
				isDisabled={!hasPreviousPage}
				onClick={(event) => handleClick(event, currentPage - 1)}
			>
				<ArrowBackIcon/>
			</Button>
			{
				pageNumbers.map(number => (
					<Button
						key={number}
						variant="outline"
						mx="2px"
						//Highlight the current page
						bg={currentPage === number ? "tf_red" : ""}
						color={currentPage === number ? "white" : ""}
						onClick={(event) => handleClick(event, number)}
					>
						{number}
					</Button>
				))
			}
			<Button
				variant="outline"
				isDisabled={!hasNextPage}
				onClick={(event) => handleClick(event, currentPage + 1)}
			>
				<ArrowForwardIcon/>
			</Button>
			<Editable
				ml={4}
				px="0.9rem"
				border="1px dashed gray"
				boxShadow="xl"
				rounded="md"
				value={limit}
				defaultValue={limit}
			>
				<EditablePreview />
				<EditableInput 
					value={limit}
					type="number"
					onChange={(event) => {
						try{
							let value = parseInt(event.target.value);
							if(isNaN(value)) value = 1;
							if(value < 1) value = 1;
							handleItemsPerPageChange(value);
						}
						catch(err) {
							handleItemsPerPageChange(1);
						}
					}}
				/>
			</Editable>
		</Flex>
	);
};

export default BackendPagination;
