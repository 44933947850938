/**
 * @file - DeleteCategory.js
 */

// ----- Imports ----- //
import React, { useState } from "react";

import { 
	Button, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

import useDeleteCategory from "hooks/Category/mutations/useDeleteCategory";

// ----- Exports ----- //
/**
 * @function DeleteAdminModal
 * @description - This function is used to create the modal that is used to delete an admin.
 * @returns - This function returns the JSX used to create the modal.
 */
const DeleteCategory = ({ isOpen, onClose, category, refetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { deleteCategory } = useDeleteCategory();
	/**
	 * @function handleDeleteCategory
	 * @description - This function is used to delete an category from the database.
	 * 
	 * @param {*} e - The event object
	 */
	const handleDeleteCategory = async (e) => {
		e.preventDefault();
		setErrorMsg(null);
		setLoading(true);

		let response = await deleteCategory(category._id);
		if(response.error){
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.success){
			setLoading(false);
			await refetch();
			onClose();
		}
	};

	return(
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Category</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						{`Are you sure you want to delete the category: ${category.name}?`}
					</Text>
					<Text>
						This action cannot be undone.
					</Text>
					<Text>
						Type the category's name to confirm.
					</Text>
					<Input
						placeholder="Category Name"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
					{errorMsg && (
						<Alert status="error" mt={4}>
							<AlertIcon />
							<AlertDescription>{errorMsg}</AlertDescription>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						onClick={handleDeleteCategory}
						isLoading={loading}
						isDisabled={confirmText !== category.name}
					>
						Delete Category
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>		
	);
};

export default DeleteCategory;
