/** 
 * @file mutations.js is a file for all Category mutations 
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_CATEGORY - GraphQL mutation to create a category.
 * 
 * @summary
 * - This mutation will create a new Category and return the category object if successful.
 * 
 * @param {Object} createCategoryInput - The input object for creating a category.
 * @property {String} createCategoryInput.name - The name of the category.
 * @property {String} createCategoryInput.parent - The parent of the category.
 * @property {String} createCategoryInput.child_category_label - The label for the child categories of the category.
 */
export const CREATE_CATEGORY = gql`
    mutation createCategory($input: CreateCategoryInput!) {
        createCategory(input: $input) {
			_id
            name
            child_descriptor
			child_categories
			child_partners
            products {
                _id
            }
			images
			description
            createdAt
            updatedAt
        }
    }
`;

/**
 * @const {Object} UPDATE_CATEGORY - GraphQL mutation to update a category.
 * 
 * @summary
 * - This mutation will update a Category and return the category object if successful.
 * 
 * @param {Object} updateCategoryInput - The input object for updating a category.
 * @property {String} updateCategoryInput._id - The ID of the category.
 * @property {String} updateCategoryInput.name - The name of the category.
 * @property {String} updateCategoryInput.parent - The parent of the category.
 * @property {String} updateCategoryInput.child_category_label - The label for the child categories of the category.
 */
export const UPDATE_CATEGORY = gql`
    mutation updateCategory($input: UpdateCategoryInput!) {
        updateCategory(input: $input) {
			_id
            name
            child_descriptor
			child_categories
			child_partners
            products {
                _id
            }
			images
			description
            createdAt
            updatedAt
        }
    }
`;

/**
 * @const {Object} UPDATE_CATEGORY_PARTNERS - GraphQL mutation to update a category's partners.
 * 
 * @summary
 * - This mutation will update a Category's partners and return true if successful.
 * 
 * @param {String} _id - The ID of the category.
 * @param {Array} partners - The partners of the category.
 */
export const UPDATE_CATEGORY_PARTNERS = gql`
	mutation updateCategoryPartners($_id: String!, $partners: [String]) {
		updateCategoryPartners(_id: $_id, partners: $partners)
	}
`;

/**
 * @const {Object} REORDER_CATEGORY_PRODUCTS - GraphQL mutation to reorder categories.
 * 
 * @summary
 * - This mutation will reorder categories and return true if successful.
 * 
 * @param {String} _id - The ID of the category.
 * @param {Array} productIds - The product IDs of the category, in the new order
 */
export const REORDER_CATEGORY_PRODUCTS = gql`
	mutation reorderCategoryProducts($_id: ID!, $productIds: [ID!]!) {
		reorderCategoryProducts(_id: $_id, productIds: $productIds)
	}
`;


/**
 * @const {Object} DELETE_CATEGORY - GraphQL mutation to delete a category.
 * 
 * @summary
 * - This mutation will delete a Category and return true if successful.
 * 
 * @param {String} _id - The ID of the category.
 * 
 * @returns {Boolean} - True if successful. False if not.
 */
export const DELETE_CATEGORY = gql`
    mutation deleteCategory($_id: String!) {
        deleteCategory(_id: $_id)
    }
`;
