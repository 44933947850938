/**
 * @file useGetFeaturedDeals.js
 */

import { useQuery } from "@apollo/client";
import { GET_FEATURED_DEALS } from "cache/FeaturedDeal/queries"; // Update the path as per your project structure

/**
 * @hook useGetFeaturedDeals
 *
 * @description
 * Custom hook that handles the functionality to get all featured deals.
 * This hook can be used wherever the application needs to fetch featured deals.
 *
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Result data from the query containing an array of featured deals
 * - refetch: Function to refetch the data
 * - networkStatus: Network status information
 */
const useGetFeaturedDeals = () => {
	const { loading, error, data, refetch, networkStatus } = useQuery(GET_FEATURED_DEALS);

	let featuredDeals = data?.getAllFeaturedDeals;

	return { loading, error, data: featuredDeals, refetch, networkStatus };
};

export default useGetFeaturedDeals;