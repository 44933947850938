/**
 * @file UpdateFeaturedDeal.js
 */
import React, { useState, useEffect } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormErrorMessage,
	Input,
	FormLabel,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	CloseButton,
	Textarea,
} from "@chakra-ui/react";

import useUpdateFeaturedDeal from "hooks/FeaturedDeal/mutations/useUpdateFeaturedDeal";

import { convertDateToInputFormat } from "utils/utils";

const UpdateFeaturedDeal = ({ isOpen, onClose, selectedDeal, dealsRefetch }) => {

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [deal, setDeal] = useState({
		name: selectedDeal.name,
		description: selectedDeal.description,
		expires: convertDateToInputFormat(selectedDeal.expires)
	});

	const { updateFeaturedDeal } = useUpdateFeaturedDeal();

	useEffect(() => {
		setLoading(false);
		setErrorMsg(null);
		setDeal({
			name: selectedDeal.name,
			description: selectedDeal.description,
			expires: convertDateToInputFormat(selectedDeal.expires)
		});
	}, []);

	useEffect(() => {
		setDeal({
			name: selectedDeal.name,
			description: selectedDeal.description,
			expires: convertDateToInputFormat(selectedDeal.expires)
		});
	}, [selectedDeal]);

	const handleUpdateInput = (e) => {
		setDeal({
			...deal,
			[e.target.name]: e.target.value,
		});
	}; 

	const handleUpdateDeal = async () => {
		setLoading(true);

		if(!validateInput()) return;

		setErrorMsg(null);

		let updatedDeal = {
			_id: selectedDeal._id
		};
		if(deal.name !== selectedDeal.name) updatedDeal.name = deal.name;
		if(deal.description !== selectedDeal.description) updatedDeal.description = deal.description;
		if(deal.expires !== convertDateToInputFormat(selectedDeal.expires)) updatedDeal.expires = deal.expires;

		const { success, error } = await updateFeaturedDeal(updatedDeal);
		if (error) {
			setErrorMsg(error.message);
			setLoading(false);
			return;
		} else if (success) {
			setLoading(false);
			await dealsRefetch();
			onClose();
			return;			
		}
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		let errors = {};
		if(!deal.name){
			errors.name = "Name is required";
		}
		if(!deal.description){
			errors.description = "Description is required";
		}
		if(!deal.expires){
			errors.expires = "Expiration date is required";
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};


	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Featured Deal</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{errorMsg && (
						<Alert status="error">
							<AlertIcon />
							<AlertTitle mr={2}>Error!</AlertTitle>
							<AlertDescription>{errorMsg}</AlertDescription>
							<CloseButton
								position="absolute"
								right="8px"
								top="8px"
								onClick={() => setErrorMsg(null)}
							/>
						</Alert>
					)}
					<FormControl mb="4"
						isInvalid={formErrors.name}
					>
						<FormLabel>Name</FormLabel>
						<Input
							type="text"
							name="name"
							value={deal.name}
							onChange={handleUpdateInput}
						/>
						<FormErrorMessage>{formErrors.name}</FormErrorMessage>
					</FormControl>
					<FormControl mb="4"
						isInvalid={formErrors.description}
					>
						<FormLabel>Description</FormLabel>
						<Textarea
							name="description"
							value={deal.description}
							onChange={handleUpdateInput}
						/>
						<FormErrorMessage>{formErrors.description}</FormErrorMessage>
					</FormControl>
					<FormControl mb="4"
						isInvalid={formErrors.expires}
					>
						<FormLabel>Expires</FormLabel>
						<Input
							type="date"
							name="expires"
							value={deal.expires}
							onChange={handleUpdateInput}
						/>
						<FormErrorMessage>{formErrors.expires}</FormErrorMessage>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="primary"
						isLoading={loading}
						loadingText="Updating Deal"
						onClick={handleUpdateDeal}
					>
						Update Deal
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	
};

export default UpdateFeaturedDeal;