/**
 * @file useGetChildCategories.js
 */
import { useQuery } from "@apollo/client";
import { GET_CHILD_CATEGORIES } from "cache/Category/queries"; // Update the path as per your project structure

/**
 * @hook useGetChildCategories
 *
 * @description
 * Custom hook that handles getting the child categories by a given ID.
 * Used in the Category component.
 *
 * @param {String} _id - Category ID
 * @returns
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 * - data: Child categories data
 * - refetch: Function to refetch the child categories data
 * - networkStatus: Network status information
 */
const useGetChildCategories = (_id) => {

	const { loading, error, data, refetch, networkStatus } = useQuery(GET_CHILD_CATEGORIES, {
		variables: { _id },
		notifyOnNetworkStatusChange: true
	});

	let snaggedData = data?.getChildCategories;

	return { loading, error, data: snaggedData, refetch, networkStatus };
};

export default useGetChildCategories;
