import React from "react";
import Reviews from "./components/Reviews";
import Hero from "Blueprints/Hero";
import MotionPage from "Blueprints/MotionPage";

import Testimonial from "assets/Static/Testimonials/Testimonial.webp";

import "../About.scss";

const Testimonials = () => {
	return (
		<MotionPage>
			<div
				className="testimonials"
			>
				<Hero
					title="Our Customers Experience"
					image_url={Testimonial}
					objectPosition="center 50%"
				/>
				<Reviews />
			</div>
		</MotionPage>
	);
};

export default Testimonials;