/**
 * @file UpdateBrochure.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useToast
} from "@chakra-ui/react";

import useUpdateBrochure from "hooks/Brochure/mutations/useUpdateBrochure";
import useUploadImage from "hooks/useUploadImage";
import useUploadPDF from "hooks/useUploadPDF";

import { ImagesDropzone } from "Blueprints/Dropzones/ImagesDropzone";
import { PDFDropzone } from "Blueprints/Dropzones/PDFDropzone";

import ErrorMsg from "Blueprints/ErrorMsg";

/**
 * @component UpdateBrochure
 */
const UpdateBrochure = ({ isOpen, onClose, refetch, brochure }) => {

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [name, setName] = useState(brochure.name);
	const [image, setImage] = useState([]);
	const [pdf, setPDF] = useState(null);

	const handleSetImage = (imageFiles) => {
		setImage(imageFiles);
	};

	const handleSetPDF = (pdfFile) => {
		setPDF(pdfFile);
	};

	const handleClose = () => {
		setName("");
		setImage([]);
		setPDF(null);
		setError(null);
		setLoading(false);
		onClose();
	};

	const uploadImage = useUploadImage();
	const uploadPDF = useUploadPDF();
	const { updateBrochure } = useUpdateBrochure();

	const toast = useToast();

	const handleUpdate = async (e) => {
		e.preventDefault();

		setError(null);

		if(!validateInput()) return;

		setLoading(true);

		let image_url = brochure.image;
		if (image && image.length > 0) {
			try {
				image_url = await uploadImage(image[0]);
			} catch (error) {
				setLoading(false);
				setError("Unexpected error while uploading image. Please try again.");
				return;
			}
		}

		let pdf_url = brochure.url;
		if (pdf) {
			try {
				pdf_url = await uploadPDF(pdf);
			} catch (error) {
				setLoading(false);
				setError("Unexpected error while uploading PDF. Please try again.");
				return;
			}
		}

		let input = {};
		if(name !== brochure.name){
			input.name = name;
		}
		if(image_url !== brochure.image){
			input.image = image_url;
		}
		if(pdf_url !== brochure.url){
			input.url = pdf_url;
		}
		
		// If no changes were made, close the modal
		if(Object.keys(input).length === 0){
			setLoading(false);
			toast({
				title: "No changes made.",
				description: "No changes were made to the brochure.",
				status: "info",
				duration: 5000,
				isClosable: true,
			});
			handleClose();
			return;
		};

		const { data, error } = await updateBrochure(brochure._id, input);
		if(error){
			setLoading(false);
			setError(error.message);
			return;
		}
		if(data){
			setLoading(false);
			toast({
				title: "Brochure updated.",
				description: "Brochure successfully updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			handleClose();
			refetch();
			return;
		}
		setError("Unexpected error while updating gallery image. Please try again.");
		setLoading(false);
	};

	const [formErrors, setFormErrors] = useState({});

	const validateInput = () => {
		const errors = {};
		if (!name) {
			errors.name = "Name";
		}
		setFormErrors(errors);
		//Return true if errors has no keys
		return Object.keys(errors).length === 0;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			size="xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Brochure</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						spacing={4}
					>
						<FormControl 
							id="name" 
							isRequired
							isInvalid={formErrors.name}
						>
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<FormErrorMessage>{formErrors.name}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="image" 
							isRequired
							isInvalid={formErrors.image}
						>
							<FormLabel>Image</FormLabel>
							<ImagesDropzone
								imageFiles={image}
								setImageFiles={handleSetImage}
								allowMultiple={false}
							/>
							<FormErrorMessage>{formErrors.image}</FormErrorMessage>
						</FormControl>
						<FormControl 
							id="pdf" 
							isRequired
							isInvalid={formErrors.pdf}
						>
							<FormLabel>PDF</FormLabel>
							<PDFDropzone
								pdfFile={pdf}
								setPDFFile={handleSetPDF}
							/>
							<FormErrorMessage>{formErrors.pdf}</FormErrorMessage>
						</FormControl>
						{
							error &&
							<ErrorMsg>
								{error}
							</ErrorMsg>
						}
						<Button
							onClick={handleUpdate}
							variant="primary"
							isLoading={loading}
							loadingText="Updating..."
						>
							Update
						</Button>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default UpdateBrochure;