/**
 * @file CategoryDropdown.js
 * 
 * @author Steven Secreti
 */
import React, { useState } from "react";

import {
	Select
} from "@chakra-ui/react";

import useGetCategoryTree from "hooks/Category/queries/useGetCategoryTree";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

const CategoryDropdown = (props) => {

	const { loading, error: errorMsg, data: categoryTree } = useGetCategoryTree();

	return (
		<LoadingErrorDataWrapper
			loading={loading}
			error={errorMsg}
			data={categoryTree}
		>
			{categoryTree && <RecursiveCategoryDropdown category={categoryTree.root[0]} onChange={props.onChange}/>}
		</LoadingErrorDataWrapper>
	);
};

function RecursiveCategoryDropdown({ category, onChange, depth=0 }) {
	const [selected, setSelected] = useState(category._id);

	const handleSelectChange = (e) => {
		setSelected(e.target.value);
		onChange(e.target.value);
	};

	return (
		<div style={{ paddingLeft: `${depth * 20}px` }}>
			<Select 
				onChange={handleSelectChange}
				placeholder={"Select a category..."}
				borderWidth="1px"
				borderColor="gray.300"
				boxShadow="inner"
				_hover={{
					transform: "translateY(-1px)",
					transition: "all 0.2s ease-in-out",
					cursor: "pointer"
				}}	
			>
				{/* <option value={category._id}>{category.name}</option> */}
				{category.child_categories.map((subCategory) => <option key={subCategory._id} value={subCategory._id}>{subCategory.name}</option>)}
			</Select>
			{category.child_categories.map((subCategory) => selected === subCategory._id && <RecursiveCategoryDropdown key={subCategory._id} category={subCategory} onChange={onChange} depth={depth + 1} />)}
		</div>
	);
};

export default CategoryDropdown;