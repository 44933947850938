/**
 * @file
 * This is the ControlPanel component.
 * It wraps the main sections of the application including the Header, MainContents, and Footer.
 * 
 * @summary Main wrapper for the application.
 */

import React, { useContext } from "react";
import { PageChangeContext } from "contexts/PageChangeContext";
import Header from "./Header/Header";
import { Helmet } from "react-helmet";
import MainContents from "./Pages/MainContents";
import Footer from "./Footer/Footer";

import AdminController from "./Admin/AdminController";

import { Routes, Route } from "react-router-dom";

import "./ControlPanel.scss";

/**
 * ControlPanel component.
 * This component is a main wrapper for the application, containing the Header, MainContents, and Footer components.
 * It also sets the page title using the Helmet component and the PageChangeContext.
 *
 * @returns {HTMLElement} A div element that wraps the main components of the application.
 */

const ControlPanel = () => {
  
	const { page } = useContext(PageChangeContext);

	const Website = () => {
		return (
			<>
				<Helmet>
					<title>
              Taylor Fence NJ | 
						{
							" " + page.charAt(0).toUpperCase() + page.slice(1)  
						}
					</title>
				</Helmet>
				<Header/>
				<MainContents className="main-contents"/>
				<Footer/>
			</>
		);
	};


	return (
		<div 
			className="control-panel"
		>
			<Routes>
				<Route path="/*" element={
					<Website/>
				}/>
				<Route path="/admin/*" element={
					<AdminController/>
				}/>
			</Routes>  
		</div>
	);
};

export default ControlPanel;
