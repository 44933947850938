/**
 * @file Admins.js
 */
import React, { useState, useEffect } from "react";

import {
	Flex,
	Button,
} from "@chakra-ui/react";

import Table from "./Table";

import NewAdminModal from "./Modals/NewAdminModal";

import useGetAllAdmins from "hooks/Admin/queries/useGetAllAdmins";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import Pagination from "../../components/Pagination";

import { FooterContainer } from "../../components/TableContainer";

const Admins = () => {

	const { loading, error, data, refetch: adminsRefetch } = useGetAllAdmins();

	const [admins, setAdmins] = useState(data);

	useEffect(() => {
		if(data) {
			setAdmins(data);
		}
	}, [data]);

	const [showNewAdminModal, setShowNewAdminModal] = useState(false);
	const openNewAdminModal = () => setShowNewAdminModal(true);
	const closeNewAdminModal = () => setShowNewAdminModal(false);
	
	const sort = (field, order) => {
		let sortedAdmins = [...admins];
		sortedAdmins.sort((a, b) => {
			let a_value = a[field];
			let b_value = b[field];
			if(field === "name"){
				a_value = `${a.firstName} ${a.lastName}`;
				b_value = `${b.firstName} ${b.lastName}`;
			}
			if (a_value < b_value) {
				return order === "asc" ? -1 : 1;
			}
			if (a_value > b_value) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		});
		setAdmins(sortedAdmins);
	};

	const search = (field, searchInput) => {
		let searchedAdmins = [...data];
		searchedAdmins = searchedAdmins.filter((admin) => {
			let adminValue = admin[field];
			if(field === "name"){
				adminValue = `${admin.firstName} ${admin.lastName}`;
			}
			return adminValue.toLowerCase().includes(searchInput.toLowerCase());
		});
		setAdmins(searchedAdmins);
	};

	const [clearToggle, setClearToggle] = useState(false);
	const clearFilters = () => {
		setClearToggle(!clearToggle);
		setAdmins(data);
	};

	const [currentItems, setCurrentItems] = useState([]);

	return (
		<Flex
			flexDir={"column"}
			justify="flex-start"
			align="center"
			w="100%"
			h="100%"
		>
			<LoadingErrorDataWrapper
				loading={loading}
				error={error?.message}
				data={admins}
			>
				<Table
					admins={currentItems}
					adminsRefetch={adminsRefetch} 
					sort={sort}
					search={search}
					clearToggle={clearToggle} 
				/>
				<FooterContainer>
					<Pagination
						items={admins}
						setItems={setCurrentItems}
					/>
					{
						admins?.length !== data?.length &&
						<Button
							variant="primary"
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					}
					<Button
						variant="primary"
						onClick={openNewAdminModal}
						fontSize={{
							base: "xs",
							lg: "sm",
						}}
					>
							New Admin
					</Button>
				</FooterContainer>
			</LoadingErrorDataWrapper>
			<NewAdminModal
				isOpen={showNewAdminModal}
				onClose={closeNewAdminModal}
				adminsRefetch={adminsRefetch}
			/>
		</Flex>
	);
};
	
export default Admins;