/**
 * @file useRefundInvoice.js
 */

import { useMutation } from "@apollo/client";
import { REFUND_INVOICE } from "cache/Invoice/mutations"; // Update the path as per your project structure

/**
 * @hook useRefundInvoice
 *
 * @description
 * Custom hook that handles the refundInvoice mutation functionality.
 * Use this hook when you need to refund an existing invoice.
 *
 * @returns
 * - refundInvoice: Function to execute the refundInvoice mutation
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useRefundInvoice = () => {

	// Declare the refundInvoice mutation
	const [refundMutation, { loading, error }] = useMutation(REFUND_INVOICE);

	/**
	 * Executes the refundInvoice mutation.
	 *
	 * @param {String} id - The ID of the invoice to refund.
	 * @returns {Object} - An object containing the mutation result data or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const refundInvoice = async (id) => {
		try {
			const { data } = await refundMutation({
				variables: {
					id
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Process the returned data as required
			return { data: data.refundInvoice };
		} catch (error) {
			return { error };
		}
	};

	return { refundInvoice, loading, error };

};

export default useRefundInvoice;
